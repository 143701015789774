import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const menuListStyle = {
  _dark: {
    bg: 'bgTertiary',
    boxShadow: 'joeDark'
  },
  bg: 'bgSecondary',
  borderRadius: { base: 'md', md: 'xl' },
  boxShadow: 'joeLight'
}

const baseStyle = defineStyle({
  item: {
    _dark: {
      _hover: { bg: 'joeDark.400' },
      bg: 'bgTertiary'
    },
    _hover: { bg: 'joeLight.400' },
    bg: 'bgSecondary',
    borderRadius: 'lg',
    px: 2
  },
  list: {
    ...menuListStyle,
    display: 'flex',
    flexDir: 'column',
    gap: 0.5,
    p: 2
  }
})

export const menuTheme = defineStyleConfig({
  baseStyle
})
