import {
  CustomPriceHelperABI,
  CUSTOM_PRICE_HELPER_ADDRESS,
} from "@fusionx/sdk-v2";
import useChainId from "hooks/useChainId";
import { useContractRead } from "wagmi";
import { getAddress } from "ethers/lib/utils.js";
import { useMemo } from "react";
import { BigNumber } from "ethers";

const useGetActiveBinId = (decimalPriceInWei: BigNumber, bitStep: number) => {
  const chainId = useChainId();

  // const { data: bit128Price, isLoading: isLoadingPrice } = useContractRead({
  //   abi: CustomPriceHelperABI,
  //   address: getAddress(CUSTOM_PRICE_HELPER_ADDRESS[chainId]),
  //   args: [decimalPriceInWei],
  //   chainId,
  //   functionName: "convertDecimalPriceTo128x128",
  // });

  console.log("Value to generate", decimalPriceInWei, bitStep)

  const { data: activeBinId, isLoading: isLoadingActiveId } = useContractRead({
    abi: CustomPriceHelperABI,
    address: getAddress(CUSTOM_PRICE_HELPER_ADDRESS[chainId]),
    args: [decimalPriceInWei, bitStep],
    chainId,
    functionName: "getIdFromDecimalPrice",
  });

  console.log("useGetActiveBinId", activeBinId)

  return {
    activeBinId,
    isLoading: isLoadingActiveId,
  };
};

export default useGetActiveBinId;
