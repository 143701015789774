import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, HStack, Link, VStack } from "@chakra-ui/react";
import { Currency } from "@fusionx/sdk";
import {
  LB_ROUTER_ADDRESS,
  LB_ROUTER_V21_ADDRESS,
  LiquidityDistribution,
} from "@fusionx/sdk-v2";
import { FetchBalanceResult } from "@wagmi/core";
import ApproveTokenButton from "components/ApproveTokenButton";
import CurrencyInput from "components/CurrencyInput";
import MaxButton from "components/MaxButton";
import Web3Button from "components/Web3Button";
import { BigNumber } from "ethers";
import useAddLiquidityV2, {
  UseAddLiquidityV2Props,
} from "hooks/pool/v2/useAddLiquidityV2";
import useApproveSpenderIfNeeded from "hooks/useApproveSpenderIfNeeded";
import useChainId from "hooks/useChainId";
import useCurrencyInputAmount from "hooks/useCurrencyInputAmount";
import useDebounce from "hooks/useDebounce";
import React, { useCallback, useMemo, useState } from "react";
import { getAddUniformLiquidityBatches } from "utils/getAddUniformLiquidityBatches";
import { getMaxBinPerAddLiquidityBatch } from "utils/getMaxBinPerBatch";
import { poolAddLiquidity, poolSelectDistributionShape } from "utils/measure";
import { getCurrencyAddress } from "utils/wrappedCurrency";
import { useAccount } from "wagmi";

import AddUniformLiquidityPanel from "./AddUniformLiquidityPanel";
import DistributionOptions from "./DistributionOptions";
import PriceRangeSelection from "./PriceRangeSelection";

// default distribution option
const DEFAULT_DISTRIBUTION = LiquidityDistribution.SPOT;

// calculate default bin range
const getDefaultBinRange = (
  dist: LiquidityDistribution,
  activeBinId: BigNumber | undefined
) => {
  return dist === LiquidityDistribution.SPOT && activeBinId
    ? [activeBinId.toNumber() - 5, activeBinId.toNumber() + 5]
    : undefined;
};

interface AddLiquidityPanelV2Props {
  currencyPrice0: number | undefined;
  currencyPrice1: number | undefined;
  onAddLiquiditySuccess: () => void;
  activeBinId?: BigNumber;
  balance0?: FetchBalanceResult;
  balance1?: FetchBalanceResult;
  binStep?: string;
  currency0?: Currency;
  currency1?: Currency;
}

const AddLiquidityPanelV2 = ({
  activeBinId,
  balance0,
  balance1,
  binStep,
  currency0,
  currency1,
  currencyPrice0,
  currencyPrice1,
  onAddLiquiditySuccess,
}: AddLiquidityPanelV2Props) => {
  const chainId = useChainId();
  const { isConnected } = useAccount();

  const poolName = `${currency0?.symbol}-${currency1?.symbol}-${binStep}`;

  const {
    amount: amount0,
    amountBN: amount0BN,
    setAmount: setAmount0,
  } = useCurrencyInputAmount({ currency: currency0 });
  const {
    amount: amount1,
    amountBN: amount1BN,
    setAmount: setAmount1,
  } = useCurrencyInputAmount({ currency: currency1 });

  const [distribution, setDistribution] = useState<LiquidityDistribution>(
    LiquidityDistribution.SPOT
  );

  // allow the user to toggle between price ratios
  const [inversePriceRatios, setPriceRatiosInversed] = useState(false);

  const togglePriceRatiosClick = useCallback(() => {
    setPriceRatiosInversed((prev) => !prev);
  }, []);

  // bin range
  const defaultBinRange = useMemo(
    () => getDefaultBinRange(DEFAULT_DISTRIBUTION, activeBinId),
    [activeBinId]
  );
  const [_binRange, setBinRange] = useState<number[] | undefined>();
  const binRange = useMemo(
    () => _binRange ?? defaultBinRange,
    [_binRange, defaultBinRange]
  );
  const debouncedBinRange = useDebounce(binRange, 1000);

  const isToken0Disabled = useMemo(() => {
    if (!activeBinId) {
      return false;
    }
    if (debouncedBinRange && debouncedBinRange[1] < activeBinId.toNumber()) {
      setAmount0("0");
      return true;
    }
    return false;
  }, [activeBinId, debouncedBinRange, setAmount0]);

  const isToken1Disabled = useMemo(() => {
    if (!activeBinId) {
      return false;
    }
    if (debouncedBinRange && activeBinId.toNumber() < debouncedBinRange[0]) {
      setAmount1("0");
      return true;
    }
    return false;
  }, [activeBinId, debouncedBinRange, setAmount1]);

  const tokenAddress0 = getCurrencyAddress(currency0);
  const tokenAddress1 = getCurrencyAddress(currency1);

  const isExceedingBalance0 = balance0
    ? Number(balance0.formatted) < Number(amount0)
    : false;
  const isExceedingBalance1 = balance1
    ? Number(balance1.formatted) < Number(amount1)
    : false;

  const isAddLiquidityDisabled = isExceedingBalance0 || isExceedingBalance1;

  const isPriceRangeEnabled =
    distribution === LiquidityDistribution.SPOT &&
    activeBinId &&
    currency0 &&
    currency1 &&
    binStep &&
    binRange;

  const {
    approvalType: approvalType0,
    approve: approveToken0,
    isApproved: isToken0Approved,
    isApproving: isApprovingToken0,
    setApprovalType: setApprovalType0,
  } = useApproveSpenderIfNeeded({
    amount: amount0BN,
    spender: LB_ROUTER_V21_ADDRESS[chainId],
    token: tokenAddress0,
    tokenSymbol: currency0?.symbol,
  });

  const {
    approvalType: approvalType1,
    approve: approveToken1,
    isApproved: isToken1Approved,
    isApproving: isApprovingToken1,
    setApprovalType: setApprovalType1,
  } = useApproveSpenderIfNeeded({
    amount: amount1BN,
    spender: LB_ROUTER_V21_ADDRESS[chainId],
    token: tokenAddress1,
    tokenSymbol: currency1?.symbol,
  });

  // reset inputs on add liquidity success
  const onSuccess = useCallback(() => {
    setAmount0("");
    setAmount1("");
    onAddLiquiditySuccess();
  }, [setAmount0, setAmount1, onAddLiquiditySuccess]);

  const [binPerBatch, setBinPerBatch] = useState<number>(
    getMaxBinPerAddLiquidityBatch(chainId)
  );
  const addUniformLiquidityBatches = getAddUniformLiquidityBatches(
    chainId,
    binPerBatch,
    debouncedBinRange,
    activeBinId?.toNumber(),
    currency0,
    currency1,
    amount0BN,
    amount1BN
  );

  const isActiveBinTargetAdd =
    debouncedBinRange?.length === 2 &&
    debouncedBinRange[0] === debouncedBinRange[1] &&
    debouncedBinRange[0] === activeBinId?.toNumber();

  const validAmount0 =
    (amount0BN && amount0BN.gt(0)) ||
    (amount0BN && isActiveBinTargetAdd) ||
    isToken0Disabled;
  const validAmount1 =
    (amount1BN && amount1BN.gt(0)) ||
    (amount1BN && isActiveBinTargetAdd) ||
    isToken1Disabled;
  const validAmounts = validAmount0 && validAmount1;

  const addLiquidityProps: UseAddLiquidityV2Props = {
    activeBinId,
    amount0: amount0BN,
    amount1: amount1BN,
    binRange:
      addUniformLiquidityBatches && addUniformLiquidityBatches.length === 1
        ? addUniformLiquidityBatches[0].binRange
        : undefined,
    binStep,
    currency0,
    currency1,
    enabled:
      validAmounts &&
      !isExceedingBalance0 &&
      !isExceedingBalance1 &&
      (isToken0Approved === true || !tokenAddress0) &&
      (isToken1Approved === true || !tokenAddress1),
    liquidityDistribution: distribution,
  };

  const { addLiquidity, isLoading: isAddingLiquidity } = useAddLiquidityV2({
    ...addLiquidityProps,
    onSuccess,
  });
  console.log("addLiquidity", addLiquidity);

  const hintText = useMemo(() => {
    if (!validAmount0) {
      return `Enter ${currency0?.symbol} amount`;
    }
    if (!validAmount1) {
      return `Enter ${currency1?.symbol} amount`;
    }
    if (isExceedingBalance0 || isExceedingBalance1) {
      return "Not enough balance";
    }
    return undefined;
  }, [
    currency0,
    currency1,
    isExceedingBalance0,
    isExceedingBalance1,
    validAmount0,
    validAmount1,
  ]);

  return (
    <VStack align="flex-start" spacing={12}>
      <VStack align="flex-start" w="full" spacing={2}>
        <CurrencyInput
          currency={currency0}
          currencyAddress={tokenAddress0}
          value={amount0}
          onValueChange={setAmount0}
          balance={balance0?.formatted}
          error={
            isExceedingBalance0 ? `Not enough ${currency0?.symbol}` : undefined
          }
          isDisabled={isToken0Disabled}
          rightElement={
            balance0 ? (
              <MaxButton
                balance={balance0.formatted}
                isDisabled={isToken0Disabled}
                onClick={() => setAmount0(balance0.formatted)}
              />
            ) : undefined
          }
        />
        <CurrencyInput
          currency={currency1}
          currencyAddress={tokenAddress1}
          value={amount1}
          onValueChange={setAmount1}
          balance={balance1?.formatted}
          error={
            isExceedingBalance1 ? `Not enough ${currency1?.symbol}` : undefined
          }
          isDisabled={isToken1Disabled}
          rightElement={
            balance1 ? (
              <MaxButton
                balance={balance1.formatted}
                isDisabled={isToken1Disabled}
                onClick={() => setAmount1(balance1.formatted)}
              />
            ) : undefined
          }
        />
      </VStack>
      <VStack align="flex-start" spacing={4} w="full">
        <Flex w="full" align="center" justify="space-between">
          <Heading size="md">Choose Liquidity Shape</Heading>
          <Link
            isExternal
            aria-label="Liquidity strategies for FusionX Liquidity Book"
            href="https://support.fusionx.finance/en/articles/6707938-liquidity-strategies"
            color="accent.500"
            fontSize="sm"
            fontWeight="semibold"
            flexShrink={0}
          >
            <HStack spacing={1}>
              <Box as="span">Learn more</Box>
              <ExternalLinkIcon color="accent.500" />
            </HStack>
          </Link>
        </Flex>
        <DistributionOptions
          distribution={distribution}
          onDistributionChange={(dist) => {
            setDistribution(dist);
            setBinRange(getDefaultBinRange(dist, activeBinId));
            poolSelectDistributionShape(dist.toString());
          }}
        />
      </VStack>
      {isPriceRangeEnabled ? (
        <PriceRangeSelection
          currencyPrice0={currencyPrice0}
          currencyPrice1={currencyPrice1}
          currency0={currency0}
          currency1={currency1}
          binStep={Number(binStep)}
          activeBinId={activeBinId.toNumber()}
          binRange={binRange}
          onBinRangeChange={setBinRange}
          inversePriceRatios={inversePriceRatios}
          togglePriceRatiosClick={togglePriceRatiosClick}
          resetBinRange={() => {
            setBinRange(
              getDefaultBinRange(LiquidityDistribution.SPOT, activeBinId)
            );
          }}
        />
      ) : null}
      <VStack w="full" spacing={4}>
        {!isToken0Approved &&
          !isToken0Disabled &&
          approveToken0 &&
          currency0 &&
          !isAddLiquidityDisabled ? (
          <ApproveTokenButton
            amount={amount0}
            currencySymbol={currency0.symbol}
            approvalType={approvalType0}
            onApprovalTypeSelect={setApprovalType0}
            isLoading={isApprovingToken0}
            onClick={() => approveToken0()}
          >
            {`Approve ${currency0.symbol}`}
          </ApproveTokenButton>
        ) : null}
        {!isToken1Approved &&
          !isToken1Disabled &&
          approveToken1 &&
          currency1 &&
          !isAddLiquidityDisabled ? (
          <ApproveTokenButton
            amount={amount1}
            currencySymbol={currency1.symbol}
            approvalType={approvalType1}
            onApprovalTypeSelect={setApprovalType1}
            isLoading={isApprovingToken1}
            onClick={() => approveToken1()}
          >
            {`Approve ${currency1.symbol}`}
          </ApproveTokenButton>
        ) : null}
        {binStep &&
          binRange &&
          currency0 &&
          currency1 &&
          addUniformLiquidityBatches &&
          addUniformLiquidityBatches.length > 1 &&
          isConnected ? (
          <AddUniformLiquidityPanel
            batches={addUniformLiquidityBatches}
            currency0={currency0}
            currency1={currency1}
            binRange={binRange}
            binStep={Number(binStep)}
            addLiquidityProps={addLiquidityProps}
            inversePriceRatios={inversePriceRatios}
            binPerBatch={binPerBatch}
            onAddLiquidityPrepareContractWriteError={() =>
              setBinPerBatch((old) => Math.max(40, old - 20))
            }
          />
        ) : (
          <Web3Button
            variant="primary"
            colorScheme="accent"
            size="xl"
            w="full"
            isDisabled={!addLiquidity}
            isLoading={isAddingLiquidity}
            loadingText="Adding Liquidity"
            onClick={() => {
              addLiquidity?.();
              poolAddLiquidity(poolName);
            }}
          >
            {hintText ?? "Add Liquidity"}
          </Web3Button>
        )}
      </VStack>
    </VStack>
  );
};

export default AddLiquidityPanelV2;
