import {
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useChainId from 'hooks/useChainId'
import useKeyPress from 'hooks/useKeyPress'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ArrowLeftRightIcon,
  GKeyIcon,
  KeyboardIcon,
  RefreshIcon,
  TKeyIcon,
  UKeyIcon
} from 'theme/icons'
import { getPriceFromBinId } from 'utils/bin'
import { formattedNum } from 'utils/format'
import { getMaxBinPerAddLiquidityBatch } from 'utils/getMaxBinPerBatch'

import PriceRadiusInputs from './PriceRadiusInputs'
import PriceRangeInputs from './PriceRangeInputs'

// max bins from activeBin per block
const MAX_RADIUS = 29

interface PriceRangeSelectionProps {
  activeBinId: number
  binRange: number[]
  binStep: number
  currency0: Currency
  currency1: Currency
  currencyPrice0: number | undefined
  currencyPrice1: number | undefined
  onBinRangeChange: (binRange: number[]) => void
  inversePriceRatios?: boolean
  resetBinRange?: () => void
  togglePriceRatiosClick?: () => void
}

const DEFAULT_RADIUS = '5'

const PriceRangeSelection = ({
  activeBinId,
  binRange,
  binStep,
  currency0,
  currency1,
  currencyPrice0,
  currencyPrice1,
  inversePriceRatios,
  onBinRangeChange,
  resetBinRange,
  togglePriceRatiosClick
}: PriceRangeSelectionProps) => {
  const chainId = useChainId()
  const tKeyPressed = useKeyPress('t')
  const gKeyPressed = useKeyPress('g')
  const ukeyPressed = useKeyPress('u')

  const tokenPriceExists =
    currencyPrice0 !== undefined || currencyPrice1 !== undefined

  const nbBins = binRange[1] - binRange[0]
  const largeRangeDisclaimerText =
    nbBins > getMaxBinPerAddLiquidityBatch(chainId)
      ? 'Adding liquidity to a large range requires more than one transaction.'
      : undefined

  const fmtActiveBinPrice = useMemo(() => {
    const price = Number(
      getPriceFromBinId(activeBinId, binStep, currency0, currency1, 18)
    )
    const inUSDPricing = formattedNum(
      inversePriceRatios ? currencyPrice1 || 0 : currencyPrice0 || 0,
      false,
      gKeyPressed ? 8 : 5
    )
    const standardPricing = formattedNum(
      inversePriceRatios ? 1 / price : price,
      false,
      gKeyPressed ? 8 : 5
    )
    return tokenPriceExists
      ? ukeyPressed
        ? inUSDPricing
        : standardPricing
      : standardPricing
  }, [
    inversePriceRatios,
    activeBinId,
    binStep,
    currency0,
    currency1,
    gKeyPressed,
    ukeyPressed,
    currencyPrice0,
    currencyPrice1,
    tokenPriceExists
  ])

  const _getPriceFromBinId = useCallback(
    (binId: number): string => {
      const binPrice = Number(
        getPriceFromBinId(binId, binStep, currency0, currency1, 18) ?? '0'
      )
      return (inversePriceRatios ? 1 / binPrice : binPrice).toPrecision(8)
    },
    [currency0, currency1, binStep, inversePriceRatios]
  )

  // tp and radius on parent to handle resetting on tab changes
  const [targetPrice, setTargetPrice] = useState(
    _getPriceFromBinId(activeBinId) // default at activeBinId,
  )
  const [targetBinId, setTargetBinId] = useState(activeBinId)
  const [radius, setRadius] = useState(DEFAULT_RADIUS)

  const resetRanges = () => {
    resetBinRange?.()
    setRadius(DEFAULT_RADIUS)
    setTargetPrice(_getPriceFromBinId(activeBinId))
    setTargetBinId(activeBinId)
  }

  useEffect(() => {
    if (tKeyPressed) {
      togglePriceRatiosClick?.()
    }
  }, [togglePriceRatiosClick, tKeyPressed])

  const symbol0 = inversePriceRatios ? currency1.symbol : currency0.symbol
  const symbol1 = inversePriceRatios ? currency0.symbol : currency1.symbol

  return (
    <Tabs variant="soft-rounded" w="full" onChange={() => resetRanges()}>
      <VStack align="flex-start" spacing={4} w="full" pb={4}>
        <Flex w="full" align="center" justify="space-between" flexWrap="wrap">
          <Heading size="md" mr={4} mb={2}>
            Price
          </Heading>
          <HStack spacing={0.5} mb={2}>
            <Text fontSize="sm">
              {`Current Price: 1 ${symbol0} = ${fmtActiveBinPrice} ${tokenPriceExists ? (ukeyPressed ? 'USD' : symbol1) : symbol1
                }`}
            </Text>
            <IconButton
              aria-label="toggle price ratios"
              variant="ghost"
              size="sm"
              icon={<ArrowLeftRightIcon boxSize="16px" />}
              onClick={togglePriceRatiosClick}
            />
          </HStack>
          <TabList w="full">
            <Tab
              w="full"
              _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
            >
              Price Range
            </Tab>
            <Tab
              w="full"
              _selected={{
                bg: 'joeLight.400',
                color: 'textPrimary'
              }}
            >
              Bin Radius
            </Tab>
          </TabList>
          <Button
            variant="ghost"
            size="sm"
            colorScheme="accent"
            color="accent.500"
            leftIcon={<RefreshIcon boxSize="16px" fill="accent.500" />}
            onClick={resetRanges}
            mt={2}
          >
            Reset price to default
          </Button>

          <TouchFriendlyTooltip
            bg="bgPrimary"
            borderRadius="xl"
            label={
              <Flex padding={4}>
                <VStack spacing={4} alignItems="flex-start">
                  <Flex>
                    <Text fontSize="lg" fontWeight="bold">
                      Hotkeys
                    </Text>
                  </Flex>
                  <HStack fontSize="md" alignItems="center" spacing={2}>
                    <Text>Tap</Text>
                    <TKeyIcon boxSize="28px" fill="accent.500" />
                    <Text>to toggle xToken/yToken</Text>
                  </HStack>
                  <HStack fontSize="md" alignItems="center" spacing={2}>
                    <Text>Hold</Text>
                    <GKeyIcon boxSize="28px" fill="accent.500" />
                    <Text>to display price in 8 decimals</Text>
                  </HStack>
                  <HStack fontSize="md" alignItems="center" spacing={2}>
                    <Text>Hold</Text>
                    <UKeyIcon boxSize="28px" fill="accent.500" />
                    <Text>to display price in USD</Text>
                  </HStack>
                </VStack>
              </Flex>
            }
          >
            <Flex mt={2} alignItems="center" justifyContent="center">
              <KeyboardIcon boxSize="16px" fill="accent.500" mr={2} />
              <Text fontSize="sm" color="accent.500" fontWeight="semibold">
                Hotkeys
              </Text>
            </Flex>
          </TouchFriendlyTooltip>
        </Flex>

        <TabPanels>
          <TabPanel px={0}>
            <PriceRangeInputs
              currency0={currency0}
              currency1={currency1}
              binStep={binStep}
              min={activeBinId - MAX_RADIUS}
              max={activeBinId + MAX_RADIUS}
              largeRangeDisclaimerText={largeRangeDisclaimerText}
              binRange={binRange}
              inversePriceRatios={inversePriceRatios}
              onBinRangeChange={onBinRangeChange}
              currencyPrice0={currencyPrice0}
              currencyPrice1={currencyPrice1}
            />
          </TabPanel>
          <TabPanel px={0}>
            <PriceRadiusInputs
              targetPrice={targetPrice}
              setTargetPrice={setTargetPrice}
              targetBinId={targetBinId}
              setTargetBinId={setTargetBinId}
              _getPriceFromBinId={_getPriceFromBinId}
              radius={radius}
              setRadius={setRadius}
              inversePriceRatios={inversePriceRatios}
              binStep={binStep}
              currency0={currency0}
              currency1={currency1}
              onBinRangeChange={onBinRangeChange}
              currencyPrice0={currencyPrice0}
              currencyPrice1={currencyPrice1}
            />
          </TabPanel>
        </TabPanels>
      </VStack>
    </Tabs>
  )
}

export default PriceRangeSelection
