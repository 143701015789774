import { ChainId } from '@fusionx/sdk'

export const FEE_RATE = 0.0025 // 0.25%
export const POOL_SHARE_PRECISION = 100000000

export const POOL_HELMET_TITLE =
  'FusionX | Decentralized Exchange | Pool'
export const POOL_HELMET_DESCRIPTION =
  'One-stop Decentralized Trading. Provide Liquidity. Create Pools. Add Tokens. Deep Liquidity Pools. Discover Endless Cryptocurrencies. Welcome to DeFi.'

interface RewarderInfo {
  lbPair: string
  rewardedToken: string
  rewardPerDay: number
}

export const REWARD_TOKEN: { [chainId in ChainId]: string } = {
  [ChainId.FUJI]: '0x477Fd10Db0D80eAFb773cF623B258313C3739413',
  [ChainId.ARB_GOERLI]: '0x0000000000000000000000000000000000000000',
  [ChainId.BNB_TESTNET]: '0xbcc744446474A1b01B465ebb6b069706cAae2F9d',
  [ChainId.AVALANCHE]: '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
  [ChainId.ARBITRUM_ONE]: '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
  [ChainId.BNB_CHAIN]: '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
  [ChainId.MANTLE_TESTNET]: '0x9B03d4AAb0Cbe4e800Ad0bF2F055D973f2c39a02'
}

const REWARDER_LISTS: { [chainId in ChainId]: RewarderInfo[] } = {
  [ChainId.FUJI]: [
    {
      lbPair: '0x88f36a6b0e37e78d0fb1d41b07a47bad3d995453',
      rewardPerDay: 1000,
      rewardedToken: '0x477fd10db0d80eafb773cf623b258313c3739413'
    },
    {
      lbPair: '0x96ce9f178d2a96387f21f916f7b1338310be8f4c',
      rewardPerDay: 500,
      rewardedToken: '0x477fd10db0d80eafb773cf623b258313c3739413'
    },
    {
      lbPair: '0x739e1b8a2e11413540d622fc5c91694bee4177d3',
      rewardPerDay: 300,
      rewardedToken: '0x477fd10db0d80eafb773cf623b258313c3739413'
    }
  ],
  [ChainId.AVALANCHE]: [
    {
      // GMX-AVAX-20
      lbPair: '0x294dc12a20e0018f77d8e043dad60af6777f716b',
      rewardPerDay: 0,
      rewardedToken: '0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd'
    }
  ],
  [ChainId.ARBITRUM_ONE]: [
    {
      // ETH-USDC-15
      lbPair: '0x7ec3717f70894f6d9ba0be00774610394ce006ee',
      rewardPerDay: 8928.57,
      rewardedToken: '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07'
    },
    {
      // ARB-ETH-20
      lbPair: '0xafeBf9bBa7984954e42d7551AB0cE47130BfDC0A',
      rewardPerDay: 8928.57,
      rewardedToken: '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07'
    },
    {
      // ARB-USDC-100
      lbPair: '0xF3013B72a4c70A8475fF5331D8F2937E5fAF135f',
      rewardPerDay: 3571.42,
      rewardedToken: '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07'
    },
    {
      // GMX-ETH-20
      lbPair: '0x5fbbef48ce0850e5a73bc3f4a6e903458b3c0af4',
      rewardPerDay: 0,
      rewardedToken: '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07'
    },
    {
      // MAGIC-ETH-20
      lbPair: '0xa51ee8b744e6cc1f2ac12b9eeaae8deb27619c6b',
      rewardPerDay: 0,
      rewardedToken: '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07'
    },
    {
      // RDNT-ETH-25
      lbPair: '0x5813ce0679e67ddaf0e9002939550710856c06d4',
      rewardPerDay: 0,
      rewardedToken: '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07'
    },
    {
      // JOE-ETH-20
      lbPair: '0xa0fd049466d57fc3637e27ca585d59e0ad86b902',
      rewardPerDay: 0,
      rewardedToken: '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07'
    },
    {
      // USDC-USDT-02
      lbPair: '0x13fda18516eafe5e8ae930f86fa51ae4b6c35e8f',
      rewardPerDay: 0,
      rewardedToken: '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07'
    }
  ],
  [ChainId.ARB_GOERLI]: [],
  [ChainId.BNB_CHAIN]: [],
  [ChainId.BNB_TESTNET]: [],
  [ChainId.MANTLE_TESTNET]: []
}

export const getRewarderInfo = (chainId: ChainId, lbPair: string) => {
  return REWARDER_LISTS[chainId].find(
    (ri) => ri.lbPair?.toLowerCase() === lbPair.toLowerCase()
  )
}

export const getRewardRate = (chainId: ChainId, lbPair: string) => {
  return getRewarderInfo(chainId, lbPair)?.rewardPerDay
}

export const POOL_DISTRIBUTION_CHART_RADIUS = {
  initial: 60,
  max: 400,
  min: 20,
  step: 40
}
