import {
  ChainId,
  Currency,
  CurrencyAmount,
  Token,
  TokenAmount,
  WNATIVE
} from '@fusionx/sdk'
import { ethers } from 'ethers'

export function wrappedCurrency(
  currency?: Currency,
  chainId?: ChainId
): Token | undefined {
  return currency && currency.isNative && chainId
    ? WNATIVE[chainId]
    : currency instanceof Token
      ? currency
      : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: ChainId | undefined
): TokenAmount | undefined {

  console.log("wrappedCurrencyAmount", currencyAmount)
  const token =
    chainId && currencyAmount && currencyAmount.currency.isNative
      ? WNATIVE[chainId]
      : currencyAmount && chainId
        ? wrappedCurrency(currencyAmount.currency, chainId)
        : undefined
  return token && currencyAmount
    ? new TokenAmount(token, currencyAmount.raw)
    : undefined
}

export const getCurrencyAddress = (
  currency?: Currency
): `0x${string}` | undefined => {
  return currency && currency.isToken
    ? ethers.utils.getAddress(currency.address)
    : undefined
}

export function isWrappedNativeCurrency(
  address?: string,
  chainId?: ChainId
): boolean {
  return chainId
    ? address?.toLowerCase() === WNATIVE[chainId].address.toLowerCase()
    : false
}
