import { Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import { el } from "date-fns/locale";
import useScrollTabIntoView from "hooks/useScrollTabIntoView";
import React, { useState } from "react";
import { typeBinStepConfig } from "../index";

type props = {
  binSteps: typeBinStepConfig[];
  selectedBinStep: typeBinStepConfig | undefined;
  setBinStep: (step: typeBinStepConfig) => void;
};

function SelectBinStepMenu({ binSteps, selectedBinStep, setBinStep }: props) {
  const handleChange = (nextTabIndex: number) => {
    setBinStep(binSteps.filter((element) => element.index === nextTabIndex)[0]);
  };
  const tablistRef = useScrollTabIntoView(selectedBinStep?.index ?? 0);

  return (
    <div>
      {binSteps.length > 0 && selectedBinStep ? (
        <Tabs
          w="full"
          variant="soft-rounded"
          overflowX="auto"
          justifyContent="center"
          align="center"
          sx={{
            "::-webkit-scrollbar": {
              width: "10px",
              height: '10px'
            },
            " ::-webkit-scrollbar-track": {
              "box-shadow": "inset 0 0 5px grey",
              "border-radius": "10px",
            },
            /* Handle */
            "::-webkit-scrollbar-thumb": {
              "background": "gray",
              "border-radius": "10px",
            }
          }}
          index={selectedBinStep.index}
          onChange={handleChange}
          px={2}
          m={1}
        >
          <TabList
            w="fit-content"
            h="fit-content !important"
            ref={tablistRef}
            justifyContent="space-around"
            p={2}
          >
            {binSteps.map((tag, i) => (
              <Tab
                key={tag.index}
                isDisabled={false}
                value={tag.index}
                w="max-content"
                minW="168px"
                _selected={{ bg: "joeLight.400", color: "textPrimary" }}
              >
                <div>
                  <Text color="textSecondary" fontSize="md">
                    {Number(tag.fee) / 100}%
                  </Text>
                  <Text color="textSecondary" fontSize="sm">
                    Base Fee: {Number(tag.baseFee) / 100}%
                  </Text>
                </div>
              </Tab>
            ))}
          </TabList>
        </Tabs>
      ) : null}
    </div>
  );
}

export default SelectBinStepMenu;
