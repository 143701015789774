import { FlexProps, Tag } from '@chakra-ui/react'
import PoolCard from 'components/PoolCard'
import { getRewardRate } from 'constants/pool'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Pool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailUrl } from 'utils/poolUrl'

interface PoolMobileCardProps {
  pool: Pool
  rewardPriceUSD?: number
}

const PoolMobileCard = ({
  pool,
  rewardPriceUSD,
  ...props
}: PoolMobileCardProps & FlexProps): JSX.Element => {
  const chainId = useChainId()

  // get rewards APR
  const rewardRate = getRewardRate(chainId, pool.pairAddress)
  const rewardsPerDay =
    rewardPriceUSD && rewardRate ? rewardRate * rewardPriceUSD : 0
  const rewardsAPR = (rewardsPerDay / pool.liquidityUsd) * 365
  const totalAPR = pool.apr + (rewardsAPR ?? 0)
  const isRewarded = Boolean(rewardRate)

  return (
    <PoolCard
      token0={pool.tokenX}
      token1={pool.tokenY}
      href={getPoolDetailUrl(pool, chainId)}
      columns={[
        { title: 'Liquidity', value: formattedNum(pool.liquidityUsd, true) },
        { title: 'Volume (24H)', value: formattedNum(pool.volumeUsd, true) },
        {
          title: 'APR (24H)',
          value: `${formattedNum(totalAPR * 100, false, 2)}%`
        }
      ]}
      tags={[
        <Tag
          key="fees-tag"
          size="md"
          rounded="full"
          fontWeight="bold"
          bg="bgTertiary"
          textColor="textSecondary"
        >
          {`${pool.feePct}%`}
        </Tag>,
        isRewarded ? (
          <Tag
            key="rewards-tag"
            rounded="full"
            size="md"
            bg="green.400"
            textColor="white"
          >
            Rewards
          </Tag>
        ) : null
      ]}
      {...props}
    />
  )
}

export default PoolMobileCard
