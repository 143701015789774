import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { ethers } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import { capturePrepareContractWriteError } from 'utils/logger'
import {
  erc721ABI,
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'
import { LBPairV21ABI } from '@fusionx/sdk-v2'
import useChainId from './useChainId'
import useTransactionToast from './useTransactionToast'

interface UseApproveForAllIfNeededProps {
  spender: string
  token?: string
  tokenSymbol?: string
}

const useApproveForAllIfNeeded = ({
  spender,
  token,
  tokenSymbol
}: UseApproveForAllIfNeededProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: isApprovedForAll } = useContractRead({
    abi: LBPairV21ABI,
    address: token ? getAddress(token) : undefined,
    args: account ? [account, ethers.utils.getAddress(spender)] : undefined,
    chainId,
    enabled: !!account,
    functionName: 'isApprovedForAll'
  })

  const { config } = usePrepareContractWrite({
    abi: LBPairV21ABI,
    address: token ? getAddress(token) : undefined,
    args: [ethers.utils.getAddress(spender), true],
    cacheTime: 0,
    enabled: isApprovedForAll === false,
    functionName: 'approveForAll',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description = `Approve ${tokenSymbol}`
      addRecentTransaction({
        description,
        hash: data.hash
      })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const {
    data: receipt,
    isLoading: isWaitingForTransaction,
    isSuccess
  } = useWaitForTransaction({
    hash: data?.hash
  })

  return {
    approve: write,
    isApproved: isApprovedForAll === true || receipt?.status === 1,
    isApproving: isLoading || isWaitingForTransaction,
    isSuccess
  }
}

export default useApproveForAllIfNeeded
