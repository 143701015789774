import { ChainId, WNATIVE } from '@fusionx/sdk'
import { DEXLENS_ADDRESS, DexLensABI } from '@fusionx/sdk-v2'
import { BigNumber, ethers } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import { useContractReads } from 'wagmi'

import useChainId from './useChainId'

interface UseTokenPriceUSDProps {
  tokens: string[]
  enabled?: boolean
}

const useTokenPriceUSD = ({
  enabled = true,
  tokens
}: UseTokenPriceUSDProps) => {
  const chainId = useChainId()
  const contract = {
    abi: DexLensABI,
    address: getAddress(DEXLENS_ADDRESS[chainId])
  }
  const args: [`0x${string}`[]] = [tokens.map((token) => getAddress(token))]
  const reads = useContractReads({
    contracts: [
      { ...contract, args, chainId, functionName: 'getTokensPricesNative' },
      { ...contract, args, chainId, functionName: 'getTokensPricesUSD' },
      {
        ...contract,
        args: [getAddress(WNATIVE[chainId].address)],
        chainId,
        functionName: 'getTokenPriceUSD'
      }
    ],
    enabled: tokens.length > 0 && enabled
  })

  const tokensPricesNative = reads.data?.[0]
  const tokensPricesUSD = reads.data?.[1]
  const nativeCurrencyPriceUSD = reads.data?.[2]

  // USD price of a token is 6 decimals on arbitrum and avalanche and 18 decimals on binance
  const usdPriceDecimals = [ChainId.BNB_CHAIN, ChainId.BNB_TESTNET].includes(
    chainId
  )
    ? 18
    : 6
  const convertUsdPrice = (price: BigNumber): number =>
    parseFloat(ethers.utils.formatUnits(price, usdPriceDecimals))

  // we use getTokensPriceNative(token) * getTokensPricesUSD(nativeCurrency) in priority
  // and fallback to getTokenPricesUSD
  const prices =
    tokensPricesNative && nativeCurrencyPriceUSD
      ? tokensPricesNative
        .map((nativePrice) =>
          nativePrice
            .mul(nativeCurrencyPriceUSD)
            .div(ethers.constants.WeiPerEther)
        )
        .map(convertUsdPrice)
      : tokensPricesUSD?.map(convertUsdPrice)

  return {
    ...reads,
    data: prices
  }
}

export default useTokenPriceUSD
