import { ChevronDownIcon } from '@chakra-ui/icons'
import { Collapse, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { TradeType } from '@fusionx/sdk'
import { TradeV2 } from '@fusionx/sdk-v2'
import ContractLink from 'components/ContractLink'
import Warning from 'components/Warning'
import useIsTrustedTrade from 'hooks/swap/useIsTrustedTrade'
import useTradeFee from 'hooks/swap/useTradeFee'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { useState } from 'react'
import { tradeExpandSummary } from 'utils/measure'
import { computeSlippageAdjustedAmounts, Field } from 'utils/swap'

import RouteDetails from './RouteDetails'

interface TradeDetailsRowProps {
  title: string
  value: string
}

const getWarningMessage = (symbol: string): string => {
  return `${symbol} is not on the verified token list`
}

const TradeDetailsRow = ({ title, value }: TradeDetailsRowProps) => (
  <HStack w="full" justify="space-between">
    <Text fontSize="sm" color="textSecondary">
      {title}
    </Text>
    <Text fontSize="sm" fontWeight="bold">
      {value}
    </Text>
  </HStack>
)

interface TradeDetailsProps {
  allowedSlippage: number
  trade: TradeV2
}

const TradeDetails = ({ allowedSlippage, trade }: TradeDetailsProps) => {
  const { nativeCurrency } = useActiveChain()
  const [isOpen, setIsOpen] = useState(false)
  const [isInverted, setIsInverted] = useState(false)

  const formattedPrice = isInverted
    ? trade.executionPrice.invert().toSignificant(8)
    : trade.executionPrice.toSignificant(8)

  const inputTokenSymbol = trade.isNativeIn
    ? nativeCurrency?.symbol
    : trade.inputAmount.token.symbol
  const outputTokenSymbol = trade.isNativeOut
    ? nativeCurrency?.symbol
    : trade.outputAmount.token.symbol

  const symbolA = isInverted ? outputTokenSymbol : inputTokenSymbol
  const symbolB = isInverted ? inputTokenSymbol : outputTokenSymbol

  // Expected output
  const formattedExpectedOutput = `${trade.outputAmount.toSignificant(6)} ${trade.isNativeOut ? nativeCurrency?.symbol : trade.outputAmount.token.symbol
    }`

  // Minimum received
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(
    trade,
    allowedSlippage
  )
  const formattedMinimumReceived = isExactIn
    ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.isNativeOut
      ? nativeCurrency?.symbol
      : trade.outputAmount.token.symbol
    }` ?? '-'
    : `>= ${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.isNativeIn
      ? nativeCurrency?.symbol
      : trade.inputAmount.token.symbol
    }` ?? '-'

  // Price impact
  const priceImpact = Number(trade.priceImpact.toFixed(2))
  const formattedPriceImpact = priceImpact < 0.01 ? '<0.01%' : `${priceImpact}%`
  const showPriceImpactWarning = priceImpact > 2

  // Trade fees
  const tradeFee = useTradeFee(trade)
  const formattedTradeFee = tradeFee
    ? `${tradeFee.feeAmountIn.toSignificant(3)} ${trade.isNativeIn
      ? nativeCurrency?.symbol
      : tradeFee.feeAmountIn.token.symbol
    }`
    : undefined

  // Trusted
  const { inputTrusted, outputTrusted } = useIsTrustedTrade(trade)

  return (
    <Flex
      flexDir="column"
      w="full"
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
    >
      <Flex
        align="center"
        justify="space-between"
        w="full"
        minH="40px"
        px={4}
        borderRadius="md"
        cursor="pointer"
        onClick={() => {
          setIsOpen((previous) => !previous)
          if (!isOpen) {
            tradeExpandSummary(symbolA, symbolB)
          }
        }}
      >
        <Text
          fontSize="sm"
          fontWeight="bold"
          onClick={(e) => {
            e.stopPropagation()
            setIsInverted((previous) => !previous)
          }}
        >
          {`1 ${symbolA} = ${formattedPrice} ${symbolB}`}
        </Text>
        <ChevronDownIcon />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <VStack px={4} py={6} pt={4} w="full" align="flex-start" spacing={0.5}>
          <TradeDetailsRow
            title="Expected Output:"
            value={formattedExpectedOutput}
          />
          <TradeDetailsRow
            title="Minimum Received:"
            value={formattedMinimumReceived}
          />
          <TradeDetailsRow title="Price Impact:" value={formattedPriceImpact} />
          {formattedTradeFee ? (
            <TradeDetailsRow title="Fees:" value={formattedTradeFee} />
          ) : null}
        </VStack>
        <RouteDetails route={trade.route} quote={trade.quote} />
      </Collapse>
      {!inputTrusted && inputTokenSymbol && (
        <Warning
          message={getWarningMessage(inputTokenSymbol)}
          rightContent={
            <ContractLink
              address={trade.inputAmount.token.address}
              showAddress={false}
              color="white"
            />
          }
          m={2}
          mt={0}
        />
      )}
      {!outputTrusted && outputTokenSymbol && (
        <Warning
          message={getWarningMessage(outputTokenSymbol)}
          rightContent={
            <ContractLink
              address={trade.outputAmount.token.address}
              showAddress={false}
              color="white"
            />
          }
          m={2}
          mt={0}
        />
      )}
      {showPriceImpactWarning && (
        <Warning
          message="Price Impact Warning"
          rightContent={
            <Text fontSize="sm" fontWeight="bold" color="white">
              {formattedPriceImpact}
            </Text>
          }
          m={2}
          mt={0}
        />
      )}
    </Flex>
  )
}

export default TradeDetails
