import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import {
  JOE_ADDRESS,
  VEJOE_STAKING_ADDRESS,
  VEJOE_TOKEN_ADDRESS,
  VeJoeABI,
  VeJoeStakingABI
} from '@fusionx/sdk'
import { BigNumber, ethers } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { capturePrepareContractWriteError } from 'utils/logger'
import {
  erc20ABI,
  useAccount,
  useContractReads,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

/**
 * VeJoe Staking Info. Returns:
 * totalSupply
 * totalStaked
 */
const useVeJoeStaking = () => {
  const chainId = useChainId()
  const veJoeContract = {
    abi: VeJoeABI,
    address: getAddress(VEJOE_TOKEN_ADDRESS[chainId]),
    chainId
  }
  const joeContract = {
    abi: erc20ABI,
    address: getAddress(JOE_ADDRESS[chainId]),
    chainId
  }
  const veJoeAddress = getAddress(VEJOE_STAKING_ADDRESS[chainId])

  const reads = useContractReads({
    contracts: [
      { ...veJoeContract, functionName: 'totalSupply' },
      {
        ...joeContract,
        args: [veJoeAddress],
        functionName: 'balanceOf'
      }
    ]
  })

  // total supply, in 18 decimals
  const totalSupply = reads.data?.[0] as BigNumber

  // total joe staked, in 18 decimals
  const totalStaked = reads.data?.[1] as BigNumber

  return {
    ...reads,
    totalStaked,
    totalSupply
  }
}

interface UseVeJoeDepositOrWithdrawProps {
  amount?: BigNumber
  approved?: boolean
  onSuccess?: () => void
}

export const useVeJoeDeposit = ({
  amount,
  approved,
  onSuccess
}: UseVeJoeDepositOrWithdrawProps) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // check if approved and args are valid
  const enabled = !!amount && approved && walletChainId === chainId

  const { config } = usePrepareContractWrite({
    abi: VeJoeStakingABI,
    address: getAddress(VEJOE_STAKING_ADDRESS[chainId]),
    args: amount ? [BigNumber.from(amount)] : undefined,
    cacheTime: 0,
    chainId,
    enabled,
    functionName: 'deposit',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  // Note: assume JOE token is always 18 decimals
  const JOE_TOKEN_DECIMALS = 18

  const transactionSummary = useMemo(
    () =>
      amount
        ? `Staked ${formattedNum(
          ethers.utils.formatUnits(amount, JOE_TOKEN_DECIMALS)
        )} JOE `
        : undefined,
    [amount]
  )

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    deposit: write,
    isLoading: isWaitingForTransaction || isLoading,
    isSuccess
  }
}

export const useVeJoeWithdraw = ({
  amount,
  onSuccess
}: UseVeJoeDepositOrWithdrawProps) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { config } = usePrepareContractWrite({
    abi: VeJoeStakingABI,
    address: getAddress(VEJOE_STAKING_ADDRESS[chainId]),
    args: amount ? [BigNumber.from(amount)] : undefined,
    cacheTime: 0,
    chainId,
    enabled: !!amount && walletChainId === chainId,
    functionName: 'withdraw',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  // Note: assume JOE token is always 18 decimals
  const JOE_TOKEN_DECIMALS = 18

  const transactionSummary = useMemo(
    () =>
      amount
        ? `Unstaked ${formattedNum(
          ethers.utils.formatUnits(amount, JOE_TOKEN_DECIMALS)
        )} JOE `
        : undefined,
    [amount]
  )

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isLoading,
    isSuccess,
    withdraw: write
  }
}

/**
 * contract methods for VeJoe User Positions. Returns:
 * userBalance
 * pendingBalance
 * claim
 */
export const useVeJoeUserPosition = () => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { address: account } = useAccount()

  const veJoeContract = {
    abi: VeJoeStakingABI,
    address: getAddress(VEJOE_STAKING_ADDRESS[chainId]),
    chainId
  }

  const veJoeTokenContract = {
    abi: VeJoeABI,
    address: getAddress(VEJOE_TOKEN_ADDRESS[chainId]),
    chainId
  }

  const reads = useContractReads({
    contracts: [
      {
        ...veJoeContract,
        args: account ? [account] : undefined,
        functionName: 'userInfos'
      },
      {
        ...veJoeContract,
        args: account ? [account] : undefined,
        functionName: 'getPendingVeJoe'
      },
      {
        ...veJoeContract,
        functionName: 'veJoePerSharePerSec'
      },
      {
        ...veJoeContract,
        functionName: 'speedUpVeJoePerSharePerSec'
      },
      {
        ...veJoeTokenContract,
        args: account ? [account] : undefined,
        functionName: 'balanceOf'
      }
    ],
    enabled: !!account
  })

  // user JOE balance, in 18 decimals
  const userInfos = reads.data?.[0]
  const userBalance = userInfos?.balance

  // pending veJOE in 18 decimals
  const pending = reads?.data?.[1]

  const veJoePerSharePerSec = reads.data?.[2]
  const speedUpVeJoePerSharePerSec = reads.data?.[3]
  const userVeJoeBalance = reads?.data?.[4]
  const speedUpEndTimestamp = userInfos?.speedUpEndTimestamp.toNumber()

  const veJoePerDayForUser = useMemo(() => {
    if (!userBalance || userBalance.eq(0)) {
      return ethers.constants.Zero
    }

    const finalVeJoePerSharePerSec =
      speedUpEndTimestamp !== 0 &&
        veJoePerSharePerSec &&
        speedUpVeJoePerSharePerSec
        ? veJoePerSharePerSec?.add(speedUpVeJoePerSharePerSec)
        : speedUpEndTimestamp !== 0 && veJoePerSharePerSec
          ? veJoePerSharePerSec
          : ethers.constants.Zero

    return userBalance
      .mul(finalVeJoePerSharePerSec)
      .mul(60 * 60 * 24)
      .div(BigNumber.from(10).pow(36))
  }, [
    speedUpEndTimestamp,
    speedUpVeJoePerSharePerSec,
    userBalance,
    veJoePerSharePerSec
  ])

  const { config } = usePrepareContractWrite({
    abi: VeJoeStakingABI,
    address: getAddress(VEJOE_STAKING_ADDRESS[chainId]),
    cacheTime: 0,
    chainId,
    enabled: !!userBalance && userBalance.gt(0) && walletChainId === chainId,
    functionName: 'claim',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  const transactionSummary = 'Claimed veJOE tokens'

  const {
    data,
    isLoading: isClaiming,
    isSuccess: isClaimed,
    write
  } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash
    })

  return {
    ...reads,
    claim: write,
    isClaimed,
    isClaiming: isClaiming || isWaitingForTransaction,
    isSuccess,
    pending,
    userBalance,
    userVeJoeBalance,
    veJoePerDayForUser
  }
}

export default useVeJoeStaking
