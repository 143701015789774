import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { jsonAbis, LB_ROUTER_V21_ADDRESS, TradeV2 } from '@fusionx/sdk-v2'
import { getAddress } from 'ethers/lib/utils.js'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { useEffect, useMemo } from 'react'
import { capturePrepareContractWriteError } from 'utils/logger'
import {
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

import useSwapCallArguments from './useSwapCallArguments'

interface UseSwapCallArgumentsProps {
  allowedSlippage: number
  enabled: boolean
  onSwapSuccess: () => void
  trade?: TradeV2
}

export const useSwap = ({
  allowedSlippage,
  enabled,
  onSwapSuccess,
  trade
}: UseSwapCallArgumentsProps) => {
  const { LBRouterV21ABI } = jsonAbis
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const { nativeCurrency } = useActiveChain()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()
  const { defaultParameters, feeOnTransferParameters } = useSwapCallArguments({
    allowedSlippage,
    trade
  })

  console.log("defaultparamaters", defaultParameters, feeOnTransferParameters, (!!defaultParameters && enabled && walletChainId === chainId))

  const { config: defaultConfig, isError: isDefaultConfigError } =
    usePrepareContractWrite({
      abi: LBRouterV21ABI,
      address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
      args: defaultParameters?.args,
      chainId: chainId,
      cacheTime: 0,
      enabled: !!defaultParameters && enabled && walletChainId === chainId,
      functionName: defaultParameters?.methodName,
      onSettled: (_, error) => {
        console.log("default config error", error);
        capturePrepareContractWriteError(error)
      },
      overrides: { value: defaultParameters?.value }
    })

  const { config: feeOnTransferConfig } = usePrepareContractWrite({
    abi: LBRouterV21ABI,
    address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
    args: feeOnTransferParameters?.args,
    chainId: chainId,
    cacheTime: 0,
    enabled: !!feeOnTransferParameters && enabled && walletChainId === chainId,
    functionName: feeOnTransferParameters?.methodName,
    onSettled: (_, error) => {
      console.log("usePrepareContractWrite", error);
      capturePrepareContractWriteError(error)
    },
    overrides: { value: feeOnTransferParameters?.value }
  })

  const transactionSummary = useMemo(() => {
    if (!trade) return ''
    const inputSymbol = trade.isNativeIn
      ? nativeCurrency?.symbol
      : trade.inputAmount.currency.symbol
    const outputSymbol = trade.isNativeOut
      ? nativeCurrency?.symbol
      : trade.outputAmount.currency.symbol
    const inputAmount = trade.inputAmount.toSignificant(3)
    const outputAmount = trade.outputAmount.toSignificant(3)
    return `Swap ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`
  }, [trade, nativeCurrency])

  console.log("transactionSummary", transactionSummary, isDefaultConfigError, defaultConfig, feeOnTransferConfig)

  const response = useContractWrite({
    ...(!isDefaultConfigError ? defaultConfig : feeOnTransferConfig),
    onSuccess: (data) => {
      addRecentTransaction({ description: transactionSummary, hash: data.hash })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    },
    onError(error) {
      console.log('Error', error)
    },
  })
  const { data, isLoading, reset, write: swap } = response;

  console.log("useContractWrite", response)

  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: onSwapSuccess
  })

  useEffect(() => {
    reset()
  }, [chainId, reset])

  return { isSwapping: isLoading || isWaitingForTransaction, swap }
}
