import { gql } from 'graphql-request'

const tokenDaysQuery = gql`
  query tokensQuery($date: Int! = 1622419200, $first: Int! = 140) {
    tokens(first: $first, orderBy: volumeUSD, orderDirection: desc) {
      id
      name
      symbol
      decimals
      volumeUSD
      dayData(
        first: 8
        where: { date_gte: $date }
        orderBy: date
        orderDirection: desc
      ) {
        date
        volume
        volumeUSD
        volumeAVAX
        liquidity
        liquidityUSD
        liquidityAVAX
        priceUSD
      }
    }
  }
`

const pairFieldsQuery = gql`
  fragment pairFields on Pair {
    id
    name
    token0Price
    token1Price
    token0 {
      id
      symbol
      decimals
    }
    token1 {
      id
      symbol
      decimals
    }
    reserve0
    reserve1
    reserveUSD
    volumeUSD
    hourData(
      first: 24
      where: { date_gt: $dateAfter }
      orderBy: date
      orderDirection: desc
    ) {
      untrackedVolumeUSD
      volumeUSD
      date
      volumeToken0
      volumeToken1
    }
    timestamp
  }
`

const pairsQuery = gql`
  query pairsQuery(
    $first: Int! = 100
    $skip: Int! = 0
    $orderBy: String! = "reserveUSD"
    $orderDirection: String! = "desc"
    $dateAfter: Int! = 1622419200
  ) {
    pairs(
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { volumeUSD_gt: 1 }
    ) {
      ...pairFields
    }
  }
  ${pairFieldsQuery}
`

const userLiquidityPositionsQuery = gql`
  query liquidityPositionSubsetQuery($first: Int! = 100, $user: Bytes!) {
    liquidityPositions(first: $first, where: { user: $user }) {
      id
      liquidityTokenBalance
      user {
        id
      }
      pair {
        id
        name
        token0 {
          id
          symbol
          decimals
        }
        token1 {
          id
          symbol
          decimals
        }
        reserveUSD
        reserve0
        reserve1
        totalSupply
      }
    }
  }
`

const dayDatasLiquidityFieldsQuery = gql`
  fragment dayDataFields on DayData {
    id
    date
    volumeAVAX
    volumeUSD
    untrackedVolume
    liquidityAVAX
    liquidityUSD
    txCount
  }
`

const dayDatasLiquidityQuery = gql`
  query dayDatasQuery($first: Int! = 200, $date: Int! = 0, $dateAfter: Int!) {
    dayDatas(
      first: $first
      orderBy: date
      orderDirection: asc
      where: { date_gte: $dateAfter }
    ) {
      ...dayDataFields
    }
  }
  ${dayDatasLiquidityFieldsQuery}
`

const poolV1ByTokens = (tokens?: [string, string]) => {
  const tokensString = tokens
    ? `[${tokens
      .map((tok) => {
        return `"${tok.toLowerCase()}"`
      })
      .join(',')}]`
    : ''
  return gql`
    query pairDetailQuery(
      $first: Int! = 1
      $orderBy: String! = "trackedReserveAVAX"
      $orderDirection: String! = "desc"
      $dateAfter: Int! = 1622419200
    ) {
      pairs (first: $first, orderBy: $orderBy, orderDirection: $orderDirection, 
        where: {
          token0_in: ${tokensString},
          token1_in: ${tokensString}
        }) {
          ...pairFields
        }
    }
    ${pairFieldsQuery}
  `
}

const pairDayDatasQuery = gql`
  query pairDayDatasQuery($first: Int = 180, $date: Int = 0, $pairs: [Bytes]!) {
    pairDayDatas(
      first: $first
      orderBy: date
      orderDirection: desc
      where: { pair_in: $pairs, date_gt: $date }
    ) {
      date
      pair {
        id
      }
      token0 {
        derivedAVAX
      }
      token1 {
        derivedAVAX
      }
      reserveUSD
      volumeToken0
      volumeToken1
      volumeUSD
      txCount
    }
  }
`

const pairsSearchQuery = gql`
  query pairsSearchQuery(
    $first: Int = 10
    $query: String!
    $dateAfter: Int! = 1622419200
  ) {
    pairs(
      first: $first
      orderBy: volumeUSD
      orderDirection: desc
      where: { name_contains_nocase: $query, reserveUSD_gte: 50000 }
    ) {
      ...pairFields
    }
  }
  ${pairFieldsQuery}
`

const bundleFields = gql`
  fragment bundleFields on Bundle {
    id
    avaxPrice
  }
`

const avaxPriceQuery = gql`
  query avaxPriceQuery($id: Int! = 1) {
    bundles(id: $id) {
      ...bundleFields
    }
  }
  ${bundleFields}
`

export {
  avaxPriceQuery,
  dayDatasLiquidityQuery,
  pairDayDatasQuery,
  pairsQuery,
  pairsSearchQuery,
  poolV1ByTokens,
  tokenDaysQuery,
  userLiquidityPositionsQuery
}
