import { ChevronDownIcon } from '@chakra-ui/icons'
import {
    Button,
    HStack,
    Image,
    Menu,
    MenuButton,
    MenuButtonProps,
    MenuItem,
    MenuList,
    Text
} from '@chakra-ui/react'
import { Token } from '@fusionx/sdk'
import { getTokenLogoURL } from 'components/CurrencyLogo'

interface ChainSelectorProps {
    quoteTokenList: Token[]
    onTokenSelect: (token: Token) => void
    selectedQuoteToken?: Token
    menuButtonProps?: MenuButtonProps
}

const QuoteTokenPicker = ({
    quoteTokenList,
    onTokenSelect,
    selectedQuoteToken,
    menuButtonProps,
}: ChainSelectorProps) => {

    return (
        <Menu id="chain-selector">
            <MenuButton
                minW="76px"
                as={Button}
                variant="secondary"
                rightIcon={<ChevronDownIcon />}
                {...menuButtonProps}
            >
                {selectedQuoteToken ? <HStack>
                    <Image alt="token-logo" src={getTokenLogoURL(selectedQuoteToken?.address)} boxSize={5} />
                    <Text >{selectedQuoteToken?.symbol}</Text>
                </HStack> : <Text textAlign="start">Select Token</Text>}
            </MenuButton>
            <MenuList>
                {quoteTokenList.map((token, i) => token ? (
                    <MenuItem
                        key={i}
                        className={""}
                        h="48px"
                        icon={
                            <Image
                                alt="chain-selector-option-icon"
                                src={getTokenLogoURL(token.address)}
                                boxSize={5}
                            />
                        }
                        onClick={() => onTokenSelect(token)}
                    >
                        {token.name}
                    </MenuItem>
                ) : null)}
            </MenuList>
        </Menu>
    )
}

export default QuoteTokenPicker
