import { useMemo } from 'react'

interface UsePoolAnalyticsChangeProps {
  pairDayDatas?: {
    reserveUSD: string
    txCount: number
    volumeUSD: string
  }[]
}

const usePoolAnalyticsChange = ({
  pairDayDatas
}: UsePoolAnalyticsChangeProps) =>
  useMemo(() => {
    if (!pairDayDatas || pairDayDatas.length < 3) return undefined

    const pair = {
      ...pairDayDatas[0],
      oneDay: pairDayDatas[1],
      twoDay: pairDayDatas[2]
    }

    const oneDayVolumeUSD = parseFloat(pair.oneDay.volumeUSD)
    const oneDayReserveUSD = parseFloat(pair.oneDay.reserveUSD)
    const twoDayVolumeUSD = parseFloat(pair.twoDay.volumeUSD)
    const twoDayReserveUSD = parseFloat(pair.twoDay.reserveUSD)
    const reserveUSD = parseFloat(pair.reserveUSD)

    const volumeChange =
      ((oneDayVolumeUSD - twoDayVolumeUSD) / twoDayVolumeUSD) * 100

    const liquidityChange =
      ((reserveUSD - oneDayReserveUSD) / oneDayReserveUSD) * 100

    const FEE_RATE = 0.0025
    const fees = oneDayVolumeUSD * FEE_RATE
    const feesYesterday = twoDayVolumeUSD * FEE_RATE
    const feeChange = ((fees - feesYesterday) / feesYesterday) * 100

    const avgTradePrice =
      oneDayVolumeUSD / (pair.oneDay.txCount - pair.twoDay.txCount)
    const avgTradePriceYesterday =
      twoDayVolumeUSD / (pair.oneDay.txCount - pair.twoDay.txCount)
    const avgTradePriceChange =
      ((avgTradePrice - avgTradePriceYesterday) / avgTradePriceYesterday) * 100

    const utilisation = (oneDayVolumeUSD / oneDayReserveUSD) * 100
    const utilisationYesterday = (twoDayVolumeUSD / twoDayReserveUSD) * 100
    const utilisationChange =
      ((utilisation - utilisationYesterday) / utilisationYesterday) * 100

    const tx = pair.oneDay.txCount
    const txYesterday = pair.twoDay.txCount
    const txChange = ((tx - txYesterday) / txYesterday) * 100

    return {
      avgTradePriceChange,
      feeChange,
      liquidityChange,
      txChange,
      utilisationChange,
      volumeChange
    }
  }, [pairDayDatas])

export default usePoolAnalyticsChange
