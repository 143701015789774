import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Quote, RouteV2 } from '@fusionx/sdk-v2'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'

import RouteDetailsStep from './RouteDetailsStep'

interface RouteDetailsProps {
  quote: Quote
  route: RouteV2
}

const RouteDetails = ({ quote, route }: RouteDetailsProps) => {
  const { binSteps, pairs: pairsAddr } = quote
  const { path } = route

  const pairs = path.slice(1).map((token, i) => [path[i], token])

  return (
    <VStack align="flex-start" px={4} pb={4}>
      <Text fontSize="sm" fontWeight="bold">
        Route
      </Text>
      <Flex align="center" flexWrap="wrap" gap={2}>
        <HStack>
          <CurrencyLogo
            address={route.path[0].address}
            symbol={route.path[0].symbol}
            boxSize={5}
          />
        </HStack>
        <ArrowForwardIcon boxSize="14px" color="textSecondary" />
        {pairs.map((pair, i) => {
          const binStep = binSteps[i].toNumber()
          const lbPairAddress = binStep ? pairsAddr[i] : undefined
          return (
            <RouteDetailsStep
              key={i}
              pair={pair}
              binStep={binStep}
              lbPairAddress={lbPairAddress}
              showForwardArrow={route.pairs.length - 1 > i}
            />
          )
        })}
      </Flex>
    </VStack>
  )
}

export default RouteDetails
