import { ChainId, CNATIVE, Token, WNATIVE } from '@fusionx/sdk'
import { ZERO_ADDRESS } from 'constants/tokens'
import { ethers } from 'ethers'
import { ClaimableRewardsEpoch, FormattedReward, Reward } from 'types/rewards'
/**
 * Returns the unique token addresses from t
 * @param {ClaimableRewardsEpoch} epochs
 * @returns {string[]}
 */
export const getClaimableTokenAddresses = (
  epochs: ClaimableRewardsEpoch[] | undefined
) => {
  if (!epochs) {
    return []
  }

  const addresses = new Set<string>()

  Object.values(epochs).forEach((epoch) => {
    const { claimableRewards } = epoch
    claimableRewards.forEach((reward: { tokenAddress: string }) => {
      addresses.add(reward.tokenAddress)
    })
  })

  return Array.from(addresses)
}

/**
 * Returns map of tokenAddr: Token
 *
 * @param {string[]} addresses
 * @param {(Token | CNATIVE | undefined)[]} tokens
 * @returns {Object}
 */
export const getTokensMap = (
  addresses: string[],
  tokens: (Token | CNATIVE | undefined)[]
) => {
  if (addresses.length !== tokens.length) {
    return {}
  }

  return addresses.reduce(
    (prev, address, index) => ({
      ...prev,
      [address]: tokens[index]
    }),
    {}
  )
}

/**
 * Returns the rewards formatted by the token's decimals
 *
 * @param {ClaimableRewardsEpoch | undefined} rewardEpoch
 * @param { { [k: string]: Token | CNATIVE | undefined }} tokensMap
 * @returns
 */
export const getFormattedRewards = (
  rewards: Reward[] | undefined,
  tokensMap: { [address: string]: Token | CNATIVE | undefined },
  chainId: ChainId
): FormattedReward[] => {
  if (!rewards) {
    return []
  }

  try {
    return rewards.map((reward) => {
      const isNative = reward.tokenAddress === ZERO_ADDRESS
      const tokenAddress = isNative
        ? WNATIVE[chainId].address
        : reward.tokenAddress
      const rewardToken = tokensMap[isNative ? ZERO_ADDRESS : tokenAddress]
      const tokenAmount = Number(
        ethers.utils.formatUnits(reward.amount, rewardToken?.decimals)
      )
      return {
        token: rewardToken,
        tokenAddress,
        tokenAmount
      }
    })
  } catch (e) {
    console.debug('Error getFormattedRewards() ', e)
    return []
  }
}

/**
 * Returns the aggregate sum of rewards from all claimable epochs
 *
 * @param {FormattedReward[][]} rewardEpochs
 * @returns {FormattedReward[]}
 */
export const getAggregatedClaimableRewards = (
  rewardEpochs: FormattedReward[][]
): FormattedReward[] => {
  const aggRewards: { [address: string]: FormattedReward } = {}

  rewardEpochs.forEach((rewardEpoch) => {
    rewardEpoch.forEach((reward) => {
      const { token, tokenAddress, tokenAmount } = reward
      if (!aggRewards[tokenAddress]) {
        aggRewards[tokenAddress] = {
          token,
          tokenAddress,
          tokenAmount: 0
        }
      }
      aggRewards[tokenAddress].tokenAmount += tokenAmount
    })
  })

  return Object.values(aggRewards)
}
