import { ChainId, CNATIVE } from '@fusionx/sdk'
import { Pool } from 'types/pool'

import { getChainSlug } from './chains'
import { isWrappedNativeCurrency } from './wrappedCurrency'

const getPoolVersion = (pool: Pool): 'v1' | 'v2' => {
  return pool.isLb ? 'v2' : 'v1'
}

export const getPoolDetailV1Url = (
  chainId: ChainId,
  token0?: string,
  token1?: string
) => {
  const nativeCurrency = CNATIVE.onChain(chainId)
  return `/${getChainSlug(chainId)}/pool/v1/${isWrappedNativeCurrency(token0, chainId) ? nativeCurrency.symbol : token0
    }/${isWrappedNativeCurrency(token1, chainId) ? nativeCurrency.symbol : token1
    }`
}

export const getPoolDetailV2Url = (
  binStep: number,
  chainId: ChainId,
  token0?: string,
  token1?: string
) => {
  const nativeCurrency = CNATIVE.onChain(chainId)
  return `/${getChainSlug(chainId)}/pool/v2/${isWrappedNativeCurrency(token0, chainId) ? nativeCurrency.symbol : token0
    }/${isWrappedNativeCurrency(token1, chainId) ? nativeCurrency.symbol : token1
    }/${binStep}`
}

export const getPoolDetailUrl = (pool: Pool, chainId: ChainId): string => {
  const version = getPoolVersion(pool)
  switch (version) {
    case 'v1':
      return getPoolDetailV1Url(
        chainId,
        pool.tokenX.address,
        pool.tokenY.address
      )
    case 'v2':
      return getPoolDetailV2Url(
        pool.lbBinStep ?? 0,
        chainId,
        pool.tokenX.address,
        pool.tokenY.address
      )
  }
}
