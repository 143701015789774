import { ROUTER_ADDRESS, RouterABI } from '@fusionx/sdk'
import { BigNumber } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { useMemo } from 'react'
import { capturePrepareContractWriteError } from 'utils/logger'
import { useAccount, usePrepareContractWrite } from 'wagmi'

type AddLiquidityNativeArgs =
  | [`0x${string}`, BigNumber, BigNumber, BigNumber, `0x${string}`, BigNumber]
  | undefined

interface UseAddLiquidityNativeConfigProps {
  amountADesired?: BigNumber
  amountAMin?: BigNumber
  amountBDesired?: BigNumber
  amountBMin?: BigNumber
  enabled?: boolean
  tokenA?: `0x${string}`
  tokenB?: `0x${string}`
}

const useAddLiquidityNativeConfig = ({
  amountADesired,
  amountAMin,
  amountBDesired,
  amountBMin,
  enabled,
  tokenA,
  tokenB
}: UseAddLiquidityNativeConfigProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const deadline = useTransactionDeadline()

  const addLiquidityNativeArgs = useMemo((): AddLiquidityNativeArgs => {
    if (
      !account ||
      !deadline ||
      !amountADesired ||
      !amountBDesired ||
      !amountAMin ||
      !amountBMin
    ) {
      return undefined
    }
    if (!tokenA && tokenB) {
      return [tokenB, amountBDesired, amountBMin, amountAMin, account, deadline]
    }
    if (!tokenB && tokenA) {
      return [tokenA, amountADesired, amountAMin, amountBMin, account, deadline]
    }
    return undefined
  }, [
    account,
    deadline,
    tokenA,
    tokenB,
    amountADesired,
    amountBDesired,
    amountAMin,
    amountBMin
  ])

  return usePrepareContractWrite({
    abi: RouterABI,
    address: getAddress(ROUTER_ADDRESS[chainId]),
    args: addLiquidityNativeArgs,
    cacheTime: 0,
    enabled: enabled && !!addLiquidityNativeArgs,
    functionName: 'addLiquidityETH',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    overrides: {
      value: !tokenA ? amountADesired : !tokenB ? amountBDesired : undefined
    }
  })
}

export default useAddLiquidityNativeConfig
