import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonProps,
  Flex,
  Heading,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
  useDimensions,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import CurrencyPickerModal from 'components/CurrencyPickerModal'
import InputBalanceButton from 'components/InputBalanceButton'
import NumericalInput from 'components/NumericalInput'
import React, { useRef } from 'react'

export interface CurrencyInputProps {
  onValueChange: (value: string) => void
  value: string
  balance?: string
  currency?: Currency
  currencyAddress?: string
  currencyPickerButtonProps?: ButtonProps
  error?: string
  headingText?: string
  isCurrencyPickerDisabled?: boolean
  isDisabled?: boolean
  onCurrencyChange?: (currency: Currency) => void
  rightElement?: JSX.Element
}

const CurrencyInput = ({
  balance,
  currency,
  currencyAddress,
  currencyPickerButtonProps,
  error,
  headingText,
  isDisabled,
  onCurrencyChange,
  onValueChange,
  rightElement,
  value,
  ...props
}: CurrencyInputProps & InputProps) => {
  const rightElementRef = useRef<HTMLDivElement | null>(null)
  const rightElementDimensions = useDimensions(rightElementRef)

  const {
    isOpen: isCurrencyPickerOpen,
    onClose: onCurrencyPickerClose,
    onOpen: onCurrencyPickerOpen
  } = useDisclosure()

  const isCurrencyPickerEnabled = !!onCurrencyChange

  return (
    <>
      {onCurrencyChange ? (
        <CurrencyPickerModal
          isOpen={isCurrencyPickerOpen}
          onClose={onCurrencyPickerClose}
          onCurrencyChange={onCurrencyChange}
        />
      ) : null}
      <VStack w="full" spacing={headingText ? 0 : 1}>
        <Flex w="full" align="center" justify="flex-end">
          {headingText ? (
            <Heading
              fontSize="md"
              w="full"
              textAlign="left"
              textColor="textTertiary"
            >
              {headingText}
            </Heading>
          ) : null}
          {balance && !!currency ? (
            <InputBalanceButton
              flexShrink={0}
              alignSelf="flex-end"
              balance={balance}
              isDisabled={isDisabled}
              onClick={() => onValueChange(balance)}
            />
          ) : null}
        </Flex>
        <InputGroup>
          <NumericalInput
            size="lg"
            inputType="decimal"
            placeholder="Enter Amount"
            isDisabled={isDisabled}
            isInvalid={!!error}
            borderRadius={16}
            value={value}
            onValueChange={onValueChange}
            {...props}
            pr={
              rightElementDimensions
                ? Math.trunc(rightElementDimensions.contentBox.width + 12)
                : undefined
            }
          />
          <InputRightElement
            ref={rightElementRef}
            w="fit-content"
            h="full"
            pr={1}
          >
            {rightElement && rightElement}
            <Button
              data-cy="currency-picker-button"
              variant="secondary"
              bg="joeLight.400"
              size="md"
              borderRadius={12}
              leftIcon={
                currency ? (
                  <CurrencyLogo
                    address={currencyAddress}
                    symbol={currency.symbol}
                    boxSize={5}
                  />
                ) : undefined
              }
              rightIcon={
                isCurrencyPickerEnabled ? <ChevronDownIcon /> : undefined
              }
              isDisabled={isDisabled}
              pointerEvents={isCurrencyPickerEnabled ? 'auto' : 'none'}
              onClick={
                isCurrencyPickerEnabled ? onCurrencyPickerOpen : undefined
              }
              {...currencyPickerButtonProps}
            >
              {currency?.symbol ?? 'Select token'}
            </Button>
          </InputRightElement>
        </InputGroup>
        {error ? (
          <Text fontSize="sm" color="red" w="full">
            {error}
          </Text>
        ) : null}
      </VStack>
    </>
  )
}

export default CurrencyInput
