import { useToast } from '@chakra-ui/react'
import { useGetLatestAnnouncement } from 'hooks/dexbarn/announcement'
import React, { useEffect } from 'react'

import Announcement from './Announcement'

const getKey = (id: number): string => {
  return `dex-announcement-${id}`
}

// NOTE: Only for debugging; should stay false
const alwaysShowAnnouncement = false

export const useAnnouncements = () => {
  const toast = useToast()
  const { data: announcement, isLoading } = useGetLatestAnnouncement()

  useEffect(() => {
    if (isLoading || !announcement) {
      return
    }

    // Management of whether the announcement was shown already
    const key = getKey(announcement.id)
    if (window.localStorage.getItem(key) && !alwaysShowAnnouncement) {
      return
    }

    window.localStorage.setItem(key, key)

    toast({
      duration: announcement.durationMillis || 10000,
      position: 'bottom-right',
      render: ({ onClose }) => (
        <Announcement
          description={announcement.description}
          title={announcement.title}
          image={announcement.imageUrl}
          onClose={onClose}
        />
      )
    })
  }, [announcement, isLoading, toast])
}
