import { LBPairV21ABI } from '@fusionx/sdk-v2'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import { useContractRead } from 'wagmi'

const useLBPairFeeParameters = ({
  lbPairAddress
}: {
  lbPairAddress?: string
}) => {
  const chainId = useChainId()

  const read = useContractRead({
    abi: LBPairV21ABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    chainId,
    enabled: !!lbPairAddress,
    functionName: 'getStaticFeeParameters'
  })

  const feeParameters = read.data

  const { data: binStep } = useContractRead({
    abi: LBPairV21ABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    chainId,
    enabled: !!lbPairAddress,
    functionName: 'getBinStep'
  })

  console.log("feeparametrs", feeParameters, lbPairAddress, binStep);
  const baseFee =
    binStep && feeParameters?.baseFactor
      ? (binStep * feeParameters.baseFactor) / 10000
      : undefined

  return {
    ...read,
    data: {
      ...read.data,
      baseFee
    }
  }
}

export default useLBPairFeeParameters
