import { ROUTER_ADDRESS, RouterABI } from '@fusionx/sdk'
import { BigNumber } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { capturePrepareContractWriteError } from 'utils/logger'
import { useAccount, usePrepareContractWrite } from 'wagmi'

interface UseAddLiquidityConfigProps {
  amountADesired?: BigNumber
  amountAMin?: BigNumber
  amountBDesired?: BigNumber
  amountBMin?: BigNumber
  enabled?: boolean
  tokenA?: `0x${string}`
  tokenB?: `0x${string}`
}

const useAddLiquidityConfig = ({
  amountADesired,
  amountAMin,
  amountBDesired,
  amountBMin,
  enabled,
  tokenA,
  tokenB
}: UseAddLiquidityConfigProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const deadline = useTransactionDeadline()

  return usePrepareContractWrite({
    abi: RouterABI,
    address: getAddress(ROUTER_ADDRESS[chainId]),
    args:
      tokenA &&
        tokenB &&
        account &&
        deadline &&
        amountADesired &&
        amountAMin &&
        amountBDesired &&
        amountBMin
        ? [
          tokenA,
          tokenB,
          amountADesired,
          amountBDesired,
          amountAMin,
          amountBMin,
          account,
          deadline
        ]
        : undefined,
    cacheTime: 0,
    enabled:
      !!account &&
      !!deadline &&
      !!amountADesired &&
      !!amountBDesired &&
      !!tokenA &&
      !!tokenB &&
      enabled,
    functionName: 'addLiquidity',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })
}

export default useAddLiquidityConfig
