import { CheckCircleIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Grid,
  Heading,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import Web3Button from 'components/Web3Button'
import {
  ParsedAmounts,
  RemoveLiquidityBatch
} from 'hooks/pool/v2/useRemoveLiquidityInfoV2'
import useRemoveLiquidityV2, {
  RemoveLiquidityV2Option
} from 'hooks/pool/v2/useRemoveLiquidityV2'
import React, { useCallback, useEffect } from 'react'
import { useSlippageSettings } from 'state/settings/hooks'
import { getPriceFromBinId } from 'utils/bin'
import { formattedNum } from 'utils/format'
import { poolRemoveLiquidity } from 'utils/measure'
import { getCurrencyAddress } from 'utils/wrappedCurrency'

interface RemoveLiquidityDetailsRowProps {
  currency0: Currency
  currency1: Currency
  inversePriceRatios: boolean
  onRemoveLiquidityConfigError: () => void
  onRemoveLiquiditySuccess: () => void
  parsedAmounts: ParsedAmounts
  removeOption: RemoveLiquidityV2Option
  withdrawalInfo: RemoveLiquidityBatch
  binStep?: string
  isApproved?: boolean
}

const RemoveLiquidityDetailsRow = ({
  binStep,
  currency0,
  currency1,
  inversePriceRatios,
  isApproved,
  onRemoveLiquidityConfigError,
  onRemoveLiquiditySuccess,
  parsedAmounts,
  removeOption,
  withdrawalInfo
}: RemoveLiquidityDetailsRowProps) => {
  const {
    slippageSettings: { liquidityAmountV2: slippage }
  } = useSlippageSettings()

  const poolName = `${currency0.symbol}-${currency1.symbol}-${binStep}`

  const {
    isPreparingConfig,
    isRemovingLiquidity,
    isSuccess,
    prepareConfigError,
    removeLiquidity,
    resetRemoveLiquidity
  } = useRemoveLiquidityV2({
    amount0Min: withdrawalInfo.amountsToRemove.amountXMin.toString(),
    amount1Min: withdrawalInfo.amountsToRemove.amountYMin.toString(),
    binStep,
    currency0,
    currency1,
    enabled: isApproved,
    liquidityToRemove: withdrawalInfo.liquidityToRemove,
    onSuccess: onRemoveLiquiditySuccess,
    userPositionIds: withdrawalInfo.userPositionIds
  })

  const isBothTokensSelected =
    removeOption === RemoveLiquidityV2Option.BOTH_TOKENS
  const isTokenASelected =
    isBothTokensSelected || removeOption === RemoveLiquidityV2Option.TOKEN_A
  const isTokenBSelected =
    isBothTokensSelected || removeOption === RemoveLiquidityV2Option.TOKEN_B

  const _getPriceFromBinId = useCallback(
    (binId: number): string => {
      if (!binStep) {
        return ''
      }
      const binPrice = Number(
        getPriceFromBinId(binId, Number(binStep), currency0, currency1, 18) ??
        '0'
      )
      return (inversePriceRatios ? 1 / binPrice : binPrice).toPrecision(5)
    },
    [currency0, currency1, binStep, inversePriceRatios]
  )

  // reset hook on option change
  useEffect(() => {
    resetRemoveLiquidity()
  }, [removeOption, resetRemoveLiquidity])

  return (
    <Box bg="bgPrimary" p={4} borderRadius="xl">
      <Box w="full">
        {isSuccess ? (
          <HStack mb={4}>
            <Heading size="sm">Liquidity removed</Heading>
            <CheckCircleIcon color="green.400" />
          </HStack>
        ) : (
          <Heading size="sm" mb={4}>
            You will receive:
          </Heading>
        )}
        <Grid templateColumns={isBothTokensSelected ? '1fr 20px 1fr' : '1fr'}>
          {isTokenASelected ? (
            <HStack justify={isBothTokensSelected ? 'flex-start' : 'center'}>
              <CurrencyLogo
                address={getCurrencyAddress(currency0)}
                symbol={currency0.symbol}
                boxSize={6}
              />
              <Text fontSize="xl" fontWeight="bold">
                {formattedNum(parsedAmounts.amountX.toSignificant(5))}
              </Text>
            </HStack>
          ) : null}
          {removeOption === RemoveLiquidityV2Option.BOTH_TOKENS ? (
            <Text fontSize="xl">+</Text>
          ) : null}
          {isTokenBSelected ? (
            <HStack justify={isBothTokensSelected ? 'flex-end' : 'center'}>
              <CurrencyLogo
                address={getCurrencyAddress(currency1)}
                symbol={currency1.symbol}
                boxSize={6}
              />
              <Text fontSize="xl" fontWeight="bold">
                {formattedNum(parsedAmounts.amountY.toSignificant(5))}
              </Text>
            </HStack>
          ) : null}
        </Grid>
        <VStack mt={4}>
          {binStep ? (
            <Flex fontSize="sm" w="full" justify="space-between">
              <Text textColor="textSecondary">Price Range</Text>
              <Text
                fontWeight="semibold"
                textAlign="right"
              >{`${_getPriceFromBinId(
                inversePriceRatios
                  ? withdrawalInfo.userPositionIds[
                  withdrawalInfo.userPositionIds.length - 1
                  ]
                  : withdrawalInfo.userPositionIds[0]
              )} - ${_getPriceFromBinId(
                inversePriceRatios
                  ? withdrawalInfo.userPositionIds[0]
                  : withdrawalInfo.userPositionIds[
                  withdrawalInfo.userPositionIds.length - 1
                  ]
              )} ${inversePriceRatios ? currency0.symbol : currency1.symbol
                } per ${inversePriceRatios ? currency1.symbol : currency0.symbol
                }`}</Text>
            </Flex>
          ) : null}
          <Flex fontSize="sm" w="full" justify="space-between">
            <Text textColor="textSecondary">Amount Slippage Tolerance</Text>
            <Text fontWeight="semibold">{`${slippage}%`}</Text>
          </Flex>
          <Flex fontSize="sm" w="full" justify="space-between">
            <Text textColor="textSecondary">{`Minimum Expected ${currency0.symbol}`}</Text>
            <Text fontWeight="semibold">
              {formattedNum(parsedAmounts.amountXMin.toSignificant(5))}
            </Text>
          </Flex>
          <Flex fontSize="sm" w="full" justify="space-between">
            <Text textColor="textSecondary">{`Minimum Expected ${currency1.symbol}`}</Text>
            <Text fontWeight="semibold">
              {formattedNum(parsedAmounts.amountY.toSignificant(5))}
            </Text>
          </Flex>
        </VStack>
        <Flex flexDir="column" gap={4} mt={4} w="full">
          <Web3Button
            w="full"
            size="xl"
            variant="primary"
            colorScheme="accent"
            isDisabled={!isApproved || !removeLiquidity || isSuccess}
            isLoading={isRemovingLiquidity || isPreparingConfig}
            loadingText={
              isPreparingConfig ? 'Preparing transaction' : 'Removing liquidity'
            }
            onClick={() => {
              removeLiquidity?.()
              poolRemoveLiquidity(poolName)
            }}
          >
            {isSuccess ? 'Removed!' : 'Remove Liquidity'}
          </Web3Button>
          {prepareConfigError && !isSuccess ? (
            <Text
              fontSize="sm"
              onClick={onRemoveLiquidityConfigError}
              cursor="pointer"
              color="red.500"
              _hover={{ opacity: 0.5 }}
            >
              Failed to prepare transaction. Click here to refresh your position
              and try again.
            </Text>
          ) : null}
        </Flex>
      </Box>
    </Box>
  )
}

export default RemoveLiquidityDetailsRow
