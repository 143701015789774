import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency, WNATIVE, WNativeABI } from '@fusionx/sdk'
import { getAddress, parseEther } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { capturePrepareContractWriteError } from 'utils/logger'
import {
  getCurrencyAddress,
  isWrappedNativeCurrency
} from 'utils/wrappedCurrency'
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseWrapUnwrapNativeCurrencyProps {
  amount?: string
  currency0?: Currency
  currency1?: Currency
  onSuccess?: () => void
}

const useWrapUnwrapNativeCurrency = ({
  amount,
  currency0,
  currency1,
  onSuccess
}: UseWrapUnwrapNativeCurrencyProps) => {
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const isNativeInAndWrappedNativeOut =
    currency0 &&
    currency1 &&
    currency0.isNative &&
    isWrappedNativeCurrency(getCurrencyAddress(currency1), chainId)

  const isWrappedNativeInAndNativeOut =
    currency0 &&
    currency1 &&
    currency1.isNative &&
    isWrappedNativeCurrency(getCurrencyAddress(currency0), chainId)

  const { config } = usePrepareContractWrite({
    abi: WNativeABI,
    address: getAddress(WNATIVE[chainId].address),
    args:
      isWrappedNativeInAndNativeOut && amount
        ? [parseEther(amount)]
        : undefined,
    cacheTime: 0,
    enabled:
      (isNativeInAndWrappedNativeOut === true ||
        isWrappedNativeInAndNativeOut === true) &&
      !!amount,
    functionName: isNativeInAndWrappedNativeOut ? 'deposit' : 'withdraw',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    overrides:
      isNativeInAndWrappedNativeOut && amount
        ? { value: parseEther(amount) }
        : undefined
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description = isNativeInAndWrappedNativeOut
        ? `Deposit ${currency0.symbol}`
        : `Withdraw ${currency0?.symbol}`
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess
  })

  return {
    isLoading: isLoading || isWaitingForTransaction,
    state: isNativeInAndWrappedNativeOut
      ? 'Wrap'
      : isWrappedNativeInAndNativeOut
        ? 'Unwrap'
        : undefined,
    write
  }
}

export default useWrapUnwrapNativeCurrency
