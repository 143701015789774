import { Box, Flex, Hide, HStack, Image, Show } from '@chakra-ui/react'
import AppLogo from 'assets/logo.png'
import ConnectButton from 'components/ConnectButton'
import HeaderSearchPanel from 'components/HeaderSearchPanel'
import Submenu from 'components/Submenu'
import { joeFarm, joeLend, joeBridge } from 'constants/urls'
import useChainId from 'hooks/useChainId'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'

import ColorModeButton from './ColorModeButton'
import ExternalHeaderLink from './ExternalHeaderLink'
import HeaderLink from './HeaderLink'

const Header = (): JSX.Element => {
  const chainId = useChainId()
  const [isBorderVisible, setIsBorderVisible] = useState(false)

  useEffect(() => {
    const updatePosition = () => {
      setIsBorderVisible(window.scrollY > 0)
    }
    document.addEventListener('scroll', updatePosition)
    return () => {
      document.removeEventListener('scroll', updatePosition)
    }
  }, [])

  return (
    <Hide below="md">
      <Flex
        zIndex={11}
        position="fixed"
        alignItems="center"
        justifyContent="space-between"
        width="full"
        top={0}
        h="80px"
        bg="bgPrimary"
        borderBottom="1px"
        borderBottomColor={isBorderVisible ? 'border' : 'transparent'}
        padding={{
          base: '1rem 0.25rem 1rem 1rem',
          md: '1rem 2rem',
          xs: '1rem'
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="full"
          maxWidth="1616px"
          margin="0 auto"
          px={4}
          gap={4}
        >
          <Flex gap={6} w="full">
            <Box
              as={Link}
              aria-label="Link to Home page"
              to={`/${getChainSlug(chainId)}`}
              flexShrink={0}
            >
              <Image
                alt="fusionx-logo"
                fallbackSrc={AppLogo}
                srcSet={`${AppLogo} 1x ${AppLogo} 2x ${AppLogo} 3x`}
                boxSize={12}
                transition="transform 0.3s ease"
                _hover={{ transform: 'rotate(-5deg)' }}
              />
            </Box>
            <Hide below="lg">
              <HStack spacing={4} flexShrink={0}>
                <HeaderLink
                  aria-label="Link to Trade page"
                  to="trade"
                  title="Trade"
                />
                <HeaderLink
                  aria-label="Link to Pool page"
                  to="pool"
                  title="Pool"
                />
                <Hide above="xl">
                  <Submenu />
                </Hide>
                <Show above="xl">
                  <ExternalHeaderLink
                    aria-label="Link to Bridge page"
                    to={joeBridge.url}
                    title="Bridge"
                  />
                  {/* <HeaderLink
                    aria-label="Link to Stake page"
                    to="stake"
                    title="Stake"
                  />
                  <ExternalHeaderLink
                    aria-label="Link to Farm page"
                    to={joeFarm.url}
                    title="Farm"
                  />
                  <ExternalHeaderLink
                    aria-label="Link to Lend page"
                    to={joeLend.url}
                    title="Lend"
                  /> */}
                </Show>
              </HStack>
            </Hide>
            <Hide below="lg">
              <Box maxW="600px" w="full">
                <HeaderSearchPanel />
              </Box>
            </Hide>
          </Flex>
          <Hide below="lg">
            <Flex gap={4} flexShrink={0}>
              <ColorModeButton />
              <ConnectButton />
            </Flex>
          </Hide>
        </Flex>
      </Flex>
    </Hide>
  )
}

export default Header
