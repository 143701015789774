import { Button, Center, SimpleGrid, Text } from '@chakra-ui/react'
import { LiquidityDistribution } from '@fusionx/sdk-v2'
import { ReactComponent as DistributionBidAsk } from 'assets/distribution_bid_ask.svg'
import { ReactComponent as DistributionCurve } from 'assets/distribution_curve.svg'
import { ReactComponent as DistributionSpot } from 'assets/distribution_spot.svg'
import { ReactComponent as DistributionWide } from 'assets/distribution_wide.svg'
import React from 'react'

const getDistributionImage = (
  distribution: LiquidityDistribution
): JSX.Element => {
  switch (distribution) {
    case LiquidityDistribution.BID_ASK:
      return <DistributionBidAsk />
    case LiquidityDistribution.CURVE:
      return <DistributionCurve />
    case LiquidityDistribution.SPOT:
      return <DistributionSpot />
  }
}

const getDistributtionTitle = (distribution: LiquidityDistribution): string => {
  switch (distribution) {
    case LiquidityDistribution.BID_ASK:
      return 'Bid-Ask'
    case LiquidityDistribution.CURVE:
      return 'Curve'
    case LiquidityDistribution.SPOT:
      return 'Spot'
  }
}

interface DistributionOptionsProps {
  distribution: LiquidityDistribution
  onDistributionChange: (distribution: LiquidityDistribution) => void
}

const DistributionOptions = ({
  distribution,
  onDistributionChange
}: DistributionOptionsProps) => {
  const options = [
    LiquidityDistribution.SPOT,
    LiquidityDistribution.CURVE,
    LiquidityDistribution.BID_ASK,

  ]

  return (
    <SimpleGrid columns={{ base: 2, sm: 4 }} gap={2} w="full">
      {options.map((option, i) => (
        <Button
          key={i}
          variant="unstyled"
          border="1px"
          borderColor="border"
          borderRadius="lg"
          h="fit-content"
          py={3}
          _hover={{ bg: 'hover' }}
          isActive={distribution === option}
          _active={{ bg: 'accent.300', borderColor: 'accent.500' }}
          _dark={{ _active: { bg: 'rgb(50, 53, 102)' } }}
          onClick={() => onDistributionChange(option)}
        >
          <Center flexDir="column">
            {getDistributionImage(option)}
            <Text fontSize="xs" fontWeight="semibold">
              {getDistributtionTitle(option)}
            </Text>
          </Center>
        </Button>
      ))}
    </SimpleGrid>
  )
}

export default DistributionOptions
