import {
  arbitrum as wagmiArbitrum,
  arbitrumGoerli as wagmiArbitrumGoerli,
  avalanche as wagmiAvalanche,
  avalancheFuji as wagmiAvalancheFuji
} from '@wagmi/core/chains'
import ArbitrumIcon from 'assets/arbitrum.svg'
import AvalancheIcon from 'assets/avalanche.svg'
import BNBChainIcon from 'assets/bnb-chain.svg'
import MantleIcon from 'assets/mantle.svg'
import { Chain } from 'wagmi'
import { bsc as wagmiBsc, bscTestnet as wagmiBscTestnet } from 'wagmi/chains'

export interface JoeChain {
  iconUrl: string
  slug: string
}

const avalanche: Chain & JoeChain = {
  ...wagmiAvalanche,
  iconUrl: AvalancheIcon,
  slug: 'avalanche'
}

const fuji: Chain & JoeChain = {
  ...wagmiAvalancheFuji,
  iconUrl: AvalancheIcon,
  slug: 'fuji'
}

const arbitrum: Chain & JoeChain = {
  ...wagmiArbitrum,
  iconUrl: ArbitrumIcon,
  name: 'Arbitrum',
  slug: 'arbitrum'
}

const arbitrumGoerli: Chain & JoeChain = {
  ...wagmiArbitrumGoerli,
  iconUrl: ArbitrumIcon,
  slug: 'arbitrum-goerli'
}

const bsc: Chain & JoeChain = {
  ...wagmiBsc,
  iconUrl: BNBChainIcon,
  name: 'BNB Chain',
  slug: 'bnb-chain'
}

const bscTestnet: Chain & JoeChain = {
  ...wagmiBscTestnet,
  iconUrl: BNBChainIcon,
  slug: 'bnb-testnet'
}

export const mantleTestnet: Chain & JoeChain = {
  id: 5001,
  name: 'Mantle',
  network: 'mantle',
  nativeCurrency: {
    name: 'Mantle',
    symbol: 'MNT',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.testnet.mantle.xyz/'],
    },
    public: {
      http: ['https://rpc.ankr.com/mantle_testnet'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'Mantle Explorer',
      url: 'https://explorer.testnet.mantle.xyz',
    },
    default: {
      name: 'Mantle Explorer',
      url: 'https://explorer.testnet.mantle.xyz',
    },
  },
  contracts: {
    multicall3: {
      address: '0x35f317d35Ff2Ee326e56d872a2fF077E46Afa3EC',
      blockCreated: 11262105,
    },
  },
  testnet: true,
  iconUrl: MantleIcon,
  slug: 'mantle-testnet'
}

export const supportedChains = [
  // avalanche,
  // arbitrum,
  // bsc,
  // fuji,
  // arbitrumGoerli,
  // bscTestnet,
  mantleTestnet
]

export const selectableChains = [
  // avalanche, arbitrum, bsc, bscTestnet,
  mantleTestnet]

