
import { ChainId } from '@fusionx/sdk'
import { supportedChains } from 'constants/chains'
import { Chain } from 'types/dexbarn'

export const getChainSlug = (chainId: ChainId): string | undefined => {
  return supportedChains.find((chain) => chain.id === chainId)?.slug
}

export const getDexbarnChainParam = (chainId: ChainId): string => {
  switch (chainId) {
    case ChainId.AVALANCHE:
      return 'AVALANCHE'
    case ChainId.FUJI:
      return 'avalanche'
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARB_GOERLI:
      return 'arbitrum'
    case ChainId.BNB_CHAIN:
    case ChainId.BNB_TESTNET:
      return 'binance'
    case ChainId.MANTLE_TESTNET:
      return 'mantle testnet'
  }
}
