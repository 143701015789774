import { LinkBox, LinkOverlay, Td, Tr } from '@chakra-ui/react'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { UserVaultBalances, UserVaultPosition } from 'types/vault'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { getUserVaultBalanceUSD } from 'utils/vault'

import PoolTableNameColumn from '../PoolTableNameColumn'
import UserPositionValueTd from './UserPositionValueTd'

interface VaultPositionRowProps {
  position: UserVaultPosition
  balances?: UserVaultBalances
}

const VaultPositionRow = ({ balances, position }: VaultPositionRowProps) => {
  const chainId = useChainId()
  const userBalanceUSD = getUserVaultBalanceUSD(position.vault, balances)

  return (
    <LinkBox as={Tr} cursor="pointer" _hover={{ bg: 'hover' }}>
      <Td>
        <LinkOverlay
          aria-label={`${position.vault.tokenX.symbol}-${position.vault.tokenY.symbol} auto pool user position`}
          href={`/${getChainSlug(chainId)}/vault/${position.vault.id}`}
        />
        <PoolTableNameColumn
          token0={position.vault.tokenX}
          token1={position.vault.tokenY}
          fees={`${position.vault.lbPair.baseFeePct}%`}
        />
      </Td>
      <UserPositionValueTd
        symbol0={position.vault.tokenX.symbol}
        symbol1={position.vault.tokenY.symbol}
        pooledTokens0={balances?.fmtAmountX}
        pooledTokens1={balances?.fmtAmountY}
      />
      <Td textAlign="right">
        {userBalanceUSD ? formattedNum(userBalanceUSD, true) : '--'}
      </Td>
    </LinkBox>
  )
}

export default VaultPositionRow
