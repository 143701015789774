import { useQuery } from '@tanstack/react-query'
import { ChainId, EXCHANGE_SUBGRAPH } from '@fusionx/sdk'
import { BLACKLISTED_TOKENS } from 'constants/tokens'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { tokenDaysQuery } from 'graphql/dexV1'
import request from 'graphql-request'
import { useMemo } from 'react'
import { Token as DexbarnToken } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

import { useDexbarnGet } from './useDexbarn'

dayjs.extend(utc)

interface TokenDayDataV1 {
  date: number
  liquidity: string
  liquidityAVAX: string
  liquidityUSD: string
  priceUSD: string
  volume: string
  volumeAVAX: string
  volumeUSD: string
}

interface TokenData {
  decimals: string
  id: string
  name: string
  symbol: string
  volumeUSD: string
}

interface TokenDataV1 extends TokenData {
  dayData: TokenDayDataV1[]
}

interface Token {
  address: string
  price: number
  symbol: string
  volume: number
  pctChange?: number
}

interface UseTopTradedTokensProps {
  chainId: ChainId
}

const useTopTradedTokens = ({
  chainId
}: UseTopTradedTokensProps): {
  isLoading: boolean
  tokens: Token[]
} => {
  try {
    const subgraphUrlV1 = EXCHANGE_SUBGRAPH[chainId]
    const chainParam = getDexbarnChainParam(chainId)

    const utcSevenDayBack = dayjs().utc().startOf('day').subtract(7, 'day').unix()

    // fetch tokens traded on v1 pools from subgraph
    const { data: tokensDataV1, isLoading: isLoadingV1 } = useQuery(
      ['TopTradedTokensQuery', subgraphUrlV1],
      () =>
        request<{
          tokens: TokenDataV1[]
        }>(subgraphUrlV1, tokenDaysQuery, {
          date: utcSevenDayBack,
          first: 50
        })
    )

    // fetch tokens traded on v2 pools from dexbarn
    const fetchTopTradedTokensV2 = useDexbarnGet<DexbarnToken[]>(
      `/v1/tokens/${chainId}?status=unverified&orderBy=volumeUSD&filterBy=1d&pageSize=50`
    )
    const { data: tokensV2, isLoading: isLoadingV2 } = useQuery<DexbarnToken[]>(
      ['TopTradedTokensV2Query', chainParam],
      async () => {
        try {
          const data = await fetchTopTradedTokensV2()
          return data ?? []
        } catch (error) {
          console.log("TopTradedTokensV2Query Error:", error)
          return [];
        }

      }
    )

    const tokensV1 = useMemo(() => {
      if (!tokensDataV1) return []

      const utcToday = dayjs().utc().startOf('day').unix()
      const utcOneDayBack = dayjs().utc().startOf('day').subtract(1, 'day').unix()
      const utcTwoDayBack = dayjs().utc().startOf('day').subtract(2, 'day').unix()

      // index day data by token address and dte
      const indexedTokenDay: {
        [address: string]: {
          decimals: string
          id: string
          name: string
          symbol: string
          oneDayData?: TokenDayDataV1
          sevenDayData?: TokenDayDataV1
          todayData?: TokenDayDataV1
          twoDayData?: TokenDayDataV1
        }
      } = {}

      const tokensData = tokensDataV1.tokens.filter(
        (token) => !BLACKLISTED_TOKENS.includes(token.id)
      )
      console.log("tokensData", tokensData);

      // subgraph updates slowly so there will be times when we cannot retrieve the current day's data and will have to rely on old data
      const todayDataNotAvailable = tokensData.every((token) => {
        const { dayData } = token
        const todayData = dayData.find(
          (d: { date: number }) => d.date === utcToday
        )
        return todayData && Object.keys(todayData).length === 0
      })

      tokensData.forEach((data) => {
        const { dayData, decimals, id, name, symbol } = data
        const todayData = dayData.find(
          (d: { date: number }) => d.date === utcToday
        )
        const oneDayData = dayData.find(
          (d: { date: number }) => d.date === utcOneDayBack
        )
        const twoDayData = dayData.find(
          (d: { date: number }) => d.date === utcTwoDayBack
        )
        const sevenDayData = dayData.find(
          (d: { date: number }) => d.date === utcSevenDayBack
        )
        indexedTokenDay[id] = {
          decimals,
          id,
          name,
          oneDayData,
          sevenDayData,
          symbol,
          todayData,
          twoDayData
        }
      })

      const tokens = []
      for (const [, data] of Object.entries(indexedTokenDay)) {
        const todayData = todayDataNotAvailable
          ? data['oneDayData']
          : data['todayData']
        const oneDayBackData = todayDataNotAvailable
          ? data['twoDayData']
          : data['oneDayData']
        const sevenDayBackData = data['sevenDayData']

        // get prices
        const price = todayData ? parseFloat(todayData.priceUSD) : 0
        const priceYesterday = oneDayBackData
          ? parseFloat(oneDayBackData.priceUSD)
          : 0
        const priceLastWeek = sevenDayBackData
          ? parseFloat(sevenDayBackData.priceUSD)
          : 0

        // price change in decimal
        const priceChange =
          priceYesterday > 0
            ? (price - priceYesterday) / priceYesterday
            : undefined
        const sevenDayPriceChange = priceLastWeek
          ? (price - priceLastWeek) / priceLastWeek
          : undefined

        // liquidity
        const volumeYesterday = oneDayBackData
          ? parseFloat(oneDayBackData.volumeUSD)
          : 0

        const entry = {
          ...data,
          price,
          priceChange,
          priceLastWeek,
          priceYesterday,
          sevenDayPriceChange,
          volumeYesterday
        }

        console.log("tokenss", tokens);
        // skip entries that have > 690M volume (random)
        if (volumeYesterday < 69e7) {
          tokens.push(entry)
        }
      }
      return tokens
    }, [tokensDataV1, utcSevenDayBack])

    const tokens: Token[] = useMemo(() => {
      if (!tokensV1 || !tokensV2) return []

      const v1TokensNotTradedOnV2 = tokensV1.filter(
        (token) => !tokensV2.find((t) => t.tokenAddress === token.id)
      )

      return [
        ...tokensV2.map((token) => {
          const tokenV1 = tokensV1.find((t) => t.id === token.tokenAddress)
          return {
            address: token.tokenAddress,
            pctChange: token.pctChange,
            price: token.priceUsd,
            symbol: token.symbol,
            volume: token.volume + (tokenV1?.volumeYesterday ?? 0)
          }
        }),
        ...v1TokensNotTradedOnV2.map((token) => ({
          address: token.id,
          pctChange: token.priceChange,
          price: token.price,
          symbol: token.symbol,
          volume: token.volumeYesterday
        }))
      ]
    }, [tokensV1, tokensV2])

    return { isLoading: isLoadingV1 || isLoadingV2, tokens }
  } catch (error) {
    console.error("Error: fetch top traded tokens", error);
    return { isLoading: false, tokens: [] }
  }
}

export default useTopTradedTokens
