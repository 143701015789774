import { tableAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  td: {
    fontSize: 'sm',
    fontWeight: 'bold'
  },
  th: {
    color: 'textTertiary',
    fontWeight: 'normal'
  }
})

const variantSimple = definePartsStyle(() => ({
  td: {
    borderBottom: '0'
  },
  th: {
    _dark: {
      color: 'joeDark.300'
    },
    borderBottom: '0',
    color: 'joeLight.700'
  }
}))

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { simple: variantSimple }
})
