import { useQuery } from '@tanstack/react-query'
import { DEX_V2_SUBGRAPH } from '@fusionx/sdk-v2'
import { vaultDayDatasQuery } from 'graphql/dexV2'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { useMemo } from 'react'
import { VaultSubgraphModels } from 'types/vault'

interface VaultAnalyticsChartData {
  date: number
  value: number
}

interface UseVaultAnalyticsProps {
  vaultAddress?: string
}

const useVaultAnalytics = ({ vaultAddress }: UseVaultAnalyticsProps) => {
  const chainId = useChainId()
  const subgraphUrl = DEX_V2_SUBGRAPH[chainId]

  const { data, isLoading } = useQuery<{
    vaultDayDatas: VaultSubgraphModels.VaultDayData[]
  }>(
    ['VaultAnalyticsQuery', subgraphUrl, vaultAddress],
    () =>
      request(subgraphUrl, vaultDayDatasQuery, {
        vaults: vaultAddress ? [vaultAddress.toLowerCase()] : undefined
      }),
    { enabled: !!vaultAddress }
  )

  const chartData = useMemo(() => {
    if (!data) return undefined
    return data.vaultDayDatas.reduce<{
      fees: VaultAnalyticsChartData[]
      liquidity: VaultAnalyticsChartData[]
    }>(
      (previousValue, currentValue) => {
        previousValue['liquidity'].unshift({
          date: currentValue.date,
          value: parseFloat(currentValue.totalValueLockedUSD)
        })
        previousValue['fees'].unshift({
          date: currentValue.date,
          value: parseFloat(currentValue.collectedFeesUSD)
        })
        return previousValue
      },
      { fees: [], liquidity: [] }
    )
  }, [data])

  return {
    chartData,
    isLoading
  }
}

export default useVaultAnalytics
