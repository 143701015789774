import { ChainId } from '@fusionx/sdk'
import { VAULTS_INFO } from 'constants/vaults'
import { ethers } from 'ethers'
import { UserVaultBalances, Vault, VaultSubgraphModels } from 'types/vault'
import { formattedNum } from './format'

export const convertSubgraphVaultIntoVault = (
  vault: VaultSubgraphModels.Vault,
  chainId: ChainId
): Vault => {
  const apr7D =
    vault.dayData
      .map((dayData: { collectedFeesUSD: string; totalValueLockedUSD: string }) =>
        parseFloat(dayData.collectedFeesUSD) /
        parseFloat(dayData.totalValueLockedUSD)
      )
      .reduce((total: any, current: any) => total + current, 0) / vault.dayData.length
  const feesUsd7D = vault.dayData.reduce(
    (total: number, dayData: { collectedFeesUSD: string }) => total + parseFloat(dayData.collectedFeesUSD),
    0
  )
  return {
    apr7D,
    description: VAULTS_INFO[chainId][vault.id]?.description,
    feesUsd7D,
    id: vault.id,
    lbPair: {
      baseFeePct: Number(vault.lbPair.baseFeePct),
      binStep: Number(vault.lbPair.binStep),
      id: vault.lbPair.id
    },
    managementFeePct: parseFloat(vault.strategy.aumAnnualFee) / 100,
    name: VAULTS_INFO[chainId][vault.id]?.name ?? vault.name,
    strategy: {
      id: vault.strategy.id
    },
    tokenX: vault.tokenX,
    tokenY: vault.tokenY,
    totalValueLockedUSD: parseFloat(vault.totalValueLockedUSD)
  }
}

export const getUserVaultBalanceUSD = (
  vault: Vault,
  userVaultBalances?: UserVaultBalances
): number | undefined => {
  const userAmountX = userVaultBalances?.amountX
    ? ethers.utils.formatUnits(userVaultBalances.amountX, vault.tokenX.decimals)
    : undefined
  const userAmountY = userVaultBalances?.amountY
    ? ethers.utils.formatUnits(userVaultBalances.amountY, vault.tokenY.decimals)
    : undefined
  const userBalanceUSD =
    userVaultBalances?.tokenXUsdPrice &&
      userVaultBalances.tokenYUsdPrice &&
      userAmountX &&
      userAmountY
      ? Number(userAmountX) * userVaultBalances.tokenXUsdPrice +
      Number(userAmountY) * userVaultBalances.tokenYUsdPrice
      : undefined
  return userBalanceUSD
}

export const getVaultRowFormattedValues = (
  vault: Vault,
  userVaultBalances?: UserVaultBalances
) => {
  const userBalanceUSD = getUserVaultBalanceUSD(vault, userVaultBalances)
  return {
    apr7D: `${formattedNum(vault.apr7D * 100, false, 2)}%`,
    feesUsd7D: formattedNum(vault.feesUsd7D, true),
    liquidity: formattedNum(vault.totalValueLockedUSD, true),
    myFunds: userBalanceUSD ? formattedNum(userBalanceUSD, true) : '--'
  }
}
