import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import { UserPositionRowProps } from 'pages/Pool/UserPositions/UserPositionRow'
import { useMemo } from 'react'
import { UserLBPosition } from 'types/dexbarn'
import { UserPoolPosition } from 'types/poolV1'

interface UseUserPositionsRowsProps {
  lbPositions: UserLBPosition[]
  positions: UserPoolPosition[]
}

const useUserPositionsRows = ({
  lbPositions,
  positions
}: UseUserPositionsRowsProps) => {
  // fetch token prices for v1 pools
  const poolTokensV1 = useMemo(
    () =>
      positions
        .map((position) => position.token0.id)
        .concat(positions.map((position) => position.token1.id))
        .filter(Boolean) as string[],
    [positions]
  )
  const { data: usdPrices } = useTokenPriceUSD({
    tokens: poolTokensV1
  })
  const usdPricesByToken = useMemo(
    () =>
      usdPrices?.reduce((acc, price, i) => {
        acc[poolTokensV1[i]] = price
        return acc
      }, {} as { [tokenAddress: string]: number }),
    [usdPrices, poolTokensV1]
  )

  // convert v1 positions
  const v1Rows: UserPositionRowProps[] = positions.map((position) => {
    let totalAmountUsd: number | undefined
    if (
      position.token0.id &&
      position.token1.id &&
      usdPricesByToken &&
      usdPricesByToken[position.token0.id] &&
      usdPricesByToken[position.token1.id]
    ) {
      totalAmountUsd =
        position.pooledToken0 * usdPricesByToken[position.token0.id] +
        position.pooledToken1 * usdPricesByToken[position.token1.id]
    }
    return {
      feePct: 0.3,
      poolAddress: position.id,
      tokenX: {
        ...position.token0,
        address: position.token0.id,
        amount: position.pooledToken0,
        decimals: parseInt(position.token0.decimals)
      },
      tokenY: {
        ...position.token1,
        address: position.token1.id,
        amount: position.pooledToken1,
        decimals: parseInt(position.token1.decimals)
      },
      totalAmountUsd
    }
  })

  // convert v2 positions
  const v2Rows: UserPositionRowProps[] = lbPositions.map((position) => ({
    feePct: position.lbBaseFeePct,
    lbBinStep: position.lbBinStep,
    poolAddress: position.poolAddress,
    tokenX: position.tokenX,
    tokenY: position.tokenY,
    totalAmountUsd:
      position.tokenX.amount * position.tokenX.priceUsd +
      position.tokenY.amount * position.tokenY.priceUsd
  }))

  const allPositions = v1Rows.concat(v2Rows)

  // sort by total amount in usd
  return allPositions.sort(
    (a, b) => (b.totalAmountUsd ?? 0) - (a.totalAmountUsd ?? 0)
  )
}

export default useUserPositionsRows
