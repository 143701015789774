import {
  Box,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import { JOE_ADDRESS } from '@fusionx/sdk'
import Web3Button from 'components/Web3Button'
import { BigNumber, ethers } from 'ethers'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import React, { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { claimVeJoe } from 'utils/measure'

interface VeJoeClaimPanelProps {
  claim: () => void
  isClaiming: boolean
  isLoading: boolean
  userBalance: string
  veJoeBalance: string
  veJoePerDay: string
  pendingVeJoeBalance?: BigNumber
}

const VeJoeClaimPanel = ({
  claim,
  isClaiming,
  isLoading,
  pendingVeJoeBalance,
  userBalance,
  veJoeBalance,
  veJoePerDay
}: VeJoeClaimPanelProps) => {
  // get joe and reward tokens
  const chainId = useChainId()
  const joeTokenAddress = JOE_ADDRESS[chainId]
  const {
    isLoading: isLoadingCurrencies,
    tokens: [joeToken]
  } = useSdkCurrencies({
    addresses: [joeTokenAddress]
  })

  const fmtPendingVeJoeBalance = useMemo(() => {
    return pendingVeJoeBalance
      ? formattedNum(
        ethers.utils.formatUnits(pendingVeJoeBalance, 18),
        false,
        2
      )
      : '0'
  }, [pendingVeJoeBalance])

  return (
    <Box w="full" p={6} bg="bgTertiary" borderRadius="xl">
      <SimpleGrid gap={2} columns={2}>
        <Heading size="sm">Staked JOE</Heading>
        <Heading size="sm">veJOE Balance</Heading>
        <HStack align="flex-start">
          <VStack spacing={1} align="flex-start">
            <Skeleton isLoaded={!isLoading && !isLoadingCurrencies}>
              <Flex align="center" gap={2}>
                <Text fontSize="lg">
                  {userBalance} {joeToken?.symbol}
                </Text>
              </Flex>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Text fontSize="sm" color="textSecondary">
                {veJoePerDay}
              </Text>
            </Skeleton>
          </VStack>
        </HStack>
        <HStack align="flex-start">
          <VStack spacing={1} align="flex-start">
            <Skeleton isLoaded={!isLoading}>
              <Text fontSize="lg">{veJoeBalance} veJOE</Text>
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Text fontSize="sm" color="textSecondary">
                {fmtPendingVeJoeBalance} pending veJOE
              </Text>
            </Skeleton>
          </VStack>
        </HStack>
      </SimpleGrid>
      <Web3Button
        colorScheme="accent"
        variant="primary"
        size="xl"
        w="full"
        marginTop={6}
        isDisabled={
          !claim || !pendingVeJoeBalance || pendingVeJoeBalance.isZero()
        }
        isLoading={isClaiming}
        loadingText="Claiming veJOE"
        onClick={() => {
          claim()
          claimVeJoe()
        }}
      >
        Claim
      </Web3Button>
    </Box>
  )
}

export default VeJoeClaimPanel
