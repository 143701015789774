import { Box, Flex, HStack, Heading, Spacer, Text } from "@chakra-ui/react";
import PageHelmet from "components/PageHelmet";
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from "constants/pool";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Currency, Token } from "@fusionx/sdk";
import NumericalInput from "components/NumericalInput";
import Web3Button from "components/Web3Button";
import { ZERO_ADDRESS } from "constants/tokens";
import { BigNumber, ethers } from "ethers";
import useCreateNewPoolV21 from "hooks/pool/v2/useCreateNewPoolV21";
import useGetActiveBinId from "hooks/pool/v2/useCustomPriceHelper";
import useLBFactoryData from "hooks/pool/v2/useLBFactoryV21";
import useLBPairData from "hooks/pool/v2/useLBPairData";
import useChainId from "hooks/useChainId";
import useSdkCurrencies from "hooks/useSdkCurrencies";
import { WarningIcon } from "theme/icons";
import { getChainSlug } from "utils/chains";
import CurrencyPickerField from "./Component/CurrencyPicker";
import QuoteTokenPicker from "./Component/QuoteTokenPicker";
import SelectBinStepMenu from "./Component/SelectBinStepMenu";

export type typeBinStepConfig = {
  index: number;
  fee: string;
  baseFee: string;
};

const CreatePoolV21 = () => {
  // const { token0Address, token1Address } = useParams();
  const location = useLocation();
  const chainId = useChainId();
  const [binStepList, setBinStepList] = useState<typeBinStepConfig[]>([]);
  const [_currency, setCurrency] = useState<Currency | undefined>();
  const [_selectedQuoteToken, setQuoteToken] = useState<Token | undefined>();
  const [selectedBinStep, setSelectedBinStep] = useState<
    typeBinStepConfig | undefined
  >(binStepList.length > 0 ? binStepList[binStepList.length - 1] : undefined);
  const [activePrice, setActivePrice] = useState<string | undefined>();
  const navigate = useNavigate()
  const onSuccess = () => {
    const navigationUrl = `/${getChainSlug(chainId)}/pool/v2/${_currency?.isNative ? _currency?.symbol : _currency?.address
      }/${_selectedQuoteToken?.isNative
        ? _selectedQuoteToken?.symbol
        : _selectedQuoteToken?.address
      }/${selectedBinStep?.fee}`
    navigate(navigationUrl)
  }

  const { quoteAssetsAddressList, openBinSteps, presetListByBinStep } =
    useLBFactoryData();
  const { isLoading: isLoadingQuoteAssetsInfo, tokens } = useSdkCurrencies({
    addresses: quoteAssetsAddressList,
  });

  useEffect(() => {
    if (
      (openBinSteps as Array<number>).length !== 0 &&
      binStepList.length === 0
    ) {
      const dataList: typeBinStepConfig[] = [];
      (openBinSteps as Array<number>).forEach((binStep, index) => {
        dataList.push({
          index: index,
          fee: binStep.toString(),
          baseFee: BigNumber.from(
            presetListByBinStep[binStep] ? presetListByBinStep[binStep].baseFactor.mul(binStep) : 1
          )
            .div(10000)
            .toString(),
        });
      });
      if (dataList.length > 0) {
        setBinStepList(dataList);
        setSelectedBinStep(dataList[dataList.length - 1]);
      }
    }
  }, [openBinSteps, presetListByBinStep]);
  // Calculate Decimals for Price
  const decimalsForPrice = 18 + (_selectedQuoteToken?.decimals ?? 18) - (_currency?.decimals ?? 18);
  // getBinStep
  const { activeBinId, isLoading: isLoadingActiveBinId } = useGetActiveBinId(
    BigNumber.from(
      BigNumber.from(
        activePrice ? ethers.utils.parseUnits(activePrice) : 1
      ).mul(BigNumber.from(10).pow(decimalsForPrice))
    ).div(BigNumber.from(10).pow(18)),
    Number(selectedBinStep?.fee)
  );

  const {
    createNewPoolV21,
    isLoading,
    isPreparingConfig,
    isSuccess,
    prepareConfigError,
  } = useCreateNewPoolV21({
    currency0: _currency,
    currency1: _selectedQuoteToken,
    activeBinId:
      activeBinId && !isLoadingActiveBinId
        ? BigNumber.from(activeBinId)
        : undefined,
    binStep: BigNumber.from(Number(selectedBinStep?.fee ?? 0)),
    onSuccess: onSuccess,
  });

  const { isLoading: isLoadingLBPairData, lbPairInfo } = useLBPairData({
    binStep: BigNumber.from(Number(selectedBinStep?.fee ?? 0)).toString(),
    currency0: _currency,
    currency1: _selectedQuoteToken,
  });
  const isPairAlreadyCreated =
    lbPairInfo &&
    lbPairInfo.LBPair !== ZERO_ADDRESS &&
    lbPairInfo.binStep !== 0;

  return (
    <>
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <Flex
        justify="center"
        pt={{ base: 6, md: 24 }}
        maxW="1200px"
        margin="0 auto"
        minH="calc(100vh - 80px)"
        px={4}
      >
        <Flex
          flexDir="column"
          w={{ base: "full", md: "600px" }}
          h="fit-content"
          gap={4}
          p={4}
          borderRadius="xl"
          border="1px"
          borderColor="border"
          bg="bgSecondary"
        >
          <Heading size="md">Create New Pool</Heading>

          <Text color="textSecondary" fontSize="sm">
            Select Token
          </Text>
          <CurrencyPickerField
            currency={_currency}
            onCurrencyChange={(currency) => setCurrency(currency)}
            currencyAddress={
              _currency?.isNative ? undefined : _currency?.address
            }
          />
          <Spacer />

          <Text color="textSecondary" fontSize="sm">
            Select Quote Token
          </Text>
          <QuoteTokenPicker
            quoteTokenList={isLoadingQuoteAssetsInfo ? [] : (tokens as Token[])}
            onTokenSelect={(token: Token) => setQuoteToken(token)}
            selectedQuoteToken={_selectedQuoteToken}
          />
          <Spacer />

          <Text color="textSecondary" fontSize="sm">
            Select Bin Step
          </Text>
          <SelectBinStepMenu
            binSteps={binStepList}
            selectedBinStep={selectedBinStep}
            setBinStep={setSelectedBinStep}
          />
          <Spacer />

          <Text color="textSecondary" fontSize="sm">
            Enter Active Price
          </Text>
          <NumericalInput
            onValueChange={(value) => setActivePrice(value)}
            placeholder="0.0"
          />
          <Spacer />

          <Spacer />
          <Web3Button
            variant="primary"
            colorScheme="accent"
            isDisabled={
              !createNewPoolV21 ||
              isLoading ||
              isPreparingConfig ||
              prepareConfigError !== null ||
              !activePrice ||
              activePrice === "0" ||
              !selectedBinStep ||
              isPairAlreadyCreated
            }
            onClick={() => createNewPoolV21?.()}
          >
            Create Pool
          </Web3Button>
          <Spacer />
          {isPairAlreadyCreated && _currency && _selectedQuoteToken ? (

            <Box borderRadius="2xl" bg="bgTertiary" w="full" px={6} py={4} >
              <HStack >
                <WarningIcon mr={{ base: 0, md: 1 }} />
                <Text fontSize="sm">
                  This pool already exists and can't be created again.{" "}
                  <a
                    href={`/${getChainSlug(chainId)}/pool/v2/${_currency?.isNative ? _currency?.symbol : _currency?.address
                      }/${_selectedQuoteToken?.isNative
                        ? _selectedQuoteToken?.symbol
                        : _selectedQuoteToken?.address
                      }/${lbPairInfo.binStep}`}
                    style={{ textDecoration: "underline" }}
                  >
                    Click here to view pool.
                  </a>
                </Text>
              </HStack>
            </Box>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default CreatePoolV21;
