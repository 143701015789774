import {
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { SJoeRemittanceDatas } from 'types/stake'
import { formattedNum } from 'utils/format'

interface RemittancesTableProps {
  remittanceDatas: SJoeRemittanceDatas[] | undefined
}

const RemittancesTable = ({ remittanceDatas }: RemittancesTableProps) => {
  return (
    <VStack w="full" alignItems="left" spacing={4}>
      <Heading size="md">Remittances</Heading>
      <TableContainer>
        <Table maxWidth={{ md: 'full', sm: '90%' }} size="sm">
          <Thead>
            <Tr>
              <Th px={0}>Timestamp</Th>
              <Th px={0}>Fees Collected</Th>
              <Th px={0} textAlign="right">
                USD Remitted
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {remittanceDatas &&
              remittanceDatas.map((data, key) =>
                RemittanceRow({ ...data, key })
              )}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  )
}

interface RemittanceRowInterface extends SJoeRemittanceDatas {
  key: number
}

const RemittanceRow = ({
  amount0,
  amount1,
  key,
  timestamp,
  token0Symbol,
  token1Symbol,
  usdRemitted
}: RemittanceRowInterface) => {
  const description = `${formattedNum(
    amount0,
    false,
    2
  )} ${token0Symbol} / ${formattedNum(amount1, false, 2)} ${token1Symbol}`

  const fmtTimestamp = format(Number(timestamp) * 1000, 'yyyy-MM-dd hh:mm a')
  const fmtUsdRemitted = formattedNum(usdRemitted, true, 2)
  return (
    <Tr maxWidth="100%" key={key}>
      <Td px={0} fontWeight="normal" fontSize={{ base: 10, lg: 14 }}>
        {fmtTimestamp}
      </Td>
      <Td
        px={0}
        w="fit-content"
        fontSize={{ base: 10, lg: 14 }}
        fontWeight="normal"
      >
        {description}
      </Td>
      <Td
        px={0}
        fontWeight="normal"
        fontSize={{ base: 10, lg: 14 }}
        textAlign="right"
      >
        {fmtUsdRemitted}
      </Td>
    </Tr>
  )
}

export default RemittancesTable
