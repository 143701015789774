import { Token } from '@fusionx/sdk'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils.js'
import useLBPairBalances from 'hooks/pool/v2/useLBPairBalances'
import { useMemo } from 'react'

interface UseLBPositionProps {
  activeBinId?: BigNumber
  lbPairAddress?: string
  owner?: string
  token0?: Token
  token1?: Token
}

const useLBPosition = ({
  activeBinId,
  lbPairAddress,
  owner,
  token0,
  token1
}: UseLBPositionProps) => {
  // get user's positions and balances
  const {
    data: userBalances,
    isFetching,
    isInitialLoading,
    refetch
  } = useLBPairBalances({
    lbPairAddress,
    owner
  })

  // get pooled tokens
  const [pooledTokens0, pooledTokens1] = useMemo(() => {
    if (!token0 || !token1 || !userBalances || !activeBinId) {
      return [undefined, undefined]
    }
    try {
      const totalAmountX = userBalances.amounts.reduce(
        (acc, { amountX }) => acc.add(amountX),
        BigNumber.from(0)
      )
      const totalAmountY = userBalances.amounts.reduce(
        (acc, { amountY }) => acc.add(amountY),
        BigNumber.from(0)
      )
      return [
        Number(formatUnits(totalAmountX.toString(), token0.decimals)),
        Number(formatUnits(totalAmountY.toString(), token1.decimals))
      ]
    } catch {
      return [undefined, undefined]
    }
  }, [token0, token1, userBalances, activeBinId])

  return {
    isLoadingLBPosition: isInitialLoading || isFetching,
    pooledTokens0,
    pooledTokens1,
    refetchLBPosition: refetch,
    userBalances
  }
}

export default useLBPosition
