import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  useToken
} from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import useChainId from 'hooks/useChainId'
import React, { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts'
import { ArrowLeftRightIcon } from 'theme/icons'
import { LBPairDistribution } from 'types/poolV2'
import { formattedNum } from 'utils/format'
import { inverseLBPairDistributions } from 'utils/poolV2'
import { computeAndParsePriceFromBin } from 'utils/prices'
import { wrappedCurrency } from 'utils/wrappedCurrency'

import LBPairDistributionChartTooltip from './LBPairDistributionChartTooltip'

interface LBPairDistributionChartProps {
  activeBinId: number
  binStep: string
  data: LBPairDistribution[]
  title: string
  bottomRightElement?: JSX.Element
  currency0?: Currency
  currency1?: Currency
}

const LBPairDistributionChart = ({
  activeBinId,
  binStep,
  bottomRightElement,
  currency0,
  currency1,
  data: initialData,
  title
}: LBPairDistributionChartProps) => {
  const [
    graphPurpleDark,
    graphPurpleLight,
    graphGreenDark,
    graphGreenLight,
    textSecondary
  ] = useToken('colors', [
    'graphPurpleDark',
    'graphPurpleLight',
    'graphGreenDark',
    'graphGreenLight',
    'textSecondary'
  ])
  const [focusBar, setFocusBar] = useState<number | undefined>()

  // Allow users to toggle price ratios
  const [isPriceRatioInversed, setIsPriceRatioInversed] = useState(false)
  const data = useMemo(
    () =>
      isPriceRatioInversed
        ? inverseLBPairDistributions(initialData)
        : initialData,
    [initialData, isPriceRatioInversed]
  )

  const chainId = useChainId()
  const activeBinIndex = data.findIndex((el) => el.isActiveBin)
  const activeBin = activeBinIndex >= 0 ? data[activeBinIndex] : undefined
  const activeBinPrice = useMemo((): string | undefined => {
    if (activeBin) {
      return activeBin.price
    }
    const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
    const wrappedCurrency1 = wrappedCurrency(currency1, chainId)
    if (!wrappedCurrency0 || !wrappedCurrency1) {
      return undefined
    }
    const price = computeAndParsePriceFromBin(
      activeBinId,
      Number(binStep),
      wrappedCurrency0,
      wrappedCurrency1,
      wrappedCurrency1?.decimals
    )
    return isPriceRatioInversed ? (1 / Number(price)).toFixed(18) : price
  }, [
    activeBinId,
    activeBin,
    binStep,
    currency0,
    currency1,
    isPriceRatioInversed,
    chainId
  ])

  // custom ticks to always show active bin
  const xAxisTicks = useMemo(() => {
    const interval = 5 // set interval

    // active bin included in bins to display
    if (activeBinIndex >= 0) {
      const activeBinPrice = data[activeBinIndex]?.price

      const higherBins = data
        .filter(
          (_el, i) =>
            i > activeBinIndex && (i - activeBinIndex) % interval === 0
        )
        .map((el) => el.price)
      const lowerBins = data
        .filter(
          (_el, i) =>
            i < activeBinIndex && (activeBinIndex - i) % interval === 0
        )
        .map((el) => el.price)

      return [...lowerBins, activeBinPrice, ...higherBins]
    }

    // data loaded but active bin not among bins to display
    else if (data[0] && data[0].price !== '0') {
      return data
        .filter((_el, i) => Math.abs(i - activeBinIndex) % interval === 0)
        .map((el) => el.price)
    }

    // data not loaded yet
    return undefined
  }, [data, activeBinIndex])

  const activeBinReserveXRatio = useMemo(() => {
    if (activeBin) {
      const { amountYPct } = activeBin
      const amountYPctNum = Number(amountYPct)
      const amountXPctNum = 100 - amountYPctNum
      return amountXPctNum
    }
    return 50
  }, [activeBin])

  console.log("activeBinReserveXRatio", activeBin, activeBinReserveXRatio)

  return (
    <Box w="full" pos="relative">
      <Box pos="absolute" bottom={14} right={{ base: 0, md: 6 }} zIndex={1}>
        {bottomRightElement}
      </Box>
      <Heading size="md" mb={2}>
        {title}
      </Heading>
      <Flex flexDir="column" align="flex-end" mb={2}>
        <HStack>
          <HStack spacing={1}>
            <Box boxSize="10px" borderRadius="full" bg="graphGreenDark" />
            <Text fontSize="sm" fontWeight="semibold">
              {currency1?.symbol}
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Box boxSize="10px" borderRadius="full" bg="graphPurpleDark" />
            <Text fontSize="sm" fontWeight="semibold">
              {currency0?.symbol}
            </Text>
          </HStack>
        </HStack>
        {activeBinPrice ? (
          <HStack spacing={0.5} mr={-2}>
            <Text fontSize="sm">{`Current Price: 1 ${isPriceRatioInversed ? currency1?.symbol : currency0?.symbol
              } = ${formattedNum(activeBinPrice)} ${isPriceRatioInversed ? currency0?.symbol : currency1?.symbol
              }`}</Text>
            <IconButton
              aria-label="toggle price ratios"
              variant="ghost"
              size="sm"
              icon={<ArrowLeftRightIcon boxSize="16px" />}
              onClick={() => setIsPriceRatioInversed((prev) => !prev)}
            />
          </HStack>
        ) : null}
      </Flex>
      <Box h="160px">
        <ResponsiveContainer width="99%">
          <BarChart
            data={data}
            onMouseMove={(state) => {
              setFocusBar(
                state.isTooltipActive ? state.activeTooltipIndex : undefined
              )
            }}
            onMouseLeave={() => {
              setFocusBar(undefined)
            }}
            margin={{ bottom: 16 }}
          >
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              cursor={{ fill: 'transparent' }}
              content={<LBPairDistributionChartTooltip />}
            />
            <XAxis
              xAxisId="0"
              tickSize={0}
              axisLine={false}
              dataKey="price"
              ticks={xAxisTicks}
              tickFormatter={(val) => formattedNum(val, false, 5)}
              fontSize="12px"
              fontWeight={500}
              tick={{ fill: textSecondary }}
              tickMargin={8}
            />
            <defs>
              <linearGradient id="tokenXColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={graphPurpleDark} stopOpacity={1} />
                <stop
                  offset="100%"
                  stopColor={graphPurpleDark}
                  stopOpacity={0.01}
                />
              </linearGradient>
              <linearGradient id="tokenYColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={graphGreenDark} stopOpacity={1} />
                <stop
                  offset="100%"
                  stopColor={graphGreenDark}
                  stopOpacity={0.01}
                />
              </linearGradient>
              <linearGradient id="activeBinColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={graphPurpleDark} stopOpacity={1} />
                <stop
                  offset={`${activeBinReserveXRatio}%`}
                  stopColor={graphPurpleDark}
                  stopOpacity={0.01}
                />
                <stop
                  offset={`${activeBinReserveXRatio}%`}
                  stopColor={graphGreenDark}
                  stopOpacity={1}
                />
                <stop
                  offset="100%"
                  stopColor={graphGreenDark}
                  stopOpacity={0.01}
                />
              </linearGradient>
              <linearGradient
                opacity={0.5}
                id="barChartYColorActive"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor={graphGreenLight} stopOpacity={1} />
                <stop
                  offset="100%"
                  stopColor={graphGreenLight}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient
                opacity={0.5}
                id="barChartXColorActive"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={graphPurpleLight}
                  stopOpacity={1}
                />
                <stop
                  offset="100%"
                  stopColor={graphPurpleLight}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <Bar dataKey="liquidity" radius={2}>
              {data.map((data, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    focusBar === index && data.binId < activeBinId
                      ? 'url(#barChartYColorActive)'
                      : focusBar === index && data.binId > activeBinId
                        ? 'url(#barChartXColorActive)'
                        : data.binId < activeBinId
                          ? 'url(#tokenYColor)'
                          : data.binId > activeBinId
                            ? 'url(#tokenXColor)'
                            : 'url(#activeBinColor)'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Center mt={-6}>
          <Text fontSize="sm" fontWeight="semibold">
            Bin Price
          </Text>
        </Center>
      </Box>
    </Box>
  )
}

export default LBPairDistributionChart
