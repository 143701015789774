import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { CNATIVE, Currency, Token } from '@fusionx/sdk'
import SearchBar from 'components/SearchBar'
import { mantleTestnet } from 'constants/chains'
import useTokensList from 'hooks/tokensList/useTokensList'
import useChainId from 'hooks/useChainId'
import useScrollTabIntoView from 'hooks/useScrollTabIntoView'
import React, { useMemo, useState } from 'react'
import { TokenInfoTag } from 'types/tokensList'
import { formattedNum } from 'utils/format'
import { useAccount } from 'wagmi'

import CurrencyRow from './CurrencyRow'

interface CurrencyPickerModalProps {
  isOpen: boolean
  onClose: () => void
  onCurrencyChange: (currency: Currency) => void
}

const CurrencyPickerModal = ({
  isOpen,
  onClose,
  onCurrencyChange
}: CurrencyPickerModalProps) => {
  const { isConnected } = useAccount()
  const chainId = useChainId()
  const [query, setQuery] = useState<string>('')

  const isAvalanche = chainId === mantleTestnet.id
  const [tabIndex, setTabIndex] = useState(0)
  const handleChange = (nextTabIndex: number) => {
    if (nextTabIndex !== tabIndex) {
      setTabIndex(nextTabIndex)
    }
  }
  const tags = useMemo(() => {
    if (!isAvalanche) return []
    const tags = [
      TokenInfoTag.MANTLE,
      TokenInfoTag.TOP_TOKENS,
      TokenInfoTag.LARGE_CAP,
      TokenInfoTag.DEFI,
      TokenInfoTag.STABLECOINS,
      TokenInfoTag.GAMEFI
    ]
    if (isConnected) {
      tags.splice(0, 0, TokenInfoTag.USER)
    }
    return tags
  }, [isAvalanche, isConnected])

  const { isLoadingBalances, tokenFoundByAddress, tokens } = useTokensList({
    activeTag: tags.length > 0 ? tags[tabIndex] : undefined,
    query
  })
  const visibleTokens = tokenFoundByAddress ? [tokenFoundByAddress] : tokens

  const tablistRef = useScrollTabIntoView(tabIndex)

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent bg="bgPrimary" minH="80vh" overflow="hidden">
        <ModalHeader>Select a token</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Box m={4} mt={0}>
            <SearchBar
              data-cy="currency-picker-search-bar"
              placeholder="Search by name, symbol or address"
              value={query}
              onValueChange={setQuery}
              bg="bgSecondary"
            />
          </Box>
          {tags.length > 0 ? (
            <Tabs
              variant="enclosed"
              index={tabIndex}
              onChange={handleChange}
              px={4}
            >
              <TabList
                ref={tablistRef}
                overflowX="scroll"
                sx={{
                  '::-webkit-scrollbar': {
                    display: 'none'
                  },
                  scrollbarWidth: 'none'
                }}
              >
                {tags.map((tag, i) => (
                  <Tab key={i} flexShrink={0} isDisabled={query.length > 0}>
                    {tag}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          ) : null}
          <Box bg="bgSecondary" h="66vh">
            <VStack
              w="full"
              minH="280px"
              bg="bgSecondary"
              maxH="66vh"
              p={4}
              overflow="auto"
            >
              {visibleTokens.map((token, i) => (
                <CurrencyRow
                  key={i}
                  balance={
                    token.balance ? formattedNum(token.balance) : undefined
                  }
                  subtitle={
                    tokenFoundByAddress ? 'Found by address' : undefined
                  }
                  isLoadingBalance={isLoadingBalances}
                  tokenInfo={token}
                  shouldShowTags={isAvalanche}
                  onClick={() => {
                    if (!token.address) {
                      onCurrencyChange(CNATIVE.onChain(chainId))
                      onClose()
                      return
                    }
                    onCurrencyChange(
                      new Token(
                        token.chainId,
                        token.address,
                        token.decimals,
                        token.symbol,
                        token.name
                      )
                    )
                    onClose()
                  }}
                  onTagClick={(tag) => setTabIndex(tags.indexOf(tag))}
                />
              ))}
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CurrencyPickerModal
