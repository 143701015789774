import { LinkBox, LinkOverlay, Skeleton, Td, Text, Tr } from '@chakra-ui/react'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { UserVaultBalances, Vault } from 'types/vault'
import { getChainSlug } from 'utils/chains'
import { getVaultRowFormattedValues } from 'utils/vault'

import PoolTableNameColumn from '../PoolTableNameColumn'

interface VaultRowProps {
  isLoadingUserPosition: boolean
  vault: Vault
  userBalance?: UserVaultBalances
}

const VaultRow = ({
  isLoadingUserPosition,
  userBalance,
  vault
}: VaultRowProps) => {
  const chainId = useChainId()
  const { apr7D, feesUsd7D, liquidity, myFunds } = getVaultRowFormattedValues(
    vault,
    userBalance
  )
  return (
    <LinkBox
      as={Tr}
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      data-cy={`vault-row-${vault.id}`}
    >
      <Td>
        <LinkOverlay
          aria-label={`link to ${vault.name}`}
          href={`/${getChainSlug(chainId)}/vault/${vault.id}`}
        />
        <Text>{vault.name}</Text>
      </Td>
      <Td>
        <PoolTableNameColumn
          token0={vault.tokenX}
          token1={vault.tokenY}
          fees={`${vault.lbPair.baseFeePct}%`}
        />
      </Td>
      <Td textAlign="right">{liquidity}</Td>
      <Td textAlign="right">
        <Skeleton isLoaded={!isLoadingUserPosition}>{myFunds}</Skeleton>
      </Td>
      <Td textAlign="right">{feesUsd7D}</Td>
      <Td textAlign="right">{apr7D}</Td>
    </LinkBox>
  )
}

export default VaultRow
