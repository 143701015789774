import { BAR_ADDRESS } from '@fusionx/sdk'
import xJoeImage from 'assets/stake/xJoe.webp'
import { ZERO_ADDRESS } from 'constants/tokens'
import { ethers } from 'ethers'
import useXJoeStaking, { useXJoeUserPosition } from 'hooks/stake/useXJoeStaking'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { formattedNum } from 'utils/format'

import { CurrencyTab } from '.'
import { JoeUsdProps } from './SJoeCard'
import StakeCard from './StakeCard'

const XJoeCard = ({ currencyTab, joeUsd }: JoeUsdProps) => {
  const { userBalance } = useXJoeUserPosition()
  const { ratio, totalStaked } = useXJoeStaking()

  // check if not available on selected chain
  const chainId = useChainId()
  const isNotAvailable = BAR_ADDRESS[chainId] === ZERO_ADDRESS

  // user balance
  const xJoeBalanceNum = userBalance
    ? Number(ethers.utils.formatEther(userBalance))
    : 0
  const balanceNum = xJoeBalanceNum * ratio
  const fmtBalance = formattedNum(balanceNum, false, 2)
  const fmtBalanceUsd = formattedNum(balanceNum * (joeUsd ?? 0), true, 2)

  // total staked
  const totalStakedNum = totalStaked
    ? Number(ethers.utils.formatEther(totalStaked))
    : 0
  const totalStakedUSD = totalStakedNum * (joeUsd ?? 0)
  const fmtTotalStaked = formattedNum(totalStakedNum, false, 2)
  const fmtTotalStakedUSD = formattedNum(totalStakedUSD, true, 2)

  return isNotAvailable ? null : (
    <StakeCard
      isExternal
      image={xJoeImage}
      linkUrl="https://v1.fusionx.finance/stake/xjoe"
      title="xJOE"
      tag="Withdraw Only"
      label1={`Total Staked (${currencyTab})`}
      text1={
        currencyTab == CurrencyTab.JOE ? fmtTotalStaked : fmtTotalStakedUSD
      }
      label2={`Your Stake (${currencyTab})`}
      text2={currencyTab == CurrencyTab.JOE ? fmtBalance : fmtBalanceUsd}
      label3="APR (30D)"
      text3={'N/A'}
    />
  )
}

export default XJoeCard
