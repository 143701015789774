import { Currency } from '@fusionx/sdk'
import { VaultABI } from '@fusionx/sdk-v2'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import Web3Button from 'components/Web3Button'
import { BigNumber, ethers } from 'ethers'
import useChainId from 'hooks/useChainId'
import useVaultCancelQueuedWithdrawal from 'hooks/vault/useVaultCancelQueuedWithdrawal'
import useVaultRedeemQueuedWithdrawal from 'hooks/vault/useVaultRedeemQueuedWithdrawal'
import React from 'react'
import { VaultQueuedWithdrawal } from 'types/vault'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useAccount, useContractRead } from 'wagmi'

interface VaultQueuedWithdrawalPanelProps {
  queuedWithdrawal: VaultQueuedWithdrawal
  currency0?: Currency
  currency1?: Currency
  currentRound?: number
}

const VaultQueuedWithdrawalPanel = ({
  currency0,
  currency1,
  currentRound,
  queuedWithdrawal
}: VaultQueuedWithdrawalPanelProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)

  // funds can be claimed if the current round is greater than the round of the queued withdrawal
  const canClaimWithdrawal =
    currentRound !== undefined ? queuedWithdrawal.round < currentRound : false

  // if the funds can be claimed, we get the redeemable amounts
  const { data: redeemableAmounts, isLoading: isLoadingReedemableAmounts } =
    useContractRead({
      abi: VaultABI,
      address: ethers.utils.getAddress(queuedWithdrawal.vault.id),
      args: account
        ? [BigNumber.from(queuedWithdrawal.round), account]
        : undefined,
      chainId,
      enabled: canClaimWithdrawal && !!account,
      functionName: 'getRedeemableAmounts'
    })
  // else we get the preview amounts
  const { data: previewAmounts, isLoading: isLoadingPreviewAmounts } =
    useContractRead({
      abi: VaultABI,
      address: ethers.utils.getAddress(queuedWithdrawal.vault.id),
      args: [queuedWithdrawal.shares],
      chainId,
      enabled: !canClaimWithdrawal,
      functionName: 'previewAmounts'
    })
  const amounts = redeemableAmounts ?? previewAmounts

  // redeem the queued withdrawal, only enabled if the funds can be claimed
  const {
    isLoading: isRedeemingQueuedWithdrawal,
    isSuccess: isRedeemSuccess,
    redeemQueuedWithdrawal
  } = useVaultRedeemQueuedWithdrawal({
    enabled: canClaimWithdrawal,
    isOneCurrencyNative: (currency0?.isNative || currency1?.isNative) ?? false,
    round: queuedWithdrawal.round,
    vaultAddress: queuedWithdrawal.vault.id
  })

  // cancel the queued withdrawal, only enabled if the round is not yet claimable
  const {
    cancelQueuedWithdrawal,
    isLoading: isCancellingQueuedWithdrawal,
    isSuccess: isCancelSuccess
  } = useVaultCancelQueuedWithdrawal({
    enabled: !canClaimWithdrawal,
    shares: queuedWithdrawal.shares,
    vaultAddress: queuedWithdrawal.vault.id
  })

  if (!wrappedCurrency0 || !wrappedCurrency1) {
    return null
  }

  return (
    <CurrencyAmountsPanel
      title={`Queued Withdrawal #${queuedWithdrawal.round}`}
      isLoading={isLoadingPreviewAmounts || isLoadingReedemableAmounts}
      tokens={[wrappedCurrency0.address, wrappedCurrency1.address]}
      tokenAmounts={[
        Number(
          ethers.utils.formatUnits(amounts?.amountX ?? '0', currency0?.decimals)
        ),
        Number(
          ethers.utils.formatUnits(amounts?.amountY ?? '0', currency1?.decimals)
        )
      ]}
      tokenSymbols={[currency0?.symbol, currency1?.symbol]}
      bottomContent={
        canClaimWithdrawal ? (
          <Web3Button
            variant="primary"
            colorScheme="accent"
            size="xl"
            w="full"
            mt={4}
            isDisabled={!redeemQueuedWithdrawal || isRedeemSuccess}
            loadingText="Redeeming"
            isLoading={isRedeemingQueuedWithdrawal}
            onClick={redeemQueuedWithdrawal}
          >
            {isRedeemSuccess ? 'Withdrawal Redeemed!' : 'Redeem Withdrawal'}
          </Web3Button>
        ) : (
          <Web3Button
            variant="primary"
            colorScheme="accent"
            size="xl"
            w="full"
            mt={4}
            isDisabled={!cancelQueuedWithdrawal || isCancelSuccess}
            loadingText="Cancelling"
            isLoading={isCancellingQueuedWithdrawal}
            onClick={cancelQueuedWithdrawal}
          >
            {isCancelSuccess ? 'Withdrawal Cancelled!' : 'Cancel Withdrawal'}
          </Web3Button>
        )
      }
    />
  )
}

export default VaultQueuedWithdrawalPanel
