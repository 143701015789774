import {
  Box,
  Button,
  Hide,
  HStack,
  InputGroup,
  InputRightElement,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import SlippageInputRow from 'components/SlippageInputRow'
import { defaultTransactionDeadline } from 'hooks/useTransactionDeadline'
import React from 'react'
import {
  useIsDedicatedRPC,
  useIsSafeModeEnabled,
  useSlippageSettings,
  useUserTransactionDeadline
} from 'state/settings/hooks'
import { SlippageType } from 'state/settings/reducer'
import { SettingsIcon } from 'theme/icons'

export type SlippageSettingsPickerType = 'swap' | 'poolV1' | 'poolV2'

interface SlippageSettingsPickerProps {
  type: SlippageSettingsPickerType
}

const SlippageSettingsPicker = ({ type }: SlippageSettingsPickerProps) => {
  const { slippageSettings, updateSlippage } = useSlippageSettings()
  const { setUserTransactionDeadline, userTransactionDeadline } =
    useUserTransactionDeadline()
  const { isSafeModeEnabled, updateIsSafeModeEnabled } = useIsSafeModeEnabled()
  const { isDedicatedRPCSelected, updateDedicatedRPC } = useIsDedicatedRPC()

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          data-cy="settings-button"
          variant="secondary"
          borderRadius={{ base: 'lg', md: 'full' }}
        >
          <HStack>
            <SettingsIcon mr={{ base: 0, md: 1 }} />
            <Hide below="md">Settings</Hide>
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent w="375px">
        <PopoverArrow />
        <PopoverCloseButton data-cy="settings-close-button" />
        <PopoverHeader>Settings</PopoverHeader>
        <PopoverBody data-cy="settings-popover-body">
          <VStack spacing={4} align="flex-start">
            {type === 'swap' || type === 'poolV1' ? (
              <SlippageInputRow
                title="Normal slippage tolerance (V1)"
                placeholder="0.5"
                tooltipLabel="Normal slippage tolerance is applied for trades that include v1 pools. Your transaction will revert if the price changes unfavorably by more than this percentage. Default is 0.5%."
                predefinedValues={['0.1', '0.5', '1']}
                slippage={slippageSettings.v1.toString()}
                transactionFailThreshold={0.5}
                onSlippageChange={(slippage) =>
                  updateSlippage(SlippageType.V1, slippage)
                }
              />
            ) : null}
            {type === 'swap' ? (
              <SlippageInputRow
                title="Low slippage tolerance (V2)"
                placeholder="0.05"
                tooltipLabel="Low slippage tolerance is applied for trades using v2 Liquidity Book pools only. Your transaction will revert if the price changes unfavorably by more than this percentage. Default is 0.02%."
                predefinedValues={['0.02', '0.05', '0.1']}
                slippage={slippageSettings.swapV2.toString()}
                transactionFailThreshold={0}
                onSlippageChange={(slippage) =>
                  updateSlippage(SlippageType.SWAP_V2, slippage)
                }
              />
            ) : null}
            {type === 'poolV2' ? (
              <SlippageInputRow
                title="Amount slippage tolerance"
                placeholder="0.1"
                tooltipLabel="Your add/remove liquidity transaction will revert if the amount changes unfavorably by more than this percentage. Default is 0.5%"
                predefinedValues={['0.1', '0.5', '1']}
                slippage={slippageSettings.liquidityAmountV2.toString()}
                transactionFailThreshold={0}
                onSlippageChange={(slippage) =>
                  updateSlippage(SlippageType.LIQUIDITY_AMOUNT_V2, slippage)
                }
              />
            ) : null}
            {type === 'poolV2' ? (
              <SlippageInputRow
                title="Price slippage tolerance"
                placeholder="0.5"
                tooltipLabel="Your add liquidity transaction will revert if current active price changes by more than this percentage. Default is 0.5%"
                predefinedValues={['0.1', '0.5', '1']}
                slippage={slippageSettings.liquidityPriceV2.toString()}
                transactionFailThreshold={0}
                onSlippageChange={(slippage) =>
                  updateSlippage(SlippageType.LIQUIDITY_PRICE_V2, slippage)
                }
              />
            ) : null}
            <VStack align="flex-start">
              <Text fontSize="sm" color="textSecondary">
                Transaction Deadline
              </Text>
              <InputGroup>
                <NumericalInput
                  placeholder={defaultTransactionDeadline.toString()}
                  inputType="integer"
                  value={userTransactionDeadline}
                  onValueChange={setUserTransactionDeadline}
                />
                <InputRightElement w="fit-content" mr={4}>
                  minutes
                </InputRightElement>
              </InputGroup>
            </VStack>
            {type === 'swap' ? (
              <VStack align="flex-start" spacing={1}>
                <Text fontSize="sm" color="textSecondary">
                  Safe Mode
                </Text>
                <HStack>
                  <Switch
                    // colorScheme="accent"
                    size="lg"
                    isChecked={isSafeModeEnabled}
                    onChange={() => updateIsSafeModeEnabled(!isSafeModeEnabled)}
                  />
                  <Box as="span" fontSize="sm" textColor="textSecondary">
                    Prevent high price impact trades. Disable at your own risk.{' '}
                    <Link
                      aria-label="Learn more about Price Impact, Slippage, and Safe Mode"
                      href="https://support.fusionx.finance/en/articles/6735950-price-impact-slippage-and-safe-mode"
                      isExternal
                      fontSize="sm"
                      textColor="accent.500"
                    >
                      Learn more.
                    </Link>
                  </Box>
                </HStack>
              </VStack>
            ) : null}
            <VStack align="flex-start" spacing={1}>
              <Text fontSize="sm" color="textSecondary">
                Use Dedicated RPCs
              </Text>
              <HStack>
                <Switch
                  // colorScheme="accent"
                  size="lg"
                  isChecked={isDedicatedRPCSelected}
                  onChange={() => updateDedicatedRPC(!isDedicatedRPCSelected)}
                />
                <Box as="span" fontSize="sm" textColor="textSecondary">
                  Dedicated RPC endpoints provided by Infura
                </Box>
              </HStack>
            </VStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default SlippageSettingsPicker
