import { HStack, Text } from '@chakra-ui/react'
import { WNATIVE } from '@fusionx/sdk'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Link } from 'react-router-dom'
import { Pool } from 'types/pool'
import { getChainSlug } from 'utils/chains'
import { searchClickMarket } from 'utils/measure'

interface HeaderSearchResultRowProps {
  onClick: () => void
  pair: Pool
}

const HeaderSearchResultRow = ({
  onClick,
  pair
}: HeaderSearchResultRowProps) => {
  const chainId = useChainId()
  const { nativeCurrency } = useActiveChain()

  const isToken0NativeWrapped =
    pair.tokenX.address === WNATIVE[chainId].address.toLowerCase()
  const isToken1NativeWrapped =
    pair.tokenY.address === WNATIVE[chainId].address.toLowerCase()
  const symbol0 = isToken0NativeWrapped
    ? nativeCurrency.symbol
    : pair.tokenX.symbol
  const symbol1 = isToken1NativeWrapped
    ? nativeCurrency.symbol
    : pair.tokenY.symbol

  return (
    <HStack
      as={Link}
      aria-label={`Link to trade ${symbol0}-${symbol1}`}
      to={`/${getChainSlug(chainId)}/trade?inputCurrency=${isToken0NativeWrapped ? symbol0 : pair.tokenX.address
        }&outputCurrency=${isToken1NativeWrapped ? symbol1 : pair.tokenY.address
        }`}
      spacing={4}
      p={4}
      borderRadius="lg"
      _hover={{ bg: 'hover' }}
      onClick={() => {
        searchClickMarket(pair.name)
        onClick()
      }}
    >
      <DoubleCurrencyLogo
        address0={pair.tokenX.address}
        symbol0={symbol0}
        address1={pair.tokenY.address}
        symbol1={symbol1}
        boxSize={8}
      />
      <Text>{`${symbol0} - ${symbol1}`}</Text>
    </HStack>
  )
}

export default HeaderSearchResultRow
