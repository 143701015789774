import { JsonRpcProvider } from '@ethersproject/providers'
// import { createWagmiAdapter } from '@layerzerolabs/x-trader-joe-bridge'
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  braveWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  trustWallet,
  walletConnectWallet
} from '@rainbow-me/rainbowkit/wallets'
import { ChainId } from '@fusionx/sdk'
import { SafeConnector } from '@wagmi/connectors/safe'
import { publicProvider } from '@wagmi/core/providers/public'
import { supportedChains } from 'constants/chains'
import { Wallet } from 'ethers'
import store from 'state/store'
import { e2eTestsConfig, isRunningE2ETests } from 'utils/e2e'
import { Connector, createClient } from 'wagmi'
import { configureChains } from 'wagmi'
import { MockConnector } from 'wagmi/connectors/mock'
import { infuraProvider } from 'wagmi/providers/infura'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { core } from './wallet'

const infuraApiKey = process.env.REACT_APP_INFURA_KEY ?? ''
const nodeRealApiKey = process.env.REACT_APP_NODE_REAL_KEY ?? ''
const walletConnectProjectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? ''

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      // core({ chains: supportedChains }),
      coinbaseWallet({ appName: 'FusionX', chains: supportedChains }),
      trustWallet({ chains: supportedChains, projectId: walletConnectProjectId }),
      metaMaskWallet({ chains: supportedChains, projectId: walletConnectProjectId }),
      walletConnectWallet({ chains: supportedChains, projectId: walletConnectProjectId }),
      braveWallet({ chains: supportedChains }),
      injectedWallet({ chains: supportedChains })
    ]
  }
])

const hiddenConnectors: Connector[] = [
  new SafeConnector({ chains: supportedChains })
]

// check if we're running end-to-end tests
if (isRunningE2ETests) {
  const mockProvider = new JsonRpcProvider(
    e2eTestsConfig.rpcUrl,
    e2eTestsConfig.chainId
  )

  const testWalletPrivateKey = localStorage.getItem('testWalletPrivateKey')
  const wallet = testWalletPrivateKey
    ? new Wallet(testWalletPrivateKey, mockProvider)
    : Wallet.createRandom().connect(mockProvider)

  hiddenConnectors.push(
    new MockConnector({
      chains: supportedChains,
      options: { chainId: e2eTestsConfig.chainId, signer: wallet }
    })
  )
}

const isDedicatedRPC = store.getState().settings.isDedicatedRPC

const { chains: wagmiChains, provider } = configureChains(
  supportedChains,
  isRunningE2ETests
    ? e2eTestsConfig.providers
    : [
      infuraProvider({
        apiKey: infuraApiKey,
        priority: isDedicatedRPC ? 0 : 2,
        stallTimeout: 3000
      }),
      // the etherjs infura provider doesn't support avalanche/fuji yet:
      // https://github.com/ethers-io/ethers.js/blob/master/packages/providers/src.ts/infura-provider.ts#L84-L119
      jsonRpcProvider({
        priority: 1,
        rpc: (chain) => {
          switch (chain.id) {
            case ChainId.AVALANCHE:
              return {
                http: `https://avalanche-mainnet.infura.io/v3/${infuraApiKey}`
              }
            case ChainId.FUJI:
              return {
                http: `https://avalanche-fuji.infura.io/v3/${infuraApiKey}`
              }
            case ChainId.BNB_CHAIN:
              return {
                http: `https://bsc-mainnet.nodereal.io/v1/${nodeRealApiKey}`
              }
            case ChainId.BNB_TESTNET:
              return {
                http: `https://data-seed-prebsc-1-s3.binance.org:8545`
              }
            case ChainId.MANTLE_TESTNET:
              return {
                http: `https://rpc.testnet.mantle.xyz`
              }
          }
          return null
        },
        stallTimeout: 3000
      }),
      publicProvider({ priority: isDedicatedRPC ? 2 : 0 })
    ]
)

const wagmiClient = createClient({
  autoConnect: false,
  connectors: [...connectors(), ...hiddenConnectors],
  provider
})

const wagmiAdapter = ""  //createWagmiAdapter(wagmiClient as any)

export const wagmi = {
  wagmiAdapter,
  wagmiChains,
  wagmiClient
}
