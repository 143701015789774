import AvaxLogo from 'assets/avalanche.svg'
import EtherLogo from 'assets/ether_2x.webp'
import useActiveChain from 'hooks/useActiveChain'
import { TokenInfoTag } from 'types/tokensList'
import { useAccount, useBalance } from 'wagmi'

const useNativeTokenInfo = () => {
  const chain = useActiveChain()
  const { address } = useAccount()
  const { data } = useBalance({ address, chainId: chain.id })
  const logoURI = chain.nativeCurrency?.symbol === 'AVAX' ? AvaxLogo : EtherLogo
  return {
    nativeTokenInfo: {
      address: undefined,
      balance: data?.formatted,
      chainId: chain.id,
      decimals: chain.nativeCurrency.decimals,
      logoURI,
      name: chain.nativeCurrency.name,
      symbol: chain.nativeCurrency.symbol,
      tags: [
        TokenInfoTag.MANTLE,
        TokenInfoTag.TOP_TOKENS,
        TokenInfoTag.LARGE_CAP
      ]
    }
  }
}

export default useNativeTokenInfo
