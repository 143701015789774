import metatagImage from 'assets/meta.png'
import React from 'react'
import { Helmet } from 'react-helmet'

interface PageHelmetProps {
  url: string
  description?: string
  title?: string
}

const PageHelmet = ({ description, title, url }: PageHelmetProps) => (
  <Helmet>
    <title>{title ?? 'FusionX | Decentralized Exchange'}</title>
    <meta
      name="description"
      content={
        description ??
        'One-stop Decentralized Trading Platform. Trade, Earn, Stake, Lend, Borrow, Launch and Shop all on one easily accessible platform. Everyone’s favorite DEX. Welcome to DeFi.'
      }
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={`https://fusionx.finance/${url}`} />
    <meta
      property="og:title"
      content={title ?? 'FusionX | Decentralized Exchange'}
    />
    <meta
      property="og:description"
      content={
        description ??
        'One-stop Decentralized Trading Platform. Trade, Earn, Stake, Lend, Borrow, Launch and Shop all on one easily accessible platform. Everyone’s favorite DEX. Welcome to DeFi.'
      }
    />
    <meta property="og:image" content={metatagImage} />
    <meta property="twitter:card" content={metatagImage} />
    <meta property="twitter:url" content={`https://fusionx.finance/${url}`} />
    <meta
      property="twitter:title"
      content={title ?? 'FusionX | Decentralized Exchange'}
    />
    <meta
      property="twitter:description"
      content={
        description ??
        'One-stop Decentralized Trading Platform. Trade, Earn, Stake, Lend, Borrow, Launch and Shop all on one easily accessible platform. Everyone’s favorite DEX. Welcome to DeFi.'
      }
    />
    <meta property="twitter:image" content={metatagImage} />
  </Helmet>
)

export default PageHelmet
