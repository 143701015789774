import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency } from '@fusionx/sdk'
import {
  Bin,
  getLiquidityConfig,
  getUniformDistributionFromBinRange,
  LB_ROUTER_ADDRESS,
  LB_ROUTER_V21_ADDRESS,
  LBRouterABI,
  LBRouterV21ABI,
  LiquidityDistribution,
  LiquidityDistributionParams
} from '@fusionx/sdk-v2'
import { BigNumber } from 'ethers'
import { formatUnits, getAddress, parseEther } from 'ethers/lib/utils.js'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { useSlippageSettings } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { capturePrepareContractWriteError } from 'utils/logger'
import { getCurrencyAmount } from 'utils/swap'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import {
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useProvider,
  useWaitForTransaction
} from 'wagmi'
import { Contract, utils, Signer, Wallet } from 'ethers'

type AddLiquidityArguments = [
  {
    activeIdDesired: BigNumber
    amountX: BigNumber
    amountXMin: BigNumber
    amountY: BigNumber
    amountYMin: BigNumber
    binStep: BigNumber
    deadline: BigNumber
    deltaIds: BigNumber[]
    distributionX: BigNumber[]
    distributionY: BigNumber[]
    idSlippage: BigNumber
    to: `0x${string}`
    tokenX: `0x${string}`
    tokenY: `0x${string}`
    refundTo: `0x${string}`
  }
]

export interface UseAddLiquidityV2Props {
  liquidityDistribution: LiquidityDistribution
  activeBinId?: BigNumber
  amount0?: BigNumber
  amount1?: BigNumber
  binRange?: number[]
  binStep?: string
  currency0?: Currency
  currency1?: Currency
  enabled?: boolean
  onPrepareContractWriteError?: (error: Error) => void
  onSuccess?: () => void
}

const useAddLiquidityV2 = ({
  activeBinId,
  amount0,
  amount1,
  binRange,
  binStep,
  currency0,
  currency1,
  enabled,
  liquidityDistribution,
  onPrepareContractWriteError,
  onSuccess
}: UseAddLiquidityV2Props) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const { nativeCurrency } = useActiveChain()
  const { address: account } = useAccount()
  const { slippageSettings } = useSlippageSettings()
  const deadline = useTransactionDeadline()

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const isCurrency0Native = currency0?.symbol === nativeCurrency.symbol
  const isCurrency1Native = currency1?.symbol === nativeCurrency.symbol
  const isOneCurrencyNative = isCurrency0Native || isCurrency1Native

  const token0Address = wrappedCurrency(currency0, chainId)?.address
  const token1Address = wrappedCurrency(currency1, chainId)?.address

  const allowedPriceSlippage = slippageSettings.liquidityPriceV2 * 100
  const priceSlippage = allowedPriceSlippage / 10000
  const idSlippage = Bin.getIdSlippageFromPriceSlippage(
    priceSlippage,
    Number(binStep)
  )

  const allowedAmountsSlippage =
    10000 - slippageSettings.liquidityAmountV2 * 100
  const amountMin0 = amount0?.mul(allowedAmountsSlippage).div(10000)
  const amountMin1 = amount1?.mul(allowedAmountsSlippage).div(10000)

  const currencyAmount0 = getCurrencyAmount(currency0, amount0)
  const currencyAmount1 = getCurrencyAmount(currency1, amount1)

  let distributionParams: LiquidityDistributionParams | undefined
  try {
    distributionParams =
      liquidityDistribution === LiquidityDistribution.SPOT &&
        currencyAmount0 &&
        currencyAmount1 &&
        activeBinId &&
        binRange
        ? getUniformDistributionFromBinRange(activeBinId.toNumber(), binRange, [
          currencyAmount0,
          currencyAmount1
        ])
        : getLiquidityConfig(liquidityDistribution)
  } catch {
    // getUniformDistributionFromBinRange may throw an error when the bin range is invalid
    // for example: the user enters a min price greater than max price
    distributionParams = undefined
  }
  const { deltaIds, distributionX, distributionY } = distributionParams || {}

  // handle cases where distribution exceeds 10e18 due to float imprecision
  const sumDistributionX = distributionParams?.distributionX.reduce(
    (a, b) => a.add(b),
    BigNumber.from(0)
  )
  const sumDistributionY = distributionParams?.distributionY.reduce(
    (a, b) => a.add(b),
    BigNumber.from(0)
  )
  const maxValue = parseEther('1')
  if (distributionX && sumDistributionX && sumDistributionX.gt(maxValue)) {
    const diff = sumDistributionX.sub(maxValue)
    const val = distributionX[distributionX.length - 1]
    distributionX[distributionX.length - 1] = val.sub(diff)
  }
  if (distributionY && sumDistributionY && sumDistributionY.gt(maxValue)) {
    const diff = sumDistributionY.sub(maxValue)
    const val = distributionY[0]
    distributionY[0] = val.sub(diff)
  }

  const addLiquidityArgs = useMemo(
    (): AddLiquidityArguments | undefined =>
      token0Address &&
        token1Address &&
        binStep &&
        amount0 &&
        amount1 &&
        amountMin0 &&
        amountMin1 &&
        deadline &&
        account &&
        activeBinId &&
        deltaIds &&
        distributionX &&
        distributionY
        ? [
          {
            activeIdDesired: activeBinId,
            amountX: amount0,
            amountXMin: amountMin0,
            amountY: amount1,
            amountYMin: amountMin1,
            binStep: BigNumber.from(binStep),
            deadline,
            deltaIds: deltaIds.map((id) => BigNumber.from(id)),
            distributionX,
            distributionY,
            idSlippage: BigNumber.from(idSlippage),
            to: account,
            tokenX: getAddress(token0Address),
            tokenY: getAddress(token1Address),
            refundTo: account,
          }
        ]
        : undefined,
    [
      token0Address,
      token1Address,
      binStep,
      amount0,
      amount1,
      amountMin0,
      amountMin1,
      activeBinId,
      deadline,
      idSlippage,
      deltaIds,
      distributionX,
      distributionY,
      account
    ]
  )


  console.log("distributionX", distributionX?.map((e) => e.toString()))
  console.log("distributionY", distributionY?.map((e) => e.toString()))
  console.log("addLiquidityArgs", addLiquidityArgs, token0Address,
    "activeIdDesired:", activeBinId?.toString(),
    "amountX:", amount0?.toString(),
    "amountXMin:", amountMin0?.toString(),
    "amountY:", amount1?.toString(),
    "amountYMin:", amountMin1?.toString(),
    "binStep:", BigNumber.from(binStep)?.toString(),
    "deadline:", deadline?.toString(),
    "deltaIds:", deltaIds?.toString(),

    "distributionY", distributionY?.forEach((e) => console.log(e.toString())),
    "idSlippage:", BigNumber.from(idSlippage).toString(),
    " to: ", account,
    "tokenX:", getAddress(token0Address ?? ""),
    "tokenY:", getAddress(token1Address ?? "")
  )
  const {
    config: addLiquidityConfig,
    error: prepareConfigError,
    isLoading: isPreparingConfig
  } = usePrepareContractWrite({
    abi: LBRouterV21ABI,
    address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
    args: addLiquidityArgs,
    chainId: chainId,
    cacheTime: 0,
    enabled:
      !!addLiquidityArgs && enabled === true && walletChainId === chainId,
    functionName: isOneCurrencyNative ? 'addLiquidityNATIVE' : 'addLiquidity',
    onError: onPrepareContractWriteError,
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    overrides: isCurrency0Native
      ? { value: amount0 }
      : isCurrency1Native
        ? { value: amount1 }
        : undefined
  })


  const transactionSummary = useMemo(
    () =>
      amount0 && amount1
        ? `Add ${formattedNum(formatUnits(amount0, currency0?.decimals))} ${currency0?.symbol
        } and ${formattedNum(formatUnits(amount1, currency1?.decimals))} ${currency1?.symbol
        }`
        : undefined,
    [currency0, currency1, amount0, amount1]
  )
  const res = useContractWrite({
    ...addLiquidityConfig,
    onSuccess: (data) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    },
    onError(error) {
      console.log('Error', error)
    },
  })
  console.log("useAddLiquidityV2", res);


  const { data, isLoading, write } = res;

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    addLiquidity: write,
    isLoading: isWaitingForTransaction || isLoading,
    isPreparingConfig,
    isSuccess,
    prepareConfigError
  }
}

export default useAddLiquidityV2
