import { useQuery } from '@tanstack/react-query'
import { DEX_V2_SUBGRAPH } from '@fusionx/sdk-v2'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { vaultQuery } from 'graphql/dexV2'
import { request } from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { VaultSubgraphModels } from 'types/vault'
import { convertSubgraphVaultIntoVault } from 'utils/vault'

dayjs.extend(utc)

interface UseVaultProps {
  vaultAddress?: string
}

const useVault = ({ vaultAddress }: UseVaultProps) => {
  const chainId = useChainId()
  const subgraphUrl = DEX_V2_SUBGRAPH[chainId]
  const utcSevenDayBack = dayjs().utc().startOf('day').subtract(7, 'day').unix()

  const result = useQuery<{ vaults: VaultSubgraphModels.Vault[] }>(
    ['VaultQuery', subgraphUrl, vaultAddress],
    () =>
      request(subgraphUrl, vaultQuery, {
        date: utcSevenDayBack,
        id: vaultAddress?.toLowerCase()
      }),
    { enabled: !!vaultAddress }
  )

  const vault = result.data?.vaults?.[0]
  return {
    ...result,
    data: vault ? convertSubgraphVaultIntoVault(vault, chainId) : undefined
  }
}

export default useVault
