import {
  Box,
  Flex,
  Heading,
  Hide,
  SimpleGrid,
  Tab,
  TabList,
  Tabs,
  Text,
  useToken,
  VStack
} from '@chakra-ui/react'
import { JOE_ADDRESS } from '@fusionx/sdk'
import AnalyticsChart from 'components/AnalyticsChart'
import PageHelmet from 'components/PageHelmet'
import { STAKE_HELMET_DESCRIPTION, STAKE_HELMET_TITLE } from 'constants/stake'
import { ethers } from 'ethers'
import useSJoeAnalytics from 'hooks/stake/useSJoeAnalytics'
import { useSJoeStaking } from 'hooks/stake/useSJoeStaking'
import useChainId from 'hooks/useChainId'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { formattedNum } from 'utils/format'

import RJoeCard from './RJoeCard'
import SJoeCard from './SJoeCard'
import VeJoeCard from './VeJoeCard'
import XJoeCard from './XJoeCard'

export enum CurrencyTab {
  JOE = 'JOE',
  USD = 'USD'
}

const Stake = () => {
  const [graphPurple] = useToken('colors', ['graphPurpleDark'])
  const location = useLocation()

  // currency tab
  const currencyTabs = [CurrencyTab.USD, CurrencyTab.JOE]
  const [currencyTab, setCurrencyTab] = useState<CurrencyTab>(CurrencyTab.USD)

  // total staked
  const { totalStaked } = useSJoeStaking()

  const totalStakedNum = totalStaked
    ? Number(ethers.utils.formatEther(totalStaked))
    : 0
  const fmtTotalStaked = totalStaked
    ? `${formattedNum(totalStakedNum, false, 0)} JOE`
    : ''

  // chart data
  const analyticsData = useSJoeAnalytics()

  // get usd price and amounts
  const chainId = useChainId()
  const joeTokenAddress = JOE_ADDRESS[chainId]
  const { data } = useTokenPriceUSD({
    tokens: [joeTokenAddress]
  })
  const joeUsd = data?.[0]
  const totalStakedUSD = totalStakedNum * (joeUsd ?? 0)
  const fmtTotalStakedUSD = `${formattedNum(totalStakedUSD, true, 0)}`

  return (
    <Box pt={{ base: 6, md: 24 }}>
      <PageHelmet
        description={STAKE_HELMET_DESCRIPTION}
        title={STAKE_HELMET_TITLE}
        url={location.pathname}
      />
      <Box maxW="1200px" margin="0 auto">
        <Flex
          align="center"
          justify="space-between"
          pb={{ base: 8, sm: 4 }}
          px={6}
        >
          <VStack spacing={0} align="flex-start">
            <Heading>Stake</Heading>
            <Hide below="sm">
              <Text color="textSecondary" fontSize="sm">
                Stake your JOE tokens and earn more.
              </Text>
            </Hide>
          </VStack>
          <Tabs
            variant="solid-rounded"
            colorScheme="accent"
            index={currencyTabs.indexOf(currencyTab)}
            onChange={(index: number) => setCurrencyTab(currencyTabs[index])}
            size={{ base: 'sm', md: 'md' }}
          >
            <TabList>
              {currencyTabs.map((cur, i) => (
                <Tab key={i}>{cur}</Tab>
              ))}
            </TabList>
          </Tabs>
        </Flex>
        <Box pb={8} px={6}>
          {analyticsData && (
            <AnalyticsChart
              id="sJOE"
              header={
                <Text fontWeight="bold" fontSize="2xl" minW="100px">
                  {currencyTab == CurrencyTab.JOE
                    ? fmtTotalStaked
                    : fmtTotalStakedUSD}
                </Text>
              }
              subheader="Total Staked sJOE"
              tooltipTitle="Staked sJOE"
              data={analyticsData}
              fill={graphPurple}
              stroke="#894bf6"
              h={{ base: '80px', md: '200px' }}
              usdValue={false}
            />
          )}
        </Box>
      </Box>
      <Box bgColor="bgSecondary" maxWidth="100%">
        <Box maxW="1200px" margin="0 auto">
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            pt={4}
            pb={24}
            px={6}
            gap={4}
          >
            <SJoeCard currencyTab={currencyTab} joeUsd={joeUsd} />
            <VeJoeCard currencyTab={currencyTab} joeUsd={joeUsd} />
            <RJoeCard currencyTab={currencyTab} joeUsd={joeUsd} />
            <XJoeCard currencyTab={currencyTab} joeUsd={joeUsd} />
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  )
}

export default Stake
