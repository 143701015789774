import { SimpleGrid, Skeleton, Text, useToken } from '@chakra-ui/react'
import AnalyticsChart from 'components/AnalyticsChart'
import usePoolHomeAnalytics from 'hooks/analytics/usePoolHomeAnalytics'
import React, { useMemo } from 'react'
import { formattedNum } from 'utils/format'

import { PoolVersion } from './constants'

interface PoolMainAnalyticsProps {
  poolVersion: PoolVersion
}

const PoolMainAnalytics = ({ poolVersion }: PoolMainAnalyticsProps) => {
  const [graphPurple, graphGreen] = useToken('colors', [
    'graphPurpleDark',
    'graphGreenDark'
  ])

  const { analyticsData, isLoading } = usePoolHomeAnalytics()
  console.log("PoolMainAnalytics", analyticsData);
  const { allData, v1Data, v2Data } = analyticsData

  const chartData = useMemo(() => {
    switch (poolVersion) {
      case PoolVersion.ALL:
        return allData
      case PoolVersion.V1:
        return v1Data
      case PoolVersion.V2:
        return v2Data
    }
  }, [allData, v1Data, v2Data, poolVersion])

  const fmtTVL =
    chartData.liquidity.length > 0
      ? formattedNum(
        chartData.liquidity[chartData.liquidity.length - 1].value,
        true
      )
      : '0'

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} px={6} pt={4} pb={6} gap={8}>
      <AnalyticsChart
        id="tvl"
        header={
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="bold" fontSize="2xl" minW="100px">
              {fmtTVL}
            </Text>
          </Skeleton>
        }
        subheader="TVL (Total Value Locked)"
        tooltipTitle="TVL"
        data={chartData.liquidity}
        fill={graphPurple}
        stroke="#894bf6"
        h={{ base: '80px', md: '200px' }}
      />
      <AnalyticsChart
        id="volume"
        header={
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="bold" fontSize="2xl" minW="100px">
              {formattedNum(
                poolVersion === PoolVersion.V2
                  ? chartData.volume24
                  : chartData.volume1D,
                true
              )}
            </Text>
          </Skeleton>
        }
        subheader="Volume (1D)"
        tooltipTitle="Volume"
        data={chartData.volume}
        fill={graphGreen}
        stroke="#5ec5a2"
        h={{ base: '80px', md: '200px' }}
      />
    </SimpleGrid>
  )
}

export default PoolMainAnalytics
