import { statAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(statAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    _dark: { bg: 'bgTertiary' },
    bg: 'bgPrimary',
    borderRadius: 'xl',
    p: 3,
    w: 'full'
  },

  helpText: {
    fontWeight: 'semibold'
  },

  label: {
    textColor: 'textSecondary'
  },
  number: {
    fontSize: { lg: '2xl', md: 'xl', sm: 'sm' }
  }
})

const positive = definePartsStyle({
  ...baseStyle,
  helpText: {
    color: 'green.400'
  }
})

const negative = definePartsStyle({
  ...baseStyle,
  helpText: {
    color: 'red.400'
  }
})

export const statTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { negative, positive }
})
