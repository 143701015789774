import { Flex } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import { ethers } from 'ethers'
import React from 'react'

interface DoubleCurrencyLogoProps {
  boxSize: string | number
  address0?: string
  address1?: string
  ml?: string
  symbol0?: string
  symbol1?: string
}

const DoubleCurrencyLogo = ({
  address0,
  address1,
  boxSize,
  ml,
  symbol0,
  symbol1
}: DoubleCurrencyLogoProps) => (
  <Flex flexShrink={0}>
    <CurrencyLogo
      address={address0 ? ethers.utils.getAddress(address0) : undefined}
      symbol={symbol0}
      boxSize={boxSize}
    />
    <CurrencyLogo
      address={address1 ? ethers.utils.getAddress(address1) : undefined}
      symbol={symbol1}
      boxSize={boxSize}
      ml={ml ?? `calc(-${boxSize}/2)`}
    />
  </Flex>
)

export default DoubleCurrencyLogo
