import { Center, Flex, Heading, Image, VStack } from '@chakra-ui/react'
import JoeHome1x from 'assets/home/joe-home_1x.webp'
import JoeHome2x from 'assets/home/joe-home_2x.webp'
import JoeHome3x from 'assets/home/joe-home_3x.webp'
import PageHelmet from 'components/PageHelmet'
import React from 'react'
import { useLocation } from 'react-router-dom'

import HomeTiles from './HomeTiles'
import TokensRanking from './TokensRanking'

const Home = () => {
  const location = useLocation()
  return (
    <>
      <PageHelmet url={location.pathname} />
      <Flex
        flexDir="column"
        gap={4}
        pt={{ base: 12, md: 32 }}
        maxW="960px"
        margin="0 auto"
        minH="calc(100vh - 80px)"
        px={{ base: 0, md: 4 }}
        pb={{ base: 8, md: 16 }}
      >
        <VStack pos="relative" spacing={16}>
          {/* <Image
            alt="joe-home-header-image"
            fallbackSrc={JoeHome2x}
            srcSet={`${JoeHome1x} 1x ${JoeHome2x} 2x ${JoeHome3x} 3x`}
            w={{ base: '390px', lg: '866px', sm: '485px' }}
            h={{ base: '158px', lg: '350px', sm: '200px' }}
            objectFit="cover"
            zIndex={-1}
          /> */}
          <Heading
            textAlign="center"
            size="2xl"
            color="textPrimary"
            mb={{ base: 4, md: 8 }}
          >
            One-stop decentralized trading
          </Heading>
        </VStack>
        <Center my={{ base: 4, md: 8 }} flexDir="column" gap={8}>
          <HomeTiles />
        </Center>
        <TokensRanking />
      </Flex>
    </>
  )
}

export default Home
