import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import Duck1x from 'assets/duck_1x.webp'
import Duck2x from 'assets/duck_2x.webp'
import Duck3x from 'assets/duck_3x.webp'
import CreateIcon from 'assets/create.png'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Link } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'

const CreateNewPoolMenu = () => {
  const chainId = useChainId()
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline"
        size="lg"
        flexShrink={0}
        border="2px solid"
        borderColor="joeLight.600"
        _dark={{ borderColor: 'joeDark.400' }}
        _hover={{ bg: 'hover' }}
      >
        <Flex justifyContent="center" align="center" gap={3}>
          <Image
            alt="create-new-pool-icon"
            fallbackSrc={CreateIcon}
            srcSet={`${CreateIcon} 1x ${CreateIcon} 2x ${CreateIcon} 3x`}
            boxSize={{ base: 5, md: 6 }}
          />
          Create New Pool
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem
          as={Link}
          h="48px"
          to={`/${getChainSlug(chainId)}/pool/v2/create`}
        >
          v2
        </MenuItem>
        <MenuItem
          as={Link}
          h="48px"
          to={`/${getChainSlug(chainId)}/pool/v1/create`}
        >
          v1
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default CreateNewPoolMenu
