import { LBPairABI } from '@fusionx/sdk-v2'
import { BigNumber } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import { useContractReads } from 'wagmi'

interface UseLBTokensSupplyProps {
  binIds?: number[]
  lbPairAddress?: string
}

const useLBTokensSupply = ({
  binIds,
  lbPairAddress
}: UseLBTokensSupplyProps) => {
  const chainId = useChainId()
  const contract = {
    abi: LBPairABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined
  }
  const reads = useContractReads({
    contracts: binIds?.map((binId) => ({
      ...contract,
      args: [BigNumber.from(binId)],
      chainId,
      functionName: 'totalSupply'
    })),
    enabled: !!binIds && binIds.length > 0
  })
  return {
    ...reads,
    data: reads.data ? (reads.data as unknown[] as BigNumber[]) : undefined
  }
}

export default useLBTokensSupply
