import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
  borderRadius: { base: 'md', md: 'xl' }
})

const lg = defineStyle({
  borderRadius: { base: 'md', md: 'xl' },
  fontSize: 'md',
  fontWeight: 'bold',
  h: { base: '40px', md: '48px' }
})

const xl = defineStyle({
  borderRadius: { base: 'xl', md: '2xl' },
  fontSize: 'md',
  fontWeight: 'bold',
  h: { base: '48px', md: '56px' }
})

const primary = defineStyle(({ colorScheme: c }) => ({
  _active: {
    background: `${c}.600`
  },
  _dark: {
    shadow: 'joeDark'
  },
  _disabled: {
    borderBottom: 0
  },
  _hover: {
    _disabled: {
      background: `${c}.500`
    },
    background: `${c}.600`,
    borderBottomColor: `${c}.700`
  },
  background: `${c}.500`,
  border: '0px',
  borderBottom: '4px solid',
  borderBottomColor: `${c}.600`,
  color: 'white',
  shadow: `0px 8px 24px ${
    c === 'accent' ? 'rgba(126, 132, 255, 0.4)' : 'rgba(0, 0, 0, 0.24)'
  }`
}))

const secondary = defineStyle({
  _dark: { _hover: { bg: 'joeDark.400' }, bg: 'joeDark.500' },
  _hover: {
    bg: 'joeLight.500'
  },
  bg: 'white'
})

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes: { lg, xl },
  variants: { primary, secondary }
})
