import { useMemo } from 'react'
import { Pool } from 'types/pool'

export enum PoolSortMethod {
  APR = 'apr',
  FEE = 'fee',
  LIQUIDITY = 'liquidity',
  MY_FUNDS = 'myFunds',
  VOLUME = 'volume'
}

interface UseSortedPoolsProps {
  isSortDescending: boolean
  pools: Pool[]
  sortMethod: PoolSortMethod
}

const useSortedPools = ({
  isSortDescending,
  pools,
  sortMethod
}: UseSortedPoolsProps) => {
  return useMemo(() => {
    let sortedPools = pools
    switch (sortMethod) {
      case PoolSortMethod.LIQUIDITY:
        sortedPools = sortedPools.sort(
          (a, b) => (b?.liquidityUsd ?? 0) - (a?.liquidityUsd ?? 0)
        )
        break
      case PoolSortMethod.VOLUME:
        sortedPools = sortedPools.sort(
          (a, b) => (b?.volumeUsd ?? 0) - (a?.volumeUsd ?? 0)
        )
        break
      case PoolSortMethod.FEE:
        sortedPools = sortedPools.sort(
          (a, b) => (b?.feesUsd ?? 0) - (a?.feesUsd ?? 0)
        )
        break
      case PoolSortMethod.APR:
        sortedPools = sortedPools.sort((a, b) => (b?.apr ?? 0) - (a?.apr ?? 0))
        break
      case PoolSortMethod.MY_FUNDS:
        break
    }
    return isSortDescending ? sortedPools : sortedPools.reverse()
  }, [pools, sortMethod, isSortDescending])
}

export default useSortedPools
