import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent'

const AnalyticsChartTooltip = ({
  payload
}: TooltipProps<ValueType, NameType>) => {
  const point = payload && payload.length && payload[0].payload

  return (
    <Box
      p={4}
      borderRadius="xl"
      backdropFilter="blur(8px)"
      bg="rgba(245, 245, 255, 0.48)"
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.16)"
      _dark={{
        bg: 'rgba(53, 58, 108, 0.48)',
        boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.4)'
      }}
    >
      {point?.fmtDate ? (
        <Text color="textPrimary" fontSize="sm" mb={3}>
          {point.fmtDate}
        </Text>
      ) : null}
      {point?.title ? (
        <Text color="textSecondary" fontSize="sm">
          {point.title}
        </Text>
      ) : null}
      {point?.fmtValue ? <Text fontWeight="bold">{point.fmtValue}</Text> : null}
    </Box>
  )
}

export default AnalyticsChartTooltip
