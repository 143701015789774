import { useQuery } from '@tanstack/react-query'
import { ZERO_ADDRESS } from 'constants/tokens'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useMemo } from 'react'
import {
  ClaimableRewardsEpoch,
  FormattedReward,
  RewardsHistoryEpoch
} from 'types/rewards'
import { getDexbarnChainParam } from 'utils/chains'
import {
  getAggregatedClaimableRewards,
  getClaimableTokenAddresses,
  getFormattedRewards,
  getTokensMap
} from 'utils/rewards'
import { useAccount } from 'wagmi'

import useLBPairClaimRewards from './useLBPairClaimRewards'

interface UseLBPairRewardsProps {
  isRewarded: boolean
  lbPairAddress: string | undefined
}

const useLBPairRewards = ({
  isRewarded,
  lbPairAddress
}: UseLBPairRewardsProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const { nativeCurrency } = useActiveChain()

  const fetchUserClaimableRewards = useDexbarnGet<ClaimableRewardsEpoch[]>(
    `/v1/rewards/claimable/${chain}/${account}?market=${lbPairAddress}`
  )
  const { data: rewardEpochs, isLoading: isClaimableLoading } = useQuery<
    ClaimableRewardsEpoch[]
  >(
    ['ClaimableRewardsEpoch', account, lbPairAddress],
    () => fetchUserClaimableRewards(),
    {
      enabled: isRewarded && !!lbPairAddress && !!account,
      retry: 0
    }
  )

  const fetchUserRewardsHistory = useDexbarnGet<RewardsHistoryEpoch[]>(
    `/v1/rewards/history/${chain}/${account}?market=${lbPairAddress}`
  )
  const { data: rewardsHistoryData, isLoading: isHistoryLoading } = useQuery<
    RewardsHistoryEpoch[]
  >(
    ['RewardsHistoryEpoch', account, lbPairAddress],
    () => fetchUserRewardsHistory(),
    {
      enabled: isRewarded && !!lbPairAddress && !!account,
      retry: 0
    }
  )

  const tokenAddresses = useMemo(
    () => getClaimableTokenAddresses(rewardEpochs),
    [rewardEpochs]
  )
  const { tokens: rewardTokens } = useSdkCurrencies({
    addresses: tokenAddresses.map((address) =>
      address === ZERO_ADDRESS ? nativeCurrency.symbol : address
    )
  })

  const claimableRewards = useMemo(() => {
    if (!rewardEpochs) {
      return []
    }
    const tokensMap = getTokensMap(tokenAddresses, rewardTokens)

    const allEpochsFmtRewards: FormattedReward[][] = rewardEpochs.map(
      (epochData) =>
        getFormattedRewards(epochData.claimableRewards, tokensMap, chainId)
    )

    return getAggregatedClaimableRewards(allEpochsFmtRewards)
  }, [tokenAddresses, rewardTokens, rewardEpochs, chainId])

  const { handleClaimRewards } = useLBPairClaimRewards({
    lbPairAddress,
    rewardEpochs,
    rewardsHistoryData
  })

  return {
    claimableRewards,
    handleClaimRewards,
    isLoading: isClaimableLoading || isHistoryLoading,
    rewardsHistoryData
  }
}

export default useLBPairRewards
