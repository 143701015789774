import {
  Box,
  Center,
  Heading,
  Hide,
  HStack,
  LinkBox,
  LinkOverlay,
  Show,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { ChainId, CNATIVE, WNATIVE } from '@fusionx/sdk'
import ChainSelector from 'components/ChainSelector'
import ChainTabs from 'components/ChainTabs'
import CurrencyLogo from 'components/CurrencyLogo'
import SortableTableHeader from 'components/SortableTableHeader'
import { selectableChains } from 'constants/chains'
import useChainId from 'hooks/useChainId'
import useTopTradedTokens from 'hooks/useTopTradedTokens'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { homeClickToken } from 'utils/measure'

enum TokensSortMethod {
  VOLUME,
  PRICE,
  PRICE_CHANGE
}

const TokensRanking = () => {
  const activeChainId = useChainId()
  const [selectedChainIndex, setSelectedChainIndex] = useState(
    selectableChains.findIndex((chain) => chain.id === activeChainId)
  )
  const chainId: ChainId = useMemo(
    () => selectableChains[selectedChainIndex]?.id ?? activeChainId,
    [selectedChainIndex, activeChainId]
  )

  const nativeCurrency = CNATIVE.onChain(chainId)

  const [sortMethod, setSortMethod] = useState<TokensSortMethod>(
    TokensSortMethod.VOLUME
  )
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)

  const { isLoading, tokens } = useTopTradedTokens({ chainId })
  const sortedTokens = useMemo(() => {
    return tokens.sort((tokenA, tokenB) => {
      const [a, b] = isSortDescending ? [tokenA, tokenB] : [tokenB, tokenA]
      switch (sortMethod) {
        case TokensSortMethod.VOLUME:
          return b.volume - a.volume
        case TokensSortMethod.PRICE:
          return b.price - a.price
        case TokensSortMethod.PRICE_CHANGE:
          return (b.pctChange ?? 0) - (a.pctChange ?? 0)
      }
    })
  }, [tokens, sortMethod, isSortDescending])

  const onHeaderClick = useCallback(
    (method: TokensSortMethod) => {
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod]
  )

  // update selected chain tab when chain changes
  useEffect(() => {
    setSelectedChainIndex(
      selectableChains.findIndex((chain) => chain.id === activeChainId)
    )
  }, [activeChainId])

  return (
    <Box px={{ base: 4, md: 0 }}>
      <VStack spacing={4} align="flex-start">
        <Heading size="md">Top Traded</Heading>
        <Hide below="sm">
          <ChainTabs
            chains={selectableChains}
            index={selectedChainIndex}
            onChange={setSelectedChainIndex}
          />
        </Hide>
        <Show below="sm">
          <ChainSelector
            selectedChainId={
              selectableChains[selectedChainIndex]?.id ?? chainId
            }
            onChainSelect={(chainId) =>
              setSelectedChainIndex(
                selectableChains.findIndex((c) => c.id === chainId)
              )
            }
            menuButtonProps={{ w: 'full' }}
          />
        </Show>
      </VStack>
      {isLoading ? (
        <Center p={4}>
          <Spinner />
        </Center>
      ) : (
        <TableContainer mt={4}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Token</Th>
                <SortableTableHeader
                  name="Volume (24H)"
                  isSortActive={sortMethod === TokensSortMethod.VOLUME}
                  isSortDescending={isSortDescending}
                  isNumeric
                  onClick={() => onHeaderClick(TokensSortMethod.VOLUME)}
                />
                <SortableTableHeader
                  name="Price"
                  isSortActive={sortMethod === TokensSortMethod.PRICE}
                  isSortDescending={isSortDescending}
                  isNumeric
                  onClick={() => onHeaderClick(TokensSortMethod.PRICE)}
                />
                <SortableTableHeader
                  name="Change (24H)"
                  isSortActive={sortMethod === TokensSortMethod.PRICE_CHANGE}
                  isSortDescending={isSortDescending}
                  isNumeric
                  onClick={() => onHeaderClick(TokensSortMethod.PRICE_CHANGE)}
                />
              </Tr>
            </Thead>
            <Tbody>
              {sortedTokens.slice(0, 50).map((token, i) => {
                const isWrappedNative =
                  WNATIVE[chainId].address.toLowerCase() ===
                  token.address.toLowerCase()
                const symbol = isWrappedNative
                  ? nativeCurrency.symbol
                  : token.symbol
                return (
                  <LinkBox
                    as={Tr}
                    key={i}
                    transform="scale(1)"
                    cursor="pointer"
                    _hover={{ bg: 'bgTertiary' }}
                    onClick={() => {
                      homeClickToken(symbol ?? '')
                    }}
                  >
                    <Td fontWeight="normal">{i + 1}</Td>
                    <Td>
                      <LinkOverlay
                        aria-label={`Link to trade page for ${token.address}`}
                        href={
                          isWrappedNative
                            ? `/${getChainSlug(chainId)}/trade`
                            : `/${getChainSlug(chainId)}/trade?outputCurrency=${token.address
                            }`
                        }
                      />
                      <HStack>
                        <CurrencyLogo
                          address={token.address}
                          symbol={symbol}
                          boxSize={5}
                        />
                        <Text>{symbol}</Text>
                      </HStack>
                    </Td>
                    <Td isNumeric>{formattedNum(token.volume, true)}</Td>
                    <Td isNumeric>{formattedNum(token.price, true)}</Td>
                    <Td
                      isNumeric
                      color={
                        token.pctChange
                          ? token.pctChange < 0
                            ? 'red.400'
                            : 'green.400'
                          : 'textPrimary'
                      }
                    >
                      {token.pctChange
                        ? `${token.pctChange.toFixed(2)}%`
                        : '--'}
                    </Td>
                  </LinkBox>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default TokensRanking
