import { Box, Flex, Td, Tr } from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import { getRewardRate } from 'constants/pool'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Pool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailUrl } from 'utils/poolUrl'

import PoolTableNameColumn from './PoolTableNameColumn'

interface PoolTableRow {
  pool: Pool
  rewardPriceUSD: number | undefined
}

const PoolTableRow = ({ pool, rewardPriceUSD }: PoolTableRow) => {
  const chainId = useChainId()
  const navigate = useNavigate()

  // get rewards APR
  const rewardRate = getRewardRate(chainId, pool.pairAddress)
  const rewardsPerDay =
    rewardPriceUSD && rewardRate ? rewardRate * rewardPriceUSD : 0
  const rewardsAPR = (rewardsPerDay / pool.liquidityUsd) * 365
  const totalAPR = pool.apr + (rewardsAPR ?? 0)
  const isRewarded = Boolean(rewardRate)

  const feesTooltipLabel = (
    <div>
      Fees APR: {`${Number(pool.apr * 100).toFixed(2)}%`} <br />
      Rewards APR:{' '}
      {rewardsAPR ? `${Number(rewardsAPR * 100).toFixed(2)}%` : 'N/A'}
    </div>
  )

  return (
    <Tr
      data-cy={`pool-row-${pool.pairAddress}`}
      key={pool.pairAddress}
      transform="scale(1)"
      cursor="pointer"
      _hover={{ bg: 'hover' }}
      aria-label={`${pool.tokenX.symbol}-${pool.tokenY.symbol} liquidity pool`}
      onClick={() => navigate(getPoolDetailUrl(pool, chainId))}
    >
      <Td>
        <PoolTableNameColumn
          token0={pool.tokenX}
          token1={pool.tokenY}
          fees={`${pool.feePct}%`}
          isRewarded={isRewarded}
        />
      </Td>
      <Td textAlign="right">{formattedNum(pool.liquidityUsd, true)}</Td>
      <Td textAlign="right">{formattedNum(pool.volumeUsd, true)}</Td>
      <Td textAlign="right">{formattedNum(pool.feesUsd, true)}</Td>
      <Td textAlign="right">
        <Flex alignItems="center" justifyContent="end">
          {rewardsAPR ? (
            <Box textDecoration="dotted underline">
              <TouchFriendlyTooltip label={feesTooltipLabel}>
                {formattedNum(totalAPR * 100, false, 2)}%
              </TouchFriendlyTooltip>
            </Box>
          ) : (
            <Box>{formattedNum(totalAPR * 100, false, 2)}%</Box>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}

export default PoolTableRow
