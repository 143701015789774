import { useRef } from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import {
    Button,
    ButtonProps,
    Flex,
    InputProps,
    InputRightElement,
    Text,
    useDisclosure,
    VStack
} from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import CurrencyPickerModal from 'components/CurrencyPickerModal'

export interface CurrencyInputProps {
    balance?: string
    currency?: Currency
    currencyAddress?: string
    currencyPickerButtonProps?: ButtonProps
    error?: string
    headingText?: string
    isCurrencyPickerDisabled?: boolean
    isDisabled?: boolean
    onCurrencyChange?: (currency: Currency) => void
    rightElement?: JSX.Element
}

const CurrencyPickerField = ({
    balance,
    currency,
    currencyAddress,
    currencyPickerButtonProps,
    error,
    headingText,
    isDisabled,
    onCurrencyChange,
    rightElement,
    ...props
}: CurrencyInputProps & InputProps) => {
    const rightElementRef = useRef<HTMLDivElement | null>(null)

    const {
        isOpen: isCurrencyPickerOpen,
        onClose: onCurrencyPickerClose,
        onOpen: onCurrencyPickerOpen
    } = useDisclosure()

    const isCurrencyPickerEnabled = !!onCurrencyChange

    return (
        <>
            {onCurrencyChange ? (
                <CurrencyPickerModal
                    isOpen={isCurrencyPickerOpen}
                    onClose={onCurrencyPickerClose}
                    onCurrencyChange={onCurrencyChange}
                />
            ) : null}
            <VStack w="full" spacing={headingText ? 0 : 1}>
                <Button
                    justifyContent="space-between"
                    w="full"
                    data-cy="currency-picker-button"
                    variant="secondary"
                    bg="joeLight.400"
                    size="md"
                    borderRadius={12}
                    rightIcon={
                        isCurrencyPickerEnabled ? <ChevronDownIcon justifySelf="end" alignSelf="end" textAlign="right" /> : undefined
                    }
                    isDisabled={isDisabled}
                    pointerEvents={isCurrencyPickerEnabled ? 'auto' : 'none'}
                    onClick={
                        isCurrencyPickerEnabled ? onCurrencyPickerOpen : undefined
                    }
                    {...currencyPickerButtonProps}
                >
                    <Flex flexDir="row" gap={{ base: 2 }}>
                        {
                            currency ? (
                                <CurrencyLogo
                                    address={currencyAddress}
                                    symbol={currency.symbol}
                                    boxSize={5}
                                />
                            ) : undefined
                        }
                        <Text>{currency?.symbol ?? 'Select token'} </Text>
                    </Flex>

                </Button>
                {error ? (
                    <Text fontSize="sm" color="red" w="full">
                        {error}
                    </Text>
                ) : null}
            </VStack>
        </>
    )
}

export default CurrencyPickerField
