import { InfoIcon } from '@chakra-ui/icons'
import {
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  SimpleGrid,
  Text
} from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import Warning from 'components/Warning'
import useKeyPress from 'hooks/useKeyPress'
import React, { useCallback, useEffect, useState } from 'react'
import { getBinIdFromPrice, getBinPctRange, getPriceFromBinId } from 'utils/bin'

import LabeledInput from './LabeledInput'

interface PriceRangeInputsProps {
  binRange: number[]
  binStep: number
  currency0: Currency
  currency1: Currency
  currencyPrice0: number | undefined
  currencyPrice1: number | undefined
  max: number
  min: number
  onBinRangeChange: (range: number[]) => void
  inversePriceRatios?: boolean
  largeRangeDisclaimerText?: string
}

const PriceRangeInputs = ({
  binRange,
  binStep,
  currency0,
  currency1,
  currencyPrice0,
  currencyPrice1,
  inversePriceRatios,
  largeRangeDisclaimerText,
  max,
  min,
  onBinRangeChange
}: PriceRangeInputsProps) => {
  const uKeyPressed = useKeyPress('u')

  const _getPriceFromBinId = useCallback(
    (binId: number): string => {
      const binPrice = Number(
        getPriceFromBinId(binId, binStep, currency0, currency1, 18) ?? '0'
      )
      return (inversePriceRatios ? 1 / binPrice : binPrice).toPrecision(8)
    },
    [currency0, currency1, binStep, inversePriceRatios]
  )

  const [internalBinRange, setInternalBinRange] = useState(
    inversePriceRatios ? [-binRange[0], -binRange[1]] : binRange
  )

  const [isTyping, setIsTyping] = useState(false)
  const [minValue, setMinValue] = useState(_getPriceFromBinId(binRange[0]))
  const [maxValue, setMaxValue] = useState(_getPriceFromBinId(binRange[1]))
  const [numBins, setNumBins] = useState<string>(
    (internalBinRange[1] - internalBinRange[0] + 1).toString()
  )

  const minBinId = getBinIdFromPrice(minValue, binStep, currency0, currency1)
  const maxBinId = getBinIdFromPrice(maxValue, binStep, currency0, currency1)

  const isRangeInvalid =
    !isNaN(minBinId) && !isNaN(maxBinId) && minBinId > maxBinId
  const isMinValueInvalid = isNaN(minBinId)
  const isMaxValueInvalud = isNaN(maxBinId)

  const updateMinMaxValues = useCallback(
    (range: number[]) => {
      setMinValue(_getPriceFromBinId(inversePriceRatios ? -range[0] : range[0]))
      setMaxValue(_getPriceFromBinId(inversePriceRatios ? -range[1] : range[1]))
    },
    [inversePriceRatios, _getPriceFromBinId]
  )

  const updateBinRange = useCallback(
    (range: number[]) => {
      onBinRangeChange(range)
      if (range[0] > 0 && range[1] > 0) {
        setNumBins((range[1] - range[0] + 1).toString())
      } else {
        setNumBins('')
      }
    },
    [onBinRangeChange, setNumBins]
  )

  // recompute internal bin range on price ratios toggle or bin range change
  useEffect(() => {
    const newInternalRange = inversePriceRatios
      ? [-binRange[1], -binRange[0]]
      : binRange
    setInternalBinRange(newInternalRange)
    setNumBins((newInternalRange[1] - newInternalRange[0] + 1).toString())
  }, [inversePriceRatios, binRange])

  // update min/max prices on slider change
  useEffect(() => {
    if (isTyping) return
    updateMinMaxValues(internalBinRange)
  }, [updateMinMaxValues, internalBinRange, isTyping])

  const minValueInUsd = inversePriceRatios
    ? (Number(maxValue) * (currencyPrice0 || 0)).toFixed(6)
    : (Number(minValue) * (currencyPrice1 || 0)).toFixed(6)
  const maxValueInUsd = inversePriceRatios
    ? (Number(minValue) * (currencyPrice0 || 0)).toFixed(6)
    : (Number(maxValue) * (currencyPrice1 || 0)).toFixed(6)

  const tokenPriceExists =
    currencyPrice0 !== undefined || currencyPrice1 !== undefined

  const formatRightLabel = () => {
    return inversePriceRatios
      ? uKeyPressed
        ? `USD per ${currency1.symbol}`
        : `${currency0.symbol} per ${currency1.symbol}`
      : uKeyPressed
        ? `USD per ${currency0.symbol}`
        : `${currency1.symbol} per ${currency0.symbol}`
  }

  return (
    <Flex flexDir="column" gap={4} align="flex-start" w="full">
      {internalBinRange.length === 2 ? (
        <RangeSlider
          aria-label={['min', 'max']}
          colorScheme="accent"
          min={inversePriceRatios ? -max : min}
          max={inversePriceRatios ? -min : max}
          value={internalBinRange}
          onChange={(value) => {
            setInternalBinRange(value)
          }}
          onChangeEnd={(value) => {
            updateBinRange(inversePriceRatios ? [-value[1], -value[0]] : value)
          }}
        >
          <RangeSliderTrack borderRadius="full">
            <RangeSliderFilledTrack />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} />
          <RangeSliderThumb index={1} />
        </RangeSlider>
      ) : null}
      <SimpleGrid columns={{ base: 1, sm: 2 }} gap={4} w="full">
        <LabeledInput
          title="Min Price:"
          rightLabel={formatRightLabel()}
          isInvalid={isMinValueInvalid}
          value={
            tokenPriceExists
              ? uKeyPressed
                ? minValueInUsd
                : minValue
              : minValue
          }
          onFocus={() => setIsTyping(true)}
          onBlur={() => setIsTyping(false)}
          onValueChange={(value) => {
            const minValue = value == '' ? '0' : value
            setMinValue(minValue)
            if (!value) {
              return
            }
            if (inversePriceRatios) {
              updateBinRange([
                binRange[0],
                getBinIdFromPrice(
                  (1 / Number(minValue)).toFixed(18),
                  binStep,
                  currency0,
                  currency1
                )
              ])
            } else {
              updateBinRange([
                getBinIdFromPrice(minValue, binStep, currency0, currency1),
                binRange[1]
              ])
            }
          }}
        />
        <LabeledInput
          title="Max Price:"
          rightLabel={formatRightLabel()}
          isInvalid={isMaxValueInvalud}
          value={
            tokenPriceExists
              ? uKeyPressed
                ? maxValueInUsd
                : maxValue
              : maxValue
          }
          onFocus={() => setIsTyping(true)}
          onBlur={() => setIsTyping(false)}
          onValueChange={(value) => {
            setMaxValue(value)
            if (!value) {
              return
            }
            if (inversePriceRatios) {
              updateBinRange([
                getBinIdFromPrice(
                  (1 / Number(value)).toFixed(18),
                  binStep,
                  currency0,
                  currency1
                ),
                binRange[1]
              ])
            } else {
              updateBinRange([
                binRange[0],
                getBinIdFromPrice(value, binStep, currency0, currency1)
              ])
            }
          }}
        />
        <LabeledInput
          title="Num Bins:"
          isInvalid={isMaxValueInvalud}
          rightLabel="Bins"
          placeholder="0"
          value={numBins?.toString()}
          onFocus={() => setIsTyping(true)}
          onBlur={() => setIsTyping(false)}
          onValueChange={(value) => {
            setNumBins(value)
            if (!value) {
              return
            }
            const newBinRange = [
              internalBinRange[0],
              internalBinRange[0] + Number(value) - 1
            ]
            updateMinMaxValues(internalBinRange)
            updateBinRange(
              inversePriceRatios
                ? [-newBinRange[1], -newBinRange[0]]
                : newBinRange
            )
          }}
        />
        <LabeledInput
          title="Pct Range"
          isDisabled
          value={numBins ? getBinPctRange(binStep, Number(numBins)) : undefined}
        />
      </SimpleGrid>
      {isRangeInvalid ? (
        <Warning
          w="full"
          message="Invalid range. The min price must be lower than the max price."
        />
      ) : null}
      {largeRangeDisclaimerText ? (
        <Flex
          align="center"
          gap={4}
          p={4}
          bg="bgPrimary"
          borderRadius="xl"
          w="full"
        >
          <InfoIcon />
          <Text fontSize="sm">{largeRangeDisclaimerText}</Text>
        </Flex>
      ) : null}
    </Flex>
  )
}

export default PriceRangeInputs
