import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import { useMemo } from 'react'
import { UserVaultBalances, Vault } from 'types/vault'
import { getUserVaultBalanceUSD } from 'utils/vault'

interface UseSortedVaultsProps {
  isSortDescending: boolean
  sortMethod: PoolSortMethod
  vaults: Vault[]
  userBalancesPerVault?: {
    [vaultId: string]: UserVaultBalances | undefined
  }
}

const useSortedVaults = ({
  isSortDescending,
  sortMethod,
  userBalancesPerVault,
  vaults
}: UseSortedVaultsProps) => {
  return useMemo(() => {
    let sortedVaults = vaults
    switch (sortMethod) {
      case PoolSortMethod.LIQUIDITY:
        sortedVaults = sortedVaults.sort(
          (a, b) => b.totalValueLockedUSD - a.totalValueLockedUSD
        )
        break
      case PoolSortMethod.FEE:
        sortedVaults = sortedVaults.sort((a, b) => b.feesUsd7D - a.feesUsd7D)
        break
      case PoolSortMethod.APR:
        sortedVaults = sortedVaults.sort((a, b) => b.apr7D - a.apr7D)
        break
      case PoolSortMethod.MY_FUNDS:
        if (!userBalancesPerVault) return sortedVaults
        sortedVaults = sortedVaults.sort(
          (a, b) =>
            (getUserVaultBalanceUSD(b, userBalancesPerVault[b.id]) ?? 0) -
            (getUserVaultBalanceUSD(a, userBalancesPerVault[a.id]) ?? 0)
        )
        break
    }
    return isSortDescending ? sortedVaults : sortedVaults.reverse()
  }, [vaults, userBalancesPerVault, sortMethod, isSortDescending])
}

export default useSortedVaults
