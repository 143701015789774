import { Divider, Flex, HStack, Spinner, Text, VStack } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'
import { TokenInfo, TokenInfoTag } from 'types/tokensList'

interface CurrencyRowProps {
  isLoadingBalance: boolean
  onClick: () => void
  onTagClick: (tag: TokenInfoTag) => void
  shouldShowTags: boolean
  tokenInfo: TokenInfo
  balance?: string
  subtitle?: string
}

const CurrencyRow = ({
  balance,
  isLoadingBalance,
  onClick,
  onTagClick,
  shouldShowTags,
  subtitle,
  tokenInfo
}: CurrencyRowProps) => {
  return (
    <Flex
      data-cy={`token-item-${tokenInfo.symbol}`}
      w="full"
      align="center"
      justify="space-between"
      cursor="pointer"
      px={4}
      py={3}
      borderRadius="xl"
      _hover={{ bg: 'hover' }}
      onClick={onClick}
    >
      <HStack spacing={4}>
        <CurrencyLogo
          address={tokenInfo.address}
          symbol={tokenInfo.symbol}
          boxSize={8}
        />
        <VStack align="flex-start" spacing={0.5}>
          <HStack>
            <Text fontWeight="bold">{tokenInfo.symbol}</Text>
            <Text fontSize="sm">{tokenInfo.name}</Text>
          </HStack>
          {subtitle ? (
            <Text fontSize="sm" color="textSecondary">
              {subtitle}
            </Text>
          ) : shouldShowTags ? (
            null
            // <HStack>
            //   {tokenInfo.tags.map((tag, i) => (
            //     <Flex key={i} align="center">
            //       <Text
            //         fontSize="sm"
            //         color="textSecondary"
            //         _hover={{
            //           color: 'textPrimary',
            //           textDecoration: 'underline'
            //         }}
            //         onClick={(e) => {
            //           e.stopPropagation()
            //           onTagClick(tag)
            //         }}
            //       >
            //         {tag}
            //       </Text>
            //       {i < tokenInfo.tags.length - 1 ? (
            //         <Divider orientation="vertical" h="16px" ml={2} />
            //       ) : null}
            //     </Flex>
            //   ))}
            // </HStack>
          ) : null}
        </VStack>
      </HStack>
      {isLoadingBalance ? (
        <Spinner size="sm" color="textTertiary" />
      ) : balance ? (
        <Text fontSize="sm" fontWeight="bold">
          {balance}
        </Text>
      ) : null}
    </Flex>
  )
}

export default CurrencyRow
