import {
  Box,
  Flex,
  Grid,
  Heading,
  Hide,
  HStack,
  Image,
  ListItem,
  Show,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  UnorderedList,
  VStack
} from '@chakra-ui/react'
import { JOE_ADDRESS } from '@fusionx/sdk'
import veJoeImage from 'assets/stake/veJoe.webp'
import AnalyticsStat from 'components/AnalyticsStat'
import BackButton from 'components/BackButton'
import PageHelmet from 'components/PageHelmet'
import { STAKE_HELMET_DESCRIPTION, STAKE_HELMET_TITLE } from 'constants/stake'
import { BigNumber, ethers } from 'ethers'
import useVeJoeStaking, {
  useVeJoeUserPosition
} from 'hooks/stake/useVeJoeStaking'
import useChainId from 'hooks/useChainId'
import { useTokenBalance } from 'hooks/useTokenBalance'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import debounce from 'lodash.debounce'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { formattedNum } from 'utils/format'
import { useAccount } from 'wagmi'

import VeJoeClaimPanel from './VeJoeClaimPanel'
import VeJoeStakePanel from './VeJoeStakePanel'
import VeJoeUnstakePanel from './VeJoeUnstakePanel'

const VeJoeDetail = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { address: account } = useAccount()

  const chainId = useChainId()
  const joeTokenAddress = JOE_ADDRESS[chainId]
  const { data } = useTokenPriceUSD({
    tokens: [joeTokenAddress]
  })
  const joeUSD = data?.[0]

  const { totalStaked, totalSupply } = useVeJoeStaking()
  const totalStakedNum = totalStaked
    ? Number(ethers.utils.formatEther(totalStaked))
    : 0
  const totalStakedUSD = totalStakedNum * (joeUSD ?? 0)
  const fmtTotalStakedUSD = formattedNum(totalStakedUSD, true, 2)
  const totalSupplyNum = totalSupply
    ? Number(ethers.utils.formatEther(totalSupply))
    : 0
  const fmtTotalSupply = formattedNum(totalSupplyNum, false, 2)

  // get JOE token balance
  const { data: joeBalance, refetch: refetchJoeBalance } = useTokenBalance({
    token: joeTokenAddress
  })

  // get veJOE userBalance and rewards
  const {
    claim,
    isClaiming,
    isLoading,
    pending,
    refetch: refetchUserPositions,
    userBalance,
    userVeJoeBalance,
    veJoePerDayForUser
  } = useVeJoeUserPosition()

  const debouncedRefetchJoeBalance = debounce(() => refetchJoeBalance(), 2000)
  const debouncedRefetchUserPositions = debounce(
    () => refetchUserPositions(),
    4000
  )

  const userBalanceBN = useMemo(() => {
    return userBalance ?? ethers.constants.Zero
  }, [userBalance])

  const fmtUserBalance = useMemo(() => {
    return userBalance
      ? formattedNum(ethers.utils.formatEther(userBalance), false, 2)
      : 'N/A'
  }, [userBalance])

  const fmtVeJoePerDay = useMemo(() => {
    return veJoePerDayForUser
      ? `${BigNumber.from(veJoePerDayForUser).toNumber()} veJOE per day`
      : '0'
  }, [veJoePerDayForUser])

  const fmtVeJoeBalance = useMemo(() => {
    return userVeJoeBalance
      ? formattedNum(
        Number(ethers.utils.formatUnits(userVeJoeBalance, 18)),
        false,
        2
      )
      : '0'
  }, [userVeJoeBalance])

  const onClaim = () => {
    claim?.()
  }

  return (
    <Box pt={{ base: 6, md: 24 }} w="full">
      <PageHelmet
        description={STAKE_HELMET_DESCRIPTION}
        title={STAKE_HELMET_TITLE}
        url={location.pathname}
      />
      <Flex
        maxW="1200px"
        margin="0 auto"
        flexDir="column"
        align="flex-start"
        gap={2}
        px={4}
        pos="relative"
        h="170px"
      >
        <BackButton
          title="Back"
          ml={-3}
          minH="24px"
          onClick={() => navigate('/stake')}
        />

        <HStack>
          <Image boxSize={12} src={veJoeImage} />
          <Heading fontSize="5xl">veJOE</Heading>
        </HStack>
      </Flex>
      <Box bgColor="bgSecondary" maxWidth="100%">
        <Box px={4} maxW="1200px" margin="0 auto">
          <Grid
            gap={{ base: 4, lg: 12 }}
            pb={24}
            templateColumns={{ base: '1fr', lg: '1fr 1fr' }}
          >
            <VStack spacing={8} id="vstack1">
              <VStack w="full" spacing={4}>
                <Flex align="center" justify="space-between" w="full" pt={10}>
                  <Heading size="md">Overview</Heading>
                </Flex>
                <SimpleGrid columns={{ lg: 2, sm: 1 }} gap={4} w="full">
                  <AnalyticsStat
                    title="Total Staked (USD)"
                    value={fmtTotalStakedUSD}
                    change={0}
                  />
                  <AnalyticsStat
                    title="Total veJOE"
                    value={`${fmtTotalSupply} veJOE`}
                    change={0}
                  />
                </SimpleGrid>
              </VStack>
              <Show below="md">
                <VStack w="full" spacing={4} id="vstack2">
                  <Tabs isFitted w="full" colorScheme="accent">
                    <TabList>
                      <Tab data-cy="vejoe-stake-tab">Stake</Tab>
                      <Tab data-cy="vejoe-unstake-tab">Unstake</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <VeJoeStakePanel
                          joeBalance={joeBalance}
                          onSuccess={() => {
                            debouncedRefetchJoeBalance()
                            debouncedRefetchUserPositions()
                          }}
                        />
                      </TabPanel>
                      <TabPanel>
                        <VeJoeUnstakePanel
                          userBalance={userBalanceBN}
                          onSuccess={() => {
                            debouncedRefetchJoeBalance()
                            debouncedRefetchUserPositions()
                          }}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  {account ? (
                    <VeJoeClaimPanel
                      claim={onClaim}
                      isClaiming={isClaiming}
                      isLoading={isLoading}
                      pendingVeJoeBalance={pending}
                      userBalance={fmtUserBalance}
                      veJoeBalance={fmtVeJoeBalance}
                      veJoePerDay={fmtVeJoePerDay}
                    />
                  ) : null}
                </VStack>
              </Show>
              <VStack align="flex-start" spacing={4} w="full">
                <Heading fontSize="xl">Stake Information</Heading>
                <UnorderedList pl={4}>
                  <ListItem>
                    Stake JOE into veJOE to increase your JOE rewards in Boost
                    Farms.
                  </ListItem>
                  <ListItem>
                    When you claim veJOE you will automatically apply a Boost
                    APR.
                  </ListItem>
                  <ListItem>
                    Rewards are distributed every few days, and you can Harvest
                    at any time.
                  </ListItem>
                  <ListItem>
                    Boost APR will be additional yield earned on top of JOE APR
                    and Pool APR.
                  </ListItem>
                  <ListItem>
                    The amount of veJOE you have will determine your share of
                    the boosted rewards. If you unstake any amount of JOE from
                    veJOE you will lose all of your accrued veJOE.
                  </ListItem>
                </UnorderedList>
              </VStack>
            </VStack>
            <Hide below="md">
              <VStack spacing={4} id="vstack2">
                <Tabs isFitted w="full" colorScheme="accent">
                  <TabList pt={10}>
                    <Tab data-cy="vejoe-stake-tab">Stake</Tab>
                    <Tab data-cy="vejoe-unstake-tab">Unstake</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <VeJoeStakePanel
                        joeBalance={joeBalance}
                        onSuccess={() => {
                          debouncedRefetchJoeBalance()
                          debouncedRefetchUserPositions()
                        }}
                      />
                    </TabPanel>
                    <TabPanel>
                      <VeJoeUnstakePanel
                        userBalance={userBalanceBN}
                        onSuccess={() => {
                          debouncedRefetchJoeBalance()
                          debouncedRefetchUserPositions()
                        }}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                {account ? (
                  <VeJoeClaimPanel
                    claim={onClaim}
                    isClaiming={isClaiming}
                    isLoading={isLoading}
                    pendingVeJoeBalance={pending}
                    userBalance={fmtUserBalance}
                    veJoeBalance={fmtVeJoeBalance}
                    veJoePerDay={fmtVeJoePerDay}
                  />
                ) : null}
              </VStack>
            </Hide>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default VeJoeDetail
