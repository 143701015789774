export const BLACKLISTED_TOKENS = [
  // XETA
  '0x31c994ac062c1970c086260bc61babb708643fac',
  // REVO
  '0xd4000aac6de096b9d9379df862aa99af2013333f',
  // bullUSD
  '0x186b8bd6cfa6e0aaf9f6fe6df71bf58824c6e339',
  // Fake JOE token on Arbitrum
  '0xafb8caaf957b1c43c3febc94dd8aa759d9de06b1'
]

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
