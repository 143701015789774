import { gql } from 'graphql-request'

// For sJOE Analytics
const SJoeAnalyticsQuery = gql`
  query stableJoeDayDatasQuery($first: Int! = 100, $dateAfter: Int!) {
    stableJoeDayDatas(
      first: $first
      orderBy: date
      orderDirection: asc
      where: { date_gte: $dateAfter }
    ) {
      id
      date
      totalJoeStaked
    }
  }
`

// sJOE Money Maker USD
const MoneyMakerDayDatasQuery = gql`
  query moneyMakerDayDatasQuery($first: Int! = 100, $dateAfter: Int!) {
    dayDatas(
      first: $first
      orderBy: date
      orderDirection: desc
      where: { date_gte: $dateAfter }
    ) {
      id
      date
      usdRemitted
    }
  }
`

// sJOE remittance
const RemittancesQuery = gql`
  query remittancesQuery($first: Int!) {
    remits(first: $first, orderBy: timestamp, orderDirection: desc) {
      id
      token0Symbol
      amount0
      token1Symbol
      amount1
      tokenRemitted
      usdRemitted
      timestamp
    }
  }
`

const VeJoeActiveUserCount = gql`
  query veJoeActiveUserCount {
    veJoes {
      activeUserCount
    }
  }
`

const VeJoeMedianStakerBalanceQuery = gql`
  query veJoeMedianStakerBalanceQuery($first: Int!, $skip: Int!) {
    users(
      where: { veJoeBalance_gt: "0" }
      first: $first
      skip: $skip
      orderBy: veJoeBalance
      orderDirection: desc
    ) {
      id
      veJoeBalance
    }
  }
`

export {
  MoneyMakerDayDatasQuery,
  RemittancesQuery,
  SJoeAnalyticsQuery,
  VeJoeActiveUserCount,
  VeJoeMedianStakerBalanceQuery
}
