import { LBFactoryV21ABI, LB_ROUTER_V21_ADDRESS, LB_FACTORY_V21_ADDRESS, LBRouterV21ABI } from "@fusionx/sdk-v2";
import useChainId from "hooks/useChainId";
import {
    useContractWrite,
    usePrepareContractWrite,
    useWaitForTransaction,
} from "wagmi";
import { formatUnits, getAddress, parseEther } from "ethers/lib/utils.js";
import { useMemo } from "react";
import { capturePrepareContractWriteError } from "utils/logger";
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit";
import useTransactionToast from "hooks/useTransactionToast";
import { BigNumber } from "ethers";
import { wrappedCurrency } from "utils/wrappedCurrency";
import { Currency } from "@fusionx/sdk";

type typeCreaetPoolArgs = [
    {
        tokenX: `0x${string}`;
        tokenY: `0x${string}`;
        activeId: BigNumber;
        binStep: BigNumber;
    }
];

type createNewPoolV21Types = {
    currency0?: Currency;
    currency1?: Currency;
    activeBinId?: BigNumber;
    binStep?: BigNumber;
    onSuccess?: () => void;
};

const useCreateNewPoolV21 = ({
    currency0,
    currency1,
    activeBinId,
    binStep,
    onSuccess,
}: createNewPoolV21Types) => {
    const chainId = useChainId();
    const addRecentTransaction = useAddRecentTransaction();
    const addTransactionToast = useTransactionToast();

    const token0Address = wrappedCurrency(currency0, chainId)?.address;
    const token1Address = wrappedCurrency(currency1, chainId)?.address;

    const createPoolArgs = useMemo(
        (): any | undefined =>
            token0Address && token1Address && activeBinId && binStep
                ? [getAddress(token0Address), getAddress(token1Address), activeBinId, binStep]
                : undefined,
        [token0Address, token1Address, activeBinId, binStep]
    );

    const {
        config: createPoolConfig,
        error: prepareConfigError,
        isSuccess: createPoolConfigSuccess,
        isLoading: isPreparingConfig,
    } = usePrepareContractWrite({
        abi: LBFactoryV21ABI,
        address: getAddress(LB_FACTORY_V21_ADDRESS[chainId]),
        args: createPoolArgs,
        chainId: chainId,
        functionName: "createLBPair",
        // onError: onPrepareContractWriteError,
        onSettled: (_, error) => {
            capturePrepareContractWriteError(error);
        },
    });

    console.log("createPoolConfig", createPoolConfig, prepareConfigError, createPoolConfigSuccess);

    const transactionSummary = useMemo(() => "", []);

    const res = useContractWrite({
        ...createPoolConfig,
        onSuccess: (data: any) => {
            if (!transactionSummary) return;
            addRecentTransaction({
                description: transactionSummary,
                hash: data.hash,
            });
            addTransactionToast({ description: transactionSummary, hash: data.hash });
        },
    });

    console.log("response", res)
    const { data, isLoading, write } = res;

    const { isLoading: isWaitingForTransaction, isSuccess } =
        useWaitForTransaction({
            hash: data?.hash,
            onSuccess,
        });

    return {
        createNewPoolV21: write,
        isLoading: isWaitingForTransaction || isLoading,
        isPreparingConfig,
        isSuccess,
        prepareConfigError,
    };
};

export default useCreateNewPoolV21;
