import { STABLE_JOE_STAKING_ADDRESS } from '@fusionx/sdk'
import sJoeImage from 'assets/stake/sJoe.webp'
import { ZERO_ADDRESS } from 'constants/tokens'
import { ethers } from 'ethers'
import { useSJoePayouts } from 'hooks/stake/useSJoeAnalytics'
import { useSJoeStaking } from 'hooks/stake/useSJoeStaking'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'

import { CurrencyTab } from '.'
import NAStakeCard from './NotAvailableStakeCard'
import StakeCard from './StakeCard'

export interface JoeUsdProps {
  currencyTab: CurrencyTab
  joeUsd: number | undefined
}

const SJoeCard = ({ currencyTab, joeUsd }: JoeUsdProps) => {
  const { depositFee, sJoeUserBalance, totalStaked } = useSJoeStaking()
  console.log("depositalStaked", depositFee, sJoeUserBalance, totalStaked)

  const remittanceDayDatas = useSJoePayouts() // 30 day remittances

  // check if not available on selected chain
  const chainId = useChainId()
  const isNotAvailable = STABLE_JOE_STAKING_ADDRESS[chainId] === ZERO_ADDRESS

  // user balance
  const balanceNum = sJoeUserBalance
    ? Number(ethers.utils.formatEther(sJoeUserBalance))
    : 0
  const fmtBalance = formattedNum(balanceNum, false, 2)
  const fmtBalanceUsd = formattedNum(balanceNum * (joeUsd ?? 0), true, 2)

  // total staked
  const totalStakedNum = totalStaked
    ? Number(ethers.utils.formatEther(totalStaked))
    : 0
  const totalStakedUSD = totalStakedNum * (joeUsd ?? 0)
  const fmtTotalStaked = formattedNum(totalStakedNum, false, 2)
  const fmtTotalStakedUSD = formattedNum(totalStakedUSD, true, 2)

  // deposit fee
  const fmtDepositFee = depositFee
    ? `${formattedNum(
      Number(ethers.utils.formatEther(depositFee)) * 100,
      false,
      2
    )}%`
    : ''

  // apr
  const totalRemittance = remittanceDayDatas?.reduce((sum: number, current) => {
    return sum + Number(current.usdRemitted)
  }, 0)
  const apr30D = ((365 / 30) * (totalRemittance ?? 0)) / totalStakedUSD
  const fmtApr30D = `${formattedNum(apr30D * 100, false, 2)}%`

  // link
  const link = `${getChainSlug(chainId)}/stake/sjoe`

  return isNotAvailable ? (
    <NAStakeCard image={sJoeImage} title="sJOE" />
  ) : (
    <StakeCard
      image={sJoeImage}
      linkUrl={link}
      title="sJOE"
      tag="Earn USD Stablecoins"
      label1={`Total Staked (${currencyTab})`}
      text1={
        currencyTab == CurrencyTab.JOE ? fmtTotalStaked : fmtTotalStakedUSD
      }
      label2={`Your Stake (${currencyTab})`}
      text2={currencyTab == CurrencyTab.JOE ? fmtBalance : fmtBalanceUsd}
      label3="APR (30D)"
      text3={fmtApr30D}
      label4="Deposit Fee"
      text4={fmtDepositFee}
    />
  )
}

export default SJoeCard
