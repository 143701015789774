import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import {
  joeBridge,
  joeFarm,
  joeLend,
  joeStake,
  SubmenuItem
} from 'constants/urls'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ThreeDotsIcon } from 'theme/icons'
import { getChainSlug } from 'utils/chains'

const Submenu = () => {
  const chainId = useChainId()
  const navigate = useNavigate()
  const submenuItems: SubmenuItem[] = [joeBridge] // joeStake, joeFarm, joeLend

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Click for links to Bridge, Farm, Lend and Stake on FusionX"
        variant="ghost"
        color="textTertiary"
        _hover={{ bg: 'bgTertiary' }}
        _active={{ bg: 'bgTertiary', color: 'textPrimary' }}
      >
        <Center>
          <ThreeDotsIcon fill="textTertiary" />
        </Center>
      </MenuButton>
      <MenuList>
        {submenuItems.map((item, i) => {
          return item.external ? (
            <MenuItem
              key={i}
              h="48px"
              onClick={() => window.open(item.url, '_blank')}
            >
              <Flex gap={2} align="center" justify="space-between">
                {item.name}
                <ExternalLinkIcon />
              </Flex>
            </MenuItem>
          ) : (
            <MenuItem
              key={i}
              h="48px"
              onClick={() => navigate(`/${getChainSlug(chainId)}/${item.url}`)}
            >
              {item.name}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default Submenu
