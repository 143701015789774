import { Heading, Tag } from '@chakra-ui/react'
import PoolCard from 'components/PoolCard'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { UserVaultBalances, Vault } from 'types/vault'
import { getChainSlug } from 'utils/chains'
import { getVaultRowFormattedValues } from 'utils/vault'

interface VaultMobileRowProps {
  vault: Vault
  userBalance?: UserVaultBalances
}

const VaultMobileRow = ({ userBalance, vault }: VaultMobileRowProps) => {
  const chainId = useChainId()
  const { apr7D, feesUsd7D, liquidity } = getVaultRowFormattedValues(
    vault,
    userBalance
  )
  return (
    <PoolCard
      w="full"
      href={`/${getChainSlug(chainId)}/vault/${vault.id}`}
      token0={vault.tokenX}
      token1={vault.tokenY}
      topContent={
        <Heading size="sm" mb={4}>
          {vault.name}
        </Heading>
      }
      tags={[
        <Tag
          key="fees-tag"
          size="md"
          rounded="full"
          fontWeight="bold"
          bg="bgTertiary"
          textColor="textSecondary"
        >
          {`${vault.lbPair.baseFeePct}%`}
        </Tag>
      ]}
      columns={[
        {
          title: 'Liquidity',
          value: liquidity
        },
        {
          title: 'Fees (7D)',
          value: feesUsd7D
        },
        {
          title: 'APR (7D)',
          value: apr7D
        }
      ]}
    />
  )
}

export default VaultMobileRow
