import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency } from '@fusionx/sdk'
import { SendTransactionResult } from '@wagmi/core'
import { BigNumber, ethers } from 'ethers'
import useActiveChain from 'hooks/useActiveChain'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { useSlippageSettings } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { getCurrencyAddress } from 'utils/wrappedCurrency'
import { useContractWrite, useWaitForTransaction } from 'wagmi'

import useAddLiquidityConfig from './useAddLiquidityConfig'
import useAddLiquidityNativeConfig from './useAddLiquidityNativeConfig'

interface UseAddLiquidityProps {
  amountADesired?: BigNumber
  amountBDesired?: BigNumber
  enabled?: boolean
  onSuccess?: (data: ethers.providers.TransactionReceipt) => void
  tokenA?: Currency
  tokenB?: Currency
}

/**  A hook to add liquidity to v1 pools
 * @tokenA : a token to add, can be undefined to use native currency
 * @tokenB : a token to add, can be undefined to use native currency
 * @amountADesired : amount of token A to add to the pool
 * @amountBDesired : amount of token B to add to the pool
 * @enabled : can be set to false if not all conditions are met for this call (tokens not approved for example)
 * @onSuccess : success callback
 */
const useAddLiquidity = ({
  amountADesired,
  amountBDesired,
  enabled,
  onSuccess,
  tokenA,
  tokenB
}: UseAddLiquidityProps) => {
  const { nativeCurrency } = useActiveChain()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const {
    slippageSettings: { v1: slippage }
  } = useSlippageSettings()
  const amountAMin = amountADesired?.mul(10000 - slippage * 100).div(10000)
  const amountBMin = amountBDesired?.mul(10000 - slippage * 100).div(10000)

  const tokenAddressA = getCurrencyAddress(tokenA)
  const tokenAddressB = getCurrencyAddress(tokenB)

  const shouldUseAddLiquidityNative =
    tokenA && tokenB && (tokenA.isNative || tokenB.isNative)

  const { config: addLiquidityNativeConfig } = useAddLiquidityNativeConfig({
    amountADesired,
    amountAMin,
    amountBDesired,
    amountBMin,
    enabled: enabled && shouldUseAddLiquidityNative,
    tokenA: tokenAddressA,
    tokenB: tokenAddressB
  })

  const { config: addLiquidityConfig } = useAddLiquidityConfig({
    amountADesired,
    amountAMin,
    amountBDesired,
    amountBMin,
    enabled: enabled && !shouldUseAddLiquidityNative,
    tokenA: tokenAddressA,
    tokenB: tokenAddressB
  })

  const transactionSummary = useMemo(
    () =>
      amountADesired && amountBDesired
        ? `Add ${formattedNum(
          ethers.utils.formatUnits(amountADesired, tokenA?.decimals)
        )} ${tokenA?.symbol ?? nativeCurrency?.symbol} and ${formattedNum(
          ethers.utils.formatUnits(amountBDesired, tokenB?.decimals)
        )} ${tokenB?.symbol ?? nativeCurrency?.symbol}`
        : undefined,
    [tokenA, tokenB, amountADesired, amountBDesired, nativeCurrency]
  )

  const onWriteSuccess = (data: SendTransactionResult) => {
    if (!transactionSummary) return
    addRecentTransaction({
      description: transactionSummary,
      hash: data.hash
    })
    addTransactionToast({ description: transactionSummary, hash: data.hash })
  }

  const {
    data: addLiquidityData,
    isLoading: isLoadingAddLiquidity,
    write: addLiquidity
  } = useContractWrite({ ...addLiquidityConfig, onSuccess: onWriteSuccess })

  const {
    data: addLiquidityNativeData,
    isLoading: isLoadingAddLiquidityNative,
    write: addLiquidityNative
  } = useContractWrite({
    ...addLiquidityNativeConfig,
    onSuccess: onWriteSuccess
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: shouldUseAddLiquidityNative
        ? addLiquidityNativeData?.hash
        : addLiquidityData?.hash,
      onSuccess
    })

  return {
    addLiquidity: shouldUseAddLiquidityNative
      ? addLiquidityNative
      : addLiquidity,
    isLoading:
      isLoadingAddLiquidity ||
      isLoadingAddLiquidityNative ||
      isWaitingForTransaction,
    isSuccess
  }
}

export default useAddLiquidity
