import { Box, Center, Heading, Spinner, Text } from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import useChainId from 'hooks/useChainId'
import React, { useMemo } from 'react'
import { LBPairUserBalances } from 'types/poolV2'
import { convertLBPositionToLiquidityChartData } from 'utils/poolV2'
import { wrappedCurrency } from 'utils/wrappedCurrency'

interface UserLiquidityChartProps {
  activeBinId: number
  binStep: string
  isLoadingUserBalances: boolean
  currency0?: Currency
  currency1?: Currency
  userBalances?: LBPairUserBalances
}

const UserLiquidityChart = ({
  activeBinId,
  binStep,
  currency0,
  currency1,
  isLoadingUserBalances,
  userBalances
}: UserLiquidityChartProps) => {
  const chainId = useChainId()

  const data = useMemo(() => {
    const token0 = wrappedCurrency(currency0, chainId)
    const token1 = wrappedCurrency(currency1, chainId)
    console.log("userLiquidityChart", activeBinId,
      binStep,
      token0,
      token1,
      userBalances)
    return convertLBPositionToLiquidityChartData({
      activeBinId,
      binStep,
      token0,
      token1,
      userBalances
    })
  }, [currency0, currency1, binStep, userBalances, activeBinId, chainId])

  return data.length === 0 || isLoadingUserBalances ? (
    <Box w="full">
      <Heading size="md" mb={2}>
        My Liquidity
      </Heading>
      <Center h="200px">
        {isLoadingUserBalances ? (
          <Spinner size="sm" color="textSecondary" />
        ) : (
          <Text fontSize="sm" color="textSecondary">
            You have no liquidity in this pool
          </Text>
        )}
      </Center>
    </Box>
  ) : (
    <LBPairDistributionChart
      title="My Liquidity"
      binStep={binStep}
      data={data}
      activeBinId={activeBinId}
      currency0={currency0}
      currency1={currency1}
    />
  )
}

export default UserLiquidityChart
