import { Currency, WNATIVE } from '@fusionx/sdk'
import usePair from 'hooks/pool/v1/usePair'
import usePoolV1ByTokens, {
  fetchPoolV1ByTokens
} from 'hooks/pool/v1/usePoolV1ByTokens'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'
import { wrappedCurrency } from 'utils/wrappedCurrency'

interface UsePoolV1LiquidityPanelProps {
  token0Address?: string
  token1Address?: string
}

const usePoolV1LiquidityPanel = ({
  token0Address,
  token1Address
}: UsePoolV1LiquidityPanelProps) => {
  const navigate = useNavigate()
  const chainId = useChainId()
  const { nativeCurrency } = useActiveChain()

  const isParam0NativeCurrency =
    nativeCurrency?.symbol.toLowerCase() === token0Address?.toLowerCase()
  const poolQueryToken0 = isParam0NativeCurrency
    ? WNATIVE[chainId].address
    : token0Address

  const isParam1NativeCurrency =
    nativeCurrency?.symbol.toLowerCase() === token1Address?.toLowerCase()
  const poolQueryToken1 = isParam1NativeCurrency
    ? WNATIVE[chainId].address
    : token1Address

  const { data: pool, isLoading: isLoadingPool } = usePoolV1ByTokens({
    tokens:
      poolQueryToken0 && poolQueryToken1
        ? [poolQueryToken0, poolQueryToken1]
        : undefined
  })

  const [_currency0, setCurrency0] = useState<Currency | undefined>()
  const [_currency1, setCurrency1] = useState<Currency | undefined>()

  const {
    tokens: [paramCurrency0, paramCurrency1]
  } = useSdkCurrencies({
    addresses: [token0Address, token1Address]
  })

  const currency0 = useMemo(
    () => _currency0 ?? paramCurrency0,
    [_currency0, paramCurrency0]
  )
  const currency1 = useMemo(
    () => _currency1 ?? paramCurrency1,
    [_currency1, paramCurrency1]
  )

  const { data: pair, isLoading: isLoadingPair } = usePair({ pool })

  const [isLoadingNewPair, setIsLoadingNewPair] = useState(false)
  const onCurrencySelect = useCallback(
    async (currency: Currency, pairedCurrency?: Currency) => {
      const tokenAddress = wrappedCurrency(currency, chainId)?.address
      const pairedTokenAddress = wrappedCurrency(
        pairedCurrency,
        chainId
      )?.address
      if (!pairedTokenAddress || !tokenAddress) {
        return
      }
      setIsLoadingNewPair(true)
      const result = await fetchPoolV1ByTokens({
        chainId,
        tokens: [pairedTokenAddress, tokenAddress]
      })
      setIsLoadingNewPair(false)
      if (result.pairs.length > 0) {
        const pool = result.pairs[0]
        setCurrency0(undefined)
        setCurrency1(undefined)
        navigate(
          `/${getChainSlug(chainId)}/pool/v1/${currency.isNative ? currency.symbol : tokenAddress
          }/${pairedCurrency?.isNative
            ? nativeCurrency?.symbol
            : pool.token0.id?.toLowerCase() === tokenAddress.toLowerCase()
              ? pool.token1.id
              : pool.token0.id
          }`
        )
      }
    },
    [navigate, chainId, nativeCurrency]
  )

  const onToken0Select = useCallback(
    async (currency: Currency) => {
      setCurrency0(currency)
      if (
        !currency1 ||
        currency.symbol === currency0?.symbol ||
        currency.symbol === currency1?.symbol
      ) {
        return
      }
      onCurrencySelect(currency, currency1)
    },
    [onCurrencySelect, currency1, currency0]
  )

  const onToken1Select = useCallback(
    async (currency: Currency) => {
      setCurrency1(currency)
      if (
        !currency0 ||
        currency.symbol === currency1?.symbol ||
        currency.symbol === currency0?.symbol
      ) {
        return
      }
      onCurrencySelect(currency, currency0)
    },
    [onCurrencySelect, currency0, currency1]
  )

  return {
    isLoading: isLoadingPair || isLoadingPool || isLoadingNewPair,
    onToken0Select,
    onToken1Select,
    pair,
    pool,
    token0: currency0,
    token1: currency1
  }
}

export default usePoolV1LiquidityPanel
