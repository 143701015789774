import { InputProps } from '@chakra-ui/react'
import CurrencyInput, { CurrencyInputProps } from 'components/CurrencyInput'
import React from 'react'

const TradeCurrencyInput = (props: CurrencyInputProps & InputProps) => (
  <CurrencyInput
    border={0}
    borderRadius={0}
    p={0}
    _focus={{ border: 0, boxShadow: 'none' }}
    fontWeight="bold"
    fontSize="3xl"
    placeholder="0.0"
    _placeholder={{ color: 'textTertiary' }}
    currencyPickerButtonProps={{ borderRadius: 'full' }}
    {...props}
  />
)

export default TradeCurrencyInput
