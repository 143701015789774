import { JSBI, Token, TokenAmount } from '@fusionx/sdk'
import { Bin } from '@fusionx/sdk-v2'
import { ethers } from 'ethers'

/**
 * Computes the price for the given binId,
 * parses the decimals given tokens A and B
 * and finally formats the output given the number of decimals
 * @param {number} binId
 * @param {number} binStep
 * @param {Token} tokenA
 * @param {Token} tokenB
 * @param {number} decimals
 * @returns
 */
export const computeAndParsePriceFromBin = (
  binId: number,
  binStep: number,
  tokenA: Token,
  tokenB: Token,
  decimals = 6
) => {
  const price = Bin.getPriceFromId(binId, binStep)
  const priceInYDecimals =
    (price * 10 ** tokenA.decimals) / 10 ** tokenB.decimals
  const tokenAmount = new TokenAmount(
    tokenB,
    JSBI.BigInt(
      ethers.utils.parseUnits(
        parseFloat(priceInYDecimals.toString()).toFixed(tokenB.decimals),
        tokenB.decimals
      )
    ).toString()
  )

  return tokenAmount.toSignificant(decimals).toString()
}
