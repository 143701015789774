import { Text, VStack } from '@chakra-ui/react'
import React from 'react'

interface VaultInfoStatsProps {
  title: string
  value: string
}

const VaultInfoStats = ({ title, value }: VaultInfoStatsProps) => (
  <VStack align="flex-start" spacing={0}>
    <Text fontSize="sm" textColor="textSecondary" fontWeight="bold">
      {title}
    </Text>
    <Text fontSize="lg" fontWeight="bold">
      {value}
    </Text>
  </VStack>
)

export default VaultInfoStats
