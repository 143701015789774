import { useQuery } from '@tanstack/react-query'
import { MONEY_MAKER_SUBGRAPH, SJOE_SUBGRAPH } from '@fusionx/sdk'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  MoneyMakerDayDatasQuery,
  RemittancesQuery,
  SJoeAnalyticsQuery
} from 'graphql/stake'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { SJoeDayDatas, SJoePayoutDatas, SJoeRemittanceDatas } from 'types/stake'

dayjs.extend(utc)

type SJoeAnalyticsQueryResponse = { stableJoeDayDatas: SJoeDayDatas[] }
type SJoePayoutsQueryResponse = { dayDatas: SJoePayoutDatas[] }
type SJoeRemittancesQueryResponse = { remits: SJoeRemittanceDatas[] }

interface ChartData {
  date: number
  value: number
}

// Returns sJOE chart data
const useSJoeAnalytics = () => {
  const chainId = useChainId()
  const subgraphUrl = SJOE_SUBGRAPH[chainId]

  const DAYS_IN_A_MONTH = 30
  const utcThirtyDayBack = dayjs()
    .utc()
    .startOf('day')
    .subtract(DAYS_IN_A_MONTH, 'day')
    .unix()

  const result = useQuery(['SJoeAnalyticsQuery', subgraphUrl], () =>
    request<SJoeAnalyticsQueryResponse>(subgraphUrl, SJoeAnalyticsQuery, {
      dateAfter: utcThirtyDayBack
    })
  )
  const chartData: ChartData[] | undefined = result.data?.stableJoeDayDatas.map(
    (data) => {
      return { date: data.date, value: data.totalJoeStaked }
    }
  )
  return chartData
}

// Returns sJOE payout data
export const useSJoePayouts = () => {
  const chainId = useChainId()
  const subgraphUrl = MONEY_MAKER_SUBGRAPH[chainId]

  const DAYS_IN_A_MONTH = 30
  const utcThirtyDayBack = dayjs()
    .utc()
    .startOf('day')
    .subtract(DAYS_IN_A_MONTH, 'day')
    .unix()

  const result = useQuery(['MoneyMakerDayDatasQuery', subgraphUrl], () =>
    request<SJoePayoutsQueryResponse>(subgraphUrl, MoneyMakerDayDatasQuery, {
      dateAfter: utcThirtyDayBack
    })
  )
  return result.data?.dayDatas
}

// Returns sJOE remittance data
export const useSJoeRemittances = (first = 15) => {
  const chainId = useChainId()
  const subgraphUrl = MONEY_MAKER_SUBGRAPH[chainId]

  const result = useQuery(['RemittancesQuery', subgraphUrl, first], () =>
    request<SJoeRemittancesQueryResponse>(subgraphUrl, RemittancesQuery, {
      first
    })
  )
  return result.data?.remits
}

export default useSJoeAnalytics
