import {
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  useToken
} from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import AnalyticsChart from 'components/AnalyticsChart'
import AnalyticsStat from 'components/AnalyticsStat'
import BinsTradedChart from 'components/BinsTradedChart'
import ChartZoomButtons from 'components/ChartZoomButtons'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import {
  getRewardRate,
  POOL_DISTRIBUTION_CHART_RADIUS,
  REWARD_TOKEN
} from 'constants/pool'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils.js'
import useBinAnalytics from 'hooks/analytics/useBinAnalytics'
import useLBPairDetailAnalytics from 'hooks/analytics/useLBPairDetailAnalytics'
import useLBPairSwaps from 'hooks/pool/v2/useLBPairSwaps'
import useChainId from 'hooks/useChainId'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import React, { useState } from 'react'
import { formattedNum } from 'utils/format'
import { wrappedCurrency } from 'utils/wrappedCurrency'

import PoolSwapsTable from './PoolSwapsTable'

interface PoolDetailV2AnalyticsPanelProps {
  currency0: Currency
  currency1: Currency
  isSelected: boolean
  activeBinId?: number
  binStep?: string
  lbPairAddress?: string
  reserveX?: BigNumber
  reserveY?: BigNumber
}

const PoolDetailV2AnalyticsPanel = ({
  activeBinId,
  binStep,
  currency0,
  currency1,
  isSelected,
  lbPairAddress,
  reserveX,
  reserveY
}: PoolDetailV2AnalyticsPanelProps) => {
  const chainId = useChainId()
  const [graphPurple, graphGreen] = useToken('colors', [
    'graphPurpleDark',
    'graphGreenDark'
  ])

  const tokenA = wrappedCurrency(currency0, chainId)
  const tokenB = wrappedCurrency(currency1, chainId)

  const { data, isLoading } = useLBPairDetailAnalytics({
    enabled: isSelected,
    lbPairAddress
  })

  const { swaps } = useLBPairSwaps({
    address: lbPairAddress,
    enabled: isSelected,
    first: 10
  })

  const [distributionRadius, setDistributionRadius] = useState<number>(
    POOL_DISTRIBUTION_CHART_RADIUS.initial
  )
  const { binsTraded24, poolDistributionData } = useBinAnalytics({
    activeBinId,
    binTradedRadius: 100,
    distributionRadius,
    enabled: isSelected,
    lbPairAddress,
    tokenA,
    tokenB
  })

  // get rewards APR
  const rewardRate = lbPairAddress ? getRewardRate(chainId, lbPairAddress) : 0
  const rewardToken = REWARD_TOKEN[chainId]
  const { data: tokenPriceData } = useTokenPriceUSD({
    tokens: rewardRate && rewardToken ? [rewardToken] : []
  })
  const rewardPriceUSD = tokenPriceData?.[0]
  const rewardsPerDay =
    rewardPriceUSD && rewardRate ? rewardRate * rewardPriceUSD : 0
  const rewardsAPR = data ? (rewardsPerDay / data?.tvl) * 365 : 0

  if (isLoading) {
    return (
      <Center p={8}>
        <Spinner />
      </Center>
    )
  }

  if (!data) return null

  return (
    <Flex
      flexDir="column"
      gap={{ base: 4, md: 16, sm: 12 }}
      mb={{ base: 4, md: 8 }}
    >
      <SimpleGrid columns={{ base: 2, md: 3 }} gap={4}>
        <AnalyticsStat
          title="Liquidity"
          value={formattedNum(data.tvl, true)}
          change={data.tvlDelta}
        />
        <AnalyticsStat
          title="Volume (24H)"
          value={formattedNum(data.volume24H, true)}
          change={data.volume24HDelta}
        />
        <AnalyticsStat
          title="Fees (24H)"
          value={formattedNum(data.fees24H, true)}
          change={data.fees24HDelta}
        />
        <AnalyticsStat title="APR (7D)" value={`${data.apr7D.toFixed(2)}%`} />
        {reserveX ? (
          <AnalyticsStat
            title={`${currency0.symbol} Reserves`}
            value={`${formattedNum(
              formatUnits(reserveX, currency0.decimals)
            )} ${currency0.symbol}`}
          />
        ) : null}
        {reserveY ? (
          <AnalyticsStat
            title={`${currency1.symbol} Reserves`}
            value={`${formattedNum(
              formatUnits(reserveY, currency1.decimals)
            )} ${currency1.symbol}`}
          />
        ) : null}
        {rewardsAPR ? (
          <AnalyticsStat
            title="Rewards APR (24H)"
            value={`${formattedNum(rewardsAPR * 100, false, 2)}%`}
          />
        ) : null}
      </SimpleGrid>
      {activeBinId && binStep ? (
        <LBPairDistributionChart
          title="Pool Distribution"
          binStep={binStep}
          data={poolDistributionData}
          activeBinId={activeBinId}
          currency0={currency0}
          currency1={currency1}
          bottomRightElement={
            <ChartZoomButtons
              minValue={POOL_DISTRIBUTION_CHART_RADIUS.min}
              maxValue={POOL_DISTRIBUTION_CHART_RADIUS.max}
              value={distributionRadius}
              step={POOL_DISTRIBUTION_CHART_RADIUS.step}
              onChange={setDistributionRadius}
            />
          }
        />
      ) : null}
      {activeBinId && binsTraded24.length > 0 ? (
        <BinsTradedChart
          activeBinId={activeBinId}
          binsTraded24={binsTraded24}
          currency0={currency0}
          currency1={currency1}
        />
      ) : null}
      <AnalyticsChart
        id="tvl"
        data={data.tvlDatas}
        header={<Heading size="md">TVL (Total Locked Value)</Heading>}
        h="220px"
        tooltipTitle="TVL"
        fill={graphPurple}
        stroke={graphPurple}
        showXAxis
        showYAxis
      />
      <AnalyticsChart
        id="volume"
        data={data.volumeDatas}
        header={<Heading size="md">Volume (24H)</Heading>}
        h="220px"
        tooltipTitle="Volume"
        fill={graphGreen}
        stroke={graphGreen}
        showXAxis
        showYAxis
      />
      {swaps && (
        <PoolSwapsTable
          first={10}
          swaps={swaps}
          currency0={currency0}
          currency1={currency1}
        />
      )}
    </Flex>
  )
}

export default PoolDetailV2AnalyticsPanel
