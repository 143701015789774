import { Box, Text, VStack } from '@chakra-ui/react'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent'
import { formattedNum } from 'utils/format'
import { ZERO_FRACTION } from 'utils/numbers'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

type LBPairDistributionChartTooltipProps = TooltipProps<ValueType, NameType>

const LBPairDistributionChartTooltip = ({
  active,
  payload
}: LBPairDistributionChartTooltipProps) => {
  const chainId = useChainId()
  const { nativeCurrency } = useActiveChain()

  const bin = payload?.[0]?.payload
  const price = bin?.price
  const amountX = bin?.amountX
  const amountY = bin?.amountY
  const noLiquidity = bin?.liquidity === 0

  const isToken0WrappedNativeCurrency = isWrappedNativeCurrency(
    amountX?.token.address,
    chainId
  )
  const isToken1WrappedNativeCurrency = isWrappedNativeCurrency(
    amountY?.token.address,
    chainId
  )

  const symbolA = isToken0WrappedNativeCurrency
    ? nativeCurrency.symbol
    : amountX?.token.symbol
  const symbolB = isToken1WrappedNativeCurrency
    ? nativeCurrency.symbol
    : amountY?.token.symbol

  return active && payload && payload.length && !noLiquidity ? (
    <Box
      p={4}
      borderRadius="xl"
      backdropFilter="blur(8px)"
      bg="rgba(245, 245, 255, 0.48)"
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.16)"
      _dark={{
        bg: 'rgba(53, 58, 108, 0.48)',
        boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.4)'
      }}
    >
      <VStack spacing={0} align="flex-start" mb={4}>
        <Text
          fontSize="sm"
          color="textSecondary"
        >{`Price (${symbolA} / ${symbolB})`}</Text>
        <Text fontWeight="semibold">
          {price ? formattedNum(price) : undefined}
        </Text>
      </VStack>
      <VStack spacing={4} align="flex-start">
        {amountX && amountX.greaterThan(ZERO_FRACTION) ? (
          <VStack spacing={0} align="flex-start">
            <Text fontSize="sm" color="textSecondary">
              {symbolA}
            </Text>
            <Text fontWeight="semibold">{amountX.toSignificant(5)}</Text>
          </VStack>
        ) : null}
        {amountY && amountY.greaterThan(ZERO_FRACTION) ? (
          <VStack spacing={0} align="flex-start">
            <Text fontSize="sm" color="textSecondary">
              {symbolB}
            </Text>
            <Text fontWeight="semibold">{amountY.toSignificant(5)}</Text>
          </VStack>
        ) : null}
      </VStack>
    </Box>
  ) : null
}

export default LBPairDistributionChartTooltip
