import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Pool as DexbarnPool } from 'types/dexbarn'
import { Pool } from 'types/pool'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolToPool } from 'utils/poolV2'

dayjs.extend(utc)

interface UsePoolV2Props {
  pageSize: number
}

const usePoolsV2 = ({ pageSize }: UsePoolV2Props) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  console.log("usePoolsV2", chain);

  // fetch from dexbarn
  const fetchPoolsV2 = useDexbarnGet<DexbarnPool[]>(`/v1/pools/${chainId}`)
  const result = useInfiniteQuery<DexbarnPool[]>(
    ['LBPairsQuery', chain, pageSize],
    ({ pageParam = 1 }) =>
      fetchPoolsV2({
        params: {
          filterBy: '1d',
          orderBy: 'volumeUSD',
          pageNum: pageParam,
          pageSize,
          status: 'unverified'
        }
      }),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length % pageSize === 0 ? allPages.length + 1 : undefined,
      keepPreviousData: true
    }
  )

  console.log(" result graph data", result)

  // convert to common pool interface
  const pools: Pool[] =
    result.data?.pages.flat().map(convertDexbarnPoolToPool) ?? []

  return {
    ...result,
    data: pools
  }
}

export default usePoolsV2
