import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Flex, Link } from '@chakra-ui/react'
import React from 'react'

interface HeaderLinkProps {
  title: string
  to: string
}

const ExternalHeaderLink = ({ title, to }: HeaderLinkProps) => (
  <Link
    aria-label={title}
    isExternal
    href={to}
    fontWeight="semibold"
    color="textTertiary"
    textAlign="center"
    px={4}
    py={2}
    borderRadius="xl"
    _hover={{ bg: 'bgTertiary', textDecor: 'none' }}
  >
    <Flex gap={2} align="center" justify="space-between">
      {title}
      <ExternalLinkIcon />
    </Flex>
  </Link>
)

export default ExternalHeaderLink
