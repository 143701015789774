import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import FarmImage1x from 'assets/home/farm_1x.webp'
import FarmImage2x from 'assets/home/farm_2x.webp'
import FarmImage3x from 'assets/home/farm_3x.webp'
import LendImage1x from 'assets/home/lend_1x.webp'
import LendImage2x from 'assets/home/lend_2x.webp'
import LendImage3x from 'assets/home/lend_3x.webp'
import NftImage1x from 'assets/home/nft_1x.webp'
import NftImage2x from 'assets/home/nft_2x.webp'
import NftImage3x from 'assets/home/nft_3x.webp'
import PoolImage1x from 'assets/home/pool_1x.webp'
import PoolImage2x from 'assets/home/pool_2x.webp'
import PoolImage3x from 'assets/home/pool_3x.webp'
import StakeImage1x from 'assets/home/stake_1x.webp'
import StakeImage2x from 'assets/home/stake_2x.webp'
import StakeImage3x from 'assets/home/stake_3x.webp'
import TradeImage1x from 'assets/home/trade_1x.webp'
import TradeImage2x from 'assets/home/trade_2x.webp'
import TradeImage3x from 'assets/home/trade_3x.webp'
import TradingIcon from 'assets/trading.png'
import AddLiquidityIcon from 'assets/add-liquidity.png'
import { joeFarm, joeLend } from 'constants/urls'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'
import { homeClickTile } from 'utils/measure'

interface Tile {
  altTag: string
  boxStyle: BoxProps
  imageSrc: string
  srcSet: string
  title: string
  to: string
  isExternal?: boolean
}

const HomeTiles = () => {
  const chainId = useChainId()
  const navigate = useNavigate()

  const tiles: Tile[] = [
    {
      altTag: 'trade-tile-image',
      boxStyle: {
        _dark: {
          _hover: { borderColor: '#cfeef8' },
          bg: '#4392ab'
        },
        _hover: {
          borderColor: '#3595B5'
        },
        background: '#cfeef8'
      },
      imageSrc: TradingIcon,
      srcSet: `${TradingIcon} 1x ${TradingIcon} 2x ${TradingIcon} 3x`,
      title: 'Trade tokens',
      to: 'trade'
    },
    {
      altTag: 'pool-tile-image',
      boxStyle: {
        _dark: {
          _hover: { borderColor: '#e1f2e2' },
          bg: '#608060'
        },
        _hover: {
          borderColor: '#5BC861'
        },
        background: '#e1f2e0'
      },
      imageSrc: AddLiquidityIcon,
      srcSet: `${AddLiquidityIcon} 1x ${AddLiquidityIcon} 2x ${AddLiquidityIcon} 3x`,
      title: 'Provide liquidity',
      to: 'pool'
    },

    // {
    //   altTag: 'farm-tile-image',
    //   boxStyle: {
    //     _dark: {
    //       _hover: {
    //         borderColor: '#f8ebc8'
    //       },
    //       bg: '#a58c4e'
    //     },
    //     _hover: {
    //       borderColor: '#a58c4e'
    //     },
    //     background: '#f8ebc8'
    //   },
    //   imageSrc: FarmImage2x,
    //   isExternal: true,
    //   srcSet: `${FarmImage1x} 1x ${FarmImage2x} 2x ${FarmImage3x} 3x`,
    //   title: 'Farm',
    //   to: joeFarm.url 
    // },
    // {
    //   altTag: 'stake-tile-image',
    //   boxStyle: {
    //     _dark: {
    //       _hover: {
    //         borderColor: '#f7dfe5'
    //       },
    //       bg: '#74434f'
    //     },
    //     _hover: {
    //       borderColor: '#74434f'
    //     },
    //     background: '#f7dfe5'
    //   },
    //   imageSrc: StakeImage2x,
    //   srcSet: `${StakeImage1x} 1x ${StakeImage2x} 2x ${StakeImage3x} 3x`,
    //   title: 'Stake',
    //   to: 'stake'
    // },
    // {
    //   altTag: 'nft-tile-image',
    //   boxStyle: {
    //     _dark: {
    //       bg: 'joeDark.500'
    //     },
    //     _hover: {
    //       borderColor: 'joeLight.700'
    //     },
    //     background: 'joeLight.500'
    //   },
    //   imageSrc: NftImage2x,
    //   isExternal: true,
    //   srcSet: `${NftImage1x} 1x ${NftImage2x} 2x ${NftImage3x} 3x`,
    //   title: 'Trade NFTs',
    //   to: 'https://www.joepegs.com'
    // },
    // {
    //   altTag: 'lend-tile-image',
    //   boxStyle: {
    //     _dark: {
    //       _hover: {
    //         borderColor: '#cecdfb'
    //       },
    //       bg: '#4d4bb8'
    //     },
    //     _hover: {
    //       borderColor: '#4d4bb8'
    //     },
    //     background: '#cecdfb'
    //   },
    //   imageSrc: LendImage2x,
    //   isExternal: true,
    //   srcSet: `${LendImage1x} 1x ${LendImage2x} 2x ${LendImage3x} 3x`,
    //   title: 'Lend',
    //   to: joeLend.url
    // }
  ]

  return (
    <Flex flexDir="column" w="full" gap={4} px={{ base: 4, md: 0 }}>
      <Heading size="md">Explore</Heading>
      <SimpleGrid columns={{ base: 2, md: 3 }} gap={{ base: 2, md: 4 }}>
        {tiles.map((tile, i) => (
          <Box
            key={i}
            px={{ base: 4, md: 8 }}
            py={{ base: 4, md: 6 }}
            borderRadius="3xl"
            cursor="pointer"
            role="group"
            border="1px"
            borderColor="transparent"
            transition="150ms all ease-in-out"
            onClick={() => {
              if (tile.isExternal) {
                window.open(tile.to, '_blank')
              } else {
                navigate(`/${getChainSlug(chainId)}/${tile.to}`)
              }
              homeClickTile(tile.title)
            }}
            {...tile.boxStyle}
          >
            <VStack spacing={4}>
              <Image
                fallbackSrc={tile.imageSrc}
                srcSet={tile.srcSet}
                alt={tile.altTag}
                w="60px"
                h="60px"
                objectFit="contain"
                transition="150ms transform ease-in-out"
                _groupHover={{ transform: 'scale(1.05)' }}
              />
              <Text fontWeight="bold">{tile.title}</Text>
            </VStack>
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  )
}

export default HomeTiles
