import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { Flex, TableColumnHeaderProps, Th } from '@chakra-ui/react'
import React from 'react'

interface SortableTableHeaderProps {
  isSortActive: boolean
  isSortDescending: boolean
  name: string
  onClick: () => void
  isNumeric?: boolean
}

const SortableTableHeader = ({
  isNumeric,
  isSortActive,
  isSortDescending,
  name,
  onClick,
  ...props
}: SortableTableHeaderProps & TableColumnHeaderProps) => {
  return (
    <Th
      cursor="pointer"
      _hover={{ opacity: '0.5' }}
      isNumeric={isNumeric}
      onClick={onClick}
      {...props}
    >
      <Flex
        justify={isNumeric ? 'flex-end' : 'flex-start'}
        align="center"
        gap={0.5}
      >
        {isSortActive ? (
          isSortDescending ? (
            <ArrowDownIcon boxSize="16px" />
          ) : (
            <ArrowUpIcon boxSize="16px" />
          )
        ) : null}
        {name}
      </Flex>
    </Th>
  )
}

export default SortableTableHeader
