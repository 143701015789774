import { ChainId } from '@fusionx/sdk'

interface VaultInfo {
  description: string
  name: string
}

export const VAULTS_INFO: {
  [chainId in ChainId]: { [address: string]: VaultInfo }
} = {
  [ChainId.FUJI]: {
    '0x939eadb7d7b33d6b0ecc64855888588e931be1ad': {
      description:
        'FusionX manages concentrated liquidity positions, rebalancing positions, and auto-compounding of all fees & rewards.\n\nDivergence Risk: Assets may experience significant divergent price movements during volatile conditions. This means your deposit is exposed to the price movements of both tokens in the pair, and your exposure to each token can also change. Volatile conditions may lead to increased impermanent/divergence loss.',
      name: 'Uniform Hourly Rebalancing'
    }
  },
  [ChainId.AVALANCHE]: {},
  [ChainId.ARBITRUM_ONE]: {},
  [ChainId.ARB_GOERLI]: {},
  [ChainId.BNB_CHAIN]: {},
  [ChainId.BNB_TESTNET]: {},
  [ChainId.MANTLE_TESTNET]: {}
}
