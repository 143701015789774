import { PoolSortMethod } from 'hooks/pool/useSortedPools'

export const poolsListHeaders = [
  { name: 'Pool name' },
  {
    isNumeric: true,
    name: 'Liquidity',
    sortMethod: PoolSortMethod.LIQUIDITY
  },
  {
    isNumeric: true,
    name: 'Volume (24h)',
    sortMethod: PoolSortMethod.VOLUME
  },
  {
    isNumeric: true,
    name: 'Fees (24h)',
    sortMethod: PoolSortMethod.FEE
  },
  {
    isNumeric: true,
    name: 'APR (24h)',
    sortMethod: PoolSortMethod.APR
  }
]

export enum PoolVersion {
  ALL = 'All Pools',
  V1 = 'V1 Pools',
  V2 = 'V2 Pools'
}
