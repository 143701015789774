import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Center, HStack, Tag, Text } from '@chakra-ui/react'
import { Token } from '@fusionx/sdk'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useLBPairFeeParameters from 'hooks/pool/v2/useLBPairFeeParameters'
import React from 'react'

interface RouteDetailsStepProps {
  pair: Token[]
  showForwardArrow: boolean
  binStep?: number
  lbPairAddress?: string
}

const RouteDetailsStep = ({
  binStep,
  lbPairAddress,
  pair,
  showForwardArrow
}: RouteDetailsStepProps) => {
  const {
    data: { baseFee }
  } = useLBPairFeeParameters({ lbPairAddress })
  const isV1 = !binStep
  const fmtFee = isV1 ? '0.3%' : baseFee ? `${Number(baseFee) / 100}%` : ''
  return (
    <HStack spacing={1}>
      <DoubleCurrencyLogo
        address0={pair[0].address}
        address1={pair[1].address}
        boxSize={5}
      />
      <Text fontSize="sm" fontWeight="bold">
        {pair[1].symbol}
      </Text>
      <Tag size="sm" rounded="full" fontWeight="bold">
        {fmtFee}
      </Tag>
      {showForwardArrow ? (
        <Center>
          <ArrowForwardIcon boxSize="14px" color="textSecondary" ml={1} />
        </Center>
      ) : null}
    </HStack>
  )
}

export default RouteDetailsStep
