import '@rainbow-me/rainbowkit/styles.css'
import 'react-toastify/dist/ReactToastify.css'

import { Flex } from '@chakra-ui/react'
import { useColorMode } from '@chakra-ui/react'
// import {
//   bootstrap,
//   Bridge as TJBridge,
//   createWagmiProvider,
//   themeDark,
//   themeLight,
//   ThemeProvider
// } from '@layerzerolabs/x-trader-joe-bridge'
import PageHelmet from 'components/PageHelmet'
import { BRIDGE_HELMET_TITLE } from 'constants/bridge'
import { wagmi } from 'constants/wagmi'
import React from 'react'

// replace with testnet config for testing
//import { testnet } from './config/testnet'
import { mainnet } from './config/mainnet'

// bootstrap(mainnet, {
//   evm: wagmi.wagmiAdapter
// })

// const EthereumAdapterProvider = createWagmiProvider(
//   wagmi
// ) as React.FC<React.PropsWithChildren>

const Bridge = () => {
  const { colorMode } = useColorMode()
  return (
    <Flex
      maxW="1200px"
      pt={{ base: 6, md: 24 }}
      margin="0 auto"
      minH="calc(100vh - 80px)"
      px={4}
      w="lg"
    >
      <PageHelmet title={BRIDGE_HELMET_TITLE} url={window.location.pathname} />
      {/* <ThemeProvider theme={colorMode === 'light' ? themeLight : themeDark}>
        <EthereumAdapterProvider>
          <TJBridge />
        </EthereumAdapterProvider>
      </ThemeProvider> */}
    </Flex>
  )
}

export default Bridge
