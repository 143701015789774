import { useQuery } from '@tanstack/react-query'
import { DEX_V2_SUBGRAPH } from '@fusionx/sdk-v2'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { vaultsQuery } from 'graphql/dexV2'
import { request } from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { VaultSubgraphModels } from 'types/vault'
import { convertSubgraphVaultIntoVault } from 'utils/vault'

dayjs.extend(utc)

interface UseVaultsProps {
  enabled?: boolean
}

const useVaults = ({ enabled }: UseVaultsProps) => {
  const chainId = useChainId()
  const subgraphUrl = DEX_V2_SUBGRAPH[chainId]
  console.log("subgraphUrl", subgraphUrl)
  const utcSevenDayBack = dayjs().utc().startOf('day').subtract(7, 'day').unix()

  const result = useQuery<{ vaults: VaultSubgraphModels.Vault[] }>(
    ['VaultsQuery', subgraphUrl],
    () =>
      request(subgraphUrl, vaultsQuery, { date: utcSevenDayBack, first: 500 }),
    { enabled }
  )

  return {
    ...result,
    data:
      result.data?.vaults.map((vault) =>
        convertSubgraphVaultIntoVault(vault, chainId)
      ) ?? []
  }
}

export default useVaults
