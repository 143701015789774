import { ethers } from 'ethers'
import { Vault } from 'types/vault'

interface UseVaultSearchProps {
  query: string
  vaults: Vault[]
}

const useVaultSearch = ({ query, vaults }: UseVaultSearchProps): Vault[] => {
  const lowercasedQuery = query.toLowerCase()
  if (ethers.utils.isAddress(query)) {
    return vaults.filter(
      (vault) =>
        vault.tokenX.address?.toLowerCase().startsWith(lowercasedQuery) ||
        vault.tokenY.address?.toLowerCase().startsWith(lowercasedQuery) ||
        vault.id?.toLowerCase().startsWith(lowercasedQuery)
    )
  }
  return vaults.filter(
    (vault) =>
      vault.tokenX.symbol?.toLowerCase().includes(lowercasedQuery) ||
      vault.tokenY.symbol?.toLowerCase().includes(lowercasedQuery) ||
      vault.name.toLowerCase().includes(lowercasedQuery)
  )
}

export default useVaultSearch
