import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import AppChainSelector from 'components/AppChainSelector'
import ColorModeButton from 'components/Header/ColorModeButton'
import {
  joeBridge,
  joeFarm,
  joeHome,
  joeLend,
  joeStake,
  SubmenuItem
} from 'constants/urls'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HamburgerIcon } from 'theme/icons'
import { getChainSlug } from 'utils/chains'

const MobileNavigationMenu = () => {
  const chainId = useChainId()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const navigate = useNavigate()
  const submenuItems: SubmenuItem[] = [
    joeHome,
    joeBridge,
    // joeStake,
    // joeFarm,
    // joeLend
  ]

  return (
    <>
      <Button
        aria-label="Click for links to Bridge, Farm, Lend and Stake on FusionX"
        variant="ghost"
        color="textPrimary"
        _hover={{ bg: 'bgTertiary' }}
        _active={{ bg: 'bgTertiary', color: 'textPrimary' }}
        onClick={onOpen}
      >
        <HamburgerIcon boxSize={6} fill="textPrimary" />
      </Button>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          py={4}
          borderTopLeftRadius={24}
          borderTopRightRadius={24}
          bg="bgSecondary"
        >
          <DrawerCloseButton />
          <DrawerBody>
            <Flex
              right={12}
              top={1}
              position="absolute"
              justifyContent="right"
              w="full"
            >
              <ColorModeButton />
            </Flex>
            <Flex
              gap={4}
              w="full"
              align="center"
              flexDir="column"
              justifyContent="center"
              p={4}
            >
              {submenuItems.map((item, i) => {
                return item.external ? (
                  <Flex
                    _hover={{ cursor: 'pointer' }}
                    key={i}
                    align="center"
                    justifyContent="center"
                    w="full"
                    h={10}
                    onClick={() => window.open(item.url, '_blank')}
                  >
                    <Flex
                      w="full"
                      gap={2}
                      justifyContent="center"
                      align="center"
                    >
                      <Text textAlign="center" fontWeight="bold">
                        {item.name}
                      </Text>
                      <ExternalLinkIcon />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    key={i}
                    w="full"
                    h={10}
                    justifyContent="center"
                    align="center"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/${getChainSlug(chainId)}/${item.url}`)
                      onClose()
                    }}
                  >
                    <Text textAlign="center" w="full" fontWeight="bold">
                      {item.name}
                    </Text>
                  </Flex>
                )
              })}
            </Flex>
            <Flex justifyContent="center" w="full">
              <AppChainSelector />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileNavigationMenu
