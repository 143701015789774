import { FEE_RATE } from 'constants/pool'
import { Pool } from 'types/pool'
import { LiquidityPosition, Pair, UserPoolPosition } from 'types/poolV1'

interface ConvertPairToPoolProps {
  pair: Pair
}

export const convertPairToPool = ({ pair }: ConvertPairToPoolProps): Pool => {
  const id = pair.id
  const name = pair.name
  const liquidity = Number(pair.reserveUSD) > 0 ? Number(pair.reserveUSD) : 0
  let volume = 0

  pair?.hourData?.forEach((hour: { volumeUSD: any; untrackedVolumeUSD: any; volumeToken0: any; volumeToken1: any }) => {
    let volumeForHour = hour
      ? Number(hour.volumeUSD) > 0
        ? Number(hour.volumeUSD)
        : Number(hour.untrackedVolumeUSD)
      : 0
    if (volumeForHour === 0 && hour) {
      volumeForHour =
        Number(hour.volumeToken0) * Number(pair?.token0Price) +
        Number(hour.volumeToken1) * Number(pair?.token1Price)
    }
    volume += volumeForHour
  })

  const fee = Number(volume) * FEE_RATE
  const apr = (fee / liquidity) * 365

  const { token0, token1 } = pair

  return {
    apr,
    feePct: 0.3,
    feesUsd: fee,
    isLb: false,
    liquidityUsd: liquidity,
    name,
    pairAddress: id,
    tokenX: {
      address: token0.id,
      decimals: parseInt(token0.decimals),
      symbol: token0.symbol
    },
    tokenY: {
      address: token1.id,
      decimals: parseInt(token1.decimals),
      symbol: token1.symbol
    },
    volumeUsd: volume
  }
}

interface ConvertPairsToPoolsProps {
  pairs: Pair[]
}

export const convertPairsToPools = ({
  pairs
}: ConvertPairsToPoolsProps): Pool[] => {
  const pools = pairs.map((pair: Pair) => {
    return convertPairToPool({ pair })
  })
  return pools.filter(
    (pool) =>
      pool.liquidityUsd < 1e12 &&
      pool.tokenX.symbol !== 'JLP' &&
      pool.tokenY.symbol !== 'JLP'
  )
}

interface ConvertLiquidityPositionToUserPoolProps {
  liquidityPosition: LiquidityPosition
}

const convertLiquidityPositionToUserPool = ({
  liquidityPosition
}: ConvertLiquidityPositionToUserPoolProps): UserPoolPosition => {
  const poolTokens = Number(liquidityPosition?.liquidityTokenBalance)
  const pair = liquidityPosition?.pair
  const id = pair?.id
  const name = pair?.name
  const poolShare = pair?.totalSupply
    ? poolTokens / Number(pair?.totalSupply)
    : 0
  const token0 = pair?.token0
  const token1 = pair?.token1
  const pooledToken0 = Number(pair?.reserve0) * poolShare
  const pooledToken1 = Number(pair?.reserve1) * poolShare

  return {
    id,
    name,
    poolShare,
    poolTokens,
    pooledToken0,
    pooledToken1,
    token0,
    token1
  }
}

interface ConvertLiquidityPositionsToUserPoolPositionsProps {
  liquidityPositions: LiquidityPosition[]
}

export const convertLiquidityPositionsToUserPoolPositions = ({
  liquidityPositions
}: ConvertLiquidityPositionsToUserPoolPositionsProps): UserPoolPosition[] => {
  const userPools = liquidityPositions
    ?.filter(
      (liquidityPosition: LiquidityPosition) =>
        parseFloat(liquidityPosition.liquidityTokenBalance) > 0
    )
    .map((liquidityPosition: LiquidityPosition) =>
      convertLiquidityPositionToUserPool({ liquidityPosition })
    )
  return userPools
}
