import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import { FetchBalanceResult } from '@wagmi/core'
import { BigNumber } from 'ethers'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import React from 'react'
import { LBPairUserBalances } from 'types/poolV2'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useChainId } from 'wagmi'

import AddLiquidityPanelV2 from './AddLiquidityPanelV2'
import RemoveLiquidityPanelV2 from './RemoveLiquidityPanelV2'

interface AddRemoveLiquidityPanelV2Props {
  currency0: Currency
  currency1: Currency
  lbPairAddress: string
  onAddLiquiditySuccess: () => void
  onRemoveLiquidityConfigError: () => void
  onRemoveLiquiditySuccess: () => void
  activeBinId?: BigNumber
  balance0?: FetchBalanceResult
  balance1?: FetchBalanceResult
  binStep?: string
  userBalances?: LBPairUserBalances
}

const AddRemoveLiquidityPanelV2 = (props: AddRemoveLiquidityPanelV2Props) => {
  const chainId = useChainId()
  const wrappedCurrency0 = wrappedCurrency(props.currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(props.currency1, chainId)

  const { data: usdPrices } = useTokenPriceUSD({
    tokens:
      wrappedCurrency0 && wrappedCurrency1
        ? [wrappedCurrency0.address, wrappedCurrency1.address]
        : []
  })

  const [currencyPrice0, currencyPrice1] = usdPrices ?? [undefined, undefined]

  return (
    <Tabs isFitted variant="soft-rounded" w="full">
      <TabList>
        <Tab _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}>
          Add Liquidity
        </Tab>
        <Tab _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}>
          Remove Liquidity
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <AddLiquidityPanelV2
            {...props}
            currencyPrice0={currencyPrice0}
            currencyPrice1={currencyPrice1}
          />
        </TabPanel>
        <TabPanel px={0}>
          <RemoveLiquidityPanelV2
            {...props}
            currencyPrice0={currencyPrice0}
            currencyPrice1={currencyPrice1}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default AddRemoveLiquidityPanelV2
