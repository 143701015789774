import { useQuery } from '@tanstack/react-query'
import { Token, TokenAmount } from '@fusionx/sdk'
import { ethers } from 'ethers'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { useState } from 'react'
import { BinAnalyticsData, PoolQueryParam } from 'types/dexbarn'
import { BinVolumeData, LBPairDistribution } from 'types/poolV2'
import { getDexbarnChainParam } from 'utils/chains'
import { percentageTokenYCompareInTokenX } from 'utils/percentage'

interface UseBinAnalyticsProps {
  binTradedRadius: number
  distributionRadius: number
  activeBinId?: number
  enabled?: boolean
  lbPairAddress?: string
  tokenA?: Token
  tokenB?: Token
}

const useBinAnalytics = ({
  activeBinId,
  binTradedRadius,
  distributionRadius,
  enabled = true,
  lbPairAddress,
  tokenA,
  tokenB
}: UseBinAnalyticsProps) => {
  const [timeFilter, setTimeFilter] = useState<PoolQueryParam.FilterBy>('1d')

  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const radius = Math.max(binTradedRadius, distributionRadius)
  const fetchBins = useDexbarnGet<BinAnalyticsData[]>(
    `/v1/bin/${chainId}/${lbPairAddress}/${activeBinId}?filterBy=${timeFilter}&radius=${radius}`
  )

  const { data: binsData, isLoading } = useQuery<BinAnalyticsData[]>(
    ['BinAnalyticsData', lbPairAddress, activeBinId, chain, radius],
    () => fetchBins(),
    {
      enabled: !!lbPairAddress && !!activeBinId && enabled,
      keepPreviousData: true,
      retry: 0
    }
  )

  const binsTraded24: BinVolumeData[] = useMemo(() => {
    if (!binsData || !activeBinId) {
      return []
    }
    return binsData
      .filter(
        (bin) =>
          bin.volumeUsd > 0 &&
          Math.abs(activeBinId - bin.binId) <= binTradedRadius
      )
      .map((bin) => ({
        binId: bin.binId,
        isActiveBin: bin.binId === activeBinId,
        priceXY: bin.priceXY,
        priceYX: bin.priceYX,
        volumeUsd: bin.volumeUsd,
        volumeX: bin.volumeX,
        volumeY: bin.volumeY
      }))
  }, [binsData, activeBinId, binTradedRadius])

  const poolDistributionData: LBPairDistribution[] = useMemo(() => {
    if (!binsData || !tokenA || !tokenB || !activeBinId) {
      return []
    }
    return binsData
      .filter((bin) => Math.abs(activeBinId - bin.binId) <= distributionRadius)
      .map((bin) => {

        const liquidity = Number(
          ethers.utils.formatUnits(bin.totalSupply, tokenB.decimals)
        )
        const reserveXRaw = !bin.reserveXRaw.includes('-')
          ? bin.reserveXRaw
          : '0'
        const reserveYRaw = !bin.reserveYRaw.includes('-')
          ? bin.reserveYRaw
          : '0'
        console.log("poolDistributionData", bin, liquidity, activeBinId)
        const amountX = new TokenAmount(tokenA, reserveXRaw);
        const amountY = new TokenAmount(tokenB, reserveYRaw);
        const price = Number(bin.priceXY.toFixed(tokenB.decimals))
        const percentage = percentageTokenYCompareInTokenX(Number(amountX.toFixed()), Number(amountY.toFixed()), price)

        return {
          amountX,
          amountY,
          amountYPct: percentage.toString(),
          binId: bin.binId,
          isActiveBin: bin.binId === activeBinId,
          liquidity,
          price: bin.priceXY.toFixed(tokenB.decimals)
        }
      })
  }, [binsData, tokenA, tokenB, activeBinId, distributionRadius])

  return { binsTraded24, isLoading, poolDistributionData, setTimeFilter }
}

export default useBinAnalytics
