import { LBFactoryV21ABI, LB_FACTORY_V21_ADDRESS } from "@fusionx/sdk-v2";
import useChainId from "hooks/useChainId";
import { useContractRead } from "wagmi";
import { getAddress } from "ethers/lib/utils.js";
import { useMemo } from "react";
import { BigNumber } from "ethers";

export type typePreset = {
  baseFactor: BigNumber;
  decayPeriod: BigNumber;
  filterPeriod: BigNumber;
  isOpen: boolean;
  maxVolatilityAccumulator: BigNumber;
  protocolShare: BigNumber;
  reductionFactor: BigNumber;
  variableFeeControl: BigNumber;
};

const useLBFactoryData = () => {
  const chainId = useChainId();
  const quoteAssetsAddressList: string[] = [];
  const presetListByBinStep: Record<number, typePreset> = {};

  const {
    data: totalQuoteAssetsCount,
    isLoading: isLoadingTotalQuoteAssetsCount,
  } = useContractRead({
    abi: LBFactoryV21ABI,
    address: getAddress(LB_FACTORY_V21_ADDRESS[chainId]),
    chainId,
    functionName: "getNumberOfQuoteAssets",
  });

  if (!isLoadingTotalQuoteAssetsCount && totalQuoteAssetsCount && Number(totalQuoteAssetsCount) > 0) {
    for (let index = 0; index < Number(totalQuoteAssetsCount); index++) {
      const { data: quoteAssetsInfo, isLoading: isLoadingQuoteAssetsInfo } =
        useContractRead({
          abi: LBFactoryV21ABI,
          args: [BigNumber.from(index)],
          address: getAddress(LB_FACTORY_V21_ADDRESS[chainId]),
          chainId,
          functionName: "getQuoteAssetAtIndex",
        });
      console.log(
        "getQuoteAssetAtIndex",
        quoteAssetsInfo,
        isLoadingQuoteAssetsInfo
      );
      if (quoteAssetsInfo) {
        quoteAssetsAddressList.push(quoteAssetsInfo as unknown as string);
      }
    }
  }

  const { data: openBinSteps, isLoading: isLoadingOpenBinSteps } =
    useContractRead({
      abi: LBFactoryV21ABI,
      address: getAddress(LB_FACTORY_V21_ADDRESS[chainId]),
      chainId,
      functionName: "getOpenBinSteps",
    });

  if (!isLoadingOpenBinSteps && openBinSteps) {
    for (
      let index = 0;
      index < (openBinSteps as Array<number>)?.length;
      index++
    ) {
      const { data: preset, isLoading: isLoadingPreset } = useContractRead({
        abi: LBFactoryV21ABI,
        address: getAddress(LB_FACTORY_V21_ADDRESS[chainId]),
        args: [(openBinSteps as Array<number>)[index]],
        chainId,
        functionName: "getPreset",
      });
      console.log("getPreset", preset);
      if (preset) {
        presetListByBinStep[(openBinSteps as Array<number>)[index]] =
          preset as unknown as typePreset;
      }
    }
  }

  console.log("binStep & preset Data ", openBinSteps, presetListByBinStep);

  return {
    totalQuoteAssetsCount,
    quoteAssetsAddressList,
    isLoading:
      isLoadingTotalQuoteAssetsCount ||
      quoteAssetsAddressList.length !== Number(totalQuoteAssetsCount),
    openBinSteps: openBinSteps ?? [],
    presetListByBinStep,
  };
};

export default useLBFactoryData;
