import {
  extendTheme,
  SystemStyleObject,
  ThemeComponents
} from '@chakra-ui/react'

import { buttonTheme } from './button'
import { fontStyle } from './fonts'
import { inputTheme } from './input'
import { menuTheme } from './menu'
import { modalTheme } from './modal'
import { popoverTheme } from './popover'
import { slidersTheme } from './slider'
import { statTheme } from './stat'
import { tableTheme } from './table'
import { tabsTheme } from './tabs'

const styles: { global: { [key: string]: SystemStyleObject } } = {
  global: {
    '#chakra-toast-manager-top-right': {
      top: 'calc(env(safe-area-inset-top, 0px) + 66px) !important'
    },
    body: {
      bg: 'bgPrimary',
      transitionDuration: 'fast !important', // 150ms
      transitionProperty: 'background !important',
      transitionTimingFunction: 'ease-in-out !important'
    }
  }
}

const colors = {
  accent: {
    100: '#e9ebfd',
    200: '#e9ebfd',
    300: '#e9ebfd',
    400: '#a7adf8',
    500: '#7e87f7',
    600: '#6670ea',
    700: '#505ad8',
    800: '#505ad8',
    900: '#505ad8'
  },
  avaxRed: '#e84142',
  borderDark: '#2d2d3a',
  borderLight: '#ebebf5',
  candleGreen: '#54c273',
  candleRed: '#f7746d',
  green: {
    100: '#d5f5c9',
    200: '#93ee73',
    300: '#6fd24c',
    400: '#5eb53f',
    500: '#54903e'
  },
  joeDark: {
    300: '#9295bf',
    400: '#474a66',
    500: '#2f3146',
    600: '#1f202e',
    700: '#181823'
  },
  joeLight: {
    300: '#fbfbff',
    400: '#f0f0ff',
    500: '#ececfe',
    600: '#d5d5fb',
    700: '#9595b2'
  },
  textSecondaryDark: '#7f7f9a',
  textSecondaryLight: '#888d9b',
  wavaxBlue: '#7e84ff'
}

const semanticTokens = {
  colors: {
    bgCard: {
      _dark: 'joeDark.500',
      _light: 'joeLight.300'
    },
    bgPrimary: {
      _dark: 'joeDark.700',
      _light: 'joeLight.400'
    },
    bgSecondary: {
      _dark: 'joeDark.600',
      _light: 'white'
    },
    bgTertiary: {
      _dark: 'joeDark.500',
      _light: 'joeLight.500'
    },
    border: {
      _dark: 'borderDark',
      _light: 'borderLight'
    },
    graphGreenDark: '#1ca07a',
    graphGreenLight: '#b3e3d5',
    graphGreenOutline: '#5ec5a2',
    graphPurpleDark: '#793cee',
    graphPurpleLight: '#d9c7fc',
    graphPurpleOutline: '#894bf6',
    hover: {
      _dark: 'borderDark',
      _light: 'joeLight.400'
    },
    textPrimary: {
      _dark: 'white',
      _light: '#242424'
    },
    textSecondary: {
      _dark: 'textSecondaryDark',
      _light: 'textSecondaryLight'
    },
    textTertiary: {
      _dark: '#565a69',
      _light: '#aeafc2'
    }
  }
}

const components: ThemeComponents = {
  Button: buttonTheme,
  Input: inputTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  Popover: popoverTheme,
  Slider: slidersTheme,
  Stat: statTheme,
  Table: tableTheme,
  Tabs: tabsTheme,
  Text: {
    baseStyle: {
      color: 'textPrimary'
    }
  }
}

const fonts = {
  body: fontStyle,
  heading: fontStyle,
  html: fontStyle
}

const shadows = {
  joeDark: '0px 8px 16px -8px rgba(0, 0, 0, 0.16)',
  joeLight: '0px 8px 24px -8px rgba(175, 178, 237, 0.24)'
}

export const chakraTheme = extendTheme({
  colors,
  components,
  fonts,
  semanticTokens,
  shadows,
  styles
})
