import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { BigNumber, ethers } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import { useMemo, useState } from 'react'
import { capturePrepareContractWriteError } from 'utils/logger'
import {
  erc20ABI,
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

import useChainId from './useChainId'
import useTransactionToast from './useTransactionToast'

export type TokenApprovalType = 'one_time' | 'unlimited'

interface UseApproveSpenderIfNeededProps {
  spender: string
  amount?: BigNumber
  token?: string
  tokenSymbol?: string
}

const useApproveSpenderIfNeeded = ({
  amount: transactionAmount,
  spender,
  token,
  tokenSymbol
}: UseApproveSpenderIfNeededProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const [approvalType, setApprovalType] =
    useState<TokenApprovalType>('one_time')
  const amount = useMemo(
    () =>
      approvalType === 'unlimited'
        ? ethers.constants.MaxUint256
        : transactionAmount,
    [approvalType, transactionAmount]
  )

  const { data: allowance, refetch: refetchAllowance } = useContractRead({
    abi: erc20ABI,
    address: token ? getAddress(token) : undefined,
    args: account ? [account, getAddress(spender)] : undefined,
    cacheTime: 0,
    chainId,
    enabled: !!account && !!amount,
    functionName: 'allowance'
  })

  const isInitiallyApproved = useMemo(
    () => (allowance && amount ? allowance.gte(amount) : undefined),
    [allowance, amount]
  )

  const { config } = usePrepareContractWrite({
    abi: erc20ABI,
    address: token ? getAddress(token) : undefined,
    args: amount ? [getAddress(spender), amount] : undefined,
    cacheTime: 0,
    chainId,
    enabled:
      !isInitiallyApproved &&
      !!amount &&
      !!allowance &&
      walletChainId === chainId,
    functionName: 'approve',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    onSuccess: () => {
      reset()
    }
  })

  const { data, isLoading, reset, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description = `Approve ${tokenSymbol}`
      addRecentTransaction({
        description,
        hash: data.hash
      })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const {
    data: receipt,
    isLoading: isWaitingForTransaction,
    isSuccess
  } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: () => refetchAllowance()
  })

  return {
    approvalType,
    approve: write,
    isApproved: isInitiallyApproved || receipt?.status === 1,
    isApproving: isLoading || isWaitingForTransaction,
    isSuccess,
    refetchAllowance,
    reset,
    setApprovalType
  }
}

export default useApproveSpenderIfNeeded
