import { ChevronDownIcon } from '@chakra-ui/icons'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Hide, IconButton } from '@chakra-ui/react'
import { ConnectButton as RainbowKitConnectButton } from '@rainbow-me/rainbowkit'
import AppChainSelector from 'components/AppChainSelector'
import { supportedChains } from 'constants/chains'
import useAutoConnect from 'hooks/useAutoConnect'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import React from 'react'
import { useSelectedChainId } from 'state/settings/hooks'
import { WalletIcon } from 'theme/icons'
import { useSwitchNetwork } from 'wagmi'

interface ConnectButtonProps {
  chainSelector?: boolean
}

const ConnectButton = ({ chainSelector }: ConnectButtonProps) => {
  const { switchNetwork } = useSwitchNetwork()
  const { selectedChainId } = useSelectedChainId()

  const selectedChain = supportedChains.filter(
    (chain) => chain.id === selectedChainId
  )[0]
  const chainInUrlParam = useChainFromUrlParam()
  const targetChain = chainInUrlParam ?? selectedChain

  useAutoConnect()

  return (
    <RainbowKitConnectButton.Custom>
      {({ account, chain, mounted, openAccountModal, openConnectModal }) => {
        const ready = mounted
        const connected = ready && account && chain

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none'
              }
            })}
          >
            {(() => {
              return (
                <Flex alignItems="center" justifyContent="center" gap={4}>
                  <Hide below="lg">
                    <AppChainSelector hideNameOnMobile />
                  </Hide>
                  {chainSelector ? null : !connected ? (
                    <>
                      <Hide below="lg">
                        <Button
                          variant="secondary"
                          leftIcon={<WalletIcon w="20px" />}
                          onClick={openConnectModal}
                        >
                          <Flex alignItems="center">
                            Connect Wallet
                            <Box
                              boxSize={2}
                              position="relative"
                              bottom={2}
                              left={2}
                              borderRadius="full"
                              bgColor="avaxRed"
                            />
                          </Flex>
                        </Button>
                      </Hide>
                      <Hide above="lg">
                        <IconButton
                          aria-label="connect-wallet"
                          variant="secondary"
                          w="full"
                          onClick={openConnectModal}
                        >
                          <Flex alignItems="center">
                            <WalletIcon w="21px" h="18px" />
                            <Box
                              boxSize={2}
                              position="relative"
                              bottom={2}
                              borderRadius="full"
                              bgColor="avaxRed"
                            />
                          </Flex>
                        </IconButton>
                      </Hide>
                    </>
                  ) : chain.id !== targetChain.id ? (
                    <>
                      <Hide below="md">
                        <Button
                          data-cy="wrong-network-button"
                          colorScheme="red"
                          rightIcon={<ChevronDownIcon />}
                          onClick={() => switchNetwork?.(targetChain.id)}
                        >
                          Wrong network
                        </Button>
                      </Hide>
                      <Hide above="md">
                        <Button
                          data-cy="wrong-network-button"
                          colorScheme="red"
                          w="full"
                          onClick={() => switchNetwork?.(targetChain.id)}
                        >
                          <WarningTwoIcon />
                        </Button>
                      </Hide>
                    </>
                  ) : (
                    <>
                      <Hide below="lg">
                        <Button
                          variant="secondary"
                          rightIcon={<ChevronDownIcon />}
                          onClick={openAccountModal}
                        >
                          <Flex alignItems="center">
                            {account.displayName}
                            <Box
                              boxSize={2}
                              borderRadius="full"
                              bgColor="green.300"
                              position="relative"
                              bottom={2}
                              left={1}
                            />
                          </Flex>
                        </Button>
                      </Hide>
                      <Hide above="lg">
                        <Button
                          w="full"
                          variant="secondary"
                          onClick={openAccountModal}
                        >
                          <Flex alignItems="center">
                            <WalletIcon
                              fill={connected ? 'textPrimary' : 'textTertiary'}
                              w="21px"
                              h="18px"
                            />
                            <Box
                              boxSize={2}
                              position="relative"
                              bottom={2}
                              borderRadius="full"
                              bgColor="green.300"
                            />
                          </Flex>
                        </Button>
                      </Hide>
                    </>
                  )}
                </Flex>
              )
            })()}
          </div>
        )
      }}
    </RainbowKitConnectButton.Custom>
  )
}

export default ConnectButton
