import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency } from '@fusionx/sdk'
import { LB_ROUTER_ADDRESS, LBRouterABI } from '@fusionx/sdk-v2'
import { BigNumber } from 'ethers'
import { formatUnits, getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { capturePrepareContractWriteError } from 'utils/logger'
import { getCurrencyAddress } from 'utils/wrappedCurrency'
import {
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'
import { LB_ROUTER_V21_ADDRESS } from '@fusionx/sdk-v2'

export enum RemoveLiquidityV2Option {
  BOTH_TOKENS,
  TOKEN_A,
  TOKEN_B
}

type RemoveLiquidityArgs = [
  `0x${string}`,
  `0x${string}`,
  number,
  BigNumber,
  BigNumber,
  readonly BigNumber[],
  readonly BigNumber[],
  `0x${string}`,
  BigNumber
]

type RemoveLiquidityAVAXArgs = [
  `0x${string}`,
  number,
  BigNumber,
  BigNumber,
  readonly BigNumber[],
  readonly BigNumber[],
  `0x${string}`,
  BigNumber
]

interface UseRemoveLiquidityV2Props {
  currency0: Currency
  currency1: Currency
  onSuccess: () => void
  amount0Min?: string
  amount1Min?: string
  binStep?: string
  enabled?: boolean
  liquidityToRemove?: string[]
  userPositionIds?: number[]
}

const useRemoveLiquidityV2 = ({
  amount0Min,
  amount1Min,
  binStep,
  currency0,
  currency1,
  enabled,
  liquidityToRemove,
  onSuccess,
  userPositionIds
}: UseRemoveLiquidityV2Props) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const { address: account } = useAccount()
  const transactionDeadline = useTransactionDeadline()

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const args = useMemo(():
    | RemoveLiquidityArgs
    | RemoveLiquidityAVAXArgs
    | undefined => {
    const address0 = getCurrencyAddress(currency0)
    const address1 = getCurrencyAddress(currency1)
    if (
      !account ||
      !transactionDeadline ||
      !binStep ||
      !amount0Min ||
      !amount1Min ||
      (amount0Min === '0' && amount1Min === '0') ||
      !userPositionIds ||
      !liquidityToRemove
    ) {
      return undefined
    }
    const amount0MinBN = BigNumber.from(amount0Min)
    const amount1MinBN = BigNumber.from(amount1Min)
    const userPositionIdsBN = userPositionIds.map((id) => BigNumber.from(id))
    const liquidityToRemoveBN = liquidityToRemove.map((l) => BigNumber.from(l))
    if ((currency0.isNative && address1) || (currency1.isNative && address0)) {
      return [
        currency1.isNative ? address0! : address1!,
        Number(binStep),
        currency1.isNative ? amount0MinBN : amount1MinBN,
        currency1.isNative ? amount1MinBN : amount0MinBN,
        userPositionIdsBN,
        liquidityToRemoveBN,
        account,
        transactionDeadline
      ]
    }
    if (!address0 || !address1) return undefined
    return [
      address0,
      address1,
      Number(binStep),
      amount0MinBN,
      amount1MinBN,
      userPositionIdsBN,
      liquidityToRemoveBN,
      account,
      transactionDeadline
    ]
  }, [
    currency0,
    currency1,
    binStep,
    amount0Min,
    amount1Min,
    liquidityToRemove,
    userPositionIds,
    account,
    transactionDeadline
  ])

  const {
    config,
    error: prepareConfigError,
    isLoading: isPreparingConfig
  } = usePrepareContractWrite({
    abi: LBRouterABI,
    address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
    args,
    cacheTime: 0,
    enabled: !!args && enabled === true && walletChainId === chainId,
    functionName:
      currency0.isNative || currency1.isNative
        ? 'removeLiquidityNATIVE'
        : 'removeLiquidity',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  const { data, isLoading, reset, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description = `Remove ${formattedNum(
        formatUnits(amount0Min ?? '0', currency0.decimals)
      )} ${currency0.symbol} and ${formattedNum(
        formatUnits(amount1Min ?? '0', currency1.decimals)
      )} ${currency1.symbol} from pool`
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    isPreparingConfig,
    isRemovingLiquidity: isLoading || isWaitingForTransaction,
    isSuccess: receipt?.status === 1,
    prepareConfigError,
    removeLiquidity: write,
    resetRemoveLiquidity: reset
  }
}

export default useRemoveLiquidityV2
