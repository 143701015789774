import { useQuery } from '@tanstack/react-query'
import { TokenInfo } from 'types/tokensList'

const useFetchTokensList = () => {
  const appTokenListUrl =
    'https://raw.githubusercontent.com/FusionX-Finance/tokens/main/lists/extended-tokens.json'

  return useQuery<{ tokens: TokenInfo[] }, Error>(
    ['TokensListV2Query'],
    () => fetch(appTokenListUrl).then((res) => res.json()),
    { cacheTime: 10 * 60 * 1000 } // 10 mins
  )
}

export default useFetchTokensList
