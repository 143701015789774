import { TradeV2 } from '@fusionx/sdk-v2'
import useChainId from 'hooks/useChainId'

import useFetchTokensList from '../tokensList/useFetchTokensList'

interface IsTrustedTradeOutput {
  inputTrusted: boolean
  outputTrusted: boolean
}

const useIsTrustedTrade = (trade: TradeV2): IsTrustedTradeOutput => {
  const chainId = useChainId()
  const { data, isLoading } = useFetchTokensList()

  // If loading, assume trusted so trusted tokens (common case) don't get
  // momentarily mis-flagged as untrusted while the token list loads
  if (isLoading) {
    return { inputTrusted: true, outputTrusted: true }
  }

  const tokensList =
    data?.tokens
      .filter((token) => token.chainId === chainId && token.address != null)
      .map((token) => token.address?.toLowerCase())
      .filter(Boolean) || []

  const inputTrusted =
    trade.isNativeIn ||
    tokensList.includes(trade.inputAmount.token.address.toLowerCase())
  const outputTrusted =
    trade.isNativeOut ||
    tokensList.includes(trade.outputAmount.token.address.toLowerCase())

  return { inputTrusted, outputTrusted }
}

export default useIsTrustedTrade
