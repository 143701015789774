import { ChainId, Currency } from '@fusionx/sdk'
import { getUnixTime, startOfHour, subHours, subWeeks } from 'date-fns'
import { CandlePeriod, CandlePeriodTab, NumberCandle } from 'types/analytics'

import { WNATIVE_ONLY } from './swap'
import { getCurrencyAddress } from './wrappedCurrency'
import { twentyFourHours, ninetyDays, oneHundredEightyDays, sevenDays, thirtyDays } from './date'
import { useMemo } from 'react'

// string type guard
export const isString = (
  value:
    | null
    | string
    | boolean
    | number
    | (string | boolean | number)[]
    | undefined
): value is string => {
  return typeof value === 'string'
}

// number type guard
export const isNumber = (
  value:
    | null
    | string
    | boolean
    | number
    | (string | boolean | number)[]
    | undefined
): value is number => {
  return typeof value === 'number'
}

export const formatChartPrice = (price: number) => {
  return price < 0
    ? 0
    : price < 0.001
      ? price.toFixed(10)
      : price >= 0.001 && price < 0.01
        ? price.toFixed(8)
        : price >= 0.01 && price < 1
          ? price.toFixed(6)
          : price.toFixed(3)
}

export const formatTimeIncrement = (increment: number): string => {
  return increment < 10 ? `0${increment}` : increment.toString()
}

export function getAddress(
  chainId: ChainId,
  token: Currency | undefined
): string | undefined {
  if (!token) {
    return undefined
  }

  return getCurrencyAddress(
    token.isNative ? WNATIVE_ONLY[chainId][0] : token
  )?.toLowerCase()
}

export function getStartTime(period: CandlePeriod): number {
  switch (period) {
    case CandlePeriod.FiveMinutes:
      return getUnixTime(startOfHour(subHours(Date.now(), 12)))
    case CandlePeriod.FifteenMinutes:
      return getUnixTime(startOfHour(subHours(Date.now(), 36)))
    case CandlePeriod.OneHour:
      return getUnixTime(startOfHour(subWeeks(Date.now(), 2)))
    case CandlePeriod.FourHours:
      return getUnixTime(startOfHour(subWeeks(Date.now(), 4)))
    case CandlePeriod.OneDay:
      return getUnixTime(startOfHour(subWeeks(Date.now(), 12)))
    case CandlePeriod.OneWeek:
      return getUnixTime(startOfHour(subWeeks(Date.now(), 26)))
    default:
      return 0
  }
}

export function candleTabToPeriod(tab: CandlePeriodTab): CandlePeriod {
  switch (tab) {
    case CandlePeriodTab.FiveMinutes:
      return CandlePeriod.FiveMinutes
    case CandlePeriodTab.FifteenMinutes:
      return CandlePeriod.FifteenMinutes
    case CandlePeriodTab.OneHour:
      return CandlePeriod.OneHour
    case CandlePeriodTab.FourHours:
      return CandlePeriod.FourHours
    case CandlePeriodTab.OneDay:
      return CandlePeriod.OneDay
    case CandlePeriodTab.OneWeek:
      return CandlePeriod.OneWeek
  }
}

/**
 * This function fills any whitespace for candlestick series data.
 */
export function fillCandlestickGaps(
  candleData: NumberCandle[],
  candlePeriod: CandlePeriod
) {
  const formattedCandleData: NumberCandle[] =
    candleData.length > 0 ? [candleData[0]] : []
  if (formattedCandleData.length === 0) return formattedCandleData
  for (let i = 1; i < candleData.length; i++) {
    const cur = candleData[i]
    const prev = candleData[i - 1]
    const timeGap = cur.time - prev.time
    if (timeGap === candlePeriod) {
      formattedCandleData.push(cur)
      continue
    }
    for (let j = 1; j < timeGap / candlePeriod; j++) {
      const emptyCandle = {
        close: prev.close,
        high: prev.close,
        low: prev.close,
        open: prev.close,
        time: prev.time + j * candlePeriod
      }
      formattedCandleData.push(emptyCandle)
    }
    formattedCandleData.push(cur)
  }

  // We fill remaining gaps until the current time
  const timestampNow = Math.floor(Number(new Date()) / 1000)
  const timestampOfNextCandle =
    timestampNow - (timestampNow % candlePeriod) + candlePeriod
  const prev = formattedCandleData[formattedCandleData.length - 1]
  const timeGap = timestampOfNextCandle - prev.time
  for (let j = 1; j <= timeGap / candlePeriod; j++) {
    const emptyCandle = {
      close: prev.close,
      high: prev.close,
      low: prev.close,
      open: prev.close,
      time: prev.time + j * candlePeriod
    }
    formattedCandleData.push(emptyCandle)
  }
  return formattedCandleData
}


export enum ChartRange {
  H24 = '24H',
  D7 = '7D',
  D30 = '30D',
  D90 = '90D',
  D180 = '180D'
}


export function getTimestempByChartRange(chartRange: ChartRange): number {
  return useMemo(() => {
    switch (chartRange) {
      case ChartRange.H24:
        return twentyFourHours()
      case ChartRange.D7:
        return sevenDays()
      case ChartRange.D30:
        return thirtyDays()
      case ChartRange.D90:
        return ninetyDays()
      case ChartRange.D180:
        return oneHundredEightyDays()
      default: return oneHundredEightyDays()
    }
  }, [chartRange])
}