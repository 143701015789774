import { ethers } from 'ethers'
import { UserLBPosition } from 'types/dexbarn'
import { UserPoolPosition } from 'types/poolV1'
import { UserVaultPosition } from 'types/vault'

interface UseUserPositionSearchProps {
  lbPositions: UserLBPosition[]
  poolV1Positions: UserPoolPosition[]
  query: string
  vaultPositions: UserVaultPosition[]
}

const useUserPositionSearch = ({
  lbPositions,
  poolV1Positions,
  query,
  vaultPositions
}: UseUserPositionSearchProps) => {
  const lowercasedQuery = query.toLowerCase()
  if (ethers.utils.isAddress(query)) {
    return {
      lbPositions: lbPositions.filter(
        (position) =>
          position.tokenX.address.toLowerCase().startsWith(lowercasedQuery) ||
          position.tokenY.address.toLowerCase().startsWith(lowercasedQuery) ||
          position.poolAddress.toLowerCase().startsWith(lowercasedQuery)
      ),
      poolV1Positions: poolV1Positions.filter(
        (position) =>
          position.token0.id?.toLowerCase().startsWith(lowercasedQuery) ||
          position.token1.id?.toLowerCase().startsWith(lowercasedQuery) ||
          position.id.toLowerCase().startsWith(lowercasedQuery)
      ),
      vaultPositions: vaultPositions.filter(
        (position) =>
          position.vault.tokenX.address
            ?.toLowerCase()
            .startsWith(lowercasedQuery) ||
          position.vault.tokenY.address
            ?.toLowerCase()
            .startsWith(lowercasedQuery) ||
          position.vault.id.toLowerCase().startsWith(lowercasedQuery)
      )
    }
  }
  return {
    lbPositions: lbPositions.filter(
      (position) =>
        position.pairName.toLowerCase().includes(lowercasedQuery) ||
        position.tokenX.symbol.toLowerCase().includes(lowercasedQuery) ||
        position.tokenY.symbol.toLowerCase().includes(lowercasedQuery)
    ),
    poolV1Positions: poolV1Positions.filter(
      (position) =>
        position.name.toLowerCase().startsWith(lowercasedQuery) ||
        position.token0.symbol.toLowerCase().startsWith(lowercasedQuery) ||
        position.token1.symbol.toLowerCase().startsWith(lowercasedQuery)
    ),
    vaultPositions: vaultPositions.filter(
      (position) =>
        position.vault.tokenX.symbol
          .toLowerCase()
          .startsWith(lowercasedQuery) ||
        position.vault.tokenY.symbol.toLowerCase().startsWith(lowercasedQuery)
    )
  }
}

export default useUserPositionSearch
