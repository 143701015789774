import { Flex, Heading, Text } from '@chakra-ui/react'
import PageHelmet from 'components/PageHelmet'
import React from 'react'

const Privacy = () => {
  return (
    <>
      <PageHelmet
        title="FusionX | Decentralized Exchange | Privacy"
        url="privacy"
      />
      <Flex
        flexDir="column"
        gap={4}
        pt={{ base: 12, md: 32 }}
        maxW="960px"
        margin="0 auto"
        minH="calc(100vh - 80px)"
        px={{ base: 0, md: 4 }}
        pb={{ base: 8, md: 16 }}
      >
        <Heading
          textAlign="center"
          size="2xl"
          color="textPrimary"
          mb={{ base: 4, md: 8 }}
        >
          Privacy Policy
        </Heading>
        <Heading textAlign="center" as="h3">
          ... Coming Soon ...
          {/* Updated 31 July 2022 */}
        </Heading>
        {/* <Text fontSize="md">
          This Privacy Policy (the “Policy”) outlines how Joemart Ltd
          (“Joemart”, “we”, “us” “our”) collects, uses, stores and discloses
          your Personal Data (defined below) when using our Services. This
          Policy should be read together with, and forms part of, our Terms and
          Conditions of Service (the “Terms”), which are available on the Site.
          Unless otherwise defined herein, capitalised terms in this Policy have
          the meaning given to them in the Terms.
        </Text>
        <Text fontSize="md">
          Joemart recognises the importance of protecting Personal Data and we
          are committed to complying with the Singapore Personal Data Protection
          Act 2012 and other applicable data protection laws. Please read this
          Policy so that you know and understand the purposes for which we
          collect, use, store and disclose Personal Data.
        </Text>

        <Text fontSize="md">
          By accessing our Site or Services, interacting with us or providing
          your data to us, and subject to your rights at law, you accept and
          agree to these terms and conditions. If you do not agree with any
          aspect of this Policy, you should immediately discontinue access or
          use of the Site and Services.
        </Text>
        <Text fontSize="md">
          This Policy supplements but does not supersede or replace any other
          consents you may have previously provided to us in respect of your
          Personal Data, and your consents herein are additional to any rights
          which Joemart may have at law to collect, use or disclose your
          Personal Data.
        </Text>
        <Text fontSize="md">
          We may from time to time update this Policy to ensure that it is
          consistent with future developments, industry trends and/or any
          changes in legal or regulatory requirements. Subject to your rights at
          law, you agree to be bound by the prevailing terms of this Policy as
          updated from time to time. Please check back regularly for updated
          information on the handling of your Personal Data.
        </Text>
        <Text fontSize="md">
          For purposes of this Policy,{' '}
          <Text as="b">&quot;Personal Data&quot;</Text> means any information
          that directly or indirectly identifies a particular individual,
          including any other information that is subject to applicable data
          protection laws.
        </Text>
        <Heading as="h3">Scope</Heading>
        <Text fontSize="md">
          This Policy applies to our collection, use and disclosure of Personal
          Data related to the Users of our Services, including on our Site or
          Platform. This Policy does not apply to job applicants or to Joemart’s
          employees and non-employee contractors, whose Personal Data is subject
          to different privacy notices.
        </Text>
        <Heading as="h3">Collection of Personal Data</Heading>
        <Text fontSize="md">
          The Personal Data we collect varies depending upon the nature of the
          Services provided or used, and our interactions with individuals.
        </Text>
        <Text fontSize="md">
          <Text as="b">Types of Personal Data Collected.</Text> While the
          Personal Data we collect varies depending upon the circumstances and
          Services you use, we collect the following categories of information
          that may constitute Personal Data:
          <ul>
            <li>
              Online identifiers: Details of your Wallet as needed for such
              Wallet to be connected to our Services. If you submit an online
              form to us, we may require your email address, Telegram tag,
              Twitter account and/or product-specific information such as your
              website URL.
            </li>
            <li>Customer records: electronic customer records.</li>
            <li>
              Device information: internet protocol (IP) address, web browser
              type, operating system version, phone carrier and manufacturer,
              application installations, device identifiers, and mobile
              advertising identifiers.
            </li>
            <li>
              User content: if you post Content on our Site, Platform or a third
              party platform, if you interact with us, our Services or any User,
              if you submit feedback and comments to us, or if you in any other
              way provide Content in the course of using our Services (“User
              Content”), we may maintain a record of your User Content,
              including the location, date and time of submission, as well as
              any other information contained in that User Content.
            </li>
            <li>
              Transaction information: including records of JOE Token or other
              Digital Assets sold, purchased, traded or otherwise dealt with.
            </li>
            <li>
              Cookie data: as explained in more detail later in this Policy.
            </li>
            <li>
              Location data: location information about a particular individual
              or device, general location information (for example, your IP
              address may indicate your more general geographic region).
            </li>
          </ul>
        </Text>
        <Text fontSize="md">
          <Text as="b">Sources of Personal Data.</Text> We may collect Personal
          Data:
          <ul>
            <li>
              Directly from you: such as when you use our Services, make a
              transaction, trade Digital Assets, participate in promotions we
              offer, register to receive marketing and other communications from
              us, or contact us for customer support purposes. If you submit any
              Personal Data relating to other people to us, you represent that
              you have the authority to do so and have informed that other
              person about the contents of this Policy.{' '}
            </li>
            <li>
              From third parties: we may collect information about Users from
              openly available information on the internet.
            </li>
            <li>
              Related to your use of our Services: including information we
              collect automatically when you use our Services or interact with
              us or information we derive about you and your preferences or
              interests based on the Personal Data we collect and our
              interactions with you. This information may be collected via the
              cookies when you use our Services and you can find more details
              about our use of cookies later in this Policy.
            </li>
          </ul>
        </Text>
        <Heading as="h3">Use of Personal Data</Heading>
        <Text fontSize="md">
          We, and our authorised third parties, will only process your Personal
          Data where we have the legal grounds to do so. We may use Personal
          Data for a variety of purposes, as follows:
          <ul>
            <li>
              Providing support and services: to provide and operate our
              Services, to respond to your inquiries, to provide
              troubleshooting, to fulfill your requests, to process any payments
              and to provide technical support; and for other customer service
              and support purposes. Our lawful basis is to fulfil any
              contractual terms with you.
            </li>
            <li>
              Analysing and improving our business: to better understand how
              Users access and use our Services, to evaluate and improve our
              Services and business operations, and to develop new features,
              offerings, and services; to conduct surveys and other evaluations
              (such as customer satisfaction surveys); to monitor consumer
              interest in our products and Services; to troubleshoot problems
              that may arise on the Site or Platform; and for other research and
              analytical purposes. Our lawful basis is our legitimate business
              interests in understanding and improving our Services or consent
              where we use cookies or similar technology that are not strictly
              necessary.
            </li>
            <li>
              Securing and protecting our business: to protect and secure our
              business operations, assets and Services; to investigate, prevent,
              detect and take action regarding fraud, unauthorised access,
              situations involving potential threats to the rights or safety of
              any person or third-party, or other unauthorised activities or
              misconduct. Our lawful basis is our legitimate business interests
              in protecting our business and Services.
            </li>
            <li>
              Defending our legal rights: to manage and respond to actual and
              potential legal disputes and claims, and to otherwise establish,
              defend or protect our rights or interests, including in the
              context of anticipated or actual litigation with third parties.
              Our lawful basis is our legitimate business interests in
              protecting our business or our need to defend ourselves legally.
            </li>
            <li>
              Auditing, reporting, corporate governance, and internal
              operations: relating to financial, tax and accounting audits;
              audits and assessments of our operations, privacy, security and
              financial controls, risk, and compliance with legal obligations;
              our general business, accounting, record keeping and legal
              functions; and relating to any actual or contemplated merger,
              acquisition, asset sale or transfer, financing, bankruptcy or
              restructuring of all or part of our business. Our lawful basis is
              our legal obligations under relevant legislation such as tax,
              reporting and/or our legitimate interests in running our
              compliance programs.
            </li>
            <li>
              Complying with legal obligations: to comply with the law, our
              legal obligations and legal process, such as warrants, subpoenas,
              court orders, and regulatory or law enforcement requests. Our
              lawful basis is compliance with applicable law.
            </li>
            <Text as="b">
              Where we have indicated above that we rely on legitimate interests
              for processing of Personal Data, we carry out assessments of
              likely adverse effects and if required a &quot;balancing&quot;
              test to ensure that our processing is necessary and that your
              fundamental rights of privacy are not outweighed by our legitimate
              interests, before we go ahead with such processing. We may also
              carry out a data protection impact assessment for processing that
              is likely to result in a high risk to you. You can contact us for
              more details on our assessments using the details in the Contact
              Us section below.
            </Text>
          </ul>
        </Text>
        <Text fontSize="md">
          <Text as="b">Aggregate and De-identified Data.</Text> We may
          de-identify information and create anonymous and aggregated data sets
          and reports to assess, improve and develop our business, products and
          Services, prepare benchmarking reports on our industry and for other
          research and analytics purposes.
        </Text>
        <Heading as="h3">Disclosure of Personal Data</Heading>
        <Text fontSize="md">
          We may share or disclose the Personal Data we may collect as follows:
          <ul>
            <li>
              Service providers: We may disclose Personal Data to third-party
              service providers who use this information to perform services for
              us, such as hosting providers, auditors, advisors, consultants,
              customer service and/or support providers.
            </li>
            <li>
              Subsidiaries, Affiliates, and business partners: We may share your
              Personal Data with our Affiliates (as defined in our Terms and
              Conditions of Service); and other businesses we have partnered
              with to offer our Services; they may use such Personal Data for
              the purposes set out in this Policy.{' '}
            </li>
            <li>
              Legal compliance: We may be required to share Personal Data in
              response to a valid court order, subpoena, government
              investigation, or as otherwise required by law. We also reserve
              the right to report to law enforcement agencies any activities
              that we, in good faith, believe to be unlawful.
            </li>
            <li>
              Business transfers: We may disclose and/or transfer Personal Data
              as part of any actual or contemplated merger, sale, transfer of
              assets, acquisition, financing and/or restructuring of all or part
              of our business, bankruptcy or similar event, including related to
              due diligence conducted prior to such event where permitted by
              law.
            </li>
            <li>
              Protect our rights: We may disclose Personal Data where we believe
              it necessary to respond to claims asserted against us, to enforce
              or administer our agreements and terms, for fraud prevention, risk
              assessment, investigation and/or to protect the rights, property
              or safety of Joemart or our Affiliates, partners, clients, Users
              and/or others.
            </li>
          </ul>
          We ensure third parties who receive your Personal Data will handle
          your Personal Data as required by applicable data protection laws,
          such by putting in place appropriate contracts with third parties to
          protect your Personal Data.
        </Text>
        <Heading as="h3">Automated Decisions</Heading>
        <Text fontSize="md">
          Automated decisions are those made without human intervention that
          have a legal effect on you or other similarly significant effect. We
          do not carry out this type of processing activity.
        </Text>
        <Heading as="h3">Cookies and Analytics</Heading>
        <Text fontSize="md">
          Cookies are small alphanumeric identifiers or small data text files
          that are sent from a server during a browsing session and is placed on
          your computer. When we refer to “cookies” we include other
          technologies with similar purposes, such as pixels, tags and
          identifiers.
        </Text>
        <Text fontSize="md">
          We use cookies or similar technologies, which may be provided by third
          parties, on our Services to enable certain functionality and for
          security and fraud detection and prevention, as well as to collect
          usage information about our Services and the emails that we send, and
          to personalise content and provide more relevant ads and information.
          We may combine the information we collect via these technologies with
          other information, including Personal Data.
        </Text>
        <Text fontSize="md">
          You may indicate and choose your cookies preferences at any time
          through your browser settings, such as to accept certain cookies but
          reject others. Our policy relating to cookies complies with applicable
          laws. There are some internet activities that cannot take place
          without cookies collecting, using or disclosing Personal Data, such as
          those relating to security and network management. Necessary cookies
          also for example allow you to navigate back and forth between pages
          without losing your previous actions from the same session. For such
          necessary cookies, if you use our Site, you will be considered to have
          consented to the collection of Personal Data by such necessary
          cookies.
        </Text>
        <Text fontSize="md">
          We will get your express consent where our cookies collect Personal
          Data in the course of internet activities which you have not clearly
          requested for, or where you have not voluntarily provided your
          Personal Data, or where Personal Data collected from such cookies are
          used for advertisements directed at you.
        </Text>
        <Heading as="h3">Children&apos;s Privacy</Heading>
        <Text fontSize="md">
          Following the Terms, our Services are restricted for Users under 18
          years of age and they are not allowed to use our Services. Joemart
          does not knowingly collect or maintain Personal Data from children we
          actually know at the time of collection are under the age of 18.
          Should we discover that we have collected Personal Data online from a
          child who is under 18, we will promptly delete that Personal Data. If
          you have concerns over the collection of children&apos;s Personal Data
          in our Services, please contact us at the information provided in the
          Contact Us section below.
        </Text>
        <Heading as="h3">Security</Heading>
        <Text fontSize="md">
          The security of your Personal Data is important to us. We have put in
          place safeguards to protect the Personal Data we collect from
          unauthorised access, use and disclosure, and we take steps to ensure
          that all of our employees, agents, contractors and other third parties
          have similar adequate measures in place. We also have procedures to
          deal with any suspected Personal Data breach, and we will notify you
          and any applicable regulator when we are legally required to do so.
          However, we cannot guarantee that unauthorised access, hacking, data
          loss, or other breaches will never occur. We urge you to take steps to
          keep your Personal Data safe, such as choosing a strong password and
          closing your web browser when you have finished using the Services.
        </Text>
        <Heading as="h3">Transfers Outside your Home Country</Heading>
        <Text fontSize="md">
          We may transfer your Personal Data to countries outside of your home
          country. The privacy laws in countries outside your home country may
          be different from those in your home country.
        </Text>
        <Text fontSize="md">
          <Text as="b">For Users in Singapore.</Text> Where we transfer data to
          a country that has not been deemed to provide adequate data protection
          standards, we will always have security measures, approved Singapore
          model clauses (available on the Personal Data Protection Commission of
          Singapore website at www.pdpc.gov.sg/) or other adequate safeguards in
          place to protect your Personal Data.
        </Text>
        <Text fontSize="md">
          <Text as="b">For other Users.</Text> If we transfer your Personal Data
          to countries outside of your home country, we will take steps to
          comply with the requirements for such transfer in your home country as
          required by relevant law.
        </Text>
        <Heading as="h3">Links to Third-party Websites</Heading>
        <Text fontSize="md">
          Our Services provide links to various websites operated by third
          parties including, but not limited to, third-party sites that display
          FusionX trademarks. This Policy does not apply to third-party
          websites that are accessible through the Services, unless such website
          directs Users or visitors to this Policy. When you click on one of
          these links, you will be transferred out of the Services and connected
          to the website of the organisation or company that maintains that
          website. Even if an affiliation exists between our Services and a
          third-party website, we exercise no control over and assume no
          responsibility for linked websites. Each of these linked websites
          maintains its own independent privacy and data collection policies and
          procedures. We encourage you to read the privacy policies of those
          other websites to learn how they collect, use, share, and secure your
          information before providing any Personal Data.
        </Text>
        <Heading as="h3">Retention</Heading>
        <Text fontSize="md">
          Except to the extent prohibited by law, and subject to this Policy, we
          will retain and use your Personal Data for as long as it is needed to
          provide you with any Services, communications, information you have
          requested, or access to the Services, to document our business
          relationship with you, and as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements. As soon as
          it is reasonable to assume your Personal Data is no longer needed or
          required, we will cease to retain your Personal Data.
        </Text>
        <Heading as="h3">Your Rights</Heading>
        <Text fontSize="md">
          <Text as="b">
            By law, you have a number of rights when it comes to your Personal
            Data. Further information and advice about your rights can be
            obtained from the data protection regulator in your country. The
            rights you have depend on the jurisdiction in which you are based.
          </Text>
        </Text>
        <Text fontSize="md">
          You may request for a copy of the Personal Data which we hold about
          you or information about the ways we use or disclose about your
          Personal Data. You may also ask us to correct or update your Personal
          Data, or withdraw your consent and request us to stop using or
          disclosing your Personal Data for any of the purposes stated in this
          Policy. You may do so by submitting your request in writing or via
          email at the contact details provided in the Contact Us section below.
        </Text>
        <Text fontSize="md">
          We usually act on requests and provide information free of charge, but
          we may charge a reasonable fee to cover our administrative costs of
          providing the information for baseless or excessive/repeated requests,
          or further copies of the same information. Alternatively, we may be
          entitled to refuse to act on the request in some circumstances.
        </Text>
        <Text fontSize="md">
          We will respond as soon as we can. Generally, this will be within one
          month from when we receive your request but, if the request is going
          to take longer to deal with, we will come back to you and let you
          know.
        </Text>
        <Text fontSize="md">
          Under Singapore regulations, your rights are:
          <ul>
            <li>
              The right to be informed. You have the right to be provided with
              clear, transparent and easily understandable information about how
              we use your Personal Data and your rights. This is why we are
              providing you with the information in this Policy. If you have any
              additional questions, for example regarding transfers and
              locations of data or our legitimate interests basis, please
              contact us.
            </li>
            <li>
              The right of access. You have the right to obtain access to your
              Personal Data (if we are processing it), and certain other
              information (similar to that provided in this Policy) save for any
              applicable exemptions to do so under the data protection law. This
              is so you are aware and can check that we are using your Personal
              Data in accordance with data protection law.
            </li>
            <li>
              The right to rectification. You are entitled to have your
              information corrected if it is inaccurate or incomplete.
            </li>
            <li>
              The right to restrict processing. You have rights to
              &quot;block&quot; or suppress further use of your Personal Data.
              We keep lists of people who have asked for further use of their
              information to be &quot;blocked&quot; to make sure the restriction
              is respected in future.
            </li>
            <li>
              The right to data portability. You have rights to have your
              Personal Data transferred to another organisation under certain
              conditions.
            </li>
            <li>
              The right to object to processing. You have the right to object to
              certain types of processing, including processing for direct
              marketing.
            </li>
            <li>
              The right to lodge a complaint. You have the right to lodge a
              complaint about the way we handle or process your Personal Data
              with your national data protection regulator.
            </li>
            <li>
              The right to withdraw consent. If you have given your consent to
              anything we do with your Personal Data, you have the right to
              withdraw your consent at any time (although if you do so, it does
              not mean that anything we have done with your Personal Data with
              your consent up to that point is unlawful). This includes your
              right to withdraw consent to us using your Personal Data for
              marketing purposes.
            </li>
          </ul>
        </Text>
        <Heading as="h3">Changes to this Policy</Heading>
        <Text fontSize="md">
          This Policy is kept under regular review and may be updated from time
          to time. When we make changes to this Policy, we will change the
          &quot;Updated&quot; date above. If a material change is made to this
          Policy, we may choose to provide notice of such change, such as by
          providing notice through the Site or Platform, or through other
          communications.
        </Text>
        <Heading as="h3">Changes to your Information</Heading>
        <Text fontSize="md">
          It is important that the information we hold about you is accurate and
          current. Please keep us informed as soon as possible if your Personal
          Data changes or is inaccurate during your relationship with us by
          using the contact details set out below.
        </Text>
        <Heading as="h3">Contact Us</Heading>
        <Text fontSize="md">
          If you have any concern about our privacy practices, please contact us
          at privacy@joemart.com.
        </Text> */}
      </Flex>
    </>
  )
}

export default Privacy
