import {
  Center,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import { Currency, Pair } from '@fusionx/sdk'
import { UsePairUserBalanceData } from 'hooks/pool/v1/usePairUserBalance'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { Pool } from 'types/pool'

import AddLiquidityPanel from './AddLiquidityPanel'
import RemoveLiquidityPanel from './RemoveLiquidityPanel'

interface AddRemoveLiquidityPanelProps {
  onAddLiquiditySuccess: () => void
  onRemoveLiquiditySuccess: () => void
  onToken0Select: (currency: Currency) => void
  onToken1Select: (currency: Currency) => void
  pair: Pair
  pool: Pool
  token0: Currency
  token1: Currency
  userBalanceData: UsePairUserBalanceData
}

const AddRemoveLiquidityPanel = (props: AddRemoveLiquidityPanelProps) => {
  const amountInput0 = useCurrencyInputAmount({ currency: props.token0 })
  const amountInput1 = useCurrencyInputAmount({ currency: props.token1 })
  return (
    <Center>
      <Tabs isFitted variant="soft-rounded" w="full">
        <TabList>
          <Tab
            data-cy="add-liquidity-tab"
            _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
          >
            Add Liquidity
          </Tab>
          <Tab
            data-cy="remove-liquidity-tab"
            _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}
          >
            Remove Liquidity
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <AddLiquidityPanel
              amountInput0={amountInput0}
              amountInput1={amountInput1}
              {...props}
            />
          </TabPanel>
          <TabPanel px={0}>
            <RemoveLiquidityPanel {...props} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Center>
  )
}

export default AddRemoveLiquidityPanel
