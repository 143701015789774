import {
  Box,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  useToken,
  VStack
} from '@chakra-ui/react'
import { CNATIVE } from '@fusionx/sdk'
import { VaultABI } from '@fusionx/sdk-v2'
import AnalyticsChart from 'components/AnalyticsChart'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import PageHelmet from 'components/PageHelmet'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import { getAddress } from 'ethers/lib/utils.js'
import useVaultAnalytics from 'hooks/analytics/useVaultAnalytics'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import useUserVaultBalances from 'hooks/vault/useUserVaultBalances'
import useVault from 'hooks/vault/useVault'
import useVaultQueuedWithdrawals from 'hooks/vault/useVaultQueuedWithdrawals'
import debounce from 'lodash.debounce'
import PoolDetailHeader from 'pages/PoolDetail/PoolDetailHeader'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { formattedNum } from 'utils/format'
import {
  getCurrencyAddress,
  isWrappedNativeCurrency
} from 'utils/wrappedCurrency'
import { useContractRead } from 'wagmi'

import VaultAddRemoveLiquidity from './VaultAddRemoveLiquidity'
import VaultInfo from './VaultInfo'
import VaultLiquidityChart from './VaultLiquidityChart'
import VaultQueuedWithdrawalPanel from './VaultQueuedWithdrawalPanel'

const VaultDetail = () => {
  const { vaultAddress } = useParams()
  const chainId = useChainId()

  const [graphPurple, graphGreen] = useToken('colors', [
    'graphPurpleDark',
    'graphGreenDark'
  ])

  const { data: vault, isLoading: isLoadingVault } = useVault({ vaultAddress })
  const { chartData, isLoading: isLoadingChartData } = useVaultAnalytics({
    vaultAddress
  })

  const nativeCurrency = CNATIVE.onChain(chainId)
  const isToken0WrappedNative = isWrappedNativeCurrency(
    vault?.tokenX.address,
    chainId
  )
  const isToken1WrappedNative = isWrappedNativeCurrency(
    vault?.tokenY.address,
    chainId
  )

  const {
    isLoading: isLoadingCurrencies,
    tokens: [currency0, currency1]
  } = useSdkCurrencies({
    addresses: [
      isToken0WrappedNative ? nativeCurrency.symbol : vault?.tokenX.address,
      isToken1WrappedNative ? nativeCurrency.symbol : vault?.tokenY.address
    ]
  })

  const { data: balance0, refetch: refetchTokenBalance0 } = useTokenBalance({
    token: getCurrencyAddress(currency0)
  })
  const { data: balance1, refetch: refetchTokenBalance1 } = useTokenBalance({
    token: getCurrencyAddress(currency1)
  })

  const { data: currentRound } = useContractRead({
    abi: VaultABI,
    address: vault ? getAddress(vault.id) : undefined,
    chainId,
    enabled: !!vault,
    functionName: 'getCurrentRound'
  })

  const { data: userVaultsBalances, refetch: refetchUserVaultPosition } =
    useUserVaultBalances({
      vaults: vault ? [vault] : []
    })
  const userVaultBalances = userVaultsBalances?.[0]

  const { data: queuedWithdrawals, refetch: refetchQueuedWithdrawals } =
    useVaultQueuedWithdrawals({
      vaultAddress: vault?.id
    })

  const debouncedRefetchBalance0 = debounce(() => refetchTokenBalance0(), 4000)
  const debouncedRefetchBalance1 = debounce(() => refetchTokenBalance1(), 4000)
  const debouncedRefetchUserVaultPosition = debounce(
    () => refetchUserVaultPosition(),
    4000
  )
  const debouncedRefetchQueuedWithdrawals = debounce(
    () => refetchQueuedWithdrawals(),
    4000
  )

  const refetchAll = useCallback(() => {
    debouncedRefetchBalance0()
    debouncedRefetchBalance1()
    debouncedRefetchUserVaultPosition()
  }, [
    debouncedRefetchBalance0,
    debouncedRefetchBalance1,
    debouncedRefetchUserVaultPosition
  ])

  if (isLoadingVault || isLoadingCurrencies || !vault) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box pt="80px" w="full">
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={window.location.pathname}
      />
      <PoolDetailHeader
        address={vaultAddress}
        token0={{
          address:
            currency0?.isToken && !isToken0WrappedNative
              ? currency0.address
              : undefined,
          symbol: isToken0WrappedNative
            ? nativeCurrency.symbol
            : currency0?.symbol
        }}
        token1={{
          address:
            currency1?.isToken && !isToken1WrappedNative
              ? currency1.address
              : undefined,
          symbol: isToken1WrappedNative
            ? nativeCurrency.symbol
            : currency1?.symbol
        }}
        hidePoolIllustration
        px={4}
        pb={{ base: 4, md: 8 }}
        h="auto"
      />
      <Box bg="bgSecondary">
        <SimpleGrid
          maxW="1200px"
          margin="0 auto"
          minH="80vh"
          alignItems="flex-start"
          rowGap={{ base: 8, md: 12 }}
          columnGap={{ base: 4, md: 12 }}
          columns={{ base: 1, lg: 2 }}
          px={{ base: 4, lg: 0 }}
          py={{ base: 4, md: 12 }}
        >
          <Flex flexDir="column" gap={{ base: 4, md: 8 }}>
            <VaultLiquidityChart
              vault={vault}
              currency0={currency0}
              currency1={currency1}
            />
            <VaultInfo vault={vault} />
            <Box p={4} border="1px" borderColor="border" borderRadius="xl">
              <AnalyticsChart
                id="tvl"
                data={chartData?.liquidity ?? []}
                subheader="TVL (Total Locked Value)"
                header={
                  <Heading size="md">
                    {formattedNum(vault.totalValueLockedUSD, true)}
                  </Heading>
                }
                fill={graphPurple}
                stroke={graphPurple}
                h={{ base: '100px', md: '250px' }}
                tooltipTitle="TVL"
                isLoading={isLoadingChartData}
              />
            </Box>
            <Box p={4} border="1px" borderColor="border" borderRadius="xl">
              <AnalyticsChart
                id="fees"
                data={chartData?.fees ?? []}
                subheader="Fees Earned (24H)"
                header={
                  <Heading size="md">
                    {formattedNum(vault.feesUsd7D, true)}
                  </Heading>
                }
                fill={graphGreen}
                stroke={graphGreen}
                h={{ base: '100px', md: '250px' }}
                tooltipTitle="Fees"
                isLoading={isLoadingChartData}
              />
            </Box>
          </Flex>
          <Flex flexDir="column" gap={{ base: 4, md: 8 }}>
            <VaultAddRemoveLiquidity
              vaultAddress={vault.id}
              currency0={currency0}
              currency1={currency1}
              balance0={balance0}
              balance1={balance1}
              onAddLiquiditySuccess={refetchAll}
              onRemoveLiquiditySuccess={() => {
                refetchAll()
                debouncedRefetchQueuedWithdrawals()
              }}
            />
            {vault.tokenX.address &&
              vault.tokenY.address &&
              userVaultBalances ? (
              <CurrencyAmountsPanel
                title="Deposit Balance"
                tokens={[vault.tokenX.address, vault.tokenY.address]}
                tokenSymbols={[currency0?.symbol, currency1?.symbol]}
                tokenAmounts={
                  userVaultBalances.fmtAmountX && userVaultBalances.fmtAmountY
                    ? [
                      Number(userVaultBalances.fmtAmountX),
                      Number(userVaultBalances.fmtAmountY)
                    ]
                    : [0, 0]
                }
              />
            ) : null}
            {queuedWithdrawals && queuedWithdrawals.length > 0 ? (
              <VStack>
                {queuedWithdrawals.map((queuedWithdrawal, i) => (
                  <VaultQueuedWithdrawalPanel
                    key={i}
                    currency0={currency0}
                    currency1={currency1}
                    currentRound={currentRound?.toNumber()}
                    queuedWithdrawal={queuedWithdrawal}
                  />
                ))}
              </VStack>
            ) : null}
          </Flex>
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default VaultDetail
