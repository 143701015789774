import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { LBPairABI } from '@fusionx/sdk-v2'
import { BigNumber } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import debounce from 'lodash.debounce'
import { useMemo } from 'react'
import { capturePrepareContractWriteError } from 'utils/logger'
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseLBPairClaimFeesProps {
  lbPairAddress?: string
  poolName?: string
  userPositions?: number[]
}

const useLBPairClaimFees = ({
  lbPairAddress,
  poolName,
  userPositions
}: UseLBPairClaimFeesProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const userBins = useMemo(
    () =>
      userPositions
        ? userPositions.map((position) => BigNumber.from(position))
        : [],
    [userPositions]
  )

  const { data: pendingFees, refetch: refetchPendingFees } = useContractRead({
    abi: LBPairABI, // TODO: update abi when methods available in LBPairV21ABI
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    args: account && userBins ? [account, userBins] : undefined,
    chainId,
    enabled: !!account,
    functionName: 'pendingFees'
  })

  const { config } = usePrepareContractWrite({
    abi: LBPairABI, // TODO: update abi when methods available in LBPairV21ABI
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    args: account && userBins ? [account, userBins] : undefined,
    cacheTime: 0,
    enabled: !!account,
    functionName: 'collectFees',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description = `Claim fees for ${poolName} pool`
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const debouncedRefetchPendingFees = debounce(() => refetchPendingFees(), 4000)
  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: (receipt) => {
      if (receipt.status === 1) debouncedRefetchPendingFees()
    }
  })

  return {
    claimFees: write,
    isLoading: isLoading || isWaitingForTransaction,
    pendingFees
  }
}

export default useLBPairClaimFees
