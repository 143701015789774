import { VStack } from '@chakra-ui/react'
import { JOE_ADDRESS, VEJOE_STAKING_ADDRESS } from '@fusionx/sdk'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CurrencyInput from 'components/CurrencyInput'
import Web3Button from 'components/Web3Button'
import { BigNumber } from 'ethers'
import { useVeJoeDeposit } from 'hooks/stake/useVeJoeStaking'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import React from 'react'
import { stakeVeJoe } from 'utils/measure'

interface JoeBalance {
  decimals: number
  formatted: string
  symbol: string
  value: BigNumber
}

interface VeJoeStakePanelProps {
  joeBalance: JoeBalance | undefined
  onSuccess: () => void
}

const VeJoeStakePanel = ({ joeBalance, onSuccess }: VeJoeStakePanelProps) => {
  // get JOE token
  const chainId = useChainId()
  const address = JOE_ADDRESS[chainId]
  const {
    tokens: [token]
  } = useSdkCurrencies({
    addresses: [address]
  })

  // get input
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: token
  })
  const isZero = Number(amount) === 0
  const isExceedingBalance =
    token && joeBalance ? Number(joeBalance.formatted) < Number(amount) : false

  // check approval
  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: amountBN,
    spender: VEJOE_STAKING_ADDRESS[chainId],
    token: address,
    tokenSymbol: token?.symbol
  })

  const { deposit, isLoading } = useVeJoeDeposit({
    amount: amountBN,
    approved: isApproved,
    onSuccess: () => {
      resetApproval()
      setAmount('')
      onSuccess()
    }
  })

  return (
    <VStack align="flex-start" spacing={4}>
      <CurrencyInput
        data-cy="vejoe-stake-input"
        currency={token}
        currencyAddress={address}
        value={amount}
        onValueChange={(value) => {
          setAmount(value)
        }}
        balance={joeBalance?.formatted}
        error={isExceedingBalance ? `Not enough ${token?.symbol}` : undefined}
      />
      {!isApproved && !isExceedingBalance && approve && token ? (
        <ApproveTokenButton
          data-cy="vejoe-stake-approve-button"
          amount={amount}
          approvalType={approvalType}
          currencySymbol={token.symbol}
          onApprovalTypeSelect={setApprovalType}
          isLoading={isApproving}
          loadingText={`Approving ${token.symbol}`}
          onClick={() => {
            approve()
          }}
        >
          {`Approve ${token.symbol}`}
        </ApproveTokenButton>
      ) : null}
      <Web3Button
        data-cy="vejoe-stake-button"
        colorScheme="accent"
        variant="primary"
        size="xl"
        w="full"
        isDisabled={isZero || isExceedingBalance || !deposit}
        isLoading={isLoading}
        loadingText="Staking Tokens"
        onClick={() => {
          deposit?.()
          stakeVeJoe()
        }}
      >
        Stake Tokens
      </Web3Button>
    </VStack>
  )
}

export default VeJoeStakePanel
