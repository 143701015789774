import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery
} from '@chakra-ui/react'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import SearchBar from 'components/SearchBar'
import useUserPositionSearch from 'hooks/pool/useUserPositionSearch'
import useUserPositionsRows from 'hooks/pool/useUserPositionsRows'
import useUserPoolV1Positions from 'hooks/pool/v1/useUserPoolV1Positions'
import useUserLBPositions from 'hooks/pool/v2/useUserLBPositions'
import useUserVaultBalances from 'hooks/vault/useUserVaultBalances'
import useUserVaultPositions from 'hooks/vault/useUserVaultPositions'
import React, { useState } from 'react'
import { useAccount } from 'wagmi'

import UserPositionRow from './UserPositionRow'
import VaultPositionRow from './VaultPositionRow'
import { isSearchBarEnabled } from 'appConstants'

interface SectionProps {
  rows: JSX.Element[]
  title: string
}

const Section = ({ rows, title }: SectionProps) => (
  <Box>
    <Heading size="sm" ml={{ base: 2, md: 6 }} mb={1}>
      {title}
    </Heading>
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Pool name</Th>
            <Th isNumeric>Pool balance</Th>
            <Th isNumeric>USD value</Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  </Box>
)

const UserPositionsTable = () => {
  const { isConnected } = useAccount()
  const { openConnectModal } = useConnectModal()

  // Workaround for Chakra issue #6927 regarding responsive sizes in InputGroup
  // https://github.com/chakra-ui/chakra-ui/issues/6927
  const [isLargerThanMd] = useMediaQuery(['(min-width: 48em)'])
  function getInputGroupSize() {
    return isLargerThanMd ? 'lg' : 'md'
  }

  const { data: lbPositions, isLoading: isLoadingLbPositions } =
    useUserLBPositions()
  const { data: poolV1Positions, isLoading: isLoadingPoolV1Positions } =
    useUserPoolV1Positions()
  const { data: vaultPositions, isLoading: isLoadingVaultPositions } =
    useUserVaultPositions()
  const { data: userVaultBalances } = useUserVaultBalances({
    vaults: vaultPositions.map((position) => position.vault)
  })

  const [query, setQuery] = useState('')
  const {
    lbPositions: filteredLbPositions,
    poolV1Positions: filteredPoolV1Positions,
    vaultPositions: filteredVaultPositions
  } = useUserPositionSearch({
    lbPositions,
    poolV1Positions,
    query,
    vaultPositions
  })

  const poolPositionsRowsProps = useUserPositionsRows({
    lbPositions: filteredLbPositions,
    positions: filteredPoolV1Positions
  })

  if (!isConnected) {
    return (
      <Center flexDir="column" gap={4} h="200px">
        <Text>Connect your wallet to see your pools.</Text>
        <Button
          variant="primary"
          colorScheme="accent"
          onClick={openConnectModal}
        >
          Connect Wallet
        </Button>
      </Center>
    )
  }

  if (
    isLoadingLbPositions ||
    isLoadingPoolV1Positions ||
    isLoadingVaultPositions
  ) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <Flex flexDir="column" gap={{ base: 8, md: 8 }} pt={{ base: 0, md: 4 }}>
   {isSearchBarEnabled &&   <Box mx={{ base: 0, md: 4 }}>
        <SearchBar
          placeholder="Search by name, symbol or address"
          value={query}
          onValueChange={setQuery}
          size={getInputGroupSize()}
        />
      </Box>}
      {filteredVaultPositions.length > 0 ? (
        <Box mb={{ base: 0, md: 4 }}>
          <Section
            title="My Auto Pools"
            rows={filteredVaultPositions.map((position, i) => (
              <VaultPositionRow
                key={`vault-${i}`}
                position={position}
                balances={userVaultBalances?.[i]}
              />
            ))}
          />
        </Box>
      ) : null}
      {poolPositionsRowsProps.length > 0 ? (
        <Section
          title="My Pools"
          rows={poolPositionsRowsProps.map((rowProps, i) => (
            <UserPositionRow key={i} {...rowProps} />
          ))}
        />
      ) : null}
      {poolPositionsRowsProps.length === 0 &&
      filteredVaultPositions.length === 0 ? (
        <Center py={8}>
          <Text size="sm" color="textSecondary">
            {`You don't have any positions.`}
          </Text>
        </Center>
      ) : null}
    </Flex>
  )
}

export default UserPositionsTable
