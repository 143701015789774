import { ROCKET_JOE_STAKING_ADDRESS } from '@fusionx/sdk'
import rJoeImage from 'assets/stake/rJoe.webp'
import { ZERO_ADDRESS } from 'constants/tokens'
import { ethers } from 'ethers'
import useRJoeStaking, { useRJoeUserPosition } from 'hooks/stake/useRJoeStaking'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { formattedNum } from 'utils/format'

import { CurrencyTab } from '.'
import { JoeUsdProps } from './SJoeCard'
import StakeCard from './StakeCard'

const RJoeCard = ({ currencyTab, joeUsd }: JoeUsdProps) => {
  const { userBalance } = useRJoeUserPosition()
  const { totalStaked } = useRJoeStaking()

  // check if not available on selected chain
  const chainId = useChainId()
  const isNotAvailable = ROCKET_JOE_STAKING_ADDRESS[chainId] === ZERO_ADDRESS

  // user balance
  const balanceNum = userBalance
    ? Number(ethers.utils.formatEther(userBalance))
    : 0
  const fmtBalance = formattedNum(balanceNum, false, 2)
  const fmtBalanceUsd = formattedNum(balanceNum * (joeUsd ?? 0), true, 2)

  // total staked
  const totalStakedNum = totalStaked
    ? Number(ethers.utils.formatEther(totalStaked))
    : 0
  const totalStakedUSD = totalStakedNum * (joeUsd ?? 0)
  const fmtTotalStaked = formattedNum(totalStakedNum, false, 2)
  const fmtTotalStakedUSD = formattedNum(totalStakedUSD, true, 2)

  return isNotAvailable ? null : (
    <StakeCard
      isExternal
      image={rJoeImage}
      linkUrl="https://v1.fusionx.finance/stake/rjoe"
      title="rJOE"
      tag="Withdraw Only"
      label1={`Total Staked (${currencyTab})`}
      text1={
        currencyTab == CurrencyTab.JOE ? fmtTotalStaked : fmtTotalStakedUSD
      }
      label2={`Your Stake (${currencyTab})`}
      text2={currencyTab == CurrencyTab.JOE ? fmtBalance : fmtBalanceUsd}
      label3="rJOE Per Day"
      text3={'N/A'}
    />
  )
}

export default RJoeCard
