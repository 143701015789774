import { useQuery } from '@tanstack/react-query'
import { ChainId, EXCHANGE_SUBGRAPH } from '@fusionx/sdk'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { poolV1ByTokens } from 'graphql/dexV1'
import { request } from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { Pair } from 'types/poolV1'
import { convertPairToPool } from 'utils/poolV1'

dayjs.extend(utc)

interface FetchPoolV1ByTokensProps {
  chainId: ChainId
  tokens?: [string, string]
}

export const fetchPoolV1ByTokens = ({
  chainId,
  tokens
}: FetchPoolV1ByTokensProps) => {
  const subgraphUrl = EXCHANGE_SUBGRAPH[chainId]
  const utcOneDayBack = dayjs().utc().startOf('hour').subtract(1, 'day').unix()
  return request<{ pairs: Pair[] }>(subgraphUrl, poolV1ByTokens(tokens), {
    dateAfter: utcOneDayBack
  })
}

interface UsePoolV1ByTokensProps {
  tokens?: [string, string]
}

const usePoolV1ByTokens = ({ tokens }: UsePoolV1ByTokensProps) => {
  const chainId = useChainId()
  const subgraphUrl = EXCHANGE_SUBGRAPH[chainId]
  const result = useQuery<{ pairs: Pair[] }>(
    ['PoolV1ByTokensQuery', subgraphUrl, tokens],
    () => fetchPoolV1ByTokens({ chainId, tokens }),
    { enabled: !!tokens }
  )
  return {
    ...result,
    data:
      result.data && result.data.pairs.length > 0
        ? convertPairToPool({ pair: result.data.pairs[0] })
        : undefined
  }
}

export default usePoolV1ByTokens
