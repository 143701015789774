import { CloseButton, Flex, Heading, Image, Link, Text } from '@chakra-ui/react'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface Props {
  description: string
  onClose: () => void
  title: string
  image?: string | null
}

const Announcement = ({
  description,
  image,
  onClose,
  title
}: Props): JSX.Element => {
  return (
    <Flex
      flexDir="row"
      alignItems="center"
      justifyContent="space-between"
      maxWidth="400px"
      bg="bgCard"
      borderColor="border"
      borderWidth={1}
      borderRadius="xl"
      p={4}
    >
      <Flex flexDir="row" alignItems="center">
        {image && (
          <Image
            src={image}
            boxSize="60px"
            objectFit="cover"
            borderRadius="lg"
          />
        )}
        <Flex flexDir="column" ml={4}>
          <Heading size="md">{title}</Heading>
          <ReactMarkdown
            components={{
              a: ({ href, ...props }) => (
                <Link
                  color="textPrimary"
                  href={href}
                  target="_blank"
                  {...props}
                />
              ),
              p: (props) => (
                <Text color="textSecondary" fontSize="sm" {...props} />
              )
            }}
          >
            {description}
          </ReactMarkdown>
        </Flex>
      </Flex>
      <CloseButton
        size="sm"
        alignSelf="baseline"
        top={4}
        right={4}
        onClick={onClose}
        mt={-3}
        mr={-3}
      />
    </Flex>
  )
}

export default Announcement
