import { JoePairABI, Pair, Token } from '@fusionx/sdk'
import { POOL_SHARE_PRECISION } from 'constants/pool'
import { BigNumber } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import { useAccount, useContractReads } from 'wagmi'

interface UsePairUserBalanceProps {
  address?: string
  pair?: Pair
}

export interface UsePairUserBalanceData {
  balance: BigNumber | undefined
  decimals: number | undefined
  pooledToken0: number
  pooledToken1: number
  poolShare: number
  token0: Token
  token1: Token
  totalSupply: BigNumber | undefined
}

const usePairUserBalance = ({
  address,
  pair
}: UsePairUserBalanceProps): {
  isLoading: boolean
  refetchUserBalance: () => void
  data?: UsePairUserBalanceData
} => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  const contract = {
    abi: JoePairABI,
    address: address ? getAddress(address) : undefined,
    chainId
  }
  const { data, isLoading, refetch } = useContractReads({
    contracts: [
      {
        ...contract,
        args: account ? [account] : undefined,
        functionName: 'balanceOf'
      },
      { ...contract, functionName: 'totalSupply' },
      { ...contract, functionName: 'decimals' }
    ],
    enabled: !!account && !!address && !!pair
  })

  const balance = data?.[0]
  const totalSupply = data?.[1]
  const decimals = data?.[2]

  const precisionBN = BigNumber.from(POOL_SHARE_PRECISION)
  const poolShare =
    totalSupply && balance && totalSupply.gt(0)
      ? balance.mul(precisionBN).div(totalSupply).toNumber() /
      POOL_SHARE_PRECISION
      : 0

  const pooledToken0 =
    pair && poolShare ? Number(pair.reserve0.toSignificant(7)) * poolShare : 0
  const pooledToken1 =
    pair && poolShare ? Number(pair.reserve1.toSignificant(7)) * poolShare : 0

  return {
    data: pair
      ? {
        balance,
        decimals,
        poolShare,
        pooledToken0,
        pooledToken1,
        token0: pair.token0,
        token1: pair.token1,
        totalSupply
      }
      : undefined,
    isLoading,
    refetchUserBalance: refetch
  }
}

export default usePairUserBalance
