import { supportedChains } from 'constants/chains'
import { useSelectedChainId } from 'state/settings/hooks'
import { Chain, useNetwork } from 'wagmi'

import useChainFromUrlParam from './useChainFromUrlParam'

interface UseActiveChainProps {
  overridesChainSlug?: string
}

const useActiveChain = ({
  overridesChainSlug
}: UseActiveChainProps = {}): Chain => {
  const chainInUrlParam = useChainFromUrlParam({ overridesChainSlug })

  const { selectedChainId } = useSelectedChainId()
  const selectedChain = supportedChains.filter(
    (chain) => chain.id === selectedChainId
  )[0]

  const { chain } = useNetwork()
  const activeChain =
    chain && !chain.unsupported ? chain : (selectedChain as Chain)

  return chainInUrlParam ?? activeChain
}

export default useActiveChain
