import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import {
  JOE_ADDRESS,
  SJOE_REWARD_TOKEN,
  STABLE_JOE_STAKING_ADDRESS,
  StableJoeStakingABI
} from '@fusionx/sdk'
import { ZERO_ADDRESS } from 'constants/tokens'
import { BigNumber, ethers } from 'ethers'
import { formatUnits, getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { capturePrepareContractWriteError } from 'utils/logger'
import {
  erc20ABI,
  useAccount,
  useContractReads,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

const useSJoeContract = () => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const { address: account } = useAccount()
  const sJoeAddress = getAddress(STABLE_JOE_STAKING_ADDRESS[chainId])
  const sJoeContract = {
    abi: StableJoeStakingABI,
    address: sJoeAddress,
    chainId: chainId
  }

  // AVAX, ARBITRUM: USDC
  // BNB: might be BUSD
  const rewardToken = ethers.utils.getAddress(SJOE_REWARD_TOKEN[chainId])

  const enabled =
    !!account &&
    !!rewardToken &&
    rewardToken != ZERO_ADDRESS &&
    walletChainId === chainId

  return { account, enabled, rewardToken, sJoeAddress, sJoeContract }
}

const useSJoeStaking = () => {
  const chainId = useChainId()
  const { account, rewardToken, sJoeAddress, sJoeContract } = useSJoeContract()
  console.log("cswdasd", account, rewardToken, sJoeAddress, sJoeContract)

  const joeAddress = getAddress(JOE_ADDRESS[chainId])
  const joeContract = {
    abi: erc20ABI,
    address: joeAddress,
    chainId
  }

  const reads = useContractReads({
    contracts: [
      {
        ...sJoeContract,
        functionName: 'depositFeePercent'
      },
      {
        ...joeContract,
        args: [sJoeAddress],
        functionName: 'balanceOf'
      },
      {
        ...sJoeContract,
        args: account && rewardToken ? [account, rewardToken] : undefined,
        functionName: 'pendingReward'
      },
      {
        ...sJoeContract,
        args: account && rewardToken ? [account, rewardToken] : undefined,
        functionName: 'getUserInfo'
      }
    ],
    enabled: sJoeContract.address != ZERO_ADDRESS
  })

  console.log("joeAddress", joeAddress, reads);

  return {
    ...reads,
    depositFee: reads.data?.[0],
    pendingRewards: reads.data?.[2],
    sJoeUserBalance: reads.data?.[3]?.[0],
    totalStaked: reads.data?.[1]
  }
}

const useSJoeClaimRewards = () => {
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()
  const { account, enabled, rewardToken, sJoeContract } = useSJoeContract()

  const { config } = usePrepareContractWrite({
    abi: sJoeContract.abi,
    address: sJoeContract.address,
    // claim is deposit(0)
    args: account && rewardToken ? [BigNumber.from('0')] : undefined,
    cacheTime: 0,
    chainId,
    enabled,
    functionName: 'deposit',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  const transactionSummary = 'Claimed sJoe rewards'

  const {
    data,
    isLoading: isClaiming,
    write
  } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash
    })

  return {
    claim: write,
    isClaiming: isClaiming || isWaitingForTransaction,
    isSuccess
  }
}

interface UseSJoeDepositProps {
  amount?: BigNumber
  approved?: boolean
  onSuccess?: () => void
}

const useSJoeDeposit = ({
  amount,
  approved,
  onSuccess
}: UseSJoeDepositProps) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // check if approved and args are valid
  const enabled = !!amount && approved && walletChainId === chainId

  const { config } = usePrepareContractWrite({
    abi: StableJoeStakingABI,
    address: getAddress(STABLE_JOE_STAKING_ADDRESS[chainId]),
    args: amount ? [BigNumber.from(amount)] : undefined,
    cacheTime: 0,
    chainId,
    enabled,
    functionName: 'deposit',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  // Note: assume JOE token is always 18 decimals
  const JOE_TOKEN_DECIMALS = 18

  const transactionSummary = useMemo(
    () =>
      amount
        ? `Staked ${formattedNum(formatUnits(amount, JOE_TOKEN_DECIMALS))} JOE `
        : undefined,
    [amount]
  )

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    deposit: write,
    isLoading: isWaitingForTransaction || isLoading,
    isSuccess
  }
}

const useSJoeWithdraw = ({ amount, onSuccess }: UseSJoeDepositProps) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { config } = usePrepareContractWrite({
    abi: StableJoeStakingABI,
    address: getAddress(STABLE_JOE_STAKING_ADDRESS[chainId]),
    args: amount ? [BigNumber.from(amount)] : undefined,
    cacheTime: 0,
    chainId,
    enabled: !!amount && walletChainId === chainId,
    functionName: 'withdraw',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  // Note: assume JOE token is always 18 decimals
  const JOE_TOKEN_DECIMALS = 18

  const transactionSummary = useMemo(
    () =>
      amount
        ? `Unstaked ${formattedNum(
          formatUnits(amount, JOE_TOKEN_DECIMALS)
        )} JOE `
        : undefined,
    [amount]
  )

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isLoading,
    isSuccess,
    withdraw: write
  }
}

export { useSJoeClaimRewards, useSJoeDeposit, useSJoeStaking, useSJoeWithdraw }
