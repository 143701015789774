import { SearchIcon, SmallCloseIcon } from '@chakra-ui/icons'
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement
} from '@chakra-ui/react'
import React from 'react'

interface SearchBarProps {
  onValueChange?: (value: string) => void
}

const SearchBar = ({
  onValueChange,
  ...props
}: SearchBarProps & InputProps) => (
  <InputGroup size={props.size}>
    <InputLeftElement>
      <SearchIcon />
    </InputLeftElement>
    <Input
      borderRadius={{ base: 'md', md: 'xl' }}
      onChange={(e) => onValueChange?.(e.currentTarget.value)}
      borderColor="joeLight.500"
      _dark={{ borderColor: 'joeDark.500' }}
      {...props}
    />
    {props.value ? (
      <InputRightElement pr={1} _hover={{ cursor: 'pointer' }}>
        <Button
          borderRadius={8}
          size="sm"
          variant="ghost"
          onClick={() => onValueChange?.('')}
        >
          <SmallCloseIcon boxSize={4} />
        </Button>
      </InputRightElement>
    ) : null}
  </InputGroup>
)

export default SearchBar
