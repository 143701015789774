import {
  Box,
  Center,
  Flex,
  Hide,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
  useMediaQuery
} from '@chakra-ui/react'
import SearchBar from 'components/SearchBar'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import useActiveChain from 'hooks/useActiveChain'
import useSortedVaults from 'hooks/vault/useSortedVaults'
import useUserVaultBalances from 'hooks/vault/useUserVaultBalances'
import useVaults from 'hooks/vault/useVaults'
import useVaultSearch from 'hooks/vault/useVaultSearch'
import React, { useCallback, useMemo, useState } from 'react'
import { UserVaultBalances } from 'types/vault'

import PoolTableHeader from '../PoolTableHeader'
import { vaultsListHeaders } from './constants'
import VaultMobileRow from './VaultMobileRow'
import VaultRow from './VaultRow'
import { isSearchBarEnabled } from 'appConstants'

interface VaultsTableProps {
  isTabSelected: boolean
}

const VaultsTable = ({ isTabSelected }: VaultsTableProps) => {
  const chain = useActiveChain()

  // Workaround for Chakra issue #6927 regarding responsive sizes in InputGroup
  // https://github.com/chakra-ui/chakra-ui/issues/6927
  const [isLargerThanMd] = useMediaQuery(['(min-width: 48em)'])
  function getInputGroupSize() {
    return isLargerThanMd ? 'lg' : 'md'
  }

  const { data: vaults, isLoading } = useVaults({ enabled: isTabSelected })

  console.log("vaults", vaults);

  const { data: userVaultsBalances, isLoading: isLoadingUserVaultPositions } =
    useUserVaultBalances({ enabled: isTabSelected, vaults })
  const userBalancesPerVault = useMemo(
    () =>
      vaults.reduce((acc, { id }, index) => {
        acc[id] = userVaultsBalances?.[index]
        return acc
      }, {} as { [vaultId: string]: UserVaultBalances | undefined }),
    [vaults, userVaultsBalances]
  )

  const [query, setQuery] = useState('')
  const filteredVaults = useVaultSearch({ query, vaults })

  const [sortMethod, setSortMethod] = useState<PoolSortMethod>(
    PoolSortMethod.LIQUIDITY
  )
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)
  const sortedVaults = useSortedVaults({
    isSortDescending,
    sortMethod,
    userBalancesPerVault,
    vaults: filteredVaults
  })

  const onHeaderClick = useCallback(
    (method?: PoolSortMethod) => {
      if (!method) return
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod]
  )

  if (isLoading) {
    return (
      <Center mt={8}>
        <Spinner />
      </Center>
    )
  }

  return (
    <Flex flexDir="column" gap={4} pt={{ base: 0, md: 4 }}>
      {vaults.length === 0 ? (
        <Center py={8}>
          <Text size="sm" color="textSecondary">
            {`There is no automated pools on ${chain.name} yet.`}
          </Text>
        </Center>
      ) : (
        <>
         {isSearchBarEnabled && <Box mx={{ base: 0, md: 4 }}>
            <SearchBar
              placeholder="Search by name, symbol or address"
              value={query}
              onValueChange={setQuery}
              size={getInputGroupSize()}
            />
          </Box>}
          <Hide below="md">
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    {vaultsListHeaders.map((header, i) => (
                      <PoolTableHeader
                        key={i}
                        selectedSortMethod={sortMethod}
                        isSortDescending={isSortDescending}
                        {...header}
                        onClick={() => onHeaderClick(header.sortMethod)}
                      />
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredVaults.map((vault, i) => (
                    <VaultRow
                      key={i}
                      vault={vault}
                      userBalance={userBalancesPerVault[vault.id]}
                      isLoadingUserPosition={isLoadingUserVaultPositions}
                    />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Hide>
          <Hide above="md">
            <Flex flexDir="column" gap={2}>
              {sortedVaults.map((vault, i) => (
                <VaultMobileRow
                  key={i}
                  vault={vault}
                  userBalance={userBalancesPerVault[vault.id]}
                />
              ))}
            </Flex>
          </Hide>
        </>
      )}
    </Flex>
  )
}

export default VaultsTable
