import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import useChainId from 'hooks/useChainId'
import PoolTableNameColumn from 'pages/Pool/PoolTableNameColumn'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Vault } from 'types/vault'
import { formattedNum } from 'utils/format'
import { getPoolDetailV2Url } from 'utils/poolUrl'

import VaultInfoStats from './VaultInfoStats'

interface VaultInfoProps {
  vault: Vault
}

const VaultInfo = ({ vault }: VaultInfoProps) => {
  const navigate = useNavigate()
  const chainId = useChainId()

  const navigateToPoolDetail = useCallback(() => {
    const url = getPoolDetailV2Url(
      vault.lbPair.binStep,
      chainId,
      vault.tokenX.address,
      vault.tokenY.address
    )
    navigate(url)
  }, [navigate, vault, chainId])

  return (
    <VStack
      align="flex-start"
      borderRadius="xl"
      border="1px"
      borderColor="border"
      p={4}
      spacing={4}
    >
      <Heading size="md">{vault.name}</Heading>
      <SimpleGrid w="full" columns={{ base: 2, sm: 4 }} gap={4}>
        <VaultInfoStats
          title="Liquidity"
          value={formattedNum(vault.totalValueLockedUSD, true)}
        />
        <VaultInfoStats
          title="Fees (7D)"
          value={formattedNum(vault.feesUsd7D, true)}
        />
        <VaultInfoStats
          title="APR (7D)"
          value={`${formattedNum(vault.apr7D * 100, false, 2)}%`}
        />
        <VaultInfoStats
          title="Management Fee"
          value={`${vault.managementFeePct}%`}
        />
      </SimpleGrid>
      <Divider />
      <Text whiteSpace="pre-line">{vault.description}</Text>
      <Divider />
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        w="full"
        justify="space-between"
        gap={4}
      >
        <PoolTableNameColumn
          fees={`${vault.lbPair.baseFeePct}%`}
          token0={vault.tokenX}
          token1={vault.tokenY}
        />
        <Button
          variant="outline"
          rightIcon={<ArrowForwardIcon />}
          onClick={navigateToPoolDetail}
        >
          Go to Pool Page
        </Button>
      </Flex>
    </VStack>
  )
}

export default VaultInfo
