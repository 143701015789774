import { BinReserves, LBPairV21ABI } from '@fusionx/sdk-v2'
import { BigNumber } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import { useContractReads } from 'wagmi'

interface UseLBPairBinsProps {
  binIds?: number[]
  enabled?: boolean
  lbPairAddress?: string
}

const useLBPairBins = ({
  binIds,
  enabled = true,
  lbPairAddress
}: UseLBPairBinsProps) => {
  const chainId = useChainId()
  const contract = {
    abi: LBPairV21ABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined
  }
  const reads = useContractReads({
    contracts: binIds?.map((binId) => ({
      ...contract,
      args: [BigNumber.from(binId)],
      chainId,
      functionName: 'getBin'
    })),
    enabled: !!binIds && binIds.length > 0 && enabled
  })
  return {
    ...reads,
    data: reads.data ? (reads.data as unknown[] as BinReserves[]) : undefined
  }
}

export default useLBPairBins
