import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency } from '@fusionx/sdk'
import { VaultABI } from '@fusionx/sdk-v2'
import { BigNumber } from 'ethers'
import { formatUnits, getAddress } from 'ethers/lib/utils.js'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseVaultDepositProps {
  vaultAddress: string
  amount0?: BigNumber
  amount1?: BigNumber
  currency0?: Currency
  currency1?: Currency
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultDeposit = ({
  amount0,
  amount1,
  currency0,
  currency1,
  enabled = true,
  onSuccess,
  vaultAddress
}: UseVaultDepositProps) => {
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const isUsingNativeCurrency = currency0?.isNative || currency1?.isNative
  const args: readonly [BigNumber, BigNumber] | undefined = useMemo(
    () =>
      amount0 && amount1 && (amount0.gt(0) || amount1.gt(0))
        ? [amount0, amount1]
        : undefined,
    [amount0, amount1]
  )
  const { config } = usePrepareContractWrite({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args,
    cacheTime: 0,
    enabled: enabled && !!args && !!currency0 && !!currency1,
    functionName: isUsingNativeCurrency ? 'depositNative' : 'deposit',
    overrides: isUsingNativeCurrency
      ? { value: currency0?.isNative ? amount0 : amount1 }
      : undefined
  })

  const transactionSummary = useMemo(
    () =>
      amount0 && amount1
        ? `Deposit ${formattedNum(formatUnits(amount0, currency0?.decimals))} ${currency0?.symbol
        } and ${formattedNum(formatUnits(amount1, currency1?.decimals))} ${currency1?.symbol
        }`
        : undefined,
    [currency0, currency1, amount0, amount1]
  )

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    deposit: write,
    isLoading: isWaitingForTransaction || isLoading,
    isSuccess
  }
}

export default useVaultDeposit
