import { Token } from '@fusionx/sdk'
import { getTokenLogoURL } from 'components/CurrencyLogo'
import { useCallback } from 'react'
import { useAccount } from 'wagmi'

const useAddTokenToWallet = () => {
  const { connector } = useAccount()
  return useCallback(
    (token: Token) => {
      connector?.watchAsset?.({
        address: token.address,
        decimals: token.decimals,
        image: getTokenLogoURL(token.address),
        symbol: token.symbol ?? ''
      })
    },
    [connector]
  )
}

export default useAddTokenToWallet
