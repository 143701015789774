import { useQuery } from '@tanstack/react-query'
import { VEJOE_SUBGRAPH } from '@fusionx/sdk'
import {
  VeJoeActiveUserCount,
  VeJoeMedianStakerBalanceQuery
} from 'graphql/stake'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { VeJoeMedianData } from 'types/stake'

type VeJoeActiveUserCountQueryResponse = {
  veJoes: [{ activeUserCount: number }]
}
type VeJoeMedianStakerBalanceQueryResponse = {
  users: [{ id: string; veJoeBalance: number }]
}

// Returns veJOE metrics
const useVeJoeAnalytics = () => {
  const chainId = useChainId()
  const subgraphUrl = VEJOE_SUBGRAPH[chainId]

  const activeUserResult = useQuery(
    ['VeJoeActiveUserCountQuery', subgraphUrl],
    () =>
      request<VeJoeActiveUserCountQueryResponse>(
        subgraphUrl,
        VeJoeActiveUserCount
      )
  )

  // get median user
  const activeUserCount =
    activeUserResult.data?.veJoes?.[0]?.activeUserCount ?? 0
  const medianStakerIndex = Math.floor(activeUserCount / 2)

  const medianUserResult = useQuery(
    ['VeJoeMedianStakerBalanceQuery', subgraphUrl, medianStakerIndex],
    () =>
      request<VeJoeMedianStakerBalanceQueryResponse>(
        subgraphUrl,
        VeJoeMedianStakerBalanceQuery,
        {
          first: 1,
          skip: medianStakerIndex
        }
      )
  )
  const medianBalance = medianUserResult.data?.users?.[0]?.veJoeBalance ?? ''

  const data: VeJoeMedianData = {
    activeUserCount,
    medianBalance: Number(medianBalance)
  }
  return data
}

export default useVeJoeAnalytics
