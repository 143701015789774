import { useQuery } from '@tanstack/react-query'
import { DEX_V2_SUBGRAPH } from '@fusionx/sdk-v2'
import dayjs from 'dayjs'
import { lbPairSwapsQuery } from 'graphql/dexV2'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { Swap } from 'types/poolV2'

interface UseLBPairProps {
  address?: string
  enabled?: boolean
  first?: number
  hoursAgo?: number
}

interface ChartData {
  date: number
  value: number
}

interface BinChartData {
  amount: number
  binId: number
}

// TODO: Update by API call with more data feature 
const useLBPairSwaps = ({
  address,
  enabled = true,
  first = 10,
  hoursAgo = 24
}: UseLBPairProps) => {
  const chainId = useChainId()
  const subgraphUrl = DEX_V2_SUBGRAPH[chainId]

  const dateAfter = dayjs().utc().subtract(hoursAgo, 'hour').unix()

  const result = useQuery<{ swaps: Swap[] }>(
    ['LBPairSwapsQuery', subgraphUrl, address],
    () =>
      request(subgraphUrl, lbPairSwapsQuery, {
        dateAfter,
        first,
        lbPair: address?.toLowerCase()
      }),
    { enabled: !!address && enabled }
  )

  // get swaps
  const swaps =
    result.data && result.data.swaps?.length > 0 ? result.data.swaps : undefined

  // format for price tokenY/tokenX chart
  const priceData: ChartData[] | undefined = result.data?.swaps?.map((swap) => {
    const price =
      swap.amountXIn == 0
        ? swap.amountYIn / swap.amountXOut
        : swap.amountYOut / swap.amountXIn
    return { date: swap.timestamp, value: price }
  })

  // format for bin chart
  const binData: ChartData[] | undefined = result.data?.swaps?.map((swap) => {
    return { date: swap.timestamp, value: swap.activeId }
  })

  // format for bin volume chart
  const binChartData: BinChartData[] | undefined = result.data?.swaps?.map(
    (swap) => {
      return { amount: swap.amountUSD, binId: swap.activeId }
    }
  )
  return {
    ...result,
    binChartData,
    binData,
    priceData,
    swaps
  }
}

export default useLBPairSwaps
