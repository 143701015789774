import {
  Flex,
  FlexProps,
  HStack,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Text
} from '@chakra-ui/react'
import { CNATIVE } from '@fusionx/sdk'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Token } from 'types/pool'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface PoolCardProps {
  columns: {
    title: string
    value: string
  }[]
  href: string
  token0: Token
  token1: Token
  tags?: (JSX.Element | null)[]
  topContent?: JSX.Element
}

const PoolCard = ({
  columns,
  href,
  tags,
  token0,
  token1,
  topContent,
  ...props
}: PoolCardProps & FlexProps): JSX.Element => {
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)

  const isToken0WrappedNative = isWrappedNativeCurrency(token0.address, chainId)
  const isToken1WrappedNative = isWrappedNativeCurrency(token1.address, chainId)

  const address0 = isToken0WrappedNative ? undefined : token0.address
  const address1 = isToken1WrappedNative ? undefined : token1.address

  const symbol0 = isToken0WrappedNative ? nativeCurrency.symbol : token0.symbol
  const symbol1 = isToken1WrappedNative ? nativeCurrency.symbol : token1.symbol

  return (
    <LinkBox>
      <Flex
        flexDir="column"
        bg="bgCard"
        borderColor="border"
        borderWidth={1}
        borderRadius="xl"
        p={4}
        cursor="pointer"
        _hover={{ bg: 'hover' }}
        {...props}
      >
        <LinkOverlay
          aria-label={`${token0.symbol}-${token1.symbol} liquidity pool`}
          href={href}
        />
        {topContent ? topContent : null}
        <HStack spacing={2}>
          <DoubleCurrencyLogo
            address0={address0}
            address1={address1}
            symbol0={symbol0}
            symbol1={symbol1}
            boxSize={7}
            ml="-2"
          />
          <Text fontWeight="bold" fontSize="md">
            {symbol0}-{symbol1}
          </Text>
          {tags ? tags : null}
        </HStack>
        <SimpleGrid columns={columns.length} mt={4}>
          {columns.map((column, i) => (
            <Flex flexDir="column" align="left" key={i}>
              <Text color="textSecondary" fontSize="sm" mb={1}>
                {column.title}
              </Text>
              <Text color="textPrimary" fontSize="sm" fontWeight="bold">
                {column.value}
              </Text>
            </Flex>
          ))}
        </SimpleGrid>
      </Flex>
    </LinkBox>
  )
}

export default PoolCard
