import { useQuery } from '@tanstack/react-query'
import { EXCHANGE_SUBGRAPH } from '@fusionx/sdk'
import { avaxPriceQuery, pairDayDatasQuery } from 'graphql/dexV1'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { useMemo } from 'react'

interface PairDayData {
  date: number
  reserveUSD: string
  token0: {
    derivedAVAX: string
  }
  token1: {
    derivedAVAX: string
  }
  txCount: number
  volumeToken0: string
  volumeToken1: string
  volumeUSD: string
}

interface PoolV1ChartData {
  date: number
  value: number
}

interface UsePoolDetailV1AnalyticsProps {
  poolAddress?: string
}

const usePoolDetailV1Analytics = ({
  poolAddress
}: UsePoolDetailV1AnalyticsProps) => {
  const chainId = useChainId()
  const subgraphUrl = EXCHANGE_SUBGRAPH[chainId]

  const { data, isLoading } = useQuery<{
    pairDayDatas: PairDayData[]
  }>(
    ['PoolDetailV1DayDatas', subgraphUrl, poolAddress],
    () =>
      request(subgraphUrl, pairDayDatasQuery, {
        pairs: poolAddress ? [poolAddress.toLowerCase()] : undefined
      }),
    { enabled: !!poolAddress }
  )

  const { data: avaxPriceData, isLoading: isLoadingAvaxPrice } = useQuery(
    ['AvaxPriceQuery'],
    () =>
      request<{
        bundles: { avaxPrice: string }[]
      }>(subgraphUrl, avaxPriceQuery)
  )

  const chartData = useMemo(() => {
    if (!data || !avaxPriceData) return undefined
    const avaxPrice = parseFloat(avaxPriceData.bundles[0].avaxPrice)
    return data.pairDayDatas.reduce<{
      liquidity: PoolV1ChartData[]
      volume: PoolV1ChartData[]
    }>(
      (previousValue, currentValue) => {
        const token0DerivedAVAX = parseFloat(currentValue.token0.derivedAVAX)
        const token1DerivedAVAX = parseFloat(currentValue.token1.derivedAVAX)
        const volumeToken0 = parseFloat(currentValue.volumeToken0)
        const volumeToken1 = parseFloat(currentValue.volumeToken1)
        const untrackedVolumeUSD =
          token0DerivedAVAX * volumeToken0 +
          token1DerivedAVAX * volumeToken1 * avaxPrice
        const volumeUSD =
          currentValue.volumeUSD === '0'
            ? untrackedVolumeUSD
            : parseFloat(currentValue.volumeUSD)
        previousValue['liquidity'].unshift({
          date: currentValue.date,
          value: parseFloat(currentValue.reserveUSD)
        })
        previousValue['volume'].unshift({
          date: currentValue.date,
          value: volumeUSD
        })
        return previousValue
      },
      { liquidity: [], volume: [] }
    )
  }, [data, avaxPriceData])

  return {
    chartData,
    isLoading: isLoading || isLoadingAvaxPrice,
    pairDayDatas: data?.pairDayDatas
  }
}

export default usePoolDetailV1Analytics
