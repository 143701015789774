import { ChainId, Currency } from '@fusionx/sdk'
import { useCallback, useEffect, useState } from 'react'
import { BUSD, USDC, USDCe, USDT, USDTe } from 'utils/swap'

import useChainId from './useChainId'

// Priority: native stable > stable > native chain > majors > alts > derivatives
const AVALANCHE_DAIe = '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70'
const AVALANCHE_MIM = '0x130966628846BFd36ff31a822705796e8cb8C18D'
const ARBITRUM_ONE_DAI = '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1'
const AVALANCHE_WETHe = '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB'
const AVALANCHE_BTCb = '0x152b9d0FdC40C096757F570A51E494bd4b943E50'
const AVALANCHE_WBTCe = '0x50b7545627a5162F82A992c33b87aDc75187B218'
const AVALANCHE_BNB = '0x264c1383EA520f73dd837F915ef3a732e204a493'
const ARBITRUM_ONE_WBTC = '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f'
const ARBITRUM_ONE_BTCb = '0x2297aEbD383787A160DD0d9F71508148769342E3'
const BINANCE_ETH = '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'
const BINANCE_BTC = '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'

const NATIVE_STABLES: { [chainId in ChainId]: string[] } = {
  [ChainId.AVALANCHE]: [USDC[ChainId.AVALANCHE].address],
  [ChainId.FUJI]: [USDC[ChainId.FUJI].address],
  [ChainId.ARB_GOERLI]: [],
  [ChainId.ARBITRUM_ONE]: [USDC[ChainId.ARBITRUM_ONE].address],
  [ChainId.BNB_CHAIN]: [BUSD[ChainId.BNB_CHAIN].address],
  [ChainId.BNB_TESTNET]: [BUSD[ChainId.BNB_TESTNET].address],
  [ChainId.MANTLE_TESTNET]: [USDC[ChainId.MANTLE_TESTNET].address]
}


const STABLES: { [chainId in ChainId]: string[] } = {
  [ChainId.AVALANCHE]: [
    USDCe[ChainId.AVALANCHE].address,
    USDTe[ChainId.AVALANCHE].address,
    USDT[ChainId.AVALANCHE].address,
    AVALANCHE_DAIe,
    AVALANCHE_MIM
  ],
  [ChainId.FUJI]: [],
  [ChainId.ARB_GOERLI]: [],
  [ChainId.ARBITRUM_ONE]: [
    USDT[ChainId.ARBITRUM_ONE].address,
    ARBITRUM_ONE_DAI
  ],
  [ChainId.BNB_CHAIN]: [
    USDC[ChainId.BNB_CHAIN].address,
    USDT[ChainId.BNB_CHAIN].address
  ],
  [ChainId.BNB_TESTNET]: [USDC[ChainId.BNB_TESTNET].address],
  [ChainId.MANTLE_TESTNET]: [USDT[ChainId.MANTLE_TESTNET].address]
}

const MAJORS: { [chainId in ChainId]: string[] } = {
  [ChainId.AVALANCHE]: [
    AVALANCHE_WETHe,
    AVALANCHE_BTCb,
    AVALANCHE_WBTCe,
    AVALANCHE_BNB
  ],
  [ChainId.FUJI]: [],
  [ChainId.ARB_GOERLI]: [],
  [ChainId.ARBITRUM_ONE]: [ARBITRUM_ONE_WBTC, ARBITRUM_ONE_BTCb],
  [ChainId.BNB_CHAIN]: [BINANCE_ETH, BINANCE_BTC],
  [ChainId.BNB_TESTNET]: [],
  [ChainId.MANTLE_TESTNET]: []
}

export const useCandleChartStandardization = (
  inputCurrency?: Currency,
  outputCurrency?: Currency
) => {
  const chainId = useChainId()
  const [standardizedInputCurrency, setStandardizedInputCurrency] = useState<
    Currency | undefined
  >()
  const [standardizedOutputCurrency, setStandardizedOutputCurrency] = useState<
    Currency | undefined
  >()
  const [isInverted, setInverted] = useState(false)

  const toggleInverted = useCallback(() => {
    setInverted(!isInverted)
  }, [isInverted])

  useEffect(() => {
    // standarize native stable USD tokens
    if (
      inputCurrency?.isToken &&
      NATIVE_STABLES[chainId].includes(inputCurrency.address)
    ) {
      setStandardizedInputCurrency(outputCurrency)
      setStandardizedOutputCurrency(inputCurrency)
    } else if (
      // standardize stable USD tokens
      inputCurrency?.isToken &&
      outputCurrency?.isToken &&
      STABLES[chainId].includes(inputCurrency.address) &&
      !NATIVE_STABLES[chainId].includes(outputCurrency?.address)
    ) {
      setStandardizedInputCurrency(outputCurrency)
      setStandardizedOutputCurrency(inputCurrency)
    } else if (
      // standardize native chain tokens
      inputCurrency?.isNative &&
      outputCurrency?.isToken &&
      !NATIVE_STABLES[chainId].includes(outputCurrency.address) &&
      !STABLES[chainId].includes(outputCurrency.address)
    ) {
      setStandardizedInputCurrency(outputCurrency)
      setStandardizedOutputCurrency(inputCurrency)
    } else if (
      // standardize majors
      inputCurrency?.isToken &&
      MAJORS[chainId].includes(inputCurrency.address) &&
      outputCurrency &&
      !outputCurrency.isNative &&
      !NATIVE_STABLES[chainId].includes(outputCurrency.address) &&
      !STABLES[chainId].includes(outputCurrency.address)
    ) {
      setStandardizedInputCurrency(outputCurrency)
      setStandardizedOutputCurrency(inputCurrency)
    } else {
      // all other pairs
      setStandardizedInputCurrency(inputCurrency)
      setStandardizedOutputCurrency(outputCurrency)
    }
  }, [chainId, inputCurrency, outputCurrency])

  return {
    isInverted,
    standardizedInputCurrency,
    standardizedOutputCurrency,
    toggleInverted
  }
}
