const legacyInterfaceBaseUrl = 'https://v1.fusionx.finance'

export interface SubmenuItem {
  name: string
  url: string
  external?: boolean
}

const joeHome: SubmenuItem = {
  external: false,
  name: 'Home',
  url: ''
}

const joeFarm: SubmenuItem = {
  external: true,
  name: 'Farm',
  url: `${legacyInterfaceBaseUrl}/farm`
}

const joeLend: SubmenuItem = {
  external: true,
  name: 'Lend',
  url: `${legacyInterfaceBaseUrl}/lend`
}

const joeStake: SubmenuItem = {
  external: false,
  name: 'Stake',
  url: 'stake'
}

const joeBridge: SubmenuItem = {
  external: true,
  name: 'Bridge',
  url: 'https://bridge.testnet.mantle.xyz/'
}

export { joeBridge, joeFarm, joeHome, joeLend, joeStake }
