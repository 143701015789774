import { Currency } from '@fusionx/sdk'
import { BigNumber, ethers } from 'ethers'
import { useMemo, useState } from 'react'

interface UseCurrencyInputAmountProps {
  currency?: Currency
}

export interface UseCurrencyInputAmountResult {
  amount: string
  setAmount: (amount: string) => void
  amountBN?: BigNumber
}

const useCurrencyInputAmount = ({
  currency
}: UseCurrencyInputAmountProps): UseCurrencyInputAmountResult => {
  const [amount, setAmount] = useState('')
  const amountBN = useMemo(() => {
    if (!currency || !amount || isNaN(Number(amount))) {
      return undefined
    }
    const splittedAmount = amount.split('.')
    let nbIntegral = 0
    let nbDecimalPlaces = 0
    if (splittedAmount && splittedAmount.length === 2) {
      nbIntegral = splittedAmount[0].length
      nbDecimalPlaces = splittedAmount[1].length
    }
    return nbDecimalPlaces > currency.decimals
      ? ethers.utils.parseUnits(
        amount.slice(0, 1 + nbIntegral + currency.decimals),
        currency.decimals
      )
      : ethers.utils.parseUnits(amount, currency.decimals)
  }, [currency, amount])
  return {
    amount,
    amountBN,
    setAmount
  }
}

export default useCurrencyInputAmount
