import { IconButton, useColorMode } from '@chakra-ui/react'
import React from 'react'
import { MoonIcon, SunIcon } from 'theme/icons'

const ColorModeButton = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <IconButton
      variant="secondary"
      aria-label="toggle color mode"
      borderRadius="full"
      icon={
        colorMode === 'light' ? (
          <MoonIcon boxSize={6} />
        ) : (
          <SunIcon boxSize={6} />
        )
      }
      onClick={toggleColorMode}
    />
  )
}

export default ColorModeButton
