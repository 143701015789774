import { avalancheFuji } from '@wagmi/core/chains'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

export const isRunningE2ETests = false//window.Cypress

const forkRpcUrl = localStorage.getItem('forkRpcUrl')
const forkChainId = localStorage.getItem('forkChainId')

const defaultRpcUrl = avalancheFuji.rpcUrls.public.http[0]
const rpcUrl = forkRpcUrl ?? defaultRpcUrl

export const e2eTestsConfig = {
  chainId: forkChainId ? Number(forkChainId) : avalancheFuji.id,
  providers: [
    jsonRpcProvider({
      rpc: () => ({
        http: rpcUrl
      })
    })
  ],
  rpcUrl
}
