import {
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import { format, formatDistance } from 'date-fns'
import React from 'react'
import { Swap } from 'types/poolV2'
import { formattedNum } from 'utils/format'

interface PoolSwapsTableProps {
  currency0: Currency
  currency1: Currency
  first?: number
  swaps?: Swap[]
}

const PoolSwapsTable = ({
  currency0,
  currency1,
  first = 10,
  swaps
}: PoolSwapsTableProps) => {
  const now = new Date()

  return (
    <Flex flexDir="column" gap={4}>
      <Heading size="md">Recent Swaps</Heading>
      <TableContainer>
        <Table maxWidth="100%" size="sm">
          <Thead>
            <Tr borderBottom="1px" borderBottomColor="border">
              <Th>From</Th>
              <Th>To</Th>
              <Th>Price</Th>
              <Th>Bin ID</Th>
              <Th textAlign="right">Timestamp</Th>
            </Tr>
          </Thead>
          <Tbody>
            {swaps &&
              swaps
                .slice(0, first)
                .map((swap, key) =>
                  SwapRow({ currency0, currency1, key, now, swap })
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}

interface SwapRowInterface {
  currency0: Currency
  currency1: Currency
  key: number
  now: Date
  swap: Swap
}

const SwapRow = ({
  currency0,
  currency1,
  key,
  now,
  swap
}: SwapRowInterface) => {
  // Swap is currency1 -> currency0 if amountXIn is 0
  const from =
    swap.amountXIn == 0
      ? `${formattedNum(swap.amountYIn, false, 4)} ${currency1.symbol}`
      : `${formattedNum(swap.amountXIn, false, 4)} ${currency0.symbol}`
  const to =
    swap.amountXIn == 0
      ? `${formattedNum(swap.amountXOut, false, 4)} ${currency0.symbol}`
      : `${formattedNum(swap.amountYOut, false, 4)} ${currency1.symbol}`
  const price =
    swap.amountXIn == 0
      ? `${formattedNum(swap.amountYIn / swap.amountXOut, false, 4)}`
      : `${formattedNum(swap.amountYOut / swap.amountXIn, false, 4)}`
  return (
    <Tr key={key} borderBottom="1px" borderBottomColor="border">
      <Td verticalAlign="top">{from}</Td>
      <Td verticalAlign="top">{to}</Td>
      <Td verticalAlign="top">{price}</Td>
      <Td verticalAlign="top">{swap.activeId}</Td>
      <Td verticalAlign="top" textAlign="right">
        <Flex flexDir="column">
          <Text>
            {format(Number(swap.timestamp) * 1000, 'yyyy-MM-dd HH:mm:ss')}
          </Text>
          <Text color={'GrayText'} fontSize={'xs'} fontWeight={'bold'}>
            {formatDistance(new Date(Number(swap.timestamp) * 1000), now, {
              addSuffix: true,
              includeSeconds: true
            })}
          </Text>
        </Flex>
      </Td>
    </Tr>
  )
}

export default PoolSwapsTable
