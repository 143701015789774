import { JSBI } from '@fusionx/sdk'

const BIPS_BASE = JSBI.BigInt(10000)

export const TRADE_HELMET_TITLE =
  'FusionX | Decentralized Exchange | Trade'
export const TRADE_HELMET_DESCRIPTION =
  "One-stop-shop Decentralized Trading Platform. Trade and discover thousands of tokens. Swap your favourite coins. Low cost trading with zero slippage. Welcome to FusionX everyone's favorite DEX."

export { BIPS_BASE }
