import { Box, Center, Flex, Heading, Text, useToken } from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import React, { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { BinVolumeData } from 'types/poolV2'
import { formattedNum } from 'utils/format'

import BinsTradedChartTooltip from './BinsTradedChartTooltip'

interface BinsTradedChartProps {
  activeBinId: number
  binsTraded24: BinVolumeData[]
  currency0: Currency
  currency1: Currency
}

const BinsTradedChart = ({
  activeBinId,
  binsTraded24,
  currency0,
  currency1
}: BinsTradedChartProps) => {
  const [
    graphPurpleDark,
    graphPurpleLight,
    graphGreenDark,
    graphGreenLight,
    textSecondary
  ] = useToken('colors', [
    'graphPurpleDark',
    'graphPurpleLight',
    'graphGreenDark',
    'graphGreenLight',
    'textSecondary'
  ])

  const [focusBar, setFocusBar] = useState<number | undefined>()
  const dataKey = 'volumeUsd'

  const yAxisDomain = useMemo(() => {
    const yAxisMaxValue = binsTraded24
      .map((data: any) => data[dataKey])
      .reduce((a, b) => Math.max(a, b), -Infinity)
    return [0, yAxisMaxValue]
  }, [binsTraded24])

  return (
    <Box pos="relative">
      <Flex justify="space-between" align="center" mb={{ base: 0, sm: 8 }}>
        <Heading size="md" mb={2}>
          Bins Traded (24H)
        </Heading>
      </Flex>
      <Box h="160px">
        <ResponsiveContainer width="99%">
          <BarChart
            data={binsTraded24}
            onMouseMove={(state) => {
              setFocusBar(
                state.isTooltipActive ? state.activeTooltipIndex : undefined
              )
            }}
            onMouseLeave={() => {
              setFocusBar(undefined)
            }}
            margin={{ bottom: 16 }}
          >
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              cursor={{ fill: 'transparent' }}
              content={
                <BinsTradedChartTooltip
                  dataKey={dataKey}
                  symbolX={currency0.symbol ?? ''}
                  symbolY={currency1.symbol ?? ''}
                />
              }
            />
            <YAxis
              width={80}
              axisLine={false}
              tickLine={false}
              tickSize={0}
              tickMargin={20}
              domain={yAxisDomain}
              fontSize="12px"
              tick={{ fill: textSecondary }}
              tickFormatter={(val) => formattedNum(val, true, 5)}
            />
            <XAxis
              xAxisId="0"
              tickSize={0}
              minTickGap={16}
              axisLine={false}
              dataKey="priceXY"
              tickFormatter={(val) => formattedNum(val, false, 5)}
              fontSize="12px"
              fontWeight={500}
              tick={{ fill: textSecondary }}
              tickMargin={8}
            />
            <defs>
              <linearGradient id="activeId" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={graphPurpleDark} stopOpacity={1} />
                <stop
                  offset="100%"
                  stopColor={graphPurpleDark}
                  stopOpacity={0.01}
                />
              </linearGradient>
              <linearGradient id="nonActiveId" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={graphGreenDark} stopOpacity={1} />
                <stop
                  offset="100%"
                  stopColor={graphGreenDark}
                  stopOpacity={0.01}
                />
              </linearGradient>
              <linearGradient
                opacity={0.5}
                id="activeIdFocus"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor={graphGreenLight} stopOpacity={1} />
                <stop
                  offset="100%"
                  stopColor={graphGreenLight}
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient
                opacity={0.5}
                id="nonActiveIdFocus"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={graphPurpleLight}
                  stopOpacity={1}
                />
                <stop
                  offset="100%"
                  stopColor={graphPurpleLight}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <Bar dataKey={dataKey} radius={2}>
              {binsTraded24.map((data, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    focusBar === index && data.binId === activeBinId
                      ? 'url(#activeIdFocus)'
                      : focusBar === index && data.binId !== activeBinId
                        ? 'url(#nonActiveIdFocus)'
                        : data.binId === activeBinId
                          ? 'url(#activeId)'
                          : 'url(#nonActiveId)'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Center mt={-6}>
          <Text fontSize="sm" fontWeight="semibold">
            Bin Price
          </Text>
        </Center>
      </Box>
    </Box>
  )
}

export default BinsTradedChart
