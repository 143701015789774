import { Box, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent'
import { formattedNum } from 'utils/format'

type BinsTradedChartTooltipProps = TooltipProps<ValueType, NameType> & {
  dataKey: string
  symbolX: string
  symbolY: string
}

const BinsTradedChartTooltip = ({
  active,
  dataKey,
  payload,
  symbolX,
  symbolY
}: BinsTradedChartTooltipProps) => {
  const bin = payload?.[0]?.payload
  const { priceXY, priceYX } = bin || {}

  return active && payload && payload.length ? (
    <Box
      p={4}
      borderRadius="xl"
      backdropFilter="blur(8px)"
      bg="rgba(245, 245, 255, 0.48)"
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.16)"
      _dark={{
        bg: 'rgba(53, 58, 108, 0.48)',
        boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.4)'
      }}
    >
      <VStack spacing={0} align="flex-start" mb={4}>
        <Text fontSize="sm" color="textSecondary">{`Trade Volume`}</Text>
        <Text fontWeight="semibold">
          {bin[dataKey]
            ? `${formattedNum(bin[dataKey], dataKey === 'volumeUsd')} ${
                dataKey === 'volumeX'
                  ? symbolX
                  : dataKey === 'volumeY'
                  ? symbolY
                  : ''
              }`
            : undefined}
        </Text>
      </VStack>
      <VStack spacing={0} align="flex-start">
        <Text fontSize="sm" color="textSecondary">
          Bin Price
        </Text>
        <Text fontWeight="semibold">
          {priceXY ? `${formattedNum(priceXY)} ${symbolY}` : undefined}
        </Text>
        <Text fontWeight="semibold">
          {priceXY ? `${formattedNum(priceYX)} ${symbolX}` : undefined}
        </Text>
      </VStack>
    </Box>
  ) : null
}

export default BinsTradedChartTooltip
