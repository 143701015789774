import { useQuery } from '@tanstack/react-query'
import { EXCHANGE_SUBGRAPH } from '@fusionx/sdk'
import { userLiquidityPositionsQuery } from 'graphql/dexV1'
import { request } from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { LiquidityPosition } from 'types/poolV1'
import { convertLiquidityPositionsToUserPoolPositions } from 'utils/poolV1'
import { useAccount } from 'wagmi'

const useUserPoolV1Positions = () => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const subgraphUrl = EXCHANGE_SUBGRAPH[chainId]
  const result = useQuery<{ liquidityPositions: LiquidityPosition[] }>(
    ['UserPoolV1PositionsQuery', subgraphUrl, account],
    () =>
      request(subgraphUrl, userLiquidityPositionsQuery, {
        user: account?.toLowerCase()
      }),
    { enabled: !!account, keepPreviousData: true }
  )
  return {
    ...result,
    data: convertLiquidityPositionsToUserPoolPositions({
      liquidityPositions: result.data?.liquidityPositions ?? []
    })
  }
}

export default useUserPoolV1Positions
