import { gql } from 'graphql-request'

const dexCandlesV2Query = gql`
  query dexCandlesV2Query(
    $token0: String!
    $token1: String!
    $period: Int!
    $first: Int!
    $skip: Int!
    $startTime: Int!
  ) {
    candles(
      first: $first
      skip: $skip
      orderBy: time
      orderDirection: asc
      where: {
        token0: $token0
        token1: $token1
        period: $period
        time_gt: $startTime
      }
    ) {
      time
      open
      low
      high
      close
    }
  }
`

const lbPairSwapsQuery = gql`
  query lbPairSwaps($first: Int! = 10, $lbPair: String!, $dateAfter: Int!) {
    swaps(
      first: $first
      orderBy: timestamp
      orderDirection: desc
      where: { lbPair: $lbPair, timestamp_gte: $dateAfter }
    ) {
      activeId
      amountUSD
      amountXIn
      amountXOut
      amountYIn
      amountYOut
      timestamp
    }
  }
`
const vaultDayDataFieldsQuery = gql`
  fragment vaultDayDataFields on VaultDayData {
    date
    collectedFeesX
    collectedFeesY
    collectedFeesUSD
    totalValueLockedUSD
  }
`

const vaultFieldsQuery = gql`
  fragment vaultFields on Vault {
    id
    name
    tokenX {
      id
      symbol
      decimals
    }
    tokenY {
      id
      symbol
      decimals
    }
    lbPair {
      id
      binStep
      baseFeePct
    }
    strategy {
      id
      aumAnnualFee
    }
    totalValueLockedUSD
    dayData(
      first: 8
      where: { date_gte: $date }
      orderBy: date
      orderDirection: desc
    ) {
      ...vaultDayDataFields
    }
  }
  ${vaultDayDataFieldsQuery}
`

const vaultsQuery = gql`
  query vaults($first: Int! = 500, $date: Int!) {
    vaults(first: $first, orderBy: totalValueLockedUSD, orderDirection: desc) {
      ...vaultFields
    }
  }
  ${vaultFieldsQuery}
`

const vaultQuery = gql`
  query vaultQuery($id: String!, $date: Int!) {
    vaults(where: { id: $id }) {
      ...vaultFields
    }
  }
  ${vaultFieldsQuery}
`

const vaultDayDatasQuery = gql`
  query vaultDayDatasQuery($first: Int = 180, $vaults: [Bytes]!) {
    vaultDayDatas(
      first: $first
      orderBy: date
      orderDirection: desc
      where: { vault_in: $vaults }
    ) {
      ...vaultDayDataFields
    }
  }
  ${vaultDayDataFieldsQuery}
`

const userVaultPositionsQuery = gql`
  query userVaultPositions($first: Int! = 1000, $user: Bytes!, $date: Int!) {
    vaultUserPositions(first: $first, where: { user: $user }) {
      id
      totalAmountDepositedX
      totalAmountDepositedY
      totalAmountDepositedUSD
      totalAmountWithdrawnX
      totalAmountWithdrawnY
      totalAmountWithdrawnUSD
      vault {
        ...vaultFields
      }
    }
  }
  ${vaultFieldsQuery}
`

const vaultQueuedWithdrawalsQuery = gql`
  query vaultQueuedWithdrawals($vault: String!, $user: String!) {
    vaultQueuedWithdrawals(
      where: { vault: $vault, user: $user, shares_gt: 0 }
    ) {
      shares
      round
      vault {
        id
      }
    }
  }
`

export {
  dexCandlesV2Query,
  lbPairSwapsQuery,
  userVaultPositionsQuery,
  vaultDayDatasQuery,
  vaultQuery,
  vaultQueuedWithdrawalsQuery,
  vaultsQuery
}
