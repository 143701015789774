import { BigNumber } from '@ethersproject/bignumber'
import { Currency } from '@fusionx/sdk'
import {
  LB_FACTORY_V21_ADDRESS,
  LBFactoryABI,
  LBPairV21ABI
} from '@fusionx/sdk-v2'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useContractRead } from 'wagmi'

import useLBPairFeeParameters from './useLBPairFeeParameters'

interface UseLBPairDataProps {
  binStep?: string
  currency0?: Currency
  currency1?: Currency
}

const useLBPairData = ({
  binStep,
  currency0,
  currency1
}: UseLBPairDataProps) => {
  const chainId = useChainId()

  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)

  const { data: lbPairInfo, isLoading: isLoadingPairInfo } = useContractRead({
    abi: LBFactoryABI,
    address: getAddress(LB_FACTORY_V21_ADDRESS[chainId]),
    args:
      wrappedCurrency0 && wrappedCurrency1 && binStep
        ? [
          getAddress(wrappedCurrency0.address),
          getAddress(wrappedCurrency1.address),
          BigNumber.from(binStep)
        ]
        : undefined,
    chainId,
    enabled: !!wrappedCurrency0 && !!wrappedCurrency1 && !!binStep,
    functionName: 'getLBPairInformation'
  })

  const {
    data: activeId,
    isLoading: isLoadingActiveId,
    refetch: refetchActiveBinId
  } = useContractRead({
    abi: LBPairV21ABI,
    address: lbPairInfo?.LBPair,
    chainId,
    enabled: !!lbPairInfo,
    functionName: 'getActiveId'
  })

  const {
    data: reserves,
    isLoading: isLoadingReserves,
    refetch: refetchReserves
  } = useContractRead({
    abi: LBPairV21ABI,
    address: lbPairInfo?.LBPair,
    chainId,
    enabled: !!lbPairInfo,
    functionName: 'getReserves'
  })

  const isLoadingReservesAndId = isLoadingActiveId || isLoadingReserves;

  const refetchReservesAndActiveBinId = () => {
    refetchReserves();
    refetchActiveBinId();
  }

  const { data: feeParameters, isLoading: isLoadingFeeParameters } =
    useLBPairFeeParameters({ lbPairAddress: lbPairInfo?.LBPair })

  return {
    activeBinId: activeId ? BigNumber.from(activeId) : undefined,
    feeParameters,
    isLoading:
      isLoadingPairInfo || isLoadingReservesAndId || isLoadingFeeParameters,
    lbPairInfo,
    refetchReservesAndActiveBinId,
    reserveX: reserves?.reserveX,
    reserveY: reserves?.reserveY
  }
}

export default useLBPairData
