import { VStack } from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import { FetchBalanceResult } from '@wagmi/core'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import Web3Button from 'components/Web3Button'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import useVaultDeposit from 'hooks/vault/useVaultDeposit'
import React from 'react'
import { getCurrencyAddress } from 'utils/wrappedCurrency'

interface VaultAddLiquidityPanelProps {
  vaultAddress: string
  balance0?: FetchBalanceResult
  balance1?: FetchBalanceResult
  currency0?: Currency
  currency1?: Currency
  onAddLiquiditySuccess?: () => void
}

const VaultAddLiquidityPanel = ({
  balance0,
  balance1,
  currency0,
  currency1,
  onAddLiquiditySuccess,
  vaultAddress
}: VaultAddLiquidityPanelProps) => {
  const {
    amount: amount0,
    amountBN: amount0BN,
    setAmount: setAmount0
  } = useCurrencyInputAmount({ currency: currency0 })
  const {
    amount: amount1,
    amountBN: amount1BN,
    setAmount: setAmount1
  } = useCurrencyInputAmount({ currency: currency1 })

  const tokenAddress0 = getCurrencyAddress(currency0)
  const tokenAddress1 = getCurrencyAddress(currency1)

  const {
    approvalType: approvalType0,
    approve: approveToken0,
    isApproved: isToken0Approved,
    isApproving: isApprovingToken0,
    setApprovalType: setApprovalType0
  } = useApproveSpenderIfNeeded({
    amount: amount0BN,
    spender: vaultAddress,
    token: tokenAddress0,
    tokenSymbol: currency0?.symbol
  })

  const {
    approvalType: approvalType1,
    approve: approveToken1,
    isApproved: isToken1Approved,
    isApproving: isApprovingToken1,
    setApprovalType: setApprovalType1
  } = useApproveSpenderIfNeeded({
    amount: amount1BN,
    spender: vaultAddress,
    token: tokenAddress1,
    tokenSymbol: currency1?.symbol
  })

  const { deposit, isLoading: isDepositing } = useVaultDeposit({
    amount0: amount0BN,
    amount1: amount1BN,
    currency0,
    currency1,
    enabled:
      (isToken0Approved === true || currency0?.isNative === true) &&
      (isToken1Approved === true || currency1?.isNative === true),
    onSuccess: () => {
      setAmount0('')
      setAmount1('')
      onAddLiquiditySuccess?.()
    },
    vaultAddress
  })

  const isExceedingBalance0 = balance0
    ? Number(balance0.formatted) < Number(amount0)
    : false
  const isExceedingBalance1 = balance1
    ? Number(balance1.formatted) < Number(amount1)
    : false
  const isAddLiquidityDisabled = isExceedingBalance0 || isExceedingBalance1

  return (
    <VStack align="flex-start" spacing={12}>
      <VStack align="flex-start" w="full" spacing={2}>
        <CurrencyInput
          currency={currency0}
          currencyAddress={tokenAddress0}
          value={amount0}
          onValueChange={setAmount0}
          balance={balance0?.formatted}
          error={
            isExceedingBalance0 ? `Not enough ${currency0?.symbol}` : undefined
          }
          rightElement={
            balance0 ? (
              <MaxButton
                balance={balance0.formatted}
                onClick={() => setAmount0(balance0.formatted)}
              />
            ) : undefined
          }
        />
        <CurrencyInput
          currency={currency1}
          currencyAddress={tokenAddress1}
          value={amount1}
          onValueChange={setAmount1}
          balance={balance1?.formatted}
          error={
            isExceedingBalance1 ? `Not enough ${currency1?.symbol}` : undefined
          }
          rightElement={
            balance1 ? (
              <MaxButton
                balance={balance1.formatted}
                onClick={() => setAmount1(balance1.formatted)}
              />
            ) : undefined
          }
        />
      </VStack>
      <VStack w="full" spacing={4}>
        {!isToken0Approved &&
          approveToken0 &&
          currency0 &&
          !isAddLiquidityDisabled ? (
          <ApproveTokenButton
            amount={amount0}
            currencySymbol={currency0.symbol}
            approvalType={approvalType0}
            onApprovalTypeSelect={setApprovalType0}
            isLoading={isApprovingToken0}
            onClick={() => approveToken0()}
          >
            {`Approve ${currency0.symbol}`}
          </ApproveTokenButton>
        ) : null}
        {!isToken1Approved &&
          approveToken1 &&
          currency1 &&
          !isAddLiquidityDisabled ? (
          <ApproveTokenButton
            amount={amount1}
            currencySymbol={currency1.symbol}
            approvalType={approvalType1}
            onApprovalTypeSelect={setApprovalType1}
            isLoading={isApprovingToken1}
            onClick={() => approveToken1()}
          >
            {`Approve ${currency1.symbol}`}
          </ApproveTokenButton>
        ) : null}
        <Web3Button
          variant="primary"
          colorScheme="accent"
          size="xl"
          w="full"
          isLoading={isDepositing}
          loadingText="Depositing"
          isDisabled={isAddLiquidityDisabled || !deposit}
          onClick={() => deposit?.()}
        >
          Add Liquidity
        </Web3Button>
      </VStack>
    </VStack>
  )
}

export default VaultAddLiquidityPanel
