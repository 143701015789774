import { Flex } from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import { UseAddLiquidityV2Props } from 'hooks/pool/v2/useAddLiquidityV2'
import React, { useEffect, useMemo, useState } from 'react'
import { AddUniformLiquidityBatch } from 'utils/getAddUniformLiquidityBatches'

import AddUniformLiquidityBatchPanel from './AddUniformLiquidityBatchPanel'

interface AddUniformLiquidityPanelProps {
  addLiquidityProps: UseAddLiquidityV2Props
  batches: AddUniformLiquidityBatch[]
  binPerBatch: number
  binRange: number[]
  binStep: number
  currency0: Currency
  currency1: Currency
  inversePriceRatios: boolean
  onAddLiquidityPrepareContractWriteError?: () => void
}

const AddUniformLiquidityPanel = ({
  addLiquidityProps,
  batches,
  binPerBatch,
  binRange,
  binStep,
  currency0,
  currency1,
  inversePriceRatios,
  onAddLiquidityPrepareContractWriteError
}: AddUniformLiquidityPanelProps) => {
  const [successfulBatches, setSuccessfulBatches] = useState<number[]>([])

  // reset successful batches when range changes
  useEffect(() => {
    setSuccessfulBatches([])
  }, [binRange])

  const sortedBatches = useMemo(
    () => (inversePriceRatios ? [...batches].reverse() : batches),
    [batches, inversePriceRatios]
  )

  return (
    <Flex flexDir="column" gap={4} w="full">
      {sortedBatches.map((batch, i) => (
        <AddUniformLiquidityBatchPanel
          key={i}
          batch={batch}
          batchIndex={i}
          currency0={currency0}
          currency1={currency1}
          inversePriceRatios={inversePriceRatios}
          binStep={Number(binStep)}
          addLiquidityProps={addLiquidityProps}
          binPerBatch={binPerBatch}
          isSuccess={successfulBatches.includes(i)}
          onSuccess={() => setSuccessfulBatches((prev) => [...prev, i])}
          onAddLiquidityPrepareContractWriteError={
            onAddLiquidityPrepareContractWriteError
          }
        />
      ))}
    </Flex>
  )
}

export default AddUniformLiquidityPanel
