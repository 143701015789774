export const CustomPriceHelperABI = [
  {
    "inputs": [],
    "name": "SafeCast__Exceeds24Bits",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "Uint128x128Math__LogUnderflow",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "x",
        "type": "uint256"
      },
      {
        "internalType": "int256",
        "name": "y",
        "type": "int256"
      }
    ],
    "name": "Uint128x128Math__PowUnderflow",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "Uint256x256Math__MulDivOverflow",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "Uint256x256Math__MulShiftOverflow",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "price128x128",
        "type": "uint256"
      }
    ],
    "name": "convert128x128PriceToDecimal",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      }
    ],
    "name": "convertDecimalPriceTo128x128",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint16",
        "name": "binStep",
        "type": "uint16"
      }
    ],
    "name": "getBase",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint24",
        "name": "id",
        "type": "uint24"
      }
    ],
    "name": "getExponent",
    "outputs": [
      {
        "internalType": "int256",
        "name": "",
        "type": "int256"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "internalType": "uint16",
        "name": "binStep",
        "type": "uint16"
      }
    ],
    "name": "getIdFromDecimalPrice",
    "outputs": [
      {
        "internalType": "uint24",
        "name": "",
        "type": "uint24"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      },
      {
        "internalType": "uint16",
        "name": "binStep",
        "type": "uint16"
      }
    ],
    "name": "getIdFromPrice",
    "outputs": [
      {
        "internalType": "uint24",
        "name": "id",
        "type": "uint24"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint24",
        "name": "id",
        "type": "uint24"
      },
      {
        "internalType": "uint16",
        "name": "binStep",
        "type": "uint16"
      }
    ],
    "name": "getPriceFromId",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "price",
        "type": "uint256"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  }
] as const