import { VEJOE_STAKING_ADDRESS } from '@fusionx/sdk'
import veJoeImage from 'assets/stake/veJoe.webp'
import { ZERO_ADDRESS } from 'constants/tokens'
import { ethers } from 'ethers'
import useVeJoeAnalytics from 'hooks/stake/useVeJoeAnalytics'
import useVeJoeStaking, {
  useVeJoeUserPosition
} from 'hooks/stake/useVeJoeStaking'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'

import { CurrencyTab } from '.'
import NAStakeCard from './NotAvailableStakeCard'
import { JoeUsdProps } from './SJoeCard'
import StakeCard from './StakeCard'

const VeJoeCard = ({ currencyTab, joeUsd }: JoeUsdProps) => {
  const { userBalance } = useVeJoeUserPosition()
  const { totalStaked, totalSupply } = useVeJoeStaking()
  const { medianBalance } = useVeJoeAnalytics()

  // check if not available on selected chain
  const chainId = useChainId()
  const isNotAvailable = VEJOE_STAKING_ADDRESS[chainId] === ZERO_ADDRESS

  // user balance
  const balanceNum = userBalance
    ? Number(ethers.utils.formatEther(userBalance))
    : 0
  const fmtBalance = formattedNum(balanceNum, false, 2)
  const fmtBalanceUsd = formattedNum(balanceNum * (joeUsd ?? 0), true, 2)

  // total staked
  const totalStakedNum = totalStaked
    ? Number(ethers.utils.formatEther(totalStaked))
    : 0
  const totalStakedUSD = totalStakedNum * (joeUsd ?? 0)
  const fmtTotalStaked = formattedNum(totalStakedNum, false, 2)
  const fmtTotalStakedUSD = formattedNum(totalStakedUSD, true, 2)

  // total supply
  const fmtTotalSupply = totalSupply
    ? `${formattedNum(ethers.utils.formatEther(totalSupply), false, 2)} veJOE`
    : ''

  // median balance
  const fmtMedianBalance = medianBalance
    ? `${formattedNum(medianBalance, false, 2)} veJOE`
    : ''

  // link
  const link = `${getChainSlug(chainId)}/stake/vejoe`

  return isNotAvailable ? (
    <NAStakeCard image={veJoeImage} title="veJOE" />
  ) : (
    <StakeCard
      image={veJoeImage}
      linkUrl={link}
      title="veJOE"
      tag="Boost Your Rewards"
      label1={`Total Staked (${currencyTab})`}
      text1={
        currencyTab == CurrencyTab.JOE ? fmtTotalStaked : fmtTotalStakedUSD
      }
      label2={`Your Stake (${currencyTab})`}
      text2={currencyTab == CurrencyTab.JOE ? fmtBalance : fmtBalanceUsd}
      label3="Total Supply"
      text3={fmtTotalSupply}
      label4="Median Balance"
      text4={fmtMedianBalance}
    />
  )
}

export default VeJoeCard
