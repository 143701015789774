import {
  ChainId,
  Currency,
  CurrencyAmount,
  JSBI,
  Percent,
  Token,
  TokenAmount,
  WNATIVE
} from '@fusionx/sdk'
import { TradeV2 } from '@fusionx/sdk-v2'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils.js'

type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const USDTe = {
  [ChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0xAb231A5744C8E6c45481754928cCfFFFD4aa0732',
    6,
    'USDT.e',
    'Tether USD'
  ),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    6,
    'USDT.e',
    'Tether USD'
  )
}

export const USDT = {
  [ChainId.FUJI]: USDTe[ChainId.FUJI],
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
    6,
    'USDT',
    'Tether USD'
  ),
  [ChainId.ARB_GOERLI]: new Token(
    ChainId.ARB_GOERLI,
    '0xf450749aeA1c5feF27Ae0237C56FecC43f6bE244',
    6,
    'USDT',
    'Tether USD'
  ),
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
    6,
    'USDT',
    'Tether USD'
  ),
  [ChainId.BNB_CHAIN]: new Token(
    ChainId.BNB_CHAIN,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'Tether USD'
  ),
  [ChainId.BNB_TESTNET]: new Token(
    ChainId.BNB_TESTNET,
    '0x0F0D81E7a4a8b8c5295299785840887046C3810a',
    18,
    'USDT',
    'Tether USD'
  ),
  [ChainId.MANTLE_TESTNET]: new Token(
    ChainId.MANTLE_TESTNET,
    '0xa9b72cCC9968aFeC98A96239B5AA48d828e8D827',
    6,
    'USDT',
    'Tether USD'
  )
}

export const USDCe = {
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    6,
    'USDC.e',
    'USD Coin'
  )
}

export const USDC: { [chainId in ChainId]: Token } = {
  [ChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0xB6076C93701D6a07266c31066B298AeC6dd65c2d',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.ARB_GOERLI]: new Token(
    ChainId.ARB_GOERLI,
    '0xb3482A25a12e5261b02E0acc5b96c656358a4086',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.ARBITRUM_ONE]: new Token(
    ChainId.ARBITRUM_ONE,
    '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.BNB_CHAIN]: new Token(
    ChainId.BNB_CHAIN,
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    18,
    'USDC',
    'USD Coin'
  ),
  [ChainId.BNB_TESTNET]: new Token(
    ChainId.BNB_TESTNET,
    '0xa3a886700f2157b0698AC5ccCAda09C3b02d41a1',
    18,
    'USDC',
    'USD Coin'
  ),
  [ChainId.MANTLE_TESTNET]: new Token(
    ChainId.MANTLE_TESTNET,
    '0xc92747b1e4Bd5F89BBB66bAE657268a5F4c4850C',
    6,
    'USDC',
    'USD Coin'
  )
}

export const BUSD = {
  [ChainId.BNB_CHAIN]: new Token(
    ChainId.BNB_CHAIN,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD'
  ),
  [ChainId.BNB_TESTNET]: new Token(
    ChainId.BNB_TESTNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD'
  )
}

export const WNATIVE_ONLY: ChainTokenList = {
  [ChainId.FUJI]: [WNATIVE[ChainId.FUJI]],
  [ChainId.AVALANCHE]: [WNATIVE[ChainId.AVALANCHE]],
  [ChainId.ARBITRUM_ONE]: [WNATIVE[ChainId.ARBITRUM_ONE]],
  [ChainId.ARB_GOERLI]: [WNATIVE[ChainId.ARB_GOERLI]],
  [ChainId.BNB_CHAIN]: [WNATIVE[ChainId.BNB_CHAIN]],
  [ChainId.BNB_TESTNET]: [WNATIVE[ChainId.BNB_TESTNET]],
  [ChainId.MANTLE_TESTNET]: [WNATIVE[ChainId.MANTLE_TESTNET]]
}

export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WNATIVE_ONLY,
  [ChainId.AVALANCHE]: [
    ...WNATIVE_ONLY[ChainId.AVALANCHE],
    USDCe[ChainId.AVALANCHE],
    USDC[ChainId.AVALANCHE],
    USDTe[ChainId.AVALANCHE],
    USDT[ChainId.AVALANCHE]
  ],
  [ChainId.FUJI]: [
    ...WNATIVE_ONLY[ChainId.FUJI],
    USDC[ChainId.FUJI],
    USDTe[ChainId.FUJI]
  ],
  [ChainId.ARB_GOERLI]: [
    ...WNATIVE_ONLY[ChainId.ARB_GOERLI],
    USDC[ChainId.ARB_GOERLI],
    USDT[ChainId.ARB_GOERLI]
  ],
  [ChainId.ARBITRUM_ONE]: [
    ...WNATIVE_ONLY[ChainId.ARBITRUM_ONE],
    USDC[ChainId.ARBITRUM_ONE],
    USDT[ChainId.ARBITRUM_ONE]
  ],
  [ChainId.BNB_CHAIN]: [
    ...WNATIVE_ONLY[ChainId.BNB_CHAIN],
    BUSD[ChainId.BNB_CHAIN],
    USDT[ChainId.BNB_CHAIN]
  ],
  [ChainId.BNB_TESTNET]: [
    ...WNATIVE_ONLY[ChainId.BNB_TESTNET],
    BUSD[ChainId.BNB_TESTNET],
    USDT[ChainId.BNB_TESTNET]
  ],
  [ChainId.MANTLE_TESTNET]: [
    ...WNATIVE_ONLY[ChainId.MANTLE_TESTNET],
    USDC[ChainId.MANTLE_TESTNET],
    USDT[ChainId.MANTLE_TESTNET]
  ]
}

export const tryParseAmount = (
  value?: string,
  currency?: Currency,
  parseZero?: boolean
): CurrencyAmount | undefined => {

  if (!value || !currency) {
    return undefined
  }
  console.log("tryPareAmount Called");

  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0' || parseZero) {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.error(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export const getCurrencyAmount = (currency?: Currency, amount?: BigNumber) => {
  if (!amount || !currency) return undefined
  return currency instanceof Token
    ? new TokenAmount(currency, JSBI.BigInt(amount.toString()))
    : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(amount.toString()))
}

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function computeSlippageAdjustedAmounts(
  trade: TradeV2 | undefined,
  allowedSlippage: number
): { [field in Field]?: CurrencyAmount } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct)
  }
}
