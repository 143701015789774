
import { BigNumber } from "ethers";

// const price = Number(bin.priceXY.toFixed(tokenB.decimals))// * (10 ** (18 - tokenB.decimals))

// const percentage = ((Number(amountY.toFixed()) * 100) / ((Number(amountY.toFixed()) * price) + Number(amountX.toFixed())))

/**
 * Computes the price for the given binId,
 * parses the decimals given tokens A and B
 * and finally formats the output given the number of decimals
 * @param {number} amountX
 * @param {number} amountY
 * @param {number} price
 * @returns
 */
export const percentageTokenYCompareInTokenX = (
    amountX: number,
    amountY: number,
    price: number
) => {
    console.log("amountYValueInAmountX", amountX, amountY, price)
    const percentage = amountX !== 0 ? ((amountY * price * 100) / ((amountY * price) + amountX)) : 0

    // const amountXF = ethers.utils.formatUnits(amountX)
    // const amountYF = ethers.utils.formatUnits(amountY)
    // const priceF = ethers.utils.formatUnits(price)
    // console.log("amountYValueInAmountX", amountX, amountY, priceF)
    // const amountYValueInAmountX = BigNumber.from(amountYF).mul(BigNumber.from(priceF.toString()));
    // console.log("amountYValueInAmountX2", amountX, amountY, price, amountYValueInAmountX)
    // const percentage = amountXF !== "0" ?
    //     BigNumber.from(amountYValueInAmountX.mul(100)).div(BigNumber.from(amountYValueInAmountX).add(amountXF)) : BigNumber.from(0)
    return BigNumber.from(percentage.toFixed(0));

};
