import { ethers } from 'ethers'
import useBalances from 'hooks/useBalances'
import useChainId from 'hooks/useChainId'
import { useMemo } from 'react'
import { TokenInfoTag } from 'types/tokensList'

import useFetchTokensList from './useFetchTokensList'
import useNativeTokenInfo from './useNativeTokenInfo'
import useTokenSearch from './useTokenSearch'

interface UseTokensListProps {
  query: string
  activeTag?: TokenInfoTag
}

const useTokensList = ({ activeTag, query }: UseTokensListProps) => {
  const chainId = useChainId()
  const { data } = useFetchTokensList()
  const tokenFoundByAddress = useTokenSearch({ query })
  const tokens = useMemo(
    () => data?.tokens.filter((token) => token.chainId.toString().toLowerCase() === chainId.toString().toLowerCase()) ?? [],
    [data, chainId]
  )
  const { nativeTokenInfo } = useNativeTokenInfo()
  const { data: balances, isLoading: isLoadingBalances } = useBalances({
    erc20Tokens: tokens
      .map((token) => token.address)
      .filter(Boolean) as `0x${string}`[]
  })
  const tokensWithBalance = useMemo(
    () =>
      tokens.map((token, i) => {
        const balance = balances?.[i]
        return {
          ...token,
          balance: balance
            ? ethers.utils.formatUnits(balance, token.decimals)
            : undefined
        }
      }),
    [balances, tokens]
  )
  console.log("useTokensList", tokens, tokensWithBalance);


  const filteredTokens = useMemo(() => {
    const lowercasedQuery = query.toLowerCase()
    const results = tokensWithBalance
      .filter((token) =>
        query.length > 0
          ? token.symbol.toLowerCase().includes(lowercasedQuery) ||
          token.name.toLowerCase().includes(lowercasedQuery)
          : //(activeTag && token.tags.includes(activeTag)) ||
          !activeTag ||
          (activeTag === TokenInfoTag.USER &&
            token.balance &&
            Number(token.balance) > 0)
      )
      .sort((a, b) => Number(b.balance ?? '0') - Number(a.balance ?? '0'))
    return nativeTokenInfo && !query ? [nativeTokenInfo, ...results] : results
  }, [tokensWithBalance, activeTag, nativeTokenInfo, query])

  return {
    isLoadingBalances,
    tokenFoundByAddress,
    tokens: filteredTokens
  }
}

export default useTokensList
