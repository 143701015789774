import { useInfiniteQuery } from '@tanstack/react-query'
import { EXCHANGE_SUBGRAPH } from '@fusionx/sdk'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { pairsQuery } from 'graphql/dexV1'
import { request } from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { Pair } from 'types/poolV1'
import { convertPairsToPools } from 'utils/poolV1'

dayjs.extend(utc)

interface UsePoolV1Props {
  enabled: boolean
  first: number
}

const usePoolsV1 = ({ enabled, first }: UsePoolV1Props) => {
  const chainId = useChainId()
  const subgraphUrl = EXCHANGE_SUBGRAPH[chainId]
  const utcOneDayBack = dayjs().utc().startOf('hour').subtract(1, 'day').unix()
  const result = useInfiniteQuery<{ pairs: Pair[] }>(
    ['PoolsV1Query', subgraphUrl, first],
    ({ pageParam = 1 }) =>
      request(subgraphUrl, pairsQuery, {
        dateAfter: utcOneDayBack,
        first,
        skip: (pageParam - 1) * first
      }),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.pairs.length % first === 0 ? allPages.length + 1 : undefined
    }
  )
  return {
    ...result,
    data: convertPairsToPools({
      pairs: result.data?.pages.map((page) => page.pairs).flat() ?? []
    })
  }
}

export default usePoolsV1
