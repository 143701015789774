import { ChainId, Currency } from '@fusionx/sdk'
import { BigNumber, ethers } from 'ethers'

const getNbOfBinsForCurrency0 = (
  start: number,
  end: number,
  activeBinId: number
): number => {
  return end < activeBinId
    ? 0
    : start < activeBinId
      ? end - activeBinId + 1
      : end - start + 1
}

const getNbOfBinsForCurrency1 = (
  start: number,
  end: number,
  activeBinId: number
): number => {
  return start > activeBinId
    ? 0
    : activeBinId > end
      ? end - start + 1
      : activeBinId - start + 1
}

export interface AddUniformLiquidityBatch {
  amount0: BigNumber
  amount1: BigNumber
  binRange: number[]
}

export const getAddUniformLiquidityBatches = (
  chainId: ChainId,
  binPerBatch: number,
  binRange?: number[],
  activeBinId?: number,
  currency0?: Currency,
  currency1?: Currency,
  totalAmount0?: BigNumber,
  totalAmount1?: BigNumber
): AddUniformLiquidityBatch[] | undefined => {
  if (
    !binRange ||
    !activeBinId ||
    !currency0 ||
    !currency1 ||
    !totalAmount0 ||
    !totalAmount1
  ) {
    return undefined
  }

  const start = binRange[0]
  const end = binRange[1]

  const isActiveBinTargetAdd =
    binRange.length === 2 &&
    binRange[0] === binRange[1] &&
    binRange[0] === activeBinId

  if (
    start > end ||
    binRange.includes(NaN) ||
    !!binRange.find((val) => !isFinite(val)) ||
    (!isActiveBinTargetAdd && totalAmount1.eq(0) && start <= activeBinId) ||
    (!isActiveBinTargetAdd && totalAmount0.eq(0) && start >= activeBinId) ||
    (isActiveBinTargetAdd && totalAmount1.eq(0) && totalAmount0.eq(0))
  ) {
    return undefined
  }

  const nbOfBins = binRange[1] - binRange[0] + 1
  const nbOfBatchs = binRange ? Math.ceil(nbOfBins / binPerBatch) : 1

  const nbBinsToFillWithCurrency0 = getNbOfBinsForCurrency0(
    start,
    end,
    activeBinId
  )

  const nbBinsToFillWithCurrency1 = getNbOfBinsForCurrency1(
    start,
    end,
    activeBinId
  )

  return [...Array(nbOfBatchs).keys()].map((i) => {
    const offset = i === 0 ? 0 : 1
    const batchStart = start + i * binPerBatch + offset
    const batchEnd = Math.min(start + (i + 1) * binPerBatch, end)

    const nbBinsToFillWithCurrency0ForBatch = getNbOfBinsForCurrency0(
      batchStart,
      batchEnd,
      activeBinId
    )

    const nbBinsToFillWithCurrency1ForBatch = getNbOfBinsForCurrency1(
      batchStart,
      batchEnd,
      activeBinId
    )

    return {
      amount0:
        nbBinsToFillWithCurrency0 === 0
          ? ethers.constants.Zero
          : totalAmount0
            .mul(nbBinsToFillWithCurrency0ForBatch)
            .div(nbBinsToFillWithCurrency0),
      amount1:
        nbBinsToFillWithCurrency1 === 0
          ? ethers.constants.Zero
          : totalAmount1
            .mul(nbBinsToFillWithCurrency1ForBatch)
            .div(nbBinsToFillWithCurrency1),
      binRange: [batchStart, batchEnd]
    }
  })
}
