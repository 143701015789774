import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { UserLBBinPosition } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertLBPositionsToUserLBPositions } from 'utils/poolV2'

interface UseLBPairBalancesProps {
  lbPairAddress?: string
  owner?: string
}

const useLBPairBalances = ({
  lbPairAddress,
  owner
}: UseLBPairBalancesProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const fetchUserBinLiquidity = useDexbarnGet<UserLBBinPosition[]>(
    `/v1/user/bin-position/${chainId}/?userAddress=${owner?.toLowerCase()}&poolAddress=${lbPairAddress?.toLowerCase()}&pageSize=1000`
  )

  const result = useQuery<UserLBBinPosition[]>(
    ['UserBinLiquidity', chain, owner, lbPairAddress],
    () => fetchUserBinLiquidity(),
    { enabled: !!lbPairAddress && !!owner, keepPreviousData: true }
  )

  console.log("useLBPairBalances", result);

  const userLbPositions = result.data
    ? convertLBPositionsToUserLBPositions({
      userLBPositions: result.data
    })
    : undefined

  return {
    ...result,
    data: userLbPositions
  }
}

export default useLBPairBalances
