import { useQuery } from '@tanstack/react-query'
import { DEX_V2_SUBGRAPH } from '@fusionx/sdk-v2'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { FEATURE_FLAGS } from 'featureFlags'
import { userVaultPositionsQuery } from 'graphql/dexV2'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { UserVaultPosition, VaultSubgraphModels } from 'types/vault'
import { convertSubgraphVaultIntoVault } from 'utils/vault'
import { useAccount } from 'wagmi'

dayjs.extend(utc)

const useUserVaultPositions = () => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const subgraphUrl = DEX_V2_SUBGRAPH[chainId]
  const utcSevenDayBack = dayjs().utc().startOf('day').subtract(7, 'day').unix()

  const result = useQuery<{
    vaultUserPositions: VaultSubgraphModels.UserVaultPosition[]
  }>(
    ['UserVaultPositionsQuery', subgraphUrl, account],
    () =>
      request(subgraphUrl, userVaultPositionsQuery, {
        date: utcSevenDayBack,
        user: account?.toLowerCase()
      }),
    { enabled: !!account && FEATURE_FLAGS.autoPools }
  )

  const vaultUserPositions = result.data?.vaultUserPositions ?? []
  const data: UserVaultPosition[] = vaultUserPositions.map(
    (vaultUserPosition) => ({
      ...vaultUserPosition,
      vault: convertSubgraphVaultIntoVault(vaultUserPosition.vault, chainId)
    })
  )

  return {
    ...result,
    data,
    isLoading: result.isLoading && FEATURE_FLAGS.autoPools
  }
}

export default useUserVaultPositions
