import { Box, Center, Flex, Heading, Hide, Tab, TabList, Tabs, Text, VStack, useToken } from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import React, { useMemo, useState } from 'react'
import {
    Bar,
    BarChart,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts'
import { FeesEarnedData } from 'types/poolV2'
import { ChartRange, getTimestempByChartRange } from 'utils/analytics'
import { formattedNum } from 'utils/format'
import { format } from 'date-fns'

interface FeesEarnedChartProps {
    title: string
    subTitle?: string
    activeBinId: number
    feesEarned: FeesEarnedData[]
    currency0: Currency
    currency1: Currency
    bottomContent?: React.ReactNode
    chartRanges?: ChartRange[]
    chartRange?: ChartRange
    setChartRange?: (value: ChartRange) => void
    hideTab?: boolean
}

const FeesEarnedChart = ({
    title,
    subTitle,
    activeBinId,
    feesEarned,
    currency0,
    currency1,
    bottomContent,
    chartRanges,
    chartRange,
    setChartRange,
    hideTab
}: FeesEarnedChartProps) => {
    const [
        graphPurpleDark,
        graphPurpleLight,
        graphGreenDark,
        graphGreenLight,
        textSecondary
    ] = useToken('colors', [
        'graphPurpleDark',
        'graphPurpleLight',
        'graphGreenDark',
        'graphGreenLight',
        'textSecondary'
    ])

    const [focusBar, setFocusBar] = useState<number | undefined>()
    const dataKey = 'accruedFeesX'

    const yAxisDomain = useMemo(() => {
        const yAxisMaxValue = feesEarned
            .map((data: any) => data[dataKey])
            .reduce((a, b) => Math.max(a, b), -Infinity)
        return [0, yAxisMaxValue]
    }, [feesEarned])

    const timespan = chartRange ? getTimestempByChartRange(chartRange) : 0;

    console.log(timespan);

    const filteredData = feesEarned
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
        .filter((d: FeesEarnedData) => timespan <= d.timestamp)
        .map((d: FeesEarnedData) => ({
            ...d,
            fmtDate: format(new Date(d.timestamp * 1000), 'LLL d, y'),
            fmtValue: d.accruedFeesX,//formattedNum(d.value, usdValue),
            title: new Date(d.timestamp).toUTCString()
        }))

    return (
        <Box pos="relative">
            <Flex justify="space-between" align="center" mb={{ base: 0, sm: 8 }}>
                <VStack align={'start'}>
                    <Heading size="md">
                        {title}
                    </Heading>
                    <Text fontSize="md" color="textSecondary">
                        {subTitle}
                    </Text>
                </VStack>
                {!hideTab && chartRanges && chartRange && setChartRange && (
                    <Hide below="md">
                        <Tabs
                            variant="soft-rounded"
                            colorScheme="accent"
                            index={chartRanges.indexOf(chartRange)}
                            onChange={(index) => setChartRange(chartRanges[index])}
                        >
                            <TabList>
                                {chartRanges.map((range, i) => (
                                    <Tab key={i}>{range}</Tab>
                                ))}
                            </TabList>
                        </Tabs>
                    </Hide>
                )}
            </Flex>
            <Box h="160px">
                <ResponsiveContainer width="99%">
                    <BarChart
                        data={filteredData}
                        onMouseMove={(state) => {
                            setFocusBar(
                                state.isTooltipActive ? state.activeTooltipIndex : undefined
                            )
                        }}
                        onMouseLeave={() => {
                            setFocusBar(undefined)
                        }}
                        margin={{ bottom: 16 }}
                    >
                        <Tooltip
                            wrapperStyle={{ outline: 'none' }}
                            cursor={{ fill: 'transparent' }}
                        // content={
                        //     <BinsTradedChartTooltip
                        //         dataKey={dataKey}
                        //         symbolX={currency0.symbol ?? ''}
                        //         symbolY={currency1.symbol ?? ''}
                        //     />
                        // }
                        />
                        <YAxis
                            width={80}
                            axisLine={false}
                            tickLine={false}
                            tickSize={0}
                            tickMargin={20}
                            domain={yAxisDomain}
                            fontSize="12px"
                            tick={{ fill: textSecondary }}
                            tickFormatter={(val) => formattedNum(val, true, 5)}
                        />
                        <XAxis
                            xAxisId="0"
                            tickSize={0}
                            minTickGap={16}
                            axisLine={false}
                            dataKey="priceXY"
                            tickFormatter={(val) => formattedNum(val, false, 5)}
                            fontSize="12px"
                            fontWeight={500}
                            tick={{ fill: textSecondary }}
                            tickMargin={8}
                        />
                        <defs>
                            <linearGradient id="activeId" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor={graphPurpleDark} stopOpacity={1} />
                                <stop
                                    offset="100%"
                                    stopColor={graphPurpleDark}
                                    stopOpacity={0.01}
                                />
                            </linearGradient>
                            <linearGradient id="nonActiveId" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor={graphGreenDark} stopOpacity={1} />
                                <stop
                                    offset="100%"
                                    stopColor={graphGreenDark}
                                    stopOpacity={0.01}
                                />
                            </linearGradient>
                            <linearGradient
                                opacity={0.5}
                                id="activeIdFocus"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop offset="0%" stopColor={graphGreenLight} stopOpacity={1} />
                                <stop
                                    offset="100%"
                                    stopColor={graphGreenLight}
                                    stopOpacity={0}
                                />
                            </linearGradient>
                            <linearGradient
                                opacity={0.5}
                                id="nonActiveIdFocus"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="0%"
                                    stopColor={graphPurpleLight}
                                    stopOpacity={1}
                                />
                                <stop
                                    offset="100%"
                                    stopColor={graphPurpleLight}
                                    stopOpacity={0}
                                />
                            </linearGradient>
                        </defs>
                        <Bar dataKey={dataKey} radius={2}>
                            {filteredData.map((data, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={
                                        focusBar === index && data.binId === activeBinId
                                            ? 'url(#activeIdFocus)'
                                            : focusBar === index && data.binId !== activeBinId
                                                ? 'url(#nonActiveIdFocus)'
                                                : data.binId === activeBinId
                                                    ? 'url(#activeId)'
                                                    : 'url(#nonActiveId)'
                                    }
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Box>
            <Center >
                {bottomContent}
            </Center>
        </Box>
    )
}

export default FeesEarnedChart
