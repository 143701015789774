import { Flex, Heading, HStack, Image, VStack } from '@chakra-ui/react'
import React from 'react'

interface NAStakeCardProps {
  image: string
  title: string
}

const NAStakeCard = ({ image, title }: NAStakeCardProps) => {
  return (
    <Flex
      flexDir="column"
      bg="bgCard"
      borderColor="border"
      borderWidth={1}
      borderRadius="xl"
      p={4}
      cursor="pointer"
      _hover={{ bg: 'hover' }}
    >
      <HStack>
        <Image boxSize={8} src={image} />
        <Heading fontSize="md">{title}</Heading>
      </HStack>
      <VStack height={32} justifyContent="center" marginTop={4}>
        <Heading fontSize="lg">
          Not yet supported on your selected chain
        </Heading>
      </VStack>
    </Flex>
  )
}

export default NAStakeCard
