export const DexLensABI = [
  {
    inputs: [
      {
        internalType: 'contract ILBFactory',
        name: 'lbFactory',
        type: 'address'
      },
      {
        internalType: 'contract ILBLegacyFactory',
        name: 'lbLegacyFactory',
        type: 'address'
      },
      {
        internalType: 'contract IJoeFactory',
        name: 'joeFactory',
        type: 'address'
      },
      { internalType: 'address', name: 'wnative', type: 'address' }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  { inputs: [], name: 'JoeDexLens__AlreadyInitialized', type: 'error' },
  {
    inputs: [
      { internalType: 'address', name: 'pair', type: 'address' },
      { internalType: 'address', name: 'collateral', type: 'address' }
    ],
    name: 'JoeDexLens__CollateralNotInPair',
    type: 'error'
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'address', name: 'dataFeed', type: 'address' }
    ],
    name: 'JoeDexLens__DataFeedAlreadyAdded',
    type: 'error'
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'address', name: 'dataFeed', type: 'address' }
    ],
    name: 'JoeDexLens__DataFeedNotInSet',
    type: 'error'
  },
  { inputs: [], name: 'JoeDexLens__InvalidChainLinkPrice', type: 'error' },
  { inputs: [], name: 'JoeDexLens__InvalidDataFeed', type: 'error' },
  { inputs: [], name: 'JoeDexLens__LengthsMismatch', type: 'error' },
  { inputs: [], name: 'JoeDexLens__NativeToken', type: 'error' },
  { inputs: [], name: 'JoeDexLens__NullWeight', type: 'error' },
  { inputs: [], name: 'JoeDexLens__SameDataFeed', type: 'error' },
  { inputs: [], name: 'JoeDexLens__SameTokens', type: 'error' },
  {
    inputs: [
      { internalType: 'address', name: 'pair', type: 'address' },
      { internalType: 'address', name: 'token', type: 'address' }
    ],
    name: 'JoeDexLens__TokenNotInPair',
    type: 'error'
  },
  { inputs: [], name: 'JoeDexLens__UnknownDataFeedType', type: 'error' },
  { inputs: [], name: 'JoeDexLens__V1ContractNotSet', type: 'error' },
  { inputs: [], name: 'JoeDexLens__V2ContractNotSet', type: 'error' },
  { inputs: [], name: 'JoeDexLens__V2_1ContractNotSet', type: 'error' },
  { inputs: [], name: 'JoeDexLens__ZeroAddress', type: 'error' },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'bytes32', name: 'role', type: 'bytes32' }
    ],
    name: 'SafeAccessControlEnumerable__AccountAlreadyHasRole',
    type: 'error'
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'bytes32', name: 'role', type: 'bytes32' }
    ],
    name: 'SafeAccessControlEnumerable__AccountDoesNotHaveRole',
    type: 'error'
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'bytes32', name: 'role', type: 'bytes32' }
    ],
    name: 'SafeAccessControlEnumerable__OnlyOwnerOrRole',
    type: 'error'
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'bytes32', name: 'role', type: 'bytes32' }
    ],
    name: 'SafeAccessControlEnumerable__OnlyRole',
    type: 'error'
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'bytes32', name: 'role', type: 'bytes32' }
    ],
    name: 'SafeAccessControlEnumerable__RoleAlreadyGranted',
    type: 'error'
  },
  { inputs: [], name: 'SafeOwnable__OnlyOwner', type: 'error' },
  { inputs: [], name: 'SafeOwnable__OnlyPendingOwner', type: 'error' },
  {
    inputs: [
      { internalType: 'uint256', name: 'x', type: 'uint256' },
      { internalType: 'int256', name: 'y', type: 'int256' }
    ],
    name: 'Uint128x128Math__PowUnderflow',
    type: 'error'
  },
  { inputs: [], name: 'Uint256x256Math__MulShiftOverflow', type: 'error' },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'token',
        type: 'address'
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'collateralAddress',
            type: 'address'
          },
          { internalType: 'address', name: 'dfAddress', type: 'address' },
          { internalType: 'uint88', name: 'dfWeight', type: 'uint88' },
          {
            internalType: 'enum IJoeDexLens.DataFeedType',
            name: 'dfType',
            type: 'uint8'
          }
        ],
        indexed: false,
        internalType: 'struct IJoeDexLens.DataFeed',
        name: 'dataFeed',
        type: 'tuple'
      }
    ],
    name: 'DataFeedAdded',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'token',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'dfAddress',
        type: 'address'
      }
    ],
    name: 'DataFeedRemoved',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'token',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'dfAddress',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'weight',
        type: 'uint256'
      }
    ],
    name: 'DataFeedsWeightSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'dfAddress',
        type: 'address'
      }
    ],
    name: 'NativeDataFeedSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'pendingOwner',
        type: 'address'
      }
    ],
    name: 'PendingOwnerSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address'
      },
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'adminRole',
        type: 'bytes32'
      }
    ],
    name: 'RoleAdminSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address'
      },
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'RoleGranted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'sender',
        type: 'address'
      },
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'RoleRevoked',
    type: 'event'
  },
  {
    inputs: [],
    name: 'DATA_FEED_MANAGER_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      {
        components: [
          {
            internalType: 'address',
            name: 'collateralAddress',
            type: 'address'
          },
          { internalType: 'address', name: 'dfAddress', type: 'address' },
          { internalType: 'uint88', name: 'dfWeight', type: 'uint88' },
          {
            internalType: 'enum IJoeDexLens.DataFeedType',
            name: 'dfType',
            type: 'uint8'
          }
        ],
        internalType: 'struct IJoeDexLens.DataFeed',
        name: 'dataFeed',
        type: 'tuple'
      }
    ],
    name: 'addDataFeed',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'tokens', type: 'address[]' },
      {
        components: [
          {
            internalType: 'address',
            name: 'collateralAddress',
            type: 'address'
          },
          { internalType: 'address', name: 'dfAddress', type: 'address' },
          { internalType: 'uint88', name: 'dfWeight', type: 'uint88' },
          {
            internalType: 'enum IJoeDexLens.DataFeedType',
            name: 'dfType',
            type: 'uint8'
          }
        ],
        internalType: 'struct IJoeDexLens.DataFeed[]',
        name: 'dataFeeds',
        type: 'tuple[]'
      }
    ],
    name: 'addDataFeeds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'becomeOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'getDataFeeds',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'collateralAddress',
            type: 'address'
          },
          { internalType: 'address', name: 'dfAddress', type: 'address' },
          { internalType: 'uint88', name: 'dfWeight', type: 'uint88' },
          {
            internalType: 'enum IJoeDexLens.DataFeedType',
            name: 'dfType',
            type: 'uint8'
          }
        ],
        internalType: 'struct IJoeDexLens.DataFeed[]',
        name: 'dataFeeds',
        type: 'tuple[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getFactoryV1',
    outputs: [
      {
        internalType: 'contract IJoeFactory',
        name: 'factoryV1',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getFactoryV2_1',
    outputs: [
      {
        internalType: 'contract ILBFactory',
        name: 'factoryV2',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getLegacyFactoryV2',
    outputs: [
      {
        internalType: 'contract ILBLegacyFactory',
        name: 'legacyFactoryV2',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'uint256', name: 'index', type: 'uint256' }
    ],
    name: 'getRoleMemberAt',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleMemberCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'getTokenPriceNative',
    outputs: [{ internalType: 'uint256', name: 'price', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
    name: 'getTokenPriceUSD',
    outputs: [{ internalType: 'uint256', name: 'price', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address[]', name: 'tokens', type: 'address[]' }],
    name: 'getTokensPricesNative',
    outputs: [{ internalType: 'uint256[]', name: 'prices', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address[]', name: 'tokens', type: 'address[]' }],
    name: 'getTokensPricesUSD',
    outputs: [{ internalType: 'uint256[]', name: 'prices', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getWNative',
    outputs: [{ internalType: 'address', name: 'wNative', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' }
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' }
    ],
    name: 'hasRole',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'aggregator', type: 'address' }],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'pendingOwner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'address', name: 'dfAddress', type: 'address' }
    ],
    name: 'removeDataFeed',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'tokens', type: 'address[]' },
      { internalType: 'address[]', name: 'dfAddresses', type: 'address[]' }
    ],
    name: 'removeDataFeeds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' }
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'token', type: 'address' },
      { internalType: 'address', name: 'dfAddress', type: 'address' },
      { internalType: 'uint88', name: 'newWeight', type: 'uint88' }
    ],
    name: 'setDataFeedWeight',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address[]', name: 'tokens', type: 'address[]' },
      { internalType: 'address[]', name: 'dfAddresses', type: 'address[]' },
      { internalType: 'uint88[]', name: 'newWeights', type: 'uint88[]' }
    ],
    name: 'setDataFeedsWeights',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'aggregator', type: 'address' }],
    name: 'setNativeDataFeed',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'setPendingOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
] as const
