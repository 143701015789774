import { JoePairABI, Pair, Token, TokenAmount } from '@fusionx/sdk'
import { ethers } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import { Pool } from 'types/pool'
import { useContractRead } from 'wagmi'

interface UsePairProps {
  pool?: Pool
}

const usePair = ({ pool }: UsePairProps) => {
  const chainId = useChainId()

  const { data: reserves, ...result } = useContractRead({
    abi: JoePairABI,
    address: pool ? getAddress(pool.pairAddress) : undefined,
    chainId,
    enabled: !!pool,
    functionName: 'getReserves'
  })
  const reserve0 = reserves?.[0]
  const reserve1 = reserves?.[1]

  const token0 = pool?.tokenX.address
    ? new Token(
      chainId,
      ethers.utils.getAddress(pool.tokenX.address),
      Number(pool.tokenX.decimals),
      pool.tokenX.symbol
    )
    : undefined
  const tokenAmount0 =
    reserve0 && token0
      ? new TokenAmount(token0, reserve0.toString())
      : undefined

  const token1 = pool?.tokenY.address
    ? new Token(
      chainId,
      ethers.utils.getAddress(pool.tokenY.address),
      pool.tokenY.decimals,
      pool.tokenY.symbol
    )
    : undefined
  const tokenAmount1 =
    reserve1 && token1
      ? new TokenAmount(token1, reserve1.toString())
      : undefined

  return {
    ...result,
    data:
      tokenAmount0 && tokenAmount1
        ? new Pair(tokenAmount0, tokenAmount1, chainId)
        : undefined
  }
}

export default usePair
