import { HStack, Tag, Text } from '@chakra-ui/react'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Token } from 'types/pool'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface PoolTableNameColumnProps {
  fees: string
  token0: Token
  token1: Token
  isRewarded?: boolean
}

const PoolTableNameColumn = ({
  fees,
  isRewarded,
  token0,
  token1
}: PoolTableNameColumnProps) => {
  const chainId = useChainId()
  const { nativeCurrency } = useActiveChain()

  const isToken0WrappedNativeCurrency = isWrappedNativeCurrency(
    token0.address,
    chainId
  )
  const isToken1WrappedNativeCurrency = isWrappedNativeCurrency(
    token1.address,
    chainId
  )

  return (
    <HStack spacing={2}>
      <DoubleCurrencyLogo
        address0={isToken0WrappedNativeCurrency ? undefined : token0.address}
        symbol0={
          isToken0WrappedNativeCurrency ? nativeCurrency.symbol : token0.symbol
        }
        address1={isToken1WrappedNativeCurrency ? undefined : token1.address}
        symbol1={
          isToken1WrappedNativeCurrency ? nativeCurrency.symbol : token1.symbol
        }
        boxSize={6}
        ml="-2"
      />
      <HStack>
        <Text fontWeight="bold" fontSize="sm">{`${
          isToken0WrappedNativeCurrency ? nativeCurrency.symbol : token0.symbol
        }-${
          isToken1WrappedNativeCurrency ? nativeCurrency.symbol : token1.symbol
        }`}</Text>
        <Tag
          rounded="full"
          size="sm"
          fontWeight="bold"
          bg="bgTertiary"
          textColor="textPrimary"
        >
          {fees}
        </Tag>
        {isRewarded ? (
          <Tag rounded="full" size="sm" bg="green.400" textColor="white">
            Rewards
          </Tag>
        ) : null}
      </HStack>
    </HStack>
  )
}

export default PoolTableNameColumn
