import { JSBI, Percent, Token, TokenAmount } from '@fusionx/sdk'
import { Bin } from '@fusionx/sdk-v2'
import { ethers } from 'ethers'
import { Pool as DexbarnPool, UserLBBinPosition } from 'types/dexbarn'
import { Pool } from 'types/pool'
import { LBPairDistribution, LBPairUserBalances } from 'types/poolV2'

import { computeAndParsePriceFromBin } from './prices'
import { percentageTokenYCompareInTokenX } from './percentage'

export const convertLBPositionsToUserLBPositions = ({
  userLBPositions
}: {
  userLBPositions: UserLBBinPosition[]
}) => {
  const userBalances: LBPairUserBalances = {
    amounts: [],
    liquidity: [],
    positions: [],
    prices: []
  }

  userLBPositions.forEach(
    ({
      binId,
      lbBinStep,
      liquidity,
      tokenX: { amountRaw: amountX },
      tokenY: { amountRaw: amountY }
    }) => {
      userBalances.positions.push(binId)
      userBalances.liquidity.push(String(liquidity))
      userBalances.prices.push(Bin.getPriceFromId(binId, lbBinStep))
      userBalances.amounts.push({ amountX, amountY })
    }
  )

  return userBalances
}

export const inverseLBPairDistributions = (
  pairDistributions: LBPairDistribution[]
): LBPairDistribution[] => {
  return pairDistributions.map((distribution) => ({
    ...distribution,
    price: (1 / Number(distribution.price)).toFixed(18)
  }))
}

interface ConvertLBPositionToLiquidityChartDataProps {
  activeBinId: number
  binStep: string
  token0?: Token
  token1?: Token
  userBalances?: LBPairUserBalances
}

export const convertLBPositionToLiquidityChartData = ({
  activeBinId,
  binStep,
  token0,
  token1,
  userBalances
}: ConvertLBPositionToLiquidityChartDataProps) => {
  try {
    console.log("convertLBPositionToLiquidityChartData", userBalances, token0, token1, activeBinId);

    if (!userBalances || !token0 || !token1) {
      return []
    }

    const userData = userBalances.positions.map((binId, i) => {
      const safeAmountX = !userBalances.amounts[i].amountX.includes('-')
        ? userBalances.amounts[i].amountX
        : '0'
      const safeAmountY = !userBalances.amounts[i].amountY.includes('-')
        ? userBalances.amounts[i].amountY
        : '0'
      const amountX = new TokenAmount(token0, safeAmountX)
      const amountY = new TokenAmount(token1, safeAmountY)
      const liquidity = userBalances.liquidity[i].toString()

      // const amountYPct = new Percent(
      //   JSBI.BigInt(safeAmountY),
      //   liquidity
      // ).toFixed(0)
      const price = userBalances.prices[i] * (10 ** (token0.decimals - token1.decimals))
      const percentage = percentageTokenYCompareInTokenX(Number(amountX.toFixed()), Number(amountY.toFixed()), price)

      return {
        amountX,
        amountY,
        amountYPct: percentage.toString(),
        binId,
        isActiveBin: binId === activeBinId,
        liquidity: Number(
          ethers.utils.formatUnits(
            userBalances.liquidity[i].toString(),
            token1.decimals
          )
        ),
        price: `${computeAndParsePriceFromBin(
          binId,
          Number(binStep),
          token0,
          token1,
          token1.decimals
        )}`
      }
    })

    // insert empty bins
    const finalData: LBPairDistribution[] = []
    userData.forEach((data, i) => {
      let thisBinId = i === 0 ? 0 : finalData[finalData.length - 1].binId + 1
      while (i !== 0 && thisBinId < data.binId) {
        finalData.push({
          amountX: new TokenAmount(token0, '0'),
          amountY: new TokenAmount(token0, '0'),
          amountYPct: '0',
          binId: thisBinId,
          isActiveBin: thisBinId === activeBinId,
          liquidity: 0,
          price: `${computeAndParsePriceFromBin(
            thisBinId,
            Number(binStep),
            token0,
            token1,
            token1.decimals
          )}`,
        })
        thisBinId += 1
      }
      finalData.push(data)
    })
    return finalData
  } catch {
    return []
  }
}

export const convertDexbarnPoolToPool = (pool: DexbarnPool): Pool => {
  return {
    apr: (pool.feesUsd * 365) / pool.liquidityUsd,
    feePct: pool.lbBaseFeePct,
    feesUsd: pool.feesUsd,
    isLb: true,
    lbBinStep: pool.lbBinStep,
    liquidityUsd: pool.liquidityUsd,
    name: pool.name,
    pairAddress: pool.pairAddress,
    tokenX: pool.tokenX,
    tokenY: pool.tokenY,
    volumeUsd: pool.volumeUsd
  }
}
