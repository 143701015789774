import { Button, ButtonProps, Hide } from '@chakra-ui/react'
import React from 'react'

interface MaxButtonProps {
  onClick: () => void
  balance?: string
}

const MaxButton = ({
  balance,
  onClick,
  ...props
}: MaxButtonProps & ButtonProps) => {
  if (!balance) return null
  return (
    <Hide below="sm">
      <Button
        variant="secondary"
        bg="joeLight.400"
        _hover={{
          bg: 'joeLight.500'
        }}
        mr={1}
        borderRadius={12}
        textTransform="uppercase"
        onClick={onClick}
        {...props}
      >
        Max
      </Button>
    </Hide>
  )
}

export default MaxButton
