import { CNATIVE, Token } from '@fusionx/sdk'
import { erc20ABI } from '@wagmi/core'
import { getAddress, isAddress } from 'ethers/lib/utils.js'
import { useMemo } from 'react'
import { useContractReads } from 'wagmi'

import useChainId from './useChainId'

interface UseSdkCurrenciesProps {
  addresses: (string | null | undefined)[]
}

const useSdkCurrencies = ({ addresses }: UseSdkCurrenciesProps) => {
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)

  const contracts = (
    addresses.filter((address) => address && isAddress(address)) as string[]
  ).map((address) => ({
    abi: erc20ABI,
    address: getAddress(address),
    chainId
  }))

  const { data, isLoading } = useContractReads({
    contracts: [
      ...contracts.map((contract) => ({
        ...contract,
        functionName: 'decimals'
      })),
      ...contracts.map((contract) => ({
        ...contract,
        functionName: 'symbol'
      })),
      ...contracts.map((contract) => ({
        ...contract,
        functionName: 'name'
      }))
    ]
  })

  const tokens = useMemo(() => {
    if (!data) return addresses.map(() => undefined)

    const diff = addresses.length - data.length / 3
    let offset = 0
    return addresses.map((address, index) => {
      if (address === nativeCurrency?.symbol) {
        offset += 1
        return nativeCurrency
      }
      if (!address || !isAddress(address)) {
        offset += 1
        return undefined
      }
      const idx = index - offset
      const decimals = data[idx] as number | undefined
      const symbol = data[idx + addresses.length - diff] as string | undefined
      const name = data[idx + addresses.length * 2 - diff * 2] as
        | string
        | undefined
      if (!decimals || !symbol || !name) return undefined
      return new Token(chainId, getAddress(address), decimals, symbol, name)
    })
  }, [data, chainId, addresses, nativeCurrency])

  return { isLoading, tokens }
}

export default useSdkCurrencies
