import { Center, Link, MenuItem } from '@chakra-ui/react'
import ChainSelector from 'components/ChainSelector'
import { selectableChains } from 'constants/chains'
import useChainChangeListener from 'hooks/useChainChangeListener'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import React from 'react'
import { useSelectedChainId } from 'state/settings/hooks'
import { chainClickHelp, chainSwitchChain } from 'utils/measure'
import { useSwitchNetwork } from 'wagmi'

const CHAIN_HELP_URL =
  'https://docs.mantle.xyz/tools-and-sdk/mantle-bridge-api'

interface AppChainSelectorProps {
  hideNameOnMobile?: boolean
}

const AppChainSelector = ({ hideNameOnMobile }: AppChainSelectorProps) => {
  const { switchNetwork } = useSwitchNetwork()
  const { updateChain } = useChainChangeListener()

  const { selectedChainId: initialChainId } = useSelectedChainId()
  const chainInUrlParam = useChainFromUrlParam()
  const selectedChainId = chainInUrlParam?.id ?? initialChainId

  return (
    <ChainSelector
      hideNameOnMobile={hideNameOnMobile ?? false}
      selectedChainId={selectedChainId ?? initialChainId}
      onChainSelect={(chainId) => {
        updateChain(chainId)
        switchNetwork?.(chainId)
        chainSwitchChain(chainId)
      }}
      additionalMenuItems={[
        <MenuItem key={selectableChains.length} h="48px">
          <Center
            as={Link}
            justifyContent="flex-start"
            w="full"
            h="full"
            isExternal
            href={CHAIN_HELP_URL}
            onClick={() => {
              chainClickHelp()
            }}
          >
            How to Bridge?
          </Center>
        </MenuItem>
      ]}
    />
  )
}

export default AppChainSelector
