import { ChainId } from '@fusionx/sdk'

export const getMaxBinPerAddLiquidityBatch = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARB_GOERLI:
      return 100
    case ChainId.AVALANCHE:
    case ChainId.FUJI:
      return 60
    case ChainId.BNB_CHAIN:
    case ChainId.BNB_TESTNET:
      return 200
    case ChainId.MANTLE_TESTNET:
      return 100
  }
}

export const getMaxBinPerRemoveLiquidityBatch = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARB_GOERLI:
      return 200
    case ChainId.AVALANCHE:
    case ChainId.FUJI:
      return 120
    case ChainId.BNB_CHAIN:
    case ChainId.BNB_TESTNET:
      return 200
    case ChainId.BNB_TESTNET:
      return 100
    case ChainId.MANTLE_TESTNET:
      return 100
  }
}
