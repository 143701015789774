import { BigNumber } from 'ethers'
import { erc20ABI, useAccount, useContractReads } from 'wagmi'

import useChainId from './useChainId'

interface UseBalancesProps {
  erc20Tokens: `0x${string}`[]
}

const useBalances = ({ erc20Tokens }: UseBalancesProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const result = useContractReads({
    contracts: erc20Tokens.map((token) => ({
      abi: erc20ABI,
      address: token,
      args: [account],
      chainId,
      functionName: 'balanceOf'
    })),
    enabled: !!account
  })
  return { ...result, data: result.data as (BigNumber | null)[] | undefined }
}

export default useBalances
