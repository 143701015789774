import { VaultABI } from '@fusionx/sdk-v2'
import { BigNumber, ethers } from 'ethers'
import useChainId from 'hooks/useChainId'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import { useMemo } from 'react'
import { UserVaultBalances, Vault } from 'types/vault'
import { formattedNum } from 'utils/format'
import { useAccount, useContractReads } from 'wagmi'

interface PreviewWithdrawOutput {
  amountX: BigNumber
  amountY: BigNumber
}

interface UseUserVaultBalancesProps {
  vaults: Vault[]
  enabled?: boolean
}

const useUserVaultBalances = ({
  enabled = true,
  vaults
}: UseUserVaultBalancesProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  // get amount of shares for all vaults
  const { data: balances, refetch } = useContractReads({
    contracts: vaults.map((vault) => ({
      abi: VaultABI,
      address: ethers.utils.getAddress(vault.id),
      args: account ? [account] : undefined,
      chainId,
      functionName: 'balanceOf'
    })),
    enabled: !!account && vaults.length > 0 && enabled
  })

  // get amounts for all vaults
  const reads = useContractReads({
    contracts: vaults.map((vault, i) => ({
      abi: VaultABI,
      address: ethers.utils.getAddress(vault.id),
      args: balances ? [balances[i]] : undefined,
      chainId,
      functionName: 'previewAmounts'
    })),
    enabled: !!balances && balances.length > 0
  })

  // get usd prices for all tokens
  const tokens = useMemo(
    () =>
      vaults
        .map((vault) => [vault.tokenX.address, vault.tokenY.address])
        .flat()
        .filter(Boolean) as string[],
    [vaults]
  )
  const { data: usdPrices } = useTokenPriceUSD({
    tokens
  })

  // convert data
  const data = reads.data as (PreviewWithdrawOutput | null)[] | undefined
  const userVaultPositions: UserVaultBalances[] | undefined = useMemo(
    () =>
      data?.map((read, i) => ({
        amountX: read?.amountX,
        amountY: read?.amountY,
        fmtAmountX: read
          ? formattedNum(
            ethers.utils.formatUnits(read.amountX, vaults[i].tokenX.decimals),
            false,
            5
          )
          : undefined,
        fmtAmountY: read
          ? formattedNum(
            ethers.utils.formatUnits(read.amountY, vaults[i].tokenY.decimals),
            false,
            5
          )
          : undefined,
        tokenXUsdPrice: usdPrices?.[0 * (i * 2)],
        tokenYUsdPrice: usdPrices?.[1 * (i * 2 + 1)]
      })),
    [data, usdPrices, vaults]
  )

  return {
    data: userVaultPositions,
    isLoading: reads.isLoading,
    refetch
  }
}

export default useUserVaultBalances
