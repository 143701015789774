import { useQuery } from '@tanstack/react-query'
import { EXCHANGE_SUBGRAPH } from '@fusionx/sdk'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { dayDatasLiquidityQuery } from 'graphql/dexV1'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useEffect, useState } from 'react'
import { DayData, DexAnalyticsData } from 'types/analytics'
import { getDexbarnChainParam } from 'utils/chains'

dayjs.extend(utc)

interface ChartData {
  date: number
  value: number
}

interface PoolData {
  liquidity: ChartData[]
  volume: ChartData[]
  volume1D: number // volume during the most recent full UTC day (00:00 UTC - 23:59 UTC)
  volume24: number // volume in the last 24 hours
}

interface AnalyticsData {
  allData: PoolData
  v1Data: PoolData
  v2Data: PoolData
}

interface DayDataResponse {
  dayDatas: DayData[]
}

const usePoolHomeAnalytics = () => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const DATA_LENGTH = 180
  const SECONDS_IN_DAY = 60 * 60 * 24

  const utcDataLengthDaysBack = dayjs()
    .utc()
    .startOf('day')
    .subtract(DATA_LENGTH - 1, 'day')
    .unix()

  const now = dayjs()
  const yesterday = now.subtract(1, 'day')

  const [analyticsData, setAnalyticsData] = useState<AnalyticsData>({
    allData: { liquidity: [], volume: [], volume1D: 0, volume24: 0 },
    v1Data: { liquidity: [], volume: [], volume1D: 0, volume24: 0 },
    v2Data: { liquidity: [], volume: [], volume1D: 0, volume24: 0 }
  })

  const fetchDexAnalyticsDayData = useDexbarnGet<DexAnalyticsData[]>(
    `/v1/dex/analytics/${chainId}?startTime=${utcDataLengthDaysBack}&aggregateBy=daily`
  )

  const fetchDexAnalyticsHourlyData = useDexbarnGet<DexAnalyticsData[]>(
    `/v1/dex/analytics/${chainId}?startTime=${yesterday.unix()}&endTime=${now.unix()}&aggregateBy=hourly`
  )

  const { data: dayDatasV2, isLoading: dayDatasV2Loading } = useQuery<
    DexAnalyticsData[]
  >({
    keepPreviousData: true,
    queryFn: () => fetchDexAnalyticsDayData(),
    queryKey: ['dayDataV2', chain]
  })

  const { data: todayData, isLoading: todayDataLoading } = useQuery<
    DexAnalyticsData[]
  >({
    keepPreviousData: true,
    queryFn: () => fetchDexAnalyticsHourlyData(),
    queryKey: ['hourlyDayDataV2', chain]
  })

  const { data: dayDatas, isLoading: dayDatasV1Loading } =
    useQuery<DayDataResponse>({
      queryFn: async () =>
        request(EXCHANGE_SUBGRAPH[chainId], dayDatasLiquidityQuery, {
          dateAfter: utcDataLengthDaysBack
        }),
      queryKey: ['dayDataV1', chain]
    })

  const dayDatasV1 = dayDatas?.dayDatas ?? []

  console.log("dayDatasV1", dayDatasV1, dayDatasV2, todayData)

  useEffect(() => {
    if (dayDatasV1 && dayDatasV2 && todayData) {
      let currentDayTimestamp = utcDataLengthDaysBack

      // fill missing day datas
      const v1Data: PoolData = {
        liquidity: [],
        volume: [],
        volume1D: 0,
        volume24: 0
      }
      const v2Data: PoolData = {
        liquidity: [],
        volume: [],
        volume1D: 0,
        volume24: 0
      }
      const allData: PoolData = {
        liquidity: [],
        volume: [],
        volume1D: 0,
        volume24: 0
      }

      let v1DataIndex = 0
      let v2DataIndex = 0

      // create dummy array to sync length of filled data with DATA_LENGTH
      new Array(DATA_LENGTH).fill(0).forEach((value, index) => {
        // V1 DATA
        // condition if timestamp of current iteration is in data from subgraph
        if (
          v1DataIndex < dayDatasV1.length
          && dayDatasV1[v1DataIndex].date === currentDayTimestamp  //TODO: ENABLE AND FIX
        ) {
          v1Data.liquidity.push({
            date: currentDayTimestamp,
            value: parseFloat(dayDatasV1[v1DataIndex].liquidityUSD)
          })
          v1Data.volume.push({
            date: currentDayTimestamp,
            value: parseFloat(dayDatasV1[v1DataIndex].volumeUSD)
          })

          v1DataIndex++
        } else {
          try {
            // if the past day timestamp have a defined TVL, use it for current timestamp if data
            // for current timestamp is not found in the data from subgraph
            v1Data.liquidity.push({
              date: currentDayTimestamp,
              value: v1Data.liquidity[index - 1].value
            })
          } catch (e) {
            // default to zero if past day TVL is undefined (edge case when index = 0)
            v1Data.liquidity.push({
              date: currentDayTimestamp,
              value: 0
            })
          }

          // volume for day timestamps not found in subgraph should default to zero
          v1Data.volume.push({
            date: currentDayTimestamp,
            value: 0
          })
        }

        // V2 DATA
        // condition if timestamp of current iteration is in data from dexbarn
        if (
          v2DataIndex < dayDatasV2.length
          && dayDatasV2[v2DataIndex].timestamp === currentDayTimestamp // TODO: ENABLE AND FIX
        ) {
          v2Data.liquidity.push({
            date: currentDayTimestamp,
            value: dayDatasV2[v2DataIndex].reserveUsd
          })
          v2Data.volume.push({
            date: currentDayTimestamp,
            value: dayDatasV2[v2DataIndex].volumeUsd
          })

          v2DataIndex++
        } else {
          try {
            // if the past day timestamp have a defined TVL, use it for current timestamp if data
            // for current timestamp is not found in the data from subgraph
            v2Data.liquidity.push({
              date: currentDayTimestamp,
              value: v2Data.liquidity[index - 1].value
            })
          } catch (e) {
            // default to zero if past day TVL is undefined (edge case when index = 0)
            v2Data.liquidity.push({
              date: currentDayTimestamp,
              value: 0
            })
          }

          // volume and fees for day timestamps not found in subgraph should default to zero
          v2Data.volume.push({
            date: currentDayTimestamp,
            value: 0
          })
        }

        // COMBINED DATA (V1 + V2 MERGE)
        // add the current timestamp values of v1 and v2 data
        allData.liquidity.push({
          date: currentDayTimestamp,
          value: v1Data.liquidity[index].value + v2Data.liquidity[index].value
        })
        allData.volume.push({
          date: currentDayTimestamp,
          value: v1Data.volume[index].value + v2Data.volume[index].value
        })

        currentDayTimestamp += SECONDS_IN_DAY

        const v2VolumeDatas: ChartData[] = todayData.map((el) => ({
          date: el.timestamp,
          value: el.volumeUsd
        }))

        const v2Volume24H = v2VolumeDatas.reduce(
          (accu: number, currData: ChartData) => accu + currData.value,
          0
        )

        // set volume24
        v1Data.volume24 = 0 // TODO @gaepsuni: change when we start tracking hourData in subgraph v1
        v2Data.volume24 = v2Volume24H
        allData.volume24 = v1Data.volume24 + v2Data.volume24

        // set volume1D
        v1Data.volume1D =
          v1Data.volume.length > 1
            ? v1Data.volume[v1Data.volume.length - 1].value
            : 0
        v2Data.volume1D =
          v2Data.volume.length > 1
            ? v2Data.volume[v2Data.volume.length - 1].value
            : 0
        allData.volume1D = v1Data.volume1D + v2Data.volume1D

        setAnalyticsData({ allData, v1Data, v2Data })
      })
    }
  }, [dayDatas, dayDatasV2, todayData, utcDataLengthDaysBack, SECONDS_IN_DAY])

  return {
    analyticsData,
    isLoading: dayDatasV1Loading || dayDatasV2Loading || todayDataLoading
  }
}

export default usePoolHomeAnalytics
