import { Box, Flex, Heading, SimpleGrid, useToken } from '@chakra-ui/react'
import AnalyticsChart from 'components/AnalyticsChart'
import AnalyticsStat from 'components/AnalyticsStat'
import usePoolAnalyticsChange from 'hooks/analytics/usePoolAnalyticsChange'
import usePoolDetailV1Analytics from 'hooks/analytics/usePoolDetailV1Analytics'
import React from 'react'
import { Pool } from 'types/pool'
import { formattedNum } from 'utils/format'

interface PoolDetailOverviewProps {
  pool: Pool
}

const PoolDetailOverview = ({ pool }: PoolDetailOverviewProps) => {
  const { chartData, isLoading, pairDayDatas } = usePoolDetailV1Analytics({
    poolAddress: pool.pairAddress
  })
  const poolChange = usePoolAnalyticsChange({ pairDayDatas })

  const [graphPurple, graphGreen] = useToken('colors', [
    'graphPurpleDark',
    'graphGreenDark'
  ])

  const fmtLiquidity = formattedNum(pool.liquidityUsd, true)
  const fmtVolume = formattedNum(pool.volumeUsd, true)
  const fmtFee = formattedNum(pool.feesUsd, true)

  return (
    <Flex flexDir="column" gap={4}>
      <Heading size="sm">Overview</Heading>
      <SimpleGrid gap={4} columns={2}>
        <AnalyticsStat
          title="Liquidity"
          value={fmtLiquidity}
          change={poolChange?.liquidityChange}
        />
        <AnalyticsStat
          title="Volume (24h)"
          value={fmtVolume}
          change={poolChange?.volumeChange}
        />
        <AnalyticsStat
          title="Fees (24h)"
          value={fmtFee}
          change={poolChange?.feeChange}
        />
        <AnalyticsStat
          title="APR"
          value={`${Number(pool.apr * 100).toFixed(2)}%`}
        />
      </SimpleGrid>
      <Box p={4} border="1px" borderColor="border" borderRadius="xl">
        <AnalyticsChart
          id="tvl"
          data={chartData?.liquidity ?? []}
          subheader="TVL (Total Locked Value)"
          header={<Heading size="md">{fmtLiquidity}</Heading>}
          fill={graphPurple}
          stroke={graphPurple}
          h={{ base: '100px', md: '250px' }}
          tooltipTitle="TVL"
          isLoading={isLoading}
        />
      </Box>
      <Box p={4} border="1px" borderColor="border" borderRadius="xl">
        <AnalyticsChart
          id="volume"
          data={chartData?.volume ?? []}
          subheader="Volume (24h)"
          header={<Heading size="md">{fmtVolume}</Heading>}
          fill={graphGreen}
          stroke={graphGreen}
          h={{ base: '100px', md: '250px' }}
          tooltipTitle="Volume (24h)"
          isLoading={isLoading}
        />
      </Box>
    </Flex>
  )
}

export default PoolDetailOverview
