import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { useQuery } from '@tanstack/react-query'
import { LB_REWARDER_ADDRESS, LBRewarderABI } from '@fusionx/sdk-v2'
import { BigNumber, ethers } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import { useDexbarnPost } from 'hooks/useDexbarn'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import {
  ClaimableRewardsEpoch,
  GetProofParams,
  RewardsHistoryEpoch
} from 'types/rewards'
import { getDexbarnChainParam } from 'utils/chains'
import { capturePrepareContractWriteError } from 'utils/logger'
import { useAccount, useContractWrite, usePrepareContractWrite } from 'wagmi'

interface MerkleEntry {
  amount: BigNumber
  epoch: BigNumber
  market: `0x${string}`
  merkleProof: `0x${string}`[]
  token: `0x${string}`
  user: `0x${string}`
}

type BatchClaimAruments = [MerkleEntry[]]

interface UseLBPairClaimRewardsProps {
  lbPairAddress: string | undefined
  rewardEpochs: ClaimableRewardsEpoch[] | undefined
  rewardsHistoryData: RewardsHistoryEpoch[] | undefined
}

const useLBPairClaimRewards = ({
  lbPairAddress,
  rewardEpochs,
  rewardsHistoryData
}: UseLBPairClaimRewardsProps) => {
  const { address: account } = useAccount()

  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // params for Rewarder API
  const proofsParams: GetProofParams[] | undefined = useMemo(() => {
    if (
      !lbPairAddress ||
      !account ||
      !rewardEpochs ||
      rewardEpochs.length === 0
    ) {
      return undefined
    }

    const proofsParams: GetProofParams[] = []

    rewardEpochs.forEach((rewardEpoch) => {
      const { claimableRewards, epoch } = rewardEpoch
      claimableRewards.forEach((reward: { tokenAddress: any }) => {
        proofsParams.push({
          epoch,
          market: lbPairAddress,
          token: reward.tokenAddress
        })
      })
    })

    return proofsParams
  }, [rewardEpochs, account, lbPairAddress])

  const fetchMerkleProofs = useDexbarnPost(
    `/v1/rewards/batch-proof/${chain}/${account}`,
    {
      batch: proofsParams
    }
  )

  // fetch merkleProof using Rewarder API
  const { data: merkleProofs } = useQuery<`0x${string}`[][]>(
    ['MerkleProofs', account, lbPairAddress],
    () => fetchMerkleProofs(),
    {
      enabled: !!lbPairAddress && !!account && !!proofsParams,
      retry: 0
    }
  )

  // args for contract call
  const args: BatchClaimAruments | undefined = useMemo(() => {
    if (
      !account ||
      !merkleProofs ||
      !proofsParams ||
      merkleProofs.length !== merkleProofs.length ||
      !rewardsHistoryData
    ) {
      return undefined
    }

    const entries: MerkleEntry[] = []

    proofsParams.forEach((param, index) => {
      const { epoch, market, token } = param
      const epochData = rewardsHistoryData.find(
        (epochData) => epochData.epoch === epoch
      )
      const reward = epochData
        ? epochData.rewards.find((reward: { tokenAddress: any }) => reward.tokenAddress === token)
        : undefined

      if (
        reward &&
        merkleProofs.length > index &&
        ethers.utils.isAddress(market) &&
        ethers.utils.isAddress(token)
      ) {
        entries.push({
          amount: BigNumber.from(reward.amount),
          epoch: BigNumber.from(epoch),
          market,
          merkleProof: merkleProofs[index],
          token,
          user: account
        })
      }
    })

    if (entries.length === 0) {
      return undefined
    }

    return [entries]
  }, [merkleProofs, proofsParams, rewardsHistoryData, account])

  const { config } = usePrepareContractWrite({
    abi: LBRewarderABI,
    address: getAddress(LB_REWARDER_ADDRESS[chainId]),
    args,
    cacheTime: 0,
    enabled: !!args,
    functionName: 'batchClaim',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  const { isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description = 'Claim rewards'
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  return {
    handleClaimRewards: write,
    isLoading
  }
}

export default useLBPairClaimRewards
