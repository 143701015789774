import { useQuery } from '@tanstack/react-query'
import { EXCHANGE_SUBGRAPH } from '@fusionx/sdk'
import { pairsSearchQuery } from 'graphql/dexV1'
import request from 'graphql-request'
import { Pool as DexbarnPool, PoolQueryParam } from 'types/dexbarn'
import { Pair } from 'types/poolV1'
import { getDexbarnChainParam } from 'utils/chains'
import { convertPairToPool } from 'utils/poolV1'
import { convertDexbarnPoolToPool } from 'utils/poolV2'

import useChainId from './useChainId'
import useDebounce from './useDebounce'
import { useDexbarnGet } from './useDexbarn'

interface UsePairsSearchProps {
  query: string
  deduplicateResults?: boolean
  enabled?: boolean
  filterBy?: PoolQueryParam.FilterBy
  includesPoolV1?: boolean
  orderBy?: PoolQueryParam.OrderBy
}

const usePairsSearch = ({
  deduplicateResults = true,
  enabled = true,
  filterBy = '1d',
  includesPoolV1 = true,
  orderBy = 'volumeUSD',
  query
}: UsePairsSearchProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const subgraphUrl = EXCHANGE_SUBGRAPH[chainId]

  const debouncedQuery = useDebounce(query, 400)
  const terms = debouncedQuery.split(' ')
  const _query = terms[0].trim()

  const _enabled = _query.length >= 3 && enabled

  const { data: resultsV1, isLoading: isLoadingV1 } = useQuery<{
    pairs: Pair[]
  }>(
    ['PairsSearchV1Query', subgraphUrl, _query, includesPoolV1],
    () =>
      request(subgraphUrl, pairsSearchQuery, {
        first: 30,
        query: _query
      }),
    { enabled: _enabled && includesPoolV1 }
  )
  const pairsV1 = resultsV1?.pairs ?? []

  const fetchLbPairs = useDexbarnGet<DexbarnPool[]>(
    `/v1/pools/search/${chainId}/?query=${_query}&status=unverified&filterBy=${filterBy}&orderBy=volumeUSD`
  )
  const { data: poolsV2, isLoading: isLoadingV2 } = useQuery<DexbarnPool[]>(
    ['LBPairsSearchV2Query', subgraphUrl, _query, filterBy, "volumeUSD"],
    () => fetchLbPairs(),
    {
      enabled: _enabled
    }
  )

  let pairs = poolsV2?.map(convertDexbarnPoolToPool) ?? []
  if (deduplicateResults) {
    // dedup pairs
    pairsV1.forEach((pairV1) => {
      const existingPair = poolsV2?.find(
        (p) =>
          p.tokenX.address.toLowerCase() === pairV1.token0.id?.toLowerCase() &&
          p.tokenY.address.toLowerCase() === pairV1.token1.id?.toLowerCase()
      )
      if (!existingPair) {
        pairs.push(convertPairToPool({ pair: pairV1 }))
      }
    })
  } else {
    pairs = pairs.concat(pairsV1.map((pair) => convertPairToPool({ pair })))
  }

  const term2 = terms.length > 1 ? terms[1].trim().toLowerCase() : undefined
  if (term2) {
    pairs = pairs.filter(
      (pair) =>
        pair.name.toLowerCase().includes(term2) ||
        pair.name.toLowerCase().includes(term2)
    )
  }

  return {
    data: pairs,
    isLoading: ((isLoadingV1 && includesPoolV1) || isLoadingV2) && _enabled
  }
}

export default usePairsSearch
