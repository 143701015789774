import { Box, Td } from '@chakra-ui/react'
import React from 'react'

interface UserPositionValueTdProps {
  pooledTokens0?: string
  pooledTokens1?: string
  symbol0?: string
  symbol1?: string
}

const UserPositionValueTd = ({
  pooledTokens0,
  pooledTokens1,
  symbol0,
  symbol1
}: UserPositionValueTdProps) => (
  <Td textAlign="right">
    <Box as="span">
      {pooledTokens0}
      <Box
        as="span"
        fontWeight="normal"
        textColor="textSecondary"
      >{` ${symbol0}  •  `}</Box>
      {pooledTokens1}
      <Box as="span" fontWeight="normal" textColor="textSecondary">
        {` ${symbol1}`}
      </Box>
    </Box>
  </Td>
)

export default UserPositionValueTd
