import { Currency } from '@fusionx/sdk'
import WithdrawSharesPanel from 'components/WithdrawSharesPanel'
import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils.js'
import useVaultQueueWithdrawal from 'hooks/vault/useVaultQueueWithdrawal'
import React, { useCallback, useState } from 'react'

interface VaultRemoveLiquidityPanelProps {
  vaultAddress: string
  currency0?: Currency
  currency1?: Currency
  onRemoveLiquiditySuccess?: () => void
}

const VaultRemoveLiquidityPanel = ({
  currency0,
  currency1,
  onRemoveLiquiditySuccess,
  vaultAddress
}: VaultRemoveLiquidityPanelProps) => {
  const [percentageToWithdraw, setPercentageToWithdraw] = useState<string>('')
  const [isPercentageInvalid, setIsPercentageInvalid] = useState(false)

  const {
    isLoading: isWithdrawing,
    previewAmounts,
    withdraw
  } = useVaultQueueWithdrawal({
    basisPointToWithdraw: percentageToWithdraw
      ? Number(percentageToWithdraw) * 100
      : undefined,
    enabled: !isPercentageInvalid,
    onSuccess: () => {
      setPercentageToWithdraw('')
      onRemoveLiquiditySuccess?.()
    },
    vaultAddress
  })

  const formatPreviewAmount = useCallback(
    (amount?: BigNumber, decimals?: number) => {
      if (!amount || isPercentageInvalid) return undefined
      return Number(formatUnits(amount, decimals))
    },
    [isPercentageInvalid]
  )

  return (
    <WithdrawSharesPanel
      isRemoving={isWithdrawing}
      onRemoveClick={withdraw}
      amountDesired0={formatPreviewAmount(
        previewAmounts?.amountX,
        currency0?.decimals
      )}
      amountDesired1={formatPreviewAmount(
        previewAmounts?.amountY,
        currency1?.decimals
      )}
      tokenSymbol0={currency0?.symbol}
      tokenSymbol1={currency1?.symbol}
      percentage={percentageToWithdraw}
      onPercentageChange={(pct, isInvalid) => {
        setPercentageToWithdraw(pct)
        setIsPercentageInvalid(isInvalid)
      }}
      footerText={
        percentageToWithdraw
          ? 'You will be able to redeem your tokens the next time the auto pool rebalances its position.'
          : undefined
      }
    />
  )
}

export default VaultRemoveLiquidityPanel
