import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency, ROUTER_ADDRESS, RouterABI } from '@fusionx/sdk'
import { BigNumber, ethers } from 'ethers'
import { formatUnits, getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { useSlippageSettings } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { capturePrepareContractWriteError } from 'utils/logger'
import { getCurrencyAddress } from 'utils/wrappedCurrency'
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

type RemoveLiquidityArgsType = [
  `0x${string}`,
  `0x${string}`,
  BigNumber,
  BigNumber,
  BigNumber,
  `0x${string}`,
  BigNumber
]

type RemoveLiquidityAVAXArgsType = [
  `0x${string}`,
  BigNumber,
  BigNumber,
  BigNumber,
  `0x${string}`,
  BigNumber
]

interface UseRemoveLiquidityProps {
  token0: Currency
  token1: Currency
  amountDesired0?: BigNumber
  amountDesired1?: BigNumber
  enabled?: boolean
  onSuccess?: (data: ethers.providers.TransactionReceipt) => void
  poolTokenAmount?: BigNumber
}

const useRemoveLiquidity = ({
  amountDesired0,
  amountDesired1,
  enabled,
  onSuccess,
  poolTokenAmount,
  token0,
  token1
}: UseRemoveLiquidityProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const transactionDeadline = useTransactionDeadline()

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const {
    slippageSettings: { v1: slippage }
  } = useSlippageSettings()
  const amountMin0 = amountDesired0?.mul(10000 - slippage * 100).div(10000)
  const amountMin1 = amountDesired1?.mul(10000 - slippage * 100).div(10000)

  const oneCurrencyIsNative = token0.isNative || token1.isNative

  const removeLiquidityArgs = useMemo(():
    | RemoveLiquidityArgsType
    | RemoveLiquidityAVAXArgsType
    | undefined => {
    if (
      !transactionDeadline ||
      !poolTokenAmount ||
      !account ||
      !amountMin0 ||
      !amountMin1
    ) {
      return undefined
    }
    const address0 = getCurrencyAddress(token0)
    const address1 = getCurrencyAddress(token1)
    if ((token0.isNative && address1) || (token1.isNative && address0)) {
      return [
        token0.isNative ? address1! : address0!,
        poolTokenAmount,
        token0.isNative ? amountMin1 : amountMin0,
        token0.isNative ? amountMin0 : amountMin1,
        account,
        transactionDeadline
      ]
    }
    if (!address0 || !address1) return undefined
    return [
      address0,
      address1,
      poolTokenAmount,
      amountMin0,
      amountMin1,
      account,
      transactionDeadline
    ]
  }, [
    token0,
    token1,
    poolTokenAmount,
    amountMin0,
    amountMin1,
    account,
    transactionDeadline
  ])

  const { config } = usePrepareContractWrite({
    abi: RouterABI,
    address: getAddress(ROUTER_ADDRESS[chainId]),
    args: removeLiquidityArgs,
    cacheTime: 0,
    enabled: !!removeLiquidityArgs && poolTokenAmount?.gt(0) && enabled,
    functionName: oneCurrencyIsNative
      ? 'removeLiquidityAVAX'
      : 'removeLiquidity',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    }
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      if (!amountDesired0 || !amountDesired1) return
      const description = `Remove ${formattedNum(
        formatUnits(amountDesired0, token0.decimals)
      )} ${token0.symbol} and ${formattedNum(
        formatUnits(amountDesired1, token1.decimals)
      )} ${token1.symbol}`
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess
  })

  return { isLoading: isLoading || isWaitingForTransaction, write }
}

export default useRemoveLiquidity
