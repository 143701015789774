import * as Sentry from '@sentry/react'
import { walletConnect } from 'utils/measure'
import { useAccount } from 'wagmi'

const useOnWalletConnectListeners = () => {
  useAccount({
    onConnect: ({ connector }) => {
      Sentry.setTag('wallet', connector?.name)
      walletConnect(connector?.name)
    },
    onDisconnect: () => {
      Sentry.setTag('wallet', undefined)
    }
  })
}

export default useOnWalletConnectListeners
