import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { VaultABI } from '@fusionx/sdk-v2'
import { BigNumber } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useTransactionToast from 'hooks/useTransactionToast'
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseVaultCancelQueuedWithdrawalProps {
  shares: BigNumber
  vaultAddress: string
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultCancelQueuedWithdrawal = ({
  enabled = true,
  onSuccess,
  shares,
  vaultAddress
}: UseVaultCancelQueuedWithdrawalProps) => {
  const { address: account } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { config } = usePrepareContractWrite({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args: shares && account ? [shares, account] : undefined,
    cacheTime: 0,
    enabled: enabled && !!account,
    functionName: 'cancelQueuedWithdrawal'
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const transactionSummary = 'Cancel vault withdrawal'
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    cancelQueuedWithdrawal: write,
    isLoading: isWaitingForTransaction || isLoading,
    isSuccess
  }
}

export default useVaultCancelQueuedWithdrawal
