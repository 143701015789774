import {
  Box,
  Flex,
  FlexProps,
  Heading,
  HStack,
  Image,
  Tag
} from '@chakra-ui/react'
import PoolImage1x from 'assets/home/pool_1x.webp'
import PoolImage2x from 'assets/home/pool_2x.webp'
import PoolImage3x from 'assets/home/pool_3x.webp'
import BackButton from 'components/BackButton'
import ContractLink from 'components/ContractLink'
import CurrencyLogo from 'components/CurrencyLogo'
import SlippageSettingsPicker, {
  SlippageSettingsPickerType
} from 'components/SlippageSettingsPicker'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'

interface PoolDetailHeaderProps {
  address?: string
  hidePoolIllustration?: boolean
  isRewarded?: boolean
  slippageSettingsPickerType?: SlippageSettingsPickerType
  tag?: string
  token0?: { address?: string; symbol?: string }
  token1?: { address?: string; symbol?: string }
}

const PoolDetailHeader = ({
  address,
  hidePoolIllustration,
  isRewarded,
  slippageSettingsPickerType,
  tag,
  token0,
  token1,
  ...props
}: PoolDetailHeaderProps & FlexProps) => {
  const chainId = useChainId()
  const navigate = useNavigate()
  return (
    <Flex
      maxW="1200px"
      margin="0 auto"
      flexDir="column"
      align="flex-start"
      gap={2}
      pos="relative"
      h="170px"
      {...props}
    >
      <BackButton
        title="Back to pools list"
        ml={-3}
        minH="24px"
        onClick={() => navigate(`/${getChainSlug(chainId)}/pool`)}
      />
      <HStack zIndex={1}>
        <HStack>
          <CurrencyLogo
            address={token0?.address}
            symbol={token0?.symbol}
            boxSize={7}
          />
          <Heading size="lg">{token0?.symbol ?? token0?.address}</Heading>
        </HStack>
        <Heading size="lg">/</Heading>
        <HStack>
          <CurrencyLogo
            address={token1?.address}
            symbol={token1?.symbol ?? token1?.address}
            boxSize={7}
          />
          <Heading size="lg">{token1?.symbol ?? token1?.address}</Heading>
        </HStack>
        <Flex flexWrap="wrap" gap={2}>
          {tag ? (
            <Tag
              borderRadius="full"
              size={{ base: 'sm', md: 'lg' }}
              fontWeight="bold"
              bg="bgTertiary"
            >
              {tag}
            </Tag>
          ) : null}
          {isRewarded ? (
            <Tag
              rounded="full"
              size={{ base: 'sm', md: 'lg' }}
              bg="green.400"
              fontWeight="bold"
              textColor="white"
            >
              Rewards
            </Tag>
          ) : null}
        </Flex>
      </HStack>
      <ContractLink address={address} />
      {/* {!hidePoolIllustration ? (
        <Box
          pos="absolute"
          zIndex={0}
          bottom={0}
          right={{ base: 0, md: '100px' }}
          h={{ base: '100px', md: '150px' }}
          overflow="hidden"
        >
          <Image
            alt="pool-header-image"
            fallbackSrc={PoolImage3x}
            srcSet={`${PoolImage1x} 1x ${PoolImage2x} 2x ${PoolImage3x} 3x`}
            w={{ base: '0px', md: '398px', sm: '239px' }}
            h={{ base: '0px', md: '250px', sm: '150px' }}
          />
        </Box>
      ) : null} */}
      {slippageSettingsPickerType ? (
        <Box
          mr={{ base: 0, md: 4 }}
          mt={{ base: 3, md: 3, sm: 4 }}
          alignSelf="flex-end"
          zIndex={4}
        >
          <SlippageSettingsPicker type={slippageSettingsPickerType} />
        </Box>
      ) : null}
    </Flex>
  )
}

export default PoolDetailHeader
