import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  LinkProps,
  Text,
  VStack
} from '@chakra-ui/react'
import React from 'react'
import {
  CoinMarketCapIcon,
  DiscordIcon,
  GithubIcon,
  SubstackIcon,
  TwitterIcon
} from 'theme/icons'

import FooterSection from './FooterSection'

const SocialNetworkLink = (props: LinkProps) => (
  <Link
    isExternal
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius="full"
    bg="accent.400"
    boxSize="40px"
    _hover={{ bg: 'accent.500' }}
    _active={{ bg: 'accent.500' }}
    {...props}
  />
)

const Footer = () => {
  return (
    <Box width="full" bgColor="bgSecondary">
      <Box
        maxWidth="1200px"
        margin="0 auto"
        p={4}
        pt={{ base: 4, md: 12 }}
        pb={{ base: 4, md: 8 }}
      >
        <Flex justify="space-between" flexWrap="wrap" gap={8}>
          <Flex flexDir="column" justify="space-between" gap={8}>
            <VStack align="flex-start" spacing={4}>
              <Heading size="sm">Need help?</Heading>
              {/* <Text>
                Visit{' '}
                <Link
                  isExternal
                  href="https://support.fusionx.finance/en/"
                  color="accent.500"
                >
                  our support centre
                </Link>{' '}
                for troubleshooting
              </Text>
              <Text>
                Join{' '}
                <Link
                  isExternal
                  href="https://support.fusionx.finance/en/"
                  color="accent.500"
                >
                  our official Discord
                </Link>{' '}
                for dedicated support
              </Text> */}
            </VStack>
            <HStack spacing={4} w="full" justify="space-between">
              <SocialNetworkLink
                aria-label="FusionX on Discord"
                href="https://discord.com/invite/fusionxfinance"
              >
                <DiscordIcon boxSize="28px" fill="white" />
              </SocialNetworkLink>
              <SocialNetworkLink
                aria-label="FusionX on Twitter"
                href="https://twitter.com/fusionx_finance"
              >
                <TwitterIcon boxSize="28px" fill="white" />
              </SocialNetworkLink>
              {/* <SocialNetworkLink
                aria-label="FusionX on Substack"
                href="https://joecontent.substack.com/"
              >
                <SubstackIcon boxSize="28px" fill="white" />
              </SocialNetworkLink> */}
              {/* <SocialNetworkLink
                aria-label="FusionX on CoinMarketCap"
                href="https://coinmarketcap.com/community/profile/FusionX"
              >
                <CoinMarketCapIcon boxSize="22px" fill="white" />
              </SocialNetworkLink> */}
              <SocialNetworkLink
                aria-label="FusionX on Github"
                href="https://github.com/fusionx-finance/"
              >
                <GithubIcon boxSize="28px" fill="white" />
              </SocialNetworkLink>
            </HStack>
            <HStack spacing={4}>
              {/* <Link
                isExternal
                aria-label="Audits of FusionX's smart contracts"
                href="https://help.fusionx.finance/main/security-and-contracts/audits"
                color="accent.500"
              >
                Security
              </Link>
              <Link
                isExternal
                aria-label="FusionX developer documentation"
                href="https://docs.fusionx.finance/"
                color="accent.500"
              >
                Developers
              </Link> */}
              <Link
                aria-label="FusionX Privacy Policy"
                href="/privacy"
                color="accent.500"
              >
                Privacy
              </Link>
              <Link
                aria-label="FusionX Terms of Service"
                href="/tos"
                color="accent.500"
              >
                Terms of Service
              </Link>
            </HStack>
            {/* <Text fontSize="sm">© 2023 Joemart Ltd</Text> */}
          </Flex>
          {/* <VStack align="flex-start" spacing={8}>
            <FooterSection
              title="JOE Token"
              items={[
                {
                  href: 'https://www.coingecko.com/en/coins/joe',
                  name: 'Coingecko'
                },
                {
                  href: 'https://coinmarketcap.com/currencies/joe/',
                  name: 'CoinMarketCap'
                }
              ]}
            />
            <FooterSection
              title="Analytics"
              items={[
                {
                  href: 'https://www.tokenterminal.com/terminal/projects/fusionx',
                  name: 'TokenTerminal'
                },
                {
                  href: 'https://defillama.com/protocol/fusionx',
                  name: 'Defi Llama'
                },
                {
                  href: 'https://www.geckoterminal.com/avax/fusionx/pools',
                  name: 'Gecko Terminal'
                }
              ]}
            />
          </VStack>
          <FooterSection
            title="Exchanges"
            items={[
              {
                href: 'https://www.binance.com/en/trade/JOE_USDT?layout=pro',
                name: 'Binance'
              },
              {
                href: 'https://crypto.com/price/joe',
                name: 'Crypto.com'
              },
              {
                href: 'https://www.gate.io/trade/JOE_USDT',
                name: 'Gate.io'
              },
              {
                href: 'https://www.mexc.com/exchange/JOE_USDT',
                name: 'MEXC'
              },
              {
                href: 'https://www.okx.com/markets/prices/joe-joe',
                name: 'OKX'
              }
            ]}
          />
          <FooterSection
            title="Business"
            items={[
              {
                href: 'https://docs.google.com/forms/d/e/1FAIpQLSezM85MhNQaogZltoxXCz2vP8R9EuX1RjeeRGuaMYJcMdUwiA/viewform',
                name: 'Apply for Partnership'
              },
              {
                href: 'https://github.com/fusionx-finance/joe-tokenlists',
                name: 'Token Listing'
              },
              {
                href: 'https://forms.gle/GAJL7GN4BSfHcvKc8',
                name: 'NFT Listing'
              },
              {
                href: 'https://twitter.com/fusionx_finance',
                name: 'Contact Us'
              }
            ]}
          /> */}
        </Flex>
      </Box>
    </Box>
  )
}

export default Footer
