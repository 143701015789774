import {
  Box,
  Center,
  Grid,
  Spinner,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import PageHelmet from 'components/PageHelmet'
import TabWithIcon from 'components/TabWithIcon'
import {
  getRewarderInfo,
  getRewardRate,
  POOL_HELMET_DESCRIPTION,
  POOL_HELMET_TITLE
} from 'constants/pool'
import useLBPairData from 'hooks/pool/v2/useLBPairData'
import useLBPairRewards from 'hooks/pool/v2/useLBPairRewards'
import useLBPosition from 'hooks/pool/v2/useLBPosition'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import PoolDetailHeader from 'pages/PoolDetail/PoolDetailHeader'
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { AchievementIcon, PieChartIcon, PoolIcon } from 'theme/icons'
import { getCurrencyAddress, wrappedCurrency } from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

import AddRemoveLiquidityPanelV2 from './AddRemoveLiquidityPanelV2'
import ClaimFees from './ClaimFees'
import ClaimRewards from './ClaimRewards'
import PoolDetailV2AnalyticsPanel from './PoolDetailV2AnalyticsPanel'
import RewardsHistoryTable from './RewardsHistoryTable'
import UserLiquidityChart from './UserLiquidityChart'

const PoolDetailV2 = () => {
  const { address: account, isConnected } = useAccount()
  const { binStep, token0Address, token1Address } = useParams()
  const location = useLocation()
  const chainId = useChainId()

  const [selectedTab, setSelectedTab] = useState<number>(0)

  const {
    tokens: [currency0, currency1]
  } = useSdkCurrencies({
    addresses: [token0Address, token1Address]
  })
  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)

  const { data: balance0, refetch: refetchBalance0 } = useTokenBalance({
    token: getCurrencyAddress(currency0)
  })
  const debouncedRefetchBalance0 = debounce(() => refetchBalance0(), 4000)
  const { data: balance1, refetch: refetchBalance1 } = useTokenBalance({
    token: getCurrencyAddress(currency1)
  })
  const debouncedRefetchBalance1 = debounce(() => refetchBalance1(), 4000)

  const {
    activeBinId,
    feeParameters: { baseFee },
    isLoading,
    lbPairInfo,
    refetchReservesAndActiveBinId,
    reserveX,
    reserveY
  } = useLBPairData({
    binStep,
    currency0,
    currency1
  })

  const {
    isLoadingLBPosition: isLoadingUserLBPosition,
    pooledTokens0,
    pooledTokens1,
    refetchLBPosition: refetchUserLBPosition,
    userBalances
  } = useLBPosition({
    activeBinId,
    lbPairAddress: lbPairInfo?.LBPair,
    owner: account,
    token0: wrappedCurrency0,
    token1: wrappedCurrency1
  })

  const hasRewarder = lbPairInfo
    ? Boolean(getRewarderInfo(chainId, lbPairInfo.LBPair))
    : false
  const isRewarded = lbPairInfo
    ? Boolean(getRewardRate(chainId, lbPairInfo.LBPair))
    : false

  const {
    claimableRewards,
    handleClaimRewards,
    isLoading: isRewardLoading,
    rewardsHistoryData
  } = useLBPairRewards({
    isRewarded: hasRewarder,
    lbPairAddress: lbPairInfo?.LBPair
  })

  if (isLoading || !lbPairInfo) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  // wait for the subgraph to update the user's positions, then ask for a refetch
  const debouncedRefetchUserLBPosition = debounce(
    () => refetchUserLBPosition(),
    10000
  )

  return (
    <Box pt={{ base: 6, md: 20 }} w="full">
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <PoolDetailHeader
        address={lbPairInfo?.LBPair}
        token0={{
          address: currency0?.isToken ? currency0.address : undefined,
          symbol: currency0?.symbol
        }}
        token1={{
          address: currency1?.isToken ? currency1.address : undefined,
          symbol: currency1?.symbol
        }}
        tag={`${baseFee} bps`}
        isRewarded={isRewarded}
        slippageSettingsPickerType="poolV2"
        px={4}
      />
      <Tabs
        variant="enclosed"
        pos="relative"
        mt="-42px"
        w="full"
        onChange={setSelectedTab}
      >
        <TabList maxW="1200px" margin="0 auto" px={4}>
          <TabWithIcon
            title="Manage"
            icon={
              <PoolIcon
                mr={2}
                boxSize="22px"
                fill={selectedTab === 0 ? 'textPrimary' : 'textTertiary'}
              />
            }
          />
          <TabWithIcon
            title="Analytics"
            icon={
              <PieChartIcon
                mr={2}
                boxSize="16px"
                fill={selectedTab === 1 ? 'textPrimary' : 'textTertiary'}
              />
            }
          />
          {hasRewarder ? (
            <TabWithIcon
              title="Rewards"
              icon={
                <AchievementIcon
                  mr={2}
                  boxSize="16px"
                  fill={selectedTab === 2 ? 'textPrimary' : 'textTertiary'}
                />
              }
            />
          ) : null}
        </TabList>
        <TabPanels w="full" minH="calc(100vh - 250px)">
          <TabPanel
            pb={32}
            pt={10}
            maxW="1200px"
            margin="0 auto"
            w="full"
            px={4}
          >
            <Grid
              templateColumns={{
                base: 'minmax(0, 1fr)',
                lg: 'minmax(0, 1fr) minmax(0, 1fr)'
              }}
              gap={{ base: 4, md: 12 }}
            >
              <VStack spacing={4}>
                {activeBinId && binStep ? (
                  <UserLiquidityChart
                    currency0={currency0}
                    currency1={currency1}
                    binStep={binStep}
                    userBalances={userBalances}
                    activeBinId={activeBinId.toNumber()}
                    isLoadingUserBalances={isLoadingUserLBPosition}
                  />
                ) : null}
                {wrappedCurrency0 && wrappedCurrency1 ? (
                  <CurrencyAmountsPanel
                    title="Deposit Balance"
                    tokens={[
                      wrappedCurrency0.address,
                      wrappedCurrency1.address
                    ]}
                    tokenAmounts={[pooledTokens0 ?? 0, pooledTokens1 ?? 0]}
                    tokenSymbols={[currency0?.symbol, currency1?.symbol]}
                  />
                ) : null}
                {isConnected && wrappedCurrency0 && wrappedCurrency1 ? (
                  <ClaimFees
                    lbPairAddress={lbPairInfo?.LBPair}
                    wrappedCurrency0={wrappedCurrency0}
                    wrappedCurrency1={wrappedCurrency1}
                    currency0Symbol={currency0?.symbol}
                    currency1Symbol={currency1?.symbol}
                  />
                ) : null}
                {isConnected && hasRewarder ? (
                  <ClaimRewards
                    claimableRewards={claimableRewards}
                    isLoading={isRewardLoading}
                    handleClaimRewards={handleClaimRewards}
                    poolSymbol={`${currency0?.symbol}-${currency1?.symbol}`}
                  />
                ) : null}
              </VStack>
              {currency0 && currency1 && lbPairInfo ? (
                <AddRemoveLiquidityPanelV2
                  lbPairAddress={lbPairInfo.LBPair}
                  currency0={currency0}
                  currency1={currency1}
                  balance0={balance0}
                  balance1={balance1}
                  binStep={binStep}
                  activeBinId={activeBinId}
                  userBalances={userBalances}
                  onAddLiquiditySuccess={() => {
                    debouncedRefetchBalance0()
                    debouncedRefetchBalance1()
                    debouncedRefetchUserLBPosition()
                  }}
                  onRemoveLiquiditySuccess={() => {
                    debouncedRefetchBalance0()
                    debouncedRefetchBalance1()
                    debouncedRefetchUserLBPosition()
                  }}
                  onRemoveLiquidityConfigError={() => {
                    refetchReservesAndActiveBinId()
                    refetchUserLBPosition()
                  }}
                />
              ) : null}
            </Grid>
          </TabPanel>
          <TabPanel pb={32} pt={10} maxW="1200px" margin="0 auto">
            {currency0 && currency1 ? (
              <PoolDetailV2AnalyticsPanel
                lbPairAddress={lbPairInfo?.LBPair}
                activeBinId={activeBinId?.toNumber()}
                binStep={binStep}
                isSelected={selectedTab === 1}
                currency0={currency0}
                currency1={currency1}
                reserveX={reserveX}
                reserveY={reserveY}
              />
            ) : null}
          </TabPanel>
          <TabPanel pb={32} pt={10} maxW="1200px" margin="0 auto">
            <Grid
              templateColumns={{ base: '1fr', lg: '2fr 1fr' }}
              gap={{ base: 2, md: 6 }}
            >
              <RewardsHistoryTable rewardsHistoryData={rewardsHistoryData} />

              {isConnected && hasRewarder ? (
                <Box>
                  <ClaimRewards
                    claimableRewards={claimableRewards}
                    isLoading={isRewardLoading}
                    handleClaimRewards={handleClaimRewards}
                    poolSymbol={`${currency0?.symbol}-${currency1?.symbol}`}
                  />
                </Box>
              ) : null}
            </Grid>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default PoolDetailV2
