import { Center, Flex, Text } from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import { LB_ROUTER_V21_ADDRESS } from '@fusionx/sdk-v2'
import Web3Button from 'components/Web3Button'
import { BigNumber } from 'ethers'
import useRemoveLiquidityInfoV2 from 'hooks/pool/v2/useRemoveLiquidityInfoV2'
import { RemoveLiquidityV2Option } from 'hooks/pool/v2/useRemoveLiquidityV2'
import useApproveForAllIfNeeded from 'hooks/useApproveForAllIfNeeded'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { LBPairUserBalances } from 'types/poolV2'

import RemoveLiquidityDetailsRow from './RemoveLiquidityDetailsRow'

interface RemoveLiquidityDetailsProps {
  currency0: Currency
  currency1: Currency
  inversePriceRatios: boolean
  lbPairAddress: string
  onRemoveLiquidityConfigError: () => void
  onRemoveLiquiditySuccess: () => void
  range: number[]
  removeOption: RemoveLiquidityV2Option
  activeBinId?: BigNumber
  binStep?: string
  userBalances?: LBPairUserBalances
}

const RemoveLiquidityDetails = ({
  activeBinId,
  binStep,
  currency0,
  currency1,
  inversePriceRatios,
  lbPairAddress,
  onRemoveLiquidityConfigError,
  onRemoveLiquiditySuccess,
  range,
  removeOption,
  userBalances
}: RemoveLiquidityDetailsProps) => {
  const chainId = useChainId()

  const { parsedAmounts, refetchBins, refetchTotalSupplies, withdrawalInfo } =
    useRemoveLiquidityInfoV2({
      activeBinId,
      currency0,
      currency1,
      lbPairAddress,
      range,
      removeOption,
      userBalances
    })

  const { approve, isApproved, isApproving } = useApproveForAllIfNeeded({
    spender: LB_ROUTER_V21_ADDRESS[chainId],
    token: lbPairAddress,
    tokenSymbol: 'LBT'
  })

  if (!withdrawalInfo) {
    return null
  }

  if (withdrawalInfo.length === 0) {
    return (
      <Center py={4}>
        <Text size="sm" color="textSecondary">
          You have no liquidity in this range.
        </Text>
      </Center>
    )
  }

  if (!parsedAmounts) {
    return null
  }

  return (
    <Flex flexDir="column" gap={4}>
      {!isApproved ? (
        <Web3Button
          w="full"
          size="xl"
          variant="primary"
          colorScheme="accent"
          isLoading={isApproving}
          loadingText="Approving"
          onClick={() => approve?.()}
        >
          Approve to continue
        </Web3Button>
      ) : null}
      {parsedAmounts.map((amounts, i) => (
        <RemoveLiquidityDetailsRow
          key={i}
          currency0={currency0}
          currency1={currency1}
          binStep={binStep}
          onRemoveLiquiditySuccess={onRemoveLiquiditySuccess}
          onRemoveLiquidityConfigError={() => {
            onRemoveLiquidityConfigError()
            refetchTotalSupplies()
            refetchBins()
          }}
          isApproved={isApproved}
          removeOption={removeOption}
          withdrawalInfo={withdrawalInfo[i]}
          parsedAmounts={amounts}
          inversePriceRatios={inversePriceRatios}
        />
      ))}
    </Flex>
  )
}

export default RemoveLiquidityDetails
