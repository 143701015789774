import { useQuery } from '@tanstack/react-query'
import { DEX_V2_SUBGRAPH } from '@fusionx/sdk-v2'
import { BigNumber } from 'ethers'
import { vaultQueuedWithdrawalsQuery } from 'graphql/dexV2'
import request from 'graphql-request'
import useChainId from 'hooks/useChainId'
import { VaultQueuedWithdrawal, VaultSubgraphModels } from 'types/vault'
import { useAccount } from 'wagmi'

interface UseVaultQueuedWithdrawalsProps {
  vaultAddress?: string
}

const useVaultQueuedWithdrawals = ({
  vaultAddress
}: UseVaultQueuedWithdrawalsProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const subgraphUrl = DEX_V2_SUBGRAPH[chainId]

  const result = useQuery<{
    vaultQueuedWithdrawals: VaultSubgraphModels.VaultQueuedWithdrawal[]
  }>(
    ['VaultQueuedWithdrawalsQuery', subgraphUrl, account, vaultAddress],
    () =>
      request(subgraphUrl, vaultQueuedWithdrawalsQuery, {
        user: account?.toLowerCase(),
        vault: vaultAddress?.toLowerCase()
      }),
    { cacheTime: 0, enabled: !!account && !!vaultAddress }
  )

  const data: VaultQueuedWithdrawal[] | undefined =
    result.data?.vaultQueuedWithdrawals.map((withdrawal) => ({
      round: Number(withdrawal.round),
      shares: BigNumber.from(withdrawal.shares),
      vault: withdrawal.vault
    }))
  return { ...result, data }
}

export default useVaultQueuedWithdrawals
