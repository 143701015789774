import { Box, Center, Flex, IconButton, Text, VStack } from '@chakra-ui/react'
import { CNATIVE, Currency, Token } from '@fusionx/sdk'
import { LB_ROUTER_V21_ADDRESS, TradeV2 } from '@fusionx/sdk-v2'
import AddToWalletButton from 'components/AddToWalletButton'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CandleChart from 'components/CandleChart/CandleChart'
import MaxButton from 'components/MaxButton'
import PageHelmet from 'components/PageHelmet'
import PoolCards from 'components/PoolCards'
import SlippageSettingsPicker from 'components/SlippageSettingsPicker'
import Web3Button from 'components/Web3Button'
import { TRADE_HELMET_DESCRIPTION, TRADE_HELMET_TITLE } from 'constants/swap'
import { useSwap } from 'hooks/swap/useSwap'
import useTrades from 'hooks/swap/useTrades'
import useWrapUnwrapNativeCurrency from 'hooks/swap/useWrapUnwrapNativeCurrency'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import { useCandleChartStandardization } from 'hooks/useCandleChartStandardization'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useIsSafeModeEnabled, useSlippageSettings } from 'state/settings/hooks'
import { ArrowUpDownIcon } from 'theme/icons'
import { convertStringToBN } from 'utils/format'
import { tradeSwap } from 'utils/measure'
import { tryParseAmount } from 'utils/swap'
import {
  getCurrencyAddress,
  wrappedCurrencyAmount
} from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

import TradeCurrencyInput from './TradeCurrencyInput'
import TradeDetails from './TradeDetails'

const Trade = () => {
  const { isConnected } = useAccount()
  const location = useLocation()
  const chainId = useChainId()

  // Decode search params
  const [searchParams] = useSearchParams()
  const inputCurrencyAddr = searchParams.get('inputCurrency')
  const outputCurrencyAddr = searchParams.get('outputCurrency')
  const paramAddresses = useMemo(
    () => [inputCurrencyAddr, outputCurrencyAddr],
    [inputCurrencyAddr, outputCurrencyAddr]
  )
  const {
    tokens: [inputCurrencyParam, outputCurrencyParam]
  } = useSdkCurrencies({
    addresses: paramAddresses
  })

  // Input/output currencies
  const [isExactIn, setIsExactIn] = useState(true)
  const [selectedInputCurrency, setInputCurrency] = useState<
    Currency | undefined
  >(CNATIVE.onChain(chainId))
  const [selectedOutputCurrency, setOutputCurrency] = useState<
    Currency | undefined
  >()

  const inputCurrency = useMemo(
    () => selectedInputCurrency ?? inputCurrencyParam,
    [selectedInputCurrency, inputCurrencyParam]
  )
  const outputCurrency = useMemo(
    () => selectedOutputCurrency ?? outputCurrencyParam,
    [selectedOutputCurrency, outputCurrencyParam]
  )

  // Addresses
  const inputCurrencyAddress = getCurrencyAddress(inputCurrency)
  const outputCurrencyAddress = getCurrencyAddress(outputCurrency)

  // User balances
  const inputBalance = useTokenBalance({
    token: inputCurrencyAddress
  })
  const debouncedRefetchInputBalance = debounce(
    () => inputBalance.refetch(),
    2000
  )
  const outputBalance = useTokenBalance({
    token:
      outputCurrency instanceof Token
        ? (outputCurrency as Token).address
        : undefined
  })
  const debouncedRefetchOutputBalance = debounce(
    () => outputBalance.refetch(),
    2000
  )

  // User input
  const [typedValue, setTypedValue] = useState('')
  const userTypedCurrency = isExactIn ? inputCurrency : outputCurrency
  const typedTokenAmount = useMemo(
    () =>
      wrappedCurrencyAmount(
        tryParseAmount(typedValue, userTypedCurrency),
        chainId
      ),
    [typedValue, userTypedCurrency, chainId]
  )

  console.log("typedTokenAmount", tryParseAmount(typedValue, userTypedCurrency), userTypedCurrency, typedTokenAmount)

  // Fetch trades
  const { isFetchingTrades, trades } = useTrades({
    inputCurrency,
    isExactIn,
    outputCurrency,
    typedTokenAmount
  })
  const bestTrade = TradeV2.chooseBestTrade(trades, isExactIn)

  console.log("bestTrade", trades, bestTrade)
  const isLiquidityInsufficient =
    !isFetchingTrades && !bestTrade && !!typedValue

  // Slippage
  const {
    slippageSettings: { swapV2: lowSlippage, v1: normalSlippage }
  } = useSlippageSettings()
  const allowedNormalSlippage = Math.trunc(normalSlippage * 100)
  const allowedLowSlippage = Math.trunc(lowSlippage * 100)
  const allowedSlippage = useMemo(() => {
    if (bestTrade) {
      // use low slippage if only v2 pools are used in the trade
      const isAllV2Pools = bestTrade.quote.binSteps.every(
        (binStepBN) => binStepBN.toNumber() > 0
      )
      if (isAllV2Pools) {
        return allowedLowSlippage
      }
    }
    return allowedNormalSlippage
  }, [bestTrade, allowedNormalSlippage, allowedLowSlippage])

  // Price impact
  const { isSafeModeEnabled } = useIsSafeModeEnabled()
  const priceImpact = bestTrade
    ? Number(bestTrade.priceImpact.toFixed(2))
    : undefined
  const isPriceImpactHigh = priceImpact ? priceImpact >= 5 : false

  // Input error
  const hasEnoughInputCurrency =
    inputBalance.data && bestTrade
      ? Number(inputBalance.data.formatted) >=
      Number(bestTrade.inputAmount.toSignificant(6))
      : true

  // Approval
  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: convertStringToBN(
      bestTrade?.inputAmount.toFixed(),
      bestTrade?.inputAmount.token.decimals
    ),
    spender: LB_ROUTER_V21_ADDRESS[chainId],
    token: inputCurrencyAddress,
    tokenSymbol: inputCurrency?.symbol
  })

  // Refresh state on swap success
  const onSwapSuccess = useCallback(() => {
    resetApproval()
    setTypedValue('')
    debouncedRefetchInputBalance()
    debouncedRefetchOutputBalance()
  }, [
    debouncedRefetchInputBalance,
    debouncedRefetchOutputBalance,
    resetApproval
  ])

  // Swap
  const isSwapEnabled =
    hasEnoughInputCurrency &&
    !isFetchingTrades &&
    (isApproved || (inputCurrency?.isNative ?? false))
  const { isSwapping, swap } = useSwap({
    allowedSlippage,
    enabled: isSwapEnabled,
    onSwapSuccess,
    trade: bestTrade
  })

  console.log("swap", isSwapEnabled, swap);

  // Wrap / Unwrap
  const {
    isLoading: isLoadingWrapUnwrap,
    state: wrapUnwrapState,
    write: wrapUnwrap
  } = useWrapUnwrapNativeCurrency({
    amount: typedTokenAmount?.toExact(),
    currency0: inputCurrency,
    currency1: outputCurrency,
    onSuccess: onSwapSuccess
  })

  // Update inputs and currencies when chain changes
  useEffect(() => {
    setInputCurrency(!!inputCurrencyAddr ? undefined : CNATIVE.onChain(chainId))
    setOutputCurrency(undefined)
    setTypedValue('')
  }, [chainId, inputCurrencyAddr])

  // Action handlers
  const onChangeSwapDirectionClick = useCallback(() => {
    const newInputCurrency = outputCurrency
    const newOutputCurrency = inputCurrency
    setTypedValue(bestTrade?.outputAmount.toFixed() ?? '')
    setInputCurrency(newInputCurrency)
    setOutputCurrency(newOutputCurrency)
  }, [inputCurrency, outputCurrency, bestTrade])

  const {
    isInverted,
    standardizedInputCurrency,
    standardizedOutputCurrency,
    toggleInverted
  } = useCandleChartStandardization(inputCurrency, outputCurrency)

  return (
    <Flex mt={{ base: 6, md: 28 }} mb="200px" justify="center">
      <PageHelmet
        title={TRADE_HELMET_TITLE}
        description={TRADE_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        gap={{ base: 0, lg: 4, xl: 20 }}
        w="full"
        maxW="1200px"
        align="center"
        justify="center"
      >
        {/* <Flex
          width="100%"
          flexDir="column"
          align="flex-end"
          px={{ base: 4, xl: 0 }}
          mb={4}
          maxW={{ base: '100%', lg: '600px' }}
        >
          <CandleChart
            inputCurrency={standardizedInputCurrency}
            outputCurrency={standardizedOutputCurrency}
            isInverted={isInverted}
            toggleInverted={toggleInverted}
          />
        </Flex> */}
        <Flex
          width="100%"
          flexDir="column"
          align="flex-end"
          px={{ base: 4, xl: 0 }}
          gap={6}
          maxW={{ base: 'auto', lg: '500px' }}
        >
          <Box pr={{ base: 4, sm: 0 }}>
            <SlippageSettingsPicker type="swap" />
          </Box>
          <VStack
            pos="relative"
            overflow="hidden"
            w="full"
            bg="bgSecondary"
            p={{ base: 4, md: 8 }}
            borderRadius={{ base: '16px', md: '32px' }}
            spacing={4}
            boxShadow="joeLight"
            _dark={{ boxShadow: 'joeDark' }}
          >
            <TradeCurrencyInput
              data-cy="trade-currency-input"
              rightElement={
                <MaxButton
                  data-cy="trade-currency-input-max-button"
                  mr={2}
                  borderRadius="full"
                  balance={inputBalance.data?.formatted}
                  onClick={() => {
                    setIsExactIn(true)
                    setTypedValue(inputBalance.data?.formatted ?? '')
                  }}
                />
              }
              headingText="Swap"
              value={
                wrapUnwrapState
                  ? typedValue
                  : isExactIn
                    ? typedValue
                    : bestTrade?.inputAmount.toFixed() ?? ''
              }
              onValueChange={(value) => {
                setIsExactIn(true)
                setTypedValue(value)
              }}
              currency={inputCurrency}
              currencyAddress={inputCurrencyAddress}
              onCurrencyChange={(currency) => {
                currency.symbol === outputCurrency?.symbol
                  ? onChangeSwapDirectionClick()
                  : setInputCurrency(currency)
              }}
              balance={inputBalance.data?.formatted}
              isDisabled={!isExactIn && isFetchingTrades}
            />
            <Flex w="full" align="center" gap={1}>
              <Box borderRadius="full" h="2px" bg="bgTertiary" w="full" />
              <IconButton
                data-cy="change-swap-direction-button"
                variant="secondary"
                bg="joeLight.400"
                borderRadius="full"
                icon={<ArrowUpDownIcon />}
                aria-label="change swap direction"
                isDisabled={isFetchingTrades || isSwapping}
                onClick={onChangeSwapDirectionClick}
              />
              <Box borderRadius="full" h="2px" bg="bgTertiary" w="full" />
            </Flex>
            <TradeCurrencyInput
              data-cy="trade-currency-output"
              headingText="To"
              value={
                wrapUnwrapState
                  ? typedValue
                  : isExactIn
                    ? bestTrade?.outputAmount.toFixed() ?? ''
                    : typedValue
              }
              onValueChange={(value) => {
                setIsExactIn(false)
                setTypedValue(value)
              }}
              currency={outputCurrency}
              currencyAddress={outputCurrencyAddress}
              onCurrencyChange={(currency) => {
                currency.symbol === inputCurrency?.symbol
                  ? onChangeSwapDirectionClick()
                  : setOutputCurrency(currency)
              }}
              balance={outputBalance.data?.formatted}
              isDisabled={isExactIn && isFetchingTrades}
            />
            {bestTrade ? (
              <TradeDetails
                trade={bestTrade}
                allowedSlippage={allowedSlippage}
              />
            ) : null}
            <Flex flexDir="column" w="full" pt={{ base: 0, md: 4 }} gap={4}>
              {approve &&
                !isApproved &&
                hasEnoughInputCurrency &&
                !isFetchingTrades ? (
                <ApproveTokenButton
                  data-cy="approve-button"
                  amount={bestTrade?.inputAmount.toExact()}
                  currencySymbol={inputCurrency?.symbol}
                  approvalType={approvalType}
                  onApprovalTypeSelect={setApprovalType}
                  isLoading={isApproving}
                  onClick={() => approve()}
                />
              ) : null}
              {wrapUnwrapState && !!wrapUnwrap ? (
                <Web3Button
                  data-cy="wrap-unwrap-button"
                  variant="primary"
                  colorScheme="accent"
                  w="full"
                  size="xl"
                  isLoading={isLoadingWrapUnwrap}
                  loadingText={wrapUnwrapState}
                  onClick={() => wrapUnwrap?.()}
                >
                  {wrapUnwrapState}
                </Web3Button>
              ) : (
                <Web3Button
                  data-cy="swap-button"
                  variant="primary"
                  colorScheme={
                    isPriceImpactHigh && isConnected ? 'red' : 'accent'
                  }
                  w="full"
                  size="xl"
                  isLoading={isFetchingTrades || isSwapping}
                  loadingText={
                    isFetchingTrades
                      ? 'Fetching best trade'
                      : 'Waiting for confirmation'
                  }
                  isDisabled={
                    !typedTokenAmount ||
                    !isSwapEnabled ||
                    isLiquidityInsufficient ||
                    !swap ||
                    (isPriceImpactHigh && isSafeModeEnabled)
                  }
                  onClick={() => {
                    swap?.()
                    tradeSwap(inputCurrency?.symbol, outputCurrency?.symbol)
                  }}
                >
                  {isLiquidityInsufficient
                    ? 'Insufficient liquidity for this trade'
                    : hasEnoughInputCurrency
                      ? isPriceImpactHigh
                        ? isSafeModeEnabled
                          ? 'Safe Mode Activated'
                          : 'Swap Anyway'
                        : 'Swap'
                      : `Not enough ${inputCurrency?.symbol}`}
                </Web3Button>
              )}
              {isPriceImpactHigh && isSafeModeEnabled ? (
                <Text
                  fontSize="sm"
                  textColor="textSecondary"
                  textAlign="center"
                  mt={-2}
                >
                  Safe mode prevents high price impact trade. It can be accessed
                  in Settings.
                </Text>
              ) : null}
            </Flex>
          </VStack>
          {outputCurrency && outputCurrency.isToken ? (
            <Center w="full">
              <AddToWalletButton token={outputCurrency} />
            </Center>
          ) : null}
          {/* {inputCurrency && outputCurrency ? (
            <PoolCards currency0={inputCurrency} currency1={outputCurrency} />
          ) : null} */}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Trade
