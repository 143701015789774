import { BAR_ADDRESS, JOE_ADDRESS, XJoeStakingABI } from '@fusionx/sdk'
import { ZERO_ADDRESS } from 'constants/tokens'
import { BigNumber, ethers } from 'ethers'
import { getAddress } from 'ethers/lib/utils.js'
import useChainId from 'hooks/useChainId'
import { erc20ABI, useAccount, useContractRead, useContractReads } from 'wagmi'

/**
 * XJoe Staking Info. Returns:
 * totalStaked in 18 decimals
 * totalSupply in 18 decimals
 * ratio in float
 */
const useXJoeStaking = () => {
  const chainId = useChainId()
  const joeContract = {
    abi: erc20ABI,
    address: getAddress(JOE_ADDRESS[chainId]),
    chainId
  }
  const xJoeStakingContract = {
    abi: XJoeStakingABI,
    address: getAddress(BAR_ADDRESS[chainId]),
    chainId
  }

  const xJoeAddress = getAddress(BAR_ADDRESS[chainId])
  const enabled = !!xJoeAddress && xJoeAddress != ZERO_ADDRESS

  const reads = useContractReads({
    contracts: [
      {
        ...joeContract,
        args: [xJoeAddress],
        functionName: 'balanceOf'
      },
      {
        ...xJoeStakingContract,
        functionName: 'totalSupply'
      }
    ],
    enabled
  })
  const totalStaked = reads.data?.[0] as BigNumber
  const totalSupply = reads.data?.[1] as BigNumber
  const ratio =
    totalStaked && totalSupply && totalSupply.gt(0)
      ? totalStaked.mul(1000).div(totalSupply).toNumber() / 1000
      : 0

  return {
    ...reads,
    ratio,
    totalStaked,
    totalSupply
  }
}

/**
 * contract methods for XJoe User Positions. Returns:
 * userBalance of xJOE tokens, in 18 decimals
 */
export const useXJoeUserPosition = () => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  const xJoeAddress = ethers.utils.getAddress(BAR_ADDRESS[chainId])
  const enabled = !!account && !!xJoeAddress && xJoeAddress != ZERO_ADDRESS
  const { data: userBalance } = useContractRead({
    abi: XJoeStakingABI,
    address: xJoeAddress,
    args: account ? [account] : undefined,
    chainId,
    enabled,
    functionName: 'balanceOf'
  })

  return { userBalance }
}

export default useXJoeStaking
