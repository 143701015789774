import { useEffect } from 'react'
import { useClient, useConnect } from 'wagmi'

// https://github.com/safe-global/safe-apps-sdk/blob/master/examples/wagmi/src/useAutoConnect.ts
const useAutoConnect = () => {
  const { connect, connectors } = useConnect()
  const client = useClient()

  useEffect(() => {
    // for end-to-end testing
    if (false) { // window.Cypress
      const connector = connectors.find((c) => c.id === 'mock')
      connect({ connector })
      return
    }

    // for production
    const safeConnector = connectors.find((c) => c.id === 'safe' && c.ready)
    if (safeConnector) {
      connect({ connector: safeConnector })
    } else {
      client.autoConnect()
    }
  }, [connect, connectors, client])
}

export default useAutoConnect
