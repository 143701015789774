import { ChevronDownIcon } from '@chakra-ui/icons'
import { SearchIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Collapse,
  Flex,
  Grid,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text
} from '@chakra-ui/react'
import SearchBar from 'components/SearchBar'
import useSortedPools, { PoolSortMethod } from 'hooks/pool/useSortedPools'
import React, { useCallback, useState } from 'react'
import { ArrowUpDownIcon } from 'theme/icons'

import PoolMobileCard from './PoolMobileCard'
import { PoolsTableProps } from './PoolsTable'
import { isSearchBarEnabled } from 'appConstants'

enum PoolsMobileSort {
  // LeastFees = 'Least Fees',
  LeastAPR = 'Lowest APR',
  LeastLiquidity = 'Lowest Liquidity',
  LeastVolume = 'Lowest Volume',
  // MostFees = 'Highest Fees',
  MostAPR = 'Highest APR',
  MostLiquidity = 'Highest Liquidity',
  MostVolume = 'Highest Volume'
}

const PoolsMobileList = ({
  hasNextPage,
  isFetchingPage,
  isLoadingPools,
  onLoadMoreClick,
  onPoolVersionChange,
  onQueryChange,
  pools,
  poolVersions,
  query,
  rewardPriceUSD,
  selectedPoolVersion
}: PoolsTableProps): JSX.Element => {
  const [showSearchBar, setShowSearchBar] = useState(false)
  const toggleSetShowSearchBar = () => setShowSearchBar(!showSearchBar)

  // Sorting
  const sorts = [
    PoolsMobileSort.MostLiquidity,
    PoolsMobileSort.LeastLiquidity,
    PoolsMobileSort.MostVolume,
    PoolsMobileSort.LeastVolume,
    PoolsMobileSort.MostAPR,
    PoolsMobileSort.LeastAPR
  ]
  const [sort, setSort] = useState(PoolsMobileSort.MostLiquidity)
  const [sortMethod, setSortMethod] = useState(PoolSortMethod.LIQUIDITY)
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)

  const handleChangeSort = useCallback((s: PoolsMobileSort) => {
    setSort(s)
    switch (s) {
      case PoolsMobileSort.MostLiquidity:
      case PoolsMobileSort.LeastLiquidity:
        setSortMethod(PoolSortMethod.LIQUIDITY)
        break
      case PoolsMobileSort.MostVolume:
      case PoolsMobileSort.LeastVolume:
        setSortMethod(PoolSortMethod.VOLUME)
        break
      case PoolsMobileSort.MostAPR:
      case PoolsMobileSort.LeastAPR:
        setSortMethod(PoolSortMethod.APR)
        break
    }

    switch (s) {
      case PoolsMobileSort.MostLiquidity:
      case PoolsMobileSort.MostVolume:
      case PoolsMobileSort.MostAPR:
        setIsSortDescending(true)
        break
      case PoolsMobileSort.LeastLiquidity:
      case PoolsMobileSort.LeastVolume:
      case PoolsMobileSort.LeastAPR:
        setIsSortDescending(false)
        break
    }
  }, [])

  const sortedPools = useSortedPools({
    isSortDescending,
    pools,
    sortMethod
  })

  return (
    <Flex flexDir="column" gap={2}>
      <Tabs
        isFitted={true}
        variant="soft-rounded-white"
        flexShrink={0}
        index={poolVersions.indexOf(selectedPoolVersion)}
        onChange={(index) => onPoolVersionChange(poolVersions[index])}
      >
        <TabList borderRadius="md" h={10}>
          {poolVersions.map((poolVersion, i) => (
            <Tab key={i} borderRadius="md">
              {poolVersion}
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Collapse in={!showSearchBar} animateOpacity>
        <Grid templateColumns="40px 1fr" gap={2}>
          <IconButton
            aria-label="open search bar"
            boxSize="40px"
            variant="outline"
            size="sm"
            icon={<SearchIcon boxSize={4} />}
            onClick={toggleSetShowSearchBar}
          />
          <Menu>
            <MenuButton
              w="full"
              variant="secondary"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              borderColor="border"
              borderWidth={1}
            >
              <HStack>
                <Show above="md">
                  <Text color="textSecondary" fontWeight="normal">
                    Sorting:
                  </Text>
                </Show>
                <Text color="textPrimary" fontWeight="bold">
                  {sort}
                </Text>
              </HStack>
            </MenuButton>
            <MenuList>
              {sorts.map((s, i) => (
                <MenuItem key={i} h="48px" onClick={() => handleChangeSort(s)}>
                  {s}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Grid>
      </Collapse>
     { isSearchBarEnabled &&  <Collapse in={showSearchBar} animateOpacity>
        <Grid p="2px" w="full" templateColumns="40px 1fr" gap={2}>
          <IconButton
            aria-label="close search bar"
            boxSize="40px"
            variant="outline"
            size="sm"
            icon={<ArrowUpDownIcon boxSize={4} />}
            onClick={toggleSetShowSearchBar}
          />
          <SearchBar
            placeholder="Search by name, symbol or address"
            value={query}
            onValueChange={onQueryChange}
          />
        </Grid>
      </Collapse>}
      {isLoadingPools ? (
        <Center mt={8}>
          <Spinner />
        </Center>
      ) : (
        <>
          <Flex flexDir="column" gap={2} mb={4}>
            {sortedPools.map((pool, idx) => (
              <PoolMobileCard
                key={idx}
                pool={pool}
                rewardPriceUSD={rewardPriceUSD}
              />
            ))}
          </Flex>
          {!isLoadingPools &&
          query === '' &&
          sortedPools.length > 0 &&
          hasNextPage ? (
            <Button
              variant="primary"
              colorScheme="accent"
              alignSelf="center"
              w="160px"
              isLoading={isFetchingPage}
              loadingText="Loading"
              onClick={onLoadMoreClick}
            >
              Load More
            </Button>
          ) : null}
        </>
      )}
    </Flex>
  )
}

export default PoolsMobileList
