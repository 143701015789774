import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import CurrencyLogo from "components/CurrencyLogo";
import TokenBalanceCard from "components/TokenBalanceCard";
import useTokenPriceUSD from "hooks/useTokenPriceUSD";
import React from "react";
import { formattedNum } from "utils/format";

interface CurrencyAmountsPanelProps {
  title?: string;
  tokenAmounts: number[];
  tokens: string[];
  tokenSymbols: (string | undefined)[];
  topContent?: React.ReactNode;
  bodyContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
  isLoading?: boolean;
}

const CurrencyAmountsPanel = ({
  topContent,
  bodyContent,
  bottomContent,
  isLoading,
  title,
  tokenAmounts,
  tokens,
  tokenSymbols,
}: CurrencyAmountsPanelProps) => {
  const { data: tokensPricesUSD } = useTokenPriceUSD({
    tokens,
  });

  const usdAmounts = tokenAmounts.map((tokenAmount: number, index: number) =>
    tokensPricesUSD ? tokenAmount * tokensPricesUSD[index] : undefined
  );

  const usdAmountTotal = usdAmounts.every((usdAmount) => !!usdAmount)
    ? usdAmounts.reduce(
      (acc: number, usdAmount: number | undefined) => acc + (usdAmount ?? 0),
      0
    )
    : undefined;

  return (
    <Box w="full" borderRadius="xl" border="1px" borderColor="border" p={4}>
      <Flex align="top" justify="space-between">
        {title ? <Heading size="sm" mb={4}>
          {title}
        </Heading> : <></>}
        {usdAmountTotal !== undefined ? (
          <Text fontSize="sm" color="textSecondary">
            {formattedNum(usdAmountTotal, true)}
          </Text>
        ) : null}
      </Flex>
      {topContent ?? null}
      <VStack>
        <Flex key={''} alignItems="center" w="full" >
          {tokens.map((token, index) => {
            return <>
              {index !== 0 ? <AddIcon mx={4} /> : <></>}
              <TokenBalanceCard
                address={token}
                symbol={tokenSymbols[index]}
                amount={formattedNum(tokenAmounts[index])}
                amountUsd={formattedNum(usdAmounts[index] as number, true)}
              />
            </>
            {/* <HStack flex="1" spacing={2}>
              <CurrencyLogo
                address={token}
                symbol={tokenSymbols[index]}
                boxSize={5}
              />
              <Text fontSize="sm" fontWeight="bold">
                {tokenSymbols[index]}
              </Text>
            </HStack>
            <VStack spacing={0} align="flex-end">
              <Skeleton isLoaded={!isLoading}>
                <Text fontSize="sm" fontWeight="bold">
                  {formattedNum(tokenAmounts[index])}
                </Text>
              </Skeleton>
              {usdAmounts[index] ? (
                <Text fontSize="sm" color="textSecondary">
                  {formattedNum(usdAmounts[index] as number, true)}
                </Text>
              ) : null}
            </VStack> */}
          })}
        </Flex>

        {bodyContent ?? null}
      </VStack>
      {bottomContent}
    </Box>
  );
};

export default CurrencyAmountsPanel;
