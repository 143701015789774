import { Box, Center, Heading, Text } from '@chakra-ui/react'
import { Currency } from '@fusionx/sdk'
import { LBPairV21ABI } from '@fusionx/sdk-v2'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import { getAddress } from 'ethers/lib/utils.js'
import useLBPairData from 'hooks/pool/v2/useLBPairData'
import useLBPosition from 'hooks/pool/v2/useLBPosition'
import useChainId from 'hooks/useChainId'
import React, { useMemo } from 'react'
import { Vault } from 'types/vault'
import { convertLBPositionToLiquidityChartData } from 'utils/poolV2'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useContractRead } from 'wagmi'

interface VaultLiquidityChartProps {
  vault: Vault
  currency0?: Currency
  currency1?: Currency
}

const VaultLiquidityChart = ({
  currency0,
  currency1,
  vault
}: VaultLiquidityChartProps) => {
  const chainId = useChainId()

  const binStep = vault.lbPair.binStep.toString()
  const { activeBinId, reserveX, reserveY } = useLBPairData({
    binStep,
    currency0: vault.lbPair.token0,
    currency1: vault.lbPair.token1
  })

  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)
  const { userBalances } = useLBPosition({
    activeBinId,
    lbPairAddress: vault.lbPair.id,
    owner: vault.strategy.id,
    token0: wrappedCurrency0,
    token1: wrappedCurrency1
  })

  const data = useMemo(() => {
    if (!activeBinId) {
      return []
    }
    return convertLBPositionToLiquidityChartData({
      activeBinId: activeBinId.toNumber(),
      binStep,
      token0: wrappedCurrency0,
      token1: wrappedCurrency1,
      userBalances
    })
  }, [wrappedCurrency0, wrappedCurrency1, binStep, userBalances, activeBinId])

  if (!activeBinId) {
    return null
  }

  const title = 'Auto Pool Liquidity'
  return data.length === 0 ? (
    <Box w="full">
      <Heading size="md" mb={2}>
        {title}
      </Heading>
      <Center h="200px">
        <Text fontSize="sm" color="textSecondary">
          There is no liquidity deployed to the pool
        </Text>
      </Center>
    </Box>
  ) : (
    <LBPairDistributionChart
      title={title}
      binStep={binStep}
      data={data}
      activeBinId={activeBinId.toNumber()}
      currency0={currency0}
      currency1={currency1}
    />
  )
}

export default VaultLiquidityChart
