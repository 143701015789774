import { LinkBox, LinkOverlay, Td, Tr } from '@chakra-ui/react'
import { getRewardRate } from 'constants/pool'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Token } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailV1Url, getPoolDetailV2Url } from 'utils/poolUrl'
import PoolTableNameColumn from '../PoolTableNameColumn'
import UserPositionValueTd from './UserPositionValueTd'

export interface UserPositionRowProps {
  feePct: number
  poolAddress: string
  tokenX: Token & {
    amount: number
    priceUsd?: number
  }
  tokenY: Token & {
    amount: number
    priceUsd?: number
  }
  lbBinStep?: number
  totalAmountUsd?: number
}

const UserPositionRow = ({
  feePct,
  lbBinStep,
  poolAddress,
  tokenX,
  tokenY,
  totalAmountUsd
}: UserPositionRowProps) => {
  const chainId = useChainId()

  return (
    <LinkBox as={Tr} cursor="pointer" _hover={{ bg: 'hover' }}>
      <Td>
        <LinkOverlay
          aria-label={`${tokenX.symbol} - ${tokenY.symbol} liquidity pool user position`}
          href={
            lbBinStep
              ? getPoolDetailV2Url(
                lbBinStep,
                chainId,
                tokenX.address,
                tokenY.address
              )
              : getPoolDetailV1Url(chainId, tokenX.address, tokenY.address)
          }
        />
        <PoolTableNameColumn
          token0={tokenX}
          token1={tokenY}
          fees={`${feePct}%`}
          isRewarded={Boolean(getRewardRate(chainId, poolAddress))}
        />
      </Td>
      <UserPositionValueTd
        symbol0={tokenX.symbol}
        symbol1={tokenY.symbol}
        pooledTokens0={formattedNum(tokenX.amount)}
        pooledTokens1={formattedNum(tokenY.amount)}
      />
      <Td textAlign="right">
        {totalAmountUsd ? formattedNum(totalAmountUsd, true) : '--'}
      </Td>
    </LinkBox>
  )
}

export default UserPositionRow
