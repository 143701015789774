import { Box } from '@chakra-ui/react'
import React from 'react'
import { formattedNum } from 'utils/format'

const AnalyticsChartYTick = ({ payload, x, y }: any) => (
  <g transform={`translate(${x + 5},${y})`}>
    <Box as="text" textAnchor="end" fontSize="xs" fill="textSecondary">
      {formattedNum(payload.value, true, 2)}
    </Box>
  </g>
)

export default AnalyticsChartYTick
