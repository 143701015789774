import { PoolSortMethod } from 'hooks/pool/useSortedPools'

export const vaultsListHeaders = [
  { name: 'Name' },
  { name: 'Pool Name' },
  {
    isNumeric: true,
    name: 'Liquidity',
    sortMethod: PoolSortMethod.LIQUIDITY
  },
  {
    isNumeric: true,
    name: 'My funds',
    sortMethod: PoolSortMethod.MY_FUNDS
  },
  {
    isNumeric: true,
    name: 'Fees (7D)',
    sortMethod: PoolSortMethod.FEE
  },
  {
    isNumeric: true,
    name: 'APR (7D)',
    sortMethod: PoolSortMethod.APR
  }
]
