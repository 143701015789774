import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Heading,
  Hide,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import PageHelmet from 'components/PageHelmet'
import TabWithIcon from 'components/TabWithIcon'
import {
  POOL_HELMET_DESCRIPTION,
  POOL_HELMET_TITLE,
  REWARD_TOKEN
} from 'constants/pool'
import { FEATURE_FLAGS } from 'featureFlags'
import usePoolsV1 from 'hooks/pool/v1/usePoolsV1'
import usePoolsV2 from 'hooks/pool/v2/usePoolsV2'
import useChainId from 'hooks/useChainId'
import usePairsSearch from 'hooks/usePairsSearch'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BookIcon, RobotIcon, StarIcon } from 'theme/icons'

import { PoolVersion } from './constants'
import PoolMainAnalytics from './PoolMainAnalytics'
import PoolsMobileList from './PoolsMobileList'
import PoolsTable from './PoolsTable'
import UserPositionsTable from './UserPositions/UserPositionsTable'
import VaultsTable from './Vault/VaultsTable'

const Pool = () => {
  const location = useLocation()

  const analyticsPoolVersions = [PoolVersion.V1, PoolVersion.V2]
  const [analyticsPoolVersion, setAnalyticsPoolVersion] = useState<PoolVersion>(
    PoolVersion.V2
  )

  const poolsTableVersions = [PoolVersion.V2, PoolVersion.ALL]
  const [poolsTableVersion, setPoolsTableVersion] = useState<PoolVersion>(
    PoolVersion.V2
  )

  const [tabIndex, setTabIndex] = useState(0)

  // fetch pools v1
  const pageSize = 25
  const {
    data: poolsV1,
    fetchNextPage: fetchNextPagePoolsV1,
    hasNextPage: hasNextPagePoolsV1,
    isFetchingNextPage: isFetchingNextPagePoolsV1,
    isLoading: isLoadingPoolsV1
  } = usePoolsV1({
    enabled: poolsTableVersion === PoolVersion.ALL,
    first: pageSize
  })

  // fetch pools v2
  const {
    data: poolsV2,
    fetchNextPage: fetchNextPagePoolsV2,
    hasNextPage: hasNextPagePoolsV2,
    isFetchingNextPage: isFetchingNextPagePoolsV2,
    isLoading: isLoadingPoolsV2
  } = usePoolsV2({
    pageSize
  })

  console.log("poolsV2", poolsV2);

  // pagination state
  const isFetchingNextPoolsPage =
    isFetchingNextPagePoolsV1 || isFetchingNextPagePoolsV2
  const hasNextPage =
    poolsTableVersion === PoolVersion.ALL
      ? hasNextPagePoolsV1 || hasNextPagePoolsV2
      : hasNextPagePoolsV2

  // load more callback
  const onLoadMoreClick = useCallback(() => {
    switch (poolsTableVersion) {
      case PoolVersion.ALL:
        fetchNextPagePoolsV1()
        fetchNextPagePoolsV2()
        break
      case PoolVersion.V1:
        fetchNextPagePoolsV1()
        break
      case PoolVersion.V2:
        fetchNextPagePoolsV2()
        break
    }
  }, [fetchNextPagePoolsV1, fetchNextPagePoolsV2, poolsTableVersion])

  // calc reward token price
  // We assume only 1 token per chain. Complex use case should be done in DexBarn.
  const chainId = useChainId()
  const rewardToken = REWARD_TOKEN[chainId]
  const { data } = useTokenPriceUSD({
    tokens: [rewardToken]
  })
  const rewardPriceUSD = data?.[0]

  // search
  const [query, setQuery] = useState<string>('')
  const { data: searchedPools, isLoading: isLoadingSearch } = usePairsSearch({
    deduplicateResults: false,
    includesPoolV1: poolsTableVersion === PoolVersion.ALL,
    query
  })

  // calculate pools to display
  const pools = useMemo(() => {
    if (query.length > 0) {
      return searchedPools
    }
    switch (poolsTableVersion) {
      case PoolVersion.ALL:
        return poolsV1.concat(poolsV2)
      case PoolVersion.V1:
        return poolsV1
      case PoolVersion.V2:
        return poolsV2
    }
  }, [poolsV1, poolsV2, poolsTableVersion, query, searchedPools])

  // loading state
  const isLoadingPools =
    isLoadingPoolsV2 ||
    (isLoadingPoolsV1 && poolsTableVersion === PoolVersion.ALL) ||
    isLoadingSearch

  // reset query when chain changes
  useEffect(() => {
    setQuery('')
  }, [chainId])

  return (
    <Box pt={{ base: 6, md: 24 }}>
      <Box maxW="1200px" margin="0 auto">
        <PageHelmet
          title={POOL_HELMET_TITLE}
          description={POOL_HELMET_DESCRIPTION}
          url={location.pathname}
        />
        <Flex pl={6} pr={4} align="center" justify="space-between">
          <VStack pb={6} spacing={0} align="flex-start">
            <Heading>Pool</Heading>
            <Text color="textSecondary" fontSize="sm">
              Provide liquidity and earn fees.
            </Text>
          </VStack>
          <Menu>
            <MenuButton
              variant="secondary"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              {analyticsPoolVersion}
            </MenuButton>
            <MenuList>
              {analyticsPoolVersions.map((poolVersion, i) => (
                <MenuItem
                  key={i}
                  h="48px"
                  onClick={() => setAnalyticsPoolVersion(poolVersion)}
                >
                  {poolVersion}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        <PoolMainAnalytics poolVersion={analyticsPoolVersion} />
      </Box>
      <Tabs variant="enclosed" onChange={(index) => setTabIndex(index)}>
        <TabList
          px={6}
          maxW="1200px"
          margin="0 auto"
          borderBottom={0}
          overflowX="auto"
          overflowY="hidden"
        >
          <TabWithIcon
            title="Pools"
            icon={
              <BookIcon
                mr={2}
                boxSize="20px"
                fill={tabIndex === 0 ? 'textPrimary' : 'textTertiary'}
              />
            }
            flexShrink={0}
          />
          {FEATURE_FLAGS.autoPools ? (
            <TabWithIcon
              data-cy="auto-pools-tab"
              title="Auto Pools"
              icon={
                <RobotIcon
                  mr={2}
                  boxSize="20px"
                  fill={tabIndex === 1 ? 'textPrimary' : 'textTertiary'}
                />
              }
              flexShrink={0}
            />
          ) : null}
          <TabWithIcon
            title="My Pools"
            icon={
              <StarIcon
                boxSize="16px"
                mr={2}
                stroke={tabIndex === 2 ? 'textPrimary' : 'textTertiary'}
              />
            }
            flexShrink={0}
          />
        </TabList>
        <TabPanels minH="60vh" pb={{ base: 4, md: 24 }}>
          <TabPanel maxW="1200px" margin="0 auto">
            <Hide below="md">
              <PoolsTable
                isFetchingPage={isFetchingNextPoolsPage}
                pools={pools}
                poolVersions={poolsTableVersions}
                selectedPoolVersion={poolsTableVersion}
                onPoolVersionChange={setPoolsTableVersion}
                isLoadingPools={isLoadingPools}
                rewardPriceUSD={rewardPriceUSD}
                onLoadMoreClick={onLoadMoreClick}
                hasNextPage={hasNextPage}
                query={query}
                onQueryChange={setQuery}
              />
            </Hide>
            <Show below="md">
              <PoolsMobileList
                pools={pools}
                poolVersions={poolsTableVersions}
                selectedPoolVersion={poolsTableVersion}
                onPoolVersionChange={setPoolsTableVersion}
                isLoadingPools={isLoadingPools}
                rewardPriceUSD={rewardPriceUSD}
                isFetchingPage={isFetchingNextPoolsPage}
                onLoadMoreClick={onLoadMoreClick}
                hasNextPage={hasNextPage}
                query={query}
                onQueryChange={setQuery}
              />
            </Show>
          </TabPanel>
          {FEATURE_FLAGS.autoPools ? (
            <TabPanel maxW="1200px" margin="0 auto">
              <VaultsTable isTabSelected={tabIndex === 1} />
            </TabPanel>
          ) : null}
          <TabPanel maxW="1200px" margin="0 auto">
            <UserPositionsTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default Pool
