import { getUnixTime, startOfDay, subDays, subMonths, subWeeks } from 'date-fns'

export function oneHundredEightyDays(): number {
  return getUnixTime(startOfDay(subMonths(Date.now(), 6)))
}

export function ninetyDays(): number {
  return getUnixTime(startOfDay(subMonths(Date.now(), 3)))
}

export function thirtyDays(): number {
  return getUnixTime(startOfDay(subMonths(Date.now(), 1)))
}

export function sevenDays(): number {
  return getUnixTime(startOfDay(subWeeks(Date.now(), 1)))
}

export function twentyFourHours(): number {
  return getUnixTime(startOfDay(subDays(Date.now(), 1)))
}
