import { JSBI, Percent, TradeType } from '@fusionx/sdk'
import { SwapParameters, TradeV2 } from '@fusionx/sdk-v2'
import { useMemo } from 'react'
import { useAccount } from 'wagmi'

import { BIPS_BASE } from '../../constants/swap'
import useChainId from '../useChainId'
import useTransactionDeadline from '../useTransactionDeadline'

interface UseSwapCallArgumentsProps {
  allowedSlippage: number
  trade?: TradeV2
}

const useSwapCallArguments = ({
  allowedSlippage,
  trade
}: UseSwapCallArgumentsProps): {
  defaultParameters?: SwapParameters
  feeOnTransferParameters?: SwapParameters
} => {
  const { address: recipient } = useAccount()
  const chainId = useChainId()
  const deadline = useTransactionDeadline()

  const defaultParameters = useMemo(() => {
    if (!trade || !recipient || !chainId || !deadline) {
      return undefined
    }
    console.log("allowedSlippage", allowedSlippage)
    return trade.swapCallParameters({
      allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
      deadline: deadline.toNumber(),
      feeOnTransfer: false,
      recipient
    })
  }, [trade, allowedSlippage, chainId, deadline, recipient])

  const feeOnTransferParameters = useMemo(() => {
    if (
      !trade ||
      !recipient ||
      !chainId ||
      !deadline ||
      trade.tradeType !== TradeType.EXACT_INPUT
    ) {
      return undefined
    }
    return trade.swapCallParameters({
      allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
      deadline: deadline.toNumber(),
      feeOnTransfer: true,
      recipient
    })
  }, [trade, allowedSlippage, chainId, deadline, recipient])


  console.log("useSwapCallArguments", defaultParameters, feeOnTransferParameters)
  return { defaultParameters, feeOnTransferParameters }
}

export default useSwapCallArguments
