import {
    HStack,
    Skeleton,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    Text,
    VStack
} from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'

interface TokenBalanceCardProps {
    address: string
    symbol?: string
    amount?: string
    amountUsd?: string
    isLoading?: boolean
}

const TokenBalanceCard = ({ address, symbol, amount, amountUsd, isLoading }: TokenBalanceCardProps) => {
    return (
        <Stat variant={'positive'}>
            <HStack flex="1" spacing={2}>
                <CurrencyLogo
                    address={address}
                    symbol={symbol}
                    boxSize={8}
                />
                <VStack spacing={0} align="flex-start">
                    <HStack flex="1" spacing={2}>
                        <Skeleton isLoaded={!isLoading}>
                            <Text fontSize="md" fontWeight="bold">
                                {amount}
                            </Text>
                        </Skeleton>
                        <StatLabel fontSize="md" fontWeight="bold">{symbol}</StatLabel>
                    </HStack>
                    {amountUsd ? (
                        <Text fontSize="md" color="textSecondary">
                            {amountUsd}
                        </Text>
                    ) : null}
                </VStack>
            </HStack>
        </Stat>
    )
}

export default TokenBalanceCard
