export interface TokenInfo {
  chainId: number
  decimals: number
  logoURI: string
  name: string
  symbol: string
  tags: TokenInfoTag[]
  address?: `0x${string}`
}

export enum TokenInfoTag {
  MANTLE = 'Mantle',
  DEFI = 'DeFi',
  GAMEFI = 'GameFi',
  LARGE_CAP = 'Large Cap',
  STABLECOINS = 'Stablecoins',
  TOP_TOKENS = 'Top Tokens',
  USER = 'My Tokens'
}
