import {
  Box,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import { Token } from '@fusionx/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import Web3Button from 'components/Web3Button'
import { BigNumber, ethers } from 'ethers'
import { useSJoeClaimRewards } from 'hooks/stake/useSJoeStaking'
import React from 'react'
import { formattedNum } from 'utils/format'
import { claimSJoe } from 'utils/measure'

interface SJoeClaimPanelProps {
  isLoading?: boolean
  joePriceUSD?: number
  joeToken?: Token
  pendingRewards?: BigNumber
  rewardToken?: Token
  rewardTokenPriceUSD?: number
  sJoeBalance?: BigNumber
}

const SJoeClaimPanel = ({
  isLoading,
  joePriceUSD,
  joeToken,
  pendingRewards,
  rewardToken,
  rewardTokenPriceUSD,
  sJoeBalance
}: SJoeClaimPanelProps) => {
  const { claim, isClaiming } = useSJoeClaimRewards()

  const fmtStakedBalance = sJoeBalance
    ? ethers.utils.formatEther(sJoeBalance)
    : ''
  const fmtRewardsBalance = pendingRewards
    ? ethers.utils.formatUnits(pendingRewards, rewardToken?.decimals)
    : ''

  const stakedAmountUSD = joePriceUSD
    ? Number(fmtStakedBalance) * joePriceUSD
    : undefined
  const rewardAmountUSD = rewardTokenPriceUSD
    ? Number(fmtRewardsBalance) * rewardTokenPriceUSD
    : undefined

  return (
    <Box w="full" p={6} bg="bgTertiary" borderRadius="xl">
      <SimpleGrid gap={2} columns={2}>
        <Heading size="sm">Staked Balance</Heading>
        <Heading size="sm">Pending Rewards</Heading>
        <HStack align="flex-start">
          <CurrencyLogo
            mt="2px"
            address={joeToken?.address}
            symbol={joeToken?.symbol}
            boxSize={6}
          />
          <VStack spacing={0} align="flex-start">
            <Skeleton isLoaded={!isLoading}>
              <Flex align="center" gap={2}>
                <Text fontSize="lg" fontWeight="bold">
                  {`${formattedNum(fmtStakedBalance)} ${joeToken?.symbol}`}
                </Text>
              </Flex>
            </Skeleton>
            {stakedAmountUSD ? (
              <Text fontSize="sm" color="textSecondary">
                {formattedNum(stakedAmountUSD, true)}
              </Text>
            ) : null}
          </VStack>
        </HStack>
        <HStack align="flex-start">
          <CurrencyLogo
            mt="2px"
            address={rewardToken?.address}
            symbol={rewardToken?.symbol}
            boxSize={6}
          />
          <VStack spacing={0} align="flex-start">
            <Skeleton isLoaded={!isLoading}>
              <Text fontSize="lg" fontWeight="bold">
                {`${formattedNum(fmtRewardsBalance)} ${rewardToken?.symbol}`}
              </Text>
            </Skeleton>
            {rewardAmountUSD ? (
              <Text fontSize="sm" color="textSecondary">
                {formattedNum(rewardAmountUSD, true)}
              </Text>
            ) : null}
          </VStack>
        </HStack>
      </SimpleGrid>

      <Web3Button
        colorScheme="accent"
        variant="primary"
        size="xl"
        w="full"
        marginTop={6}
        isDisabled={!Number(fmtRewardsBalance) || !claim}
        isLoading={isClaiming}
        loadingText="Claiming Rewards"
        onClick={() => {
          claim?.()
          claimSJoe()
        }}
      >
        Claim Rewards
      </Web3Button>
    </Box>
  )
}

export default SJoeClaimPanel
